<template>
    <div	class="batch_container"	v-loading="is_loading">
		<!-- 这边就是把批处理全部的类别都给你显示出来 -->
		<top_navigator		:sort_data_list ="sort_data_list"	:current_index="current_index"	
				@choose_sort="choose_sort"	@refresh="initialize_data"></top_navigator>
		<!-- 这里就是菜单列表，就是很多按钮的那个地方 -->
		<div	class="menu_list"		v-if="current_index != -1">
			<el-button	class="button"	type="primary"		@click="()=>{is_show.config_dialog = true;}" :disabled="is_loading_config">批量修改配置</el-button>
			<edit_button	v-if="current_index!=0"	:batch_item = "sort_data_list[current_index]"	
					@refresh="initialize_data"></edit_button>
			<el-button	class="button"	type="primary"		@click="$emit('order_by_today_uv')">切换今日uv降序</el-button>
			<el-button	class="button"	type="primary"		@click="$emit('order_by_yesterday_uv')">切换昨日uv降序</el-button>
			<el-button	class="button"	type="primary"		@click="$emit('order_by_time')">切换时间降序</el-button>
			<el-button	class="button"	type="primary"		:disabled="current_index == 0"	@click="sort_move('up')">向前排序</el-button>
			<el-button	class="button"	type="primary"		:disabled="current_index == sort_data_list.length-1"	
					@click="sort_move('down')">向后排序</el-button>
			<el-button	class="button"	type="primary"		@click="reset_batchClass">切换为全部</el-button>
			<el-button type="primary"	
				@click="change_yumma_status"		
				style="margin-left: 10px;">
				{{is_show_service_status ? '现在展示其他内容' : (is_show_yumma_status?'现在展示云码':'现在展示提报') }}<i class="el-icon-sort"></i>
			</el-button>
			<el-button	class="button"	type="primary"		@click="refresh_list_keyword">刷新关键词</el-button>
      <el-button type="primary"
                 @click="change_service_status"
                 style="margin-left: 10px;">
        {{is_show_service_status?'现在展示服务提报数据':'现在展示其他内容'}}<i class="el-icon-sort"></i>
      </el-button>
			<!-- <label	style="margin-top: 10px;">小程序内容：</label>
			<el-select		:value="app_content.current"	placeholder="请选择勾选程序的实际内容"	@change="change_app_content">
				<el-option		v-for="(item,index) in app_content.options"	:key="index"		:value="item.value"	:label="item.name"></el-option> 
			</el-select> -->
		</div>
		<div	class="menu_list"		v-if="current_index != -1">
			<el-select		
					:value = "small_routine_batch_id"	
					v-if="select_small_routines.length != 0"	
					style="margin-left: 10px;"
					@change="batch_change_prop('batchClassId',$event,'小程序分类')"	
					placeholder="请选择勾选程序的分类"
				>
				<el-option		v-for="(item,index) in sort_data_list"	:key="index"		:value="parseInt(item.id)"	:label="item.name"></el-option>
			</el-select>
			<el-select		
				:value = "icp_status"	
				v-if="select_small_routines.length != 0"	
				style="margin-left: 10px;"
				@change="batch_change_prop('icpStatus',$event,'icp状态')"	
				placeholder="请选择勾选icp状态"
				>
				<el-option		
					v-for="(item,index) in icp_status_name_map_value"	
					:key="index"		
					:value="index"	
					:label="item"
					>
				</el-option> 
			</el-select>
		</div>
		<!-- 下面是弹窗，就是配置的弹窗 -->
		<config_dialog	v-if="is_show.config_dialog" 	@close="()=>{is_show.config_dialog = false;}"	
				:appletType="appletType"		@fresh_batch_class= "initialize_data"
				:sort_data_list ="sort_data_list"	:current_index="current_index"
        :appletTypeList = "appletTypeList"
    ></config_dialog>
    </div>
</template>

<script>
import axios from 'axios';
import top_navigator from './batch_navigator/top_navigator.vue'
import config_dialog from './batch_navigator/config_dialog.vue'
import edit_button from './batch_navigator/edit_button.vue'
import { handleResponse } from '@/services/wCommonFunction.js'
export default{
    components:{
        top_navigator,config_dialog,edit_button
    },
    props: {
        appletType:{type:String},	//模板类别
		select_small_routines:{type:Array},	//勾选框的选中的小程序
		is_show_yumma_status:{type:Boolean},
		icp_status_name_map_value:{type:Object},
      register: {type: String}, // 未备案/已备案
      appletTypeList:{type:Array}, //选中的第三方（list.vue页面中选择第三方多选项）
      is_show_service_status:{type:Boolean}, //是否展示服务提报数据
    },
    data() {
        return {
			sort_data_list:[],	//批处理的所有类别
			current_index:-1,		//当前选中的类别
			is_show:{		//是否展示
				config_dialog:false,		//右边的内容
			}	,
			is_loading:true,//组件是否在加载
			is_loading_config:true,
			class_id_map_name:{},
			small_routine_batch_id:null,	//所选程序的分组（批处理类）id
			icp_status:null,	//所选程序的icp状态
			app_content:{
				options:[
					{name:"全部",value:null},
					{name:'红包',value:'redpacket'},
					{name:"记账本",value:'accountBook'},
					{name:"扭蛋机",value:'lotteryEgg'}
				],
				name_map_value:{
					"redpacket":"红包",
					"accountBook":"记账本",
					"lotteryEgg":"扭蛋机",
				},
				current:null,
			},
          password: "", //密码
        }
    },
    methods:{
		// 初始化
        async	initialize_data(){	
			this.is_loading = true ;
        	// 加载所有的批处理的类
			// let url = this.$httpUrl+"tb-third-party-wang-batch-class/listWithoutConfigByAppletType?appletType="+(this.appletType==undefined?"":this.appletType) ;
			//Yang  新的接口：多了 register 参数（未备案/已备案）
      let url = this.$httpUrl+"tb-third-party-wang-batch-class/listWithoutConfigByAppletTypeFilterNoAppIcpStatus?" + "icpStatus="+this.register + "&appletType="+(this.appletType==undefined?"":this.appletType) ;
        	const {data:{message:result}}= await axios.get(url);
			// if(this.appletType == undefined ){	//如果左边的导航栏选择全部的话
			// 	this.sort_data_list = result ;
			// }else{	//如果左边的导航栏选的是正常的类别的话，比如红包 、平安银行等
			// 	this.sort_data_list =result.filter(item=>item.appletType==this.appletType||item.appletType == "-1");
			// }
			// if(this.appletType != undefined){
			// 	this.sort_data_list = [{id:-1,name:'未分配',appletType:this.appletType}];
			// }
			this.sort_data_list=result;
          // console.log("this.sort_data_list", this.sort_data_list)
        	this.is_show.right_content = true;
			this.class_id_map_name = {	};
			for(let item of this.sort_data_list){
				this.class_id_map_name[item.id] = item.name;
			}
			if(this.current_index != -1){
				this.choose_sort(this.current_index);
			}
			// this.$emit("submit_batch_classes",this.class_id_map_name);
			this.$emit("submit_batch_classes",this.sort_data_list); //传递数据给 list 父组件
			this.is_loading = false;
        },
		// 选择 批处理类别 batchClass类别，入参就是下标，不是id号
		async choose_sort(index){
			this.current_index =index ;
			this.$emit("choose_batch_class",this.sort_data_list[this.current_index].id);
			//更新配置
			this.is_loading_config = true;
			let res =await axios
				.get(this.$httpUrl+"tb-third-party-wang-batch-class/getById?id="+this.sort_data_list[index].id);
			this.sort_data_list[index].configurationJson =	handleResponse(res).configurationJson;
			this.is_loading_config = false ;
		},
		
		// 批处理类别的移动，一般分为向前移动和向后移动就是入参direction
		// 向前就是up，向后就是down
		async	sort_move(direction){
			let current_item = this.sort_data_list[this.current_index];
			let exchange_item = null;
			if(direction == "down"){
				exchange_item = this.sort_data_list[this.current_index+1];
			}else if(direction == "up"){
				exchange_item = this.sort_data_list[this.current_index-1];
			}else{
				return ;
			}
			const	{data:{message:result}} =	await axios({
				url:this.$httpUrl+"tb-third-party-wang-batch-class/exchangeSort",
				method:"put",
				data:{
					batchClassId1:current_item.id,
					batchClassId2:exchange_item.id
				}
			})
			if(result == true){
				this.$message.success("更改成功");
				this.initialize_data();
				if(direction == "down"){
					this.current_index ++ ;
				}else{
					this.current_index -- ;
				}
			}else{
				this.$message.error("更改失败");
			}
		},
		reset_batchClass(){
			this.current_index = -1 ;
			this.$emit("choose_batch_class",'');
		},
		//改变多个小程序的批处理id
		batch_change_prop(prop,value,name){
			let prompt = '将更换'+name+', 是否继续?' ;
			this.$confirm(prompt, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async ()=>{
          // console.log("prop, value 是什么",prop, value);
          // 如果是审核状态 和 未备案/已备案的状态下，弹出密码框
          if (prop==='icpStatus' && (value==='NOT_REGISTER' || value==='REGISTER_SUCCESS')) {
            await this.open();
          }
         // console.log( this.password)
          this.updateSmallRoutineByIds(prop, value, this.password);
        }
			).catch(() => {
				this.$message({type: 'info',message: '已取消'});          
			});
		},
    // 弹出层，密码框
    async open() {
      let passwordValue; // 用于存储密码输入框的值
      await this.$prompt('请输入密码', '需要密码', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        passwordValue = value; // 用户点击确定时，保存密码值
        this.$message({
          type: 'success',
          message: '已输入密码: ' + value,
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      }).finally(() => {
        this.password = passwordValue; // 使用 finally 确保密码值被设置
      });
    },
		//改变多个小程序的 实际内容
		async	change_app_content(event){
			this.app_content.current =event ;
			if(this.select_small_routines.length == 0	|| this.app_content.current ==null ) {
				this.$emit("choose_app_content",this.app_content.current);
			}else{
				this.$confirm('将更换为 '+this.app_content.name_map_value[this.app_content.current]+' 类, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then( 
					async	()=>{
						await	this.updateSmallRoutineByIds("appContent", this.app_content.current);
						this.app_content.current = null;
						this.$emit("choose_app_content",this.app_content.current);
					}
				).catch(() => {
					this.$message({type: 'info',message: '已取消'});          
				});
			}
		},
		//更改小程序表的一些字段，注意是小程序表，表名TbThirdPartySmallRoutine
		//这里prop是字段名，要求类型String
		//prop_value是这个字段名下的值
		async updateSmallRoutineByIds(prop,prop_value, password){
			let send_data = this.select_small_routines.map(item=>{
				let return_item = {"id":item.id, "appletType":this.appletType	};
				return_item[prop]  = prop_value ;
				return return_item;
			})
			let 	{data:{message:result}} =	await axios({
				// url:this.$httpUrl+"tb-third-party-wang-batch-class/updateSmallRoutinesByIds?password=" + password,
				url:this.$httpUrl+"tb-third-party-wang-batch-class/updateSmallRoutinesByIds?register=" + this.register + "&password=" + password,
				method:"put",
				data:{smallRoutines:send_data}
			})
			if(result == true){
				this.$emit("fresh_data");
				this.initialize_data();
				return 	this.$message({type: 'success',message: '更改成功 !'});
			}
			this.$message({type: 'error',message: '更改失败 !'});
		},
		change_yumma_status(){
			this.$emit("update:is_show_yumma_status",!this.is_show_yumma_status);
      this.$emit("update:is_show_service_status",false);
		},

		//刷新一整排的小程序的关键词
		refresh_list_keyword(){
			this.$emit("refresh_list_keyword");
		},
      change_service_status(){
        this.$emit("update:is_show_service_status",!this.is_show_service_status);
        this.$emit("update:is_show_yumma_status",false);
      }
    },
    computed:{
		
	},
	watch:{
		appletType(){
			this.initialize_data();
		},
    register() { //侦听 已备案/未备案 状态的变化
      this.initialize_data();
    }
	},
    created(){
		this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
	.batch_container{
		.menu_list{
			margin-top: 10px;
			display: flex;	
		}
		.button{
			margin-right: 20px;
		}
	}
</style>