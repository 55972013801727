<template>
  <div class="div_home">
    <list v-show="showListVisible" :showMessage="showMessage" />
    <!-- <message v-show="!showListVisible" :showList="showList" /> -->
  </div>
</template>

<script>
import list from "./list.vue";
// import message from "./message.vue";
export default {
  components: {
    list,
    // message,
  },
  data() {
    return {
      showListVisible: true,
      showSingleMes: {},
    };
  },
  created() {},
  methods: {
    showMessage(e) {
      this.showListVisible = false;
      this.showSingleMes = e;
    },
    showList() {
      this.showListVisible = true;
      this.showSingleMes = {};
    },
  },
};
</script>

<style lang="less" scoped></style>
