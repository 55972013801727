// 接口请求地址
import axios from 'axios'

/**
 * 获取所有小程名称与id序列
 * @param {*} params
 */
export async function getAllSmallRoutineList() {
  const { data: res } = await axios.get(
    `/thirdPartySmallRoutine/getAllAppIdAndNameList`
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
/**
 * 获取所有小程序的类别列表，即appletType，这是用来给第三方用的
 * @param {*} params
 */
export async function getAllSmallRoutineTypeList() {
	const { data: res } = await axios({
		method:'get',
		url:'/thirdPartySmallRoutineType/getAllType',
		headers: { Authorization: window.sessionStorage.getItem("token") },
	})
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}