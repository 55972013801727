<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 图片 -->
      <el-form-item label="图片" prop="imageUrl">
        <el-upload
          ref="upload"
          :auto-upload="false"
          class="avatar-uploader"
          :action="$httpUrl + 'smallRoutine3BottomCommodity/add'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="uploadChange"
          :data="{
            ...addForm,
            parentId: parentId,
            menuOneId: menuOneId,
          }"
          :headers="headers"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="介绍" prop="mes">
        <el-input
          type="textarea"
          :rows="5"
          v-model="addForm.mes"
          placeholder="请输入介绍"
        >
        </el-input>
      </el-form-item>
      <!-- 排序 -->
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model="addForm.sort"
          placeholder="请输入排序,没填写默认99999999,值越小越靠前"
          maxlength="8"
          show-word-limit
          clearable
          @input="sortFormat"
        ></el-input>
      </el-form-item>
      <!-- 是否售卖 -->
      <el-form-item label="是否售卖" prop="sale">
        <el-switch v-model="addForm.sale"> </el-switch>
      </el-form-item>
      <!-- 售卖路径 -->
      <el-form-item label="售卖路径" prop="saleUrl">
        <el-input
          v-model="addForm.saleUrl"
          placeholder="请输入售卖路径"
          maxlength="255"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "parentId", "menuOneId"],
  data() {
    return {
      imageUrl: "",
      addForm: {
        name: "",
        mes: "",
        sort: "",
        sale: false,
        saleUrl: "",
        status: true,
      },
      // 表单验证
      addFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      disabled: false,
      headers: { Authorization: window.sessionStorage.getItem("token") },
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.imageUrl = "";
      this.$refs["addFormRed"].resetFields();
      // 清除文件历史记录
      this.$refs.upload.clearFiles();
    },
    // 点击提交
    toAdd() {
      let addForm = this.addForm;
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.isNull(this.imageUrl)) {
          return this.$message.error("请选择图片");
        }
        if (addForm.sale && this.isNull(addForm.saleUrl)) {
          return this.$message.error("商品售卖状态开启，路径不能为空");
        }
        this.disabled = true;
        this.$refs.upload.submit();
      });
    },
    // 图片上传
    handleAvatarSuccess(res) {
      this.disabled = false;
      //上传成功之后清除历史记录
      this.$refs.upload.clearFiles();
      if (res.code != 200) {
        return this.$message.error("文件上传失败：" + res.message);
      }
      this.$message.success("保存成功");
      let that = this;
      setTimeout(function () {
        that.resetForm();
      }, 100);
      this.refaushList();
    },
    // 图片格式与大小校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG || isGIF) && isLt2M;
    },
    // 选择图片
    uploadChange(file, fileList) {
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.upload.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      this.imageUrl = url;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
    // 排序正则表达式
    sortFormat(e) {
      this.addForm.sort = this.addForm.sort.match(/^\d*(\d{0,0})/g)[0] || null;
      if (isNaN(this.addForm.sort)) {
        this.addForm.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.addForm.sort.length >= 2) {
        this.addForm.sort = this.addForm.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
