<template>
  <div>
    <!-- 菜单列表 -->
    <menuList
      v-show="showType == 1"
      :showList="showList"
      :showSingleMes="showSingleMes"
      :showServiceRel="showServiceRel"
      :showMessageTemplate="showMessageTemplate"
      :showMessageGaoDeTemplate="showMessageGaoDeTemplate"
    />
  </div>
</template>

<script>
import menuList from "./menuList.vue";
export default {
  name: "index",
  props: ["showList", "showSingleMes"],
  components: {
    menuList,
  },
  data() {
    return {
      showType: 1,
    };
  },
  methods: {
    showMenuList() {
      this.showType = 1;
    },
    showServiceRel() {
      this.showType = 2;
    },
    showMessageTemplate() {
      this.showType = 3;
    },
    showMessageGaoDeTemplate() {
      this.showType = 4;
    },
  },
};
</script>

<style lang="scss" scoped></style>
