// 公共方法
export default {
  /**
   * 字符串是否为空
   * @param {Object} e
   */
  isNull(e) {
    // return (e == null || e == undefined || e == '' || e == 'undefined') && e!=0 && e!='0';
    return (
      e == null || e == undefined || (typeof e == "string" && e == "") || e == "undefined"
    );
  },
  /**
   * 发起请求
   * @param {*} this 默认传入this
   * @param {*} func 函数
   * @param {*} data 参数
   */
  async request(that, func, data) {
    const loading = that.$loading({
      lock: true,
      text: "Loading",
      spinner: "el-icon-loading",
    });
    // 如果超过指定时间无响应，则关闭加载中
    const setTimeoutId = setTimeout(function () {
      that.$message.error("连接服务器超时");
      loading.close();
    }, that.$lodingMaxTime);
    const res = await func(data);
    clearTimeout(setTimeoutId);
    loading.close();
    return res;
  },

};
