<template>
  <!-- 周边服务 -->
  <div>
    <el-form label-width="70px">
      <el-form-item label="周边类型">
        <el-select
          v-model="poiType[0]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="poiType[1]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="poiType[2]"
          filterable
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in poiList"
            :key="item.key"
            :label="item.value"
            :value="item.key"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="toSave">确 定</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "smallRoutine3PeripheralService",
  props: ["updateId", "poiList"],
  data() {
    return {
      poiType: ["", "", ""],
      id: "",
    };
  },

  mounted() {},

  created() {
    this.refaushForm(this.updateId);
  },
  methods: {
    // 刷新编辑对象
    async refaushForm(id) {
      this.id = id;
      const { data: res } = await this.$http.get(
        "smallRoutine3/getPoiById?id=" + id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.poiType = res.message;
    },
    async toSave() {
      const { data: res } = await this.$http.put(
        "smallRoutine3/updatePoiIdById",
        {
          id: this.id,
          poiType: this.poiType,
        }
      );
      // 返回结果
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
