<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="100"
          show-word-limit
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="后台路径" prop="url">
        <el-input
          v-model="form.url"
          maxlength="200"
          show-word-limit
          placeholder="请输入后台路径"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          保存
        </el-button>
        <el-button @click="resetForm('formRef')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import {
  getUpdateMesById,
  update,
} from "@/services/shortLink/shortLinkService";
export default {
  props: ["refreshData", "id", "visible"],
  name: "update",
  data() {
    return {
      form: {
        id: "",
        name: "",
        url: "",
        status: true,
      },
      // 表格提交验证
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入后台路径", trigger: "blur" }],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.getMesById();
    },
    async getMesById() {
      let id = this.id;
      const res = await getUpdateMesById(id);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      // 获取小程序分类对应的列表
      this.form = res.data;
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await update(this.form);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
      // 刷新商品页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
