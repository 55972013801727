<template>
  <div>
    <el-table :data="pagedServiceList">
      <el-table-column
          v-for="(item,index) in columns"
          :width="item.width==undefined?160:item.width"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :row-class-name="tableRowClassName"
      >
      </el-table-column>
      <!-- 审核时间列 -->
      <el-table-column width="160" label="审核时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.gmtCreate) }}
        </template>
      </el-table-column>

      <!-- 修改时间列 -->
      <el-table-column width="160" label="修改时间">
        <template slot-scope="scope">
          {{ formatDate(scope.row.gmtModified) }}
        </template>
      </el-table-column>
      <el-table-column width="100" label="当前状态">
        <template slot-scope="scope">
          {{ scope.row.auditStatus ? status_map[scope.row.auditStatus].label : '审核中' }}
        </template>
      </el-table-column>
      <el-table-column width="130" label="操作">
        <template slot-scope="scope">
          <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              style="margin-left: 10px;"
              @click="show_delete_confirm(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex'

export default {
  components: {},
  props: {
    appId: {
      type: String,
    },
    serviceList: {
      type: Array,
    }
  },
  data() {
    return {
      columns: [
        {label: '审核完成的服务', prop: 'serviceName',},
        {label: '审核原因', prop: 'auditText', width: 500},
      ],
      status_map: {
        'AGREE': {label: '审核通过'},
        'REJECT': {label: '审核驳回'},
      },
      currentPage: 1,
      pageSize: 10,
      total: 0,
    }
  }, methods: {
    // 页码变化处理
    handleCurrentChange(newPage) {
      this.currentPage = newPage;
    },

    // 每页条数变化处理
    handleSizeChange(newSize) {
      this.pageSize = newSize;
      this.currentPage = 1; // 改变每页条数后，重置到第一页
    },
    // 时间格式化方法
    formatDate(dateString) {
      if (!dateString) return '';  // 处理空值
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const seconds = date.getSeconds().toString().padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    tableRowClassName({row, rowIndex}) {
      if (row.status === 'REJECT') {
        return 'warning-row';
      } else if (row.status === 'AGREE') {
        return 'success-row';
      }
      return 'warning-row';
    },
    async show_delete_confirm(row) {
      let alertString = '此操作将删除该服务' + row.serviceName + ', 是否继续?'
      this.$confirm(alertString, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(async () => {
            await this.delete_service(row);
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
    },
    //删除关键词
    async delete_service(row) {
      let url = this.global_url + "thirdParty2/serviceDelete"
      let send_data = {
        appId: row.appId,
        serviceCode: row.serviceCode
      }
      const res = await axios({
        method: "DELETE",
        url: url,
        data: send_data,
      })
      if (res.data.message === "成功" && res.data.code === 200) {
        this.$message.success("删除成功");
        setTimeout(
            () => {
              this.$emit("refresh_service", this.appId);
            },
            1000
        )
      } else {
        // 响应失败时的处理
        this.$message.error(`删除失败: ${res.data.message}`);
      }
    },
  }, computed: {
    ...mapState({
      global_url: state => state.global_base_url.main
    }),
    // 计算分页后的列表
    pagedServiceList() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.serviceList.slice(start, end);
    }
  },
  watch:{
    serviceList: {
      immediate: true,
      handler(newVal) {
        this.total = newVal.length; // 设置总条数
      }
    }
  },
}
</script>

<style scoped lang="less">

</style>