<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="5">
            <div class="div_input">
              <label class="div_input_text">用户id:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.query"
                clearable
                @clear="getList"
              >
              </el-input>
            </div>
          </el-col>
          <!-- 小程序选择 -->
          <el-col :span="5">
            <div class="div_input">
              <label class="div_input_text">小程序选择:</label>
              <el-select
                v-model="queryInfo2.smallCode"
                clearable
                placeholder="请选择"
                @change="choodeSmallUpdate"
              >
                <el-option
                  v-for="item in smallRoutine.list"
                  :key="'userMneyLog_' + item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="search()" icon="el-icon-search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="用户id" prop="userId"></el-table-column>
        <el-table-column label="余额变更" prop="price"></el-table-column>
        <el-table-column label="变更原因" prop="typeText"></el-table-column>
        <el-table-column label="变更时间" prop="gmtCreate"></el-table-column>
        <el-table-column label="小程序标识" prop="code"></el-table-column>
        <el-table-column label="时间" prop="gmtCreate"></el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 默认展示模块
      activeIndex: "1",
      // 搜索内容
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 搜索内容(点击搜索后将queryInfo值赋予该对象)
      queryInfo2: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      //   商品列表
      pageMes: {
        list: [],
        total: 0,
      },
      addDialogVisible: false,
      updateDialogVisible: false,
      updateId: {},
      smallRoutine: {
        list: [],
        codeList: [],
      },
    };
  },
  methods: {
    //点击搜索
    async search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = queryInfo2;
      if (queryInfo.smallCodeList.length == 0) {
        let smallRoutine = this.smallRoutine;
        queryInfo.smallCodeList.push(...smallRoutine.codeList);
      }
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post("/userPriceLog/list", {
        ...this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 查询小程序变动
    choodeSmallUpdate(e) {
      let choose;
      if (e == "") {
        choose = [];
      } else {
        choose = [e];
      }
      this.queryInfo.smallCodeList = choose;
    },
    // 初始化小程序信息
    initSmallRoutine(e) {
      this.smallRoutine = e;
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .div_input {
    display: flex;
    align-items: center;
    .div_input_text {
      white-space: nowrap;
      padding-right: 3px;
    }
  }
}
</style>
