// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutine/';

/**
 * 获取所有小程名称与id序列
 * @param {*} params
 */
export async function getAllIdAndNameList() {
  const { data: res } = await axios.get(
    path+'getAllIdAndNameListType1'
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取所有小程名称与id序列失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 复制版本信息
 * @param {*} params
 */
 export async function smallRoutineCopyModular(data={}) {
  const { data: res } = await axios.post(
    path+'smallRoutineCopyModular',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "复制版本信息失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}



