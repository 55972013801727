<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加角色
            </el-button>
          </el-col>
          (您当前角色等级为：{{ roleGrade }})
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="roleList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="等级" prop="grade"></el-table-column>
        <el-table-column
          label="所属公司"
          prop="companyName"
          v-if="'1' == roleGrade"
        ></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="roleStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑角色信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除角色"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteRole(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增角色信息 -->
    <el-dialog
      title="添加角色"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="70px"
      >
        <!-- 角色 -->
        <el-form-item label="角色" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="角色名称"
            maxlength="20"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 等级 -->
        <el-form-item label="等级" prop="grade">
          <el-input
            v-model="addForm.grade"
            clearable
            maxlength="8"
            show-word-limit
            placeholder="角色等级(数值越小权限越高)"
            @input="sortFormat"
          ></el-input>
        </el-form-item>
        <!-- 公司 -->
        <el-form-item label="公司" prop="company" v-if="'1' == roleGrade">
          <el-select
            filterable
            v-model="addForm.company"
            placeholder="请选择所属公司"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="'userRole_' + index"
              :label="item.name + '(' + item.contacts + ')'"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 默认菜单 -->
        <el-form-item label="默认菜单" prop="menu">
          <el-transfer
            style="text-align: left; display: inline-block"
            filterable
            :render-content="renderFunc"
            :titles="['未授权菜单', '已授权菜单']"
            :button-texts="['取消', '授权']"
            filter-placeholder="请输入搜索的菜单名"
            v-model="addForm.menu"
            :data="permissionList"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addRole">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑角色信息 -->
    <el-dialog
      title="编辑角色信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 角色 -->
        <el-form-item label="角色" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="角色名称"
            maxlength="20"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 等级 -->
        <el-form-item label="等级" prop="grade">
          <el-input
            v-model="editForm.grade"
            clearable
            maxlength="8"
            show-word-limit
            placeholder="角色等级(数值越小权限越高)"
            @input="sortFormat"
          ></el-input>
        </el-form-item>
        <!-- 默认菜单 -->
        <el-form-item label="默认菜单" prop="menu">
          <el-transfer
            style="text-align: left; display: inline-block"
            filterable
            :render-content="renderFunc"
            :titles="['未授权菜单', '已授权菜单']"
            :button-texts="['取消', '授权']"
            filter-placeholder="请输入搜索的菜单名"
            v-model="editForm.menuIdList"
            :data="permissionList"
          >
          </el-transfer>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  created() {
    this.roleGrade = window.sessionStorage.getItem("roleGrade");
    this.getMenuList();
    this.getRoleList();
    this.getCompanyList();
  },
  data() {
    return {
      roleGrade: "", //角色等级
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      companyList: [], //公司列表
      roleList: [], // 角色列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      //添加表单信息
      addForm: {
        name: "",
        grade: "9999999",
        company: "",
        menu: [],
      },
      // 新增表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        grade: [
          { required: true, message: "请输入角色等级", trigger: "blur" },
          {
            min: 1,
            max: 8,
            message: "长度在 1 到 8 个正整数长度",
            trigger: "blur",
          },
        ],
      },
      //修改信息
      editForm: {},
      //显示/隐藏修改栏
      editDialogVisible: false,
      //表单验证
      editFormRules: {
        name: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        grade: [
          { required: true, message: "请输入角色等级", trigger: "blur" },
          {
            min: 1,
            max: 8,
            message: "长度在 1 到 8 个正整数长度",
            trigger: "blur",
          },
        ],
      },
      // 授权人拥有的菜单列表
      permissionList: [],
      // 加载中
      loadingPermission: false,
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getRoleList();
    },
    // 获取当前角色拥有的菜单列表
    async getMenuList() {
      let { data: res } = await this.$http.get("menu/getPossessMenuList");
      if ("200" != res.code) {
        return this.$message.error("获取菜单列表失败:" + res.message);
      }
      let permissionList = res.message;
      this.permissionList = permissionList;
    },
    // 获取公司列表
    async getCompanyList() {
      const { data: res } = await this.$http.get("company/getAllCompany");
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.companyList = res.message;
    },
    // 获取角色
    async getRoleList() {
      const { data: res } = await this.$http.get("role/list", {
        params: this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.roleList = res.message.records;
      this.total = res.message.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getRoleList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getRoleList();
    },
    // 修改角色状态
    async roleStateChange(e) {
      const { data: res } = await this.$http.put(`/role/updateState`, {
        id: e.id,
        status: e.status,
      });
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    //监听添加
    addDialogClose() {
      this.$refs.addFormRed.resetFields();
    },
    // 添加
    async addRole() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const { data: res } = await this.$http.post("role/add", this.addForm);
        if ("200" != res.code) {
          return this.$message.error("操作失败:" + res.message);
        }
        this.$message.success("操作成功");
        // 隐藏添加弹出层
        this.addDialogVisible = false;
        //刷新表格
        this.getRoleList();
      });
    },
    // 删除角色信息
    async deleteRole(id) {
      const result = await this.$confirm(
        "此操作将永久删除角色,是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancleButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete("role/del?id=" + id);
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getRoleList(); //刷新表格
    },
    //更改角色信息对话框
    async showEditDialog(e) {
      const { data: res } = await this.$http.get(
        "role/getUpdateRoleById?id=" + e.id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.editForm = res.message;
      this.editDialogVisible = true; //显示对话框
    },
    //关闭修改窗口
    editDialogClose() {},
    //确认修改
    editUser() {
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const { data: res } = await this.$http.put(
          "role/update",
          this.editForm
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
        // 隐藏添加弹出层
        this.editDialogVisible = false;
        //刷新表格
        this.getRoleList();
      });
    },
    // 权限修改
    renderFunc(h, option) {
      // 穿梭框标签样式
      return <span>{option.label}</span>;
    },
    // 关闭授权加载中状态
    colseLoadingPermission() {
      let that = this;
      setTimeout(() => {
        if (that.loadingPermission) {
          that.loadingPermission = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
    // 数字正则表达式
    sortFormat(e) {
      let addForm = this.addForm;
      addForm.grade = addForm.grade.match(/^\d*/g)[0] || null;
      if (isNaN(addForm.grade)) {
        addForm.grade = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && addForm.grade.length >= 2) {
        addForm.grade = addForm.grade.replace(/0/, "");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
