<template>
    <div	class="whole_container">
		<div class="header">
			<el-date-picker	v-model="time_filter.value"	 value-format="timestamp"		type="daterange"
					start-placeholder="开始日期"	end-placeholder="结束日期"		range-separator="至" size="small" 
					:picker-options="time_filter.pickerOptions"	@change="change_time" 	class="component select"></el-date-picker>
		</div>
		<el-table	class="table"	:data="table_data.list">
			<el-table-column	v-for="(item,index) in  table_data.column_struct"	
				:key="index"  :label="item.label"	:width="item.width"  :prop="item.prop" ></el-table-column>
		</el-table>
		<!-- 下面的东西 -->
		<div class="foot">
			<div>总数：{{table_data.total}}</div>
			<el-pagination class="page"  background  layout="prev, pager, next" 	:current-page="table_data.current"
					:page-size="table_data.size" :total="table_data.total"		@current-change="e=>{initialize_data(e)}"></el-pagination>
		</div>
    </div>
</template>

<script>
	import store from '@/store/index.js'
	import axios from 'axios'
export default{
    components:{
        
    },
    props: {
        
    },
    data() {
        return {
            table_data:{
				list:[],
				current:1,
				size:10,
				total:0,
				column_struct:[
					{label:'手机号',width:'120',prop:"phoneNumber"},
					{label:'ip地址',width:'130',prop:"ipAddress"},
					{label:'地理位置',width:'',prop:"location"},
					{label:'时间',width:'180',prop:"createTime"},
					{label:'小程序id',width:'160',prop:"appId"},
				]
			},
			time_filter:{		//时间过滤器
				value:"",
				pickerOptions: {
					shortcuts: [
						{text: '今天',	onClick(picker) {	picker.$emit('pick', new Date());	}}, 
						{text: '昨天',	onClick(picker) {	const date = new Date();	date.setTime(date.getTime() - 3600 * 1000 * 24);	picker.$emit('pick', date);	}},
						{text: '前天',	onClick(picker) {	const date = new Date();	date.setTime(date.getTime() - 3600 * 1000 * 24 * 2);	picker.$emit('pick', date);	}},
						{text: '大前天',	onClick(picker) {	const date = new Date();	date.setTime(date.getTime() - 3600 * 1000 * 24 * 3);	picker.$emit('pick', date);	}}
					]
				},
			},
        }
    },
    methods:{
		async	initialize_data(
			current=0,
			startTimestamp=(this.time_filter.value[0]==undefined)?"":this.time_filter.value[0],
			endTimestamp=(this.time_filter.value[1]==undefined)?"":this.time_filter.value[1]){
				const {data:{message:result}} = await	axios.get(this.$httpUrl+"tb-third-party-wang-register-for-redpacket/page?"+
					"current="+current+
					"&startTimestamp="+startTimestamp+
					"&endTimestamp="+endTimestamp
				);
				this.table_data.list = result.records;
				this.table_data.total = result.total ;
				this.table_data.current = result.current;
				this.table_data.list = this.table_data.list.map((item)=>{
					let date = new Date(item.createTime);
					item.createTime = date.getFullYear() + "-"+(date.getMonth()+1)+'-'+date.getDate()+"---" ;
					let hour = date.getHours()<10 ? ("0"+date.getHours()):date.getHours() ;
					let minute = date.getMinutes()<10?"0"+date.getMinutes():date.getMinutes();
					let second = date.getSeconds()<10?"0"+date.getSeconds():date.getSeconds();
					item.createTime += hour+":"+minute+":"+second;		
					if( item.location != null && item.location!= ""){
						item.location = item.location.split("|")[1] +"---"+item.location.split("|")[2] ;
					}
					return item;
				})
		},
		change_time(e){
			console.log(e);
			this.initialize_data(this.table_data.current);
		}
    },
    computed:{
			
	},
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
	width: 800px;
	.header{
		width:100%;
		height:60px;
		display: flex;	align-items: center;
	}

	.table{
		width:800px;
	}

	.foot{
		width: 100%;
		height: 40px;
		margin-top: 14px;
		background-color: white;		border-radius: 4px;
		display: flex;
		justify-content: space-around;
		align-items: center; 
		.button{
			margin-left: 10px;
		}
	}
}
	
</style>