<template>
  <div>
    <!-- 周边类型 -->
    <el-form-item label="周边类型" prop="poiType">
      <el-select
        v-model="updateForm.poiType[0]"
        filterable
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="item in poiList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="updateForm.poiType[1]"
        filterable
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="item in poiList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="updateForm.poiType[2]"
        filterable
        clearable
        placeholder="请选择"
      >
        <el-option
          v-for="item in poiList"
          :key="item.key"
          :label="item.value"
          :value="item.key"
        >
        </el-option>
      </el-select>
    </el-form-item>
  </div>
</template>

<script>
import { getAllPoi } from "@/services/poiCode/poiCode.js";
export default {
  name: "poiChoose",
  props: ["updateForm"],
  data() {
    return {
      poiList: [],
    };
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      let res = await getAllPoi();
      console.log(res);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "poiList", res.data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
