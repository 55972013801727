<template>

  <div>
    <el-form :model="updateForm" label-width="auto">
      <el-form-item label="打开口令红包界面时是否跳转 h5" prop="isOpenPwdRedPacketPageJumpH5">
        <el-switch v-model="updateForm.isOpenPwdRedPacketPageJumpH5"></el-switch>
      </el-form-item>
      <el-form-item label="跳转的 h5 链接" prop="openPwdRedPacketPageJumpH5">
        <el-input v-model="updateForm.openPwdRedPacketPageJumpH5" placeholder="请输入打开口令红包界面时跳转的 h5 链接" clearable></el-input>
      </el-form-item>
      <el-form-item label="领取口令红包时是否跳转 h5" prop="isClickGetPwdRedPacketJumpH5">
        <el-switch v-model="updateForm.isClickGetPwdRedPacketJumpH5"></el-switch>
      </el-form-item>
      <el-form-item label="跳转的 h5 链接" prop="clickGetPwdRedPacketJumpH5">
        <el-input v-model="updateForm.clickGetPwdRedPacketJumpH5" placeholder="请输入领取口令红包时跳转的 h5 链接" clearable></el-input>
      </el-form-item>
      <el-form-item label="口令红包的规则说明" prop="pwdRedPacketRules">
        <RichEditor v-model="updateForm.pwdRedPacketRules" editor-height="250px"></RichEditor>
      </el-form-item>
    </el-form>

    <el-button icon="el-icon-chat-line-round" round @click="updateRedPacketPageJumpH5(1)" class="custom-color" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button icon="el-icon-chat-line-round" round @click="updateRedPacketPageJumpH5(2)" class="custom-color" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>
import RichEditor from "@/components/tcw/rich-editor/RichEditor.vue";

export default {
  components: {
    RichEditor
  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        batchClassList: [],

        isOpenPwdRedPacketPageJumpH5: false,
        openPwdRedPacketPageJumpH5: '',
        isClickGetPwdRedPacketJumpH5: false,
        clickGetPwdRedPacketJumpH5: '',
        pwdRedPacketRules: '',
      },
      loading: false,

    }
  },
  methods: {
    // 查询上一次数据接口
    async getRedPacketPageJumpH5() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getRedPacketPageJumpH5",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.isOpenPwdRedPacketPageJumpH5 = res.message.isOpenPwdRedPacketPageJumpH5;
        this.updateForm.openPwdRedPacketPageJumpH5 = res.message.openPwdRedPacketPageJumpH5;
        this.updateForm.isClickGetPwdRedPacketJumpH5 = res.message.isClickGetPwdRedPacketJumpH5;
        this.updateForm.clickGetPwdRedPacketJumpH5 = res.message.clickGetPwdRedPacketJumpH5;
        this.updateForm.pwdRedPacketRules = res.message.pwdRedPacketRules;
      }
    },
    // 更新数据接口
    async updateRedPacketPageJumpH5(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      if (is === 1) {
        this.updateForm.batchClassList = this.batchClassList;
        this.updateForm.tbThirdPartySmallRoutineType = null;
      } else {
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        this.updateForm.batchClassList = null;
      }

      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateRedPacketPageJumpH5",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「口令红包」配置成功");
      } else {
        this.$message.error("「口令红包」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  mounted() {
    this.getRedPacketPageJumpH5();
  }
}
</script>

<style scoped lang="less">
.text{
  font-size: 30px;
}
.custom-color {
  color: #fff;
  background-color: #4e72b8;
}
</style>