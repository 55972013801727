<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="小程序名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入小程序名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="merchantAppId">
        <el-input
          v-model="form.merchantAppId"
          maxlength="50"
          show-word-limit
          placeholder="请输入商家小程序appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="form.appAuthToken"
          maxlength="100"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板" prop="templateId">
        <el-select v-model="form.templateId" placeholder="请选择小程序模板">
          <el-option
            v-for="item in templateList"
            :key="item.id"
            :label="item.merchantName + '(' + item.id + ')'"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
        <el-button @click="resetForm('formRef')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["refreshData", "templateList", "type"],
  name: "applet1Add",
  data() {
    return {
      form: {
        name: "",
        merchantAppId: "",
        appAuthToken: "",
        templateId: "",
        status: true,
      },
      // 表格提交验证
      formRules: {
        name: [
          { required: true, message: "请输入小程序名称", trigger: "blur" },
        ],
        merchantAppId: [
          { required: true, message: "请输入模板appId", trigger: "blur" },
        ],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        templateId: [
          { required: true, message: "请选择关联的模板", trigger: "change" },
        ],
      },
    };
  },
  created() {},
  methods: {
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let url;
      switch (this.type) {
        case "1":
          url = "/thirdSmallRoutine1/add";
          break;
        case "2":
          url = "/thirdSmallRoutine2/add";
          break;
        default:
          return this.$message.error("小程序类型有误");
      }
      const { data: res } = await this.$http.post(url, this.form);
      loading.close();
      if (res.code != 200) {
        this.$message.error("上传失败:" + res.message);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新商品页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    // 获取小程序名称与标识列表
    async getSmallRoutineList(e) {
      let res;
      switch (e) {
        case "applet1":
          res = await this.$http.get("/smallRoutine/getSmallNameCodeList");
          break;
        case "applet2":
          res = await this.$http.get("/smallRoutine2/getSmallNameCodeList");
          break;
        case "applet3":
          res = await this.$http.get("/smallRoutine3/getSmallNameCodeList");
          break;
        default:
          this.$message.error("获取小程序名称与标识列表,无效标识:" + e);
          return null;
      }
      return res.data.message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
