<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 唯一标识 -->
      <el-form-item label="标识" prop="code">
        <el-input
          v-model="addForm.code"
          clearable
          maxlength="100"
          show-word-limit
          placeholder="请输入唯一标识(不可更改，不能含有中文)"
          @input="inputCheck(addForm.code)"
        ></el-input>
      </el-form-item>
      <!-- appId -->
      <el-form-item label="appid" prop="appId">
        <el-input
          v-model="addForm.appId"
          clearable
          maxlength="100"
          placeholder="请输入appid(不可更改)"
        ></el-input>
      </el-form-item>
      <!-- 应用私钥 -->
      <el-form-item label="应用私钥" prop="privateKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="addForm.privateKey"
          clearable
          maxlength="20000"
          placeholder="请输入应用私钥"
        ></el-input>
      </el-form-item>
      <!-- 公钥 -->
      <el-form-item label="应用公钥" prop="publicKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="addForm.publicKey"
          clearable
          maxlength="20000"
          placeholder="请输入应用公钥"
        ></el-input>
      </el-form-item>
      <!-- 支付宝公钥 -->
      <el-form-item label="支付宝公钥" prop="alipayPublicKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="addForm.alipayPublicKey"
          clearable
          maxlength="20000"
          placeholder="请输入支付宝公钥"
        ></el-input>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="addForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <!-- 消息模板 -->
      <el-form-item label="消息模板" prop="message">
        <el-switch v-model="addForm.message"> </el-switch>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallRoutine2Add",
  props: ["closeThis", "refaushList"],
  data() {
    return {
      addForm: {
        customerId: "",
        icon: "",
        appId: "",
        appletPath: "",
        h5Url: "",
        lifeNumber: "",
        message: false,
        status: false,
      },
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入小程序唯一标识", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序appId", trigger: "blur" },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const { data: res } = await this.$http.post("/smallRoutine2/add", {
        ...this.addForm,
      });
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
