<template>
<div		class="container">
	<div	class="mask"  v-if="is_show_mask">
		<div 	class="loading_text"	>正在加载中</div>
		<img  class="loading_icon"	src="./image/Loading.svg"	/>
	</div>
	<el-button type="primary" @click="add"	v-if="is_editable">添加</el-button>
	<el-button type="primary" @click="clear"	v-if="is_editable">清空</el-button>
	<el-table	:data="list">
		<el-table-column  label="底下的名称"  width="220">
			<template slot-scope="scope">
				<el-input v-model="scope.row.title" placeholder="名称"	clearable	:disabled="!is_editable"></el-input>
			</template>
		</el-table-column>
		<el-table-column  label="上方的图片"  width="100">
			<template slot-scope="scope">
				<image_choose	:image_url.sync="scope.row.imagePath"	:image_size="60"	prefix_path="redpacket/menu"
					:is_editable="is_editable"></image_choose>
			</template>
		</el-table-column>
		<el-table-column  label="是否开启"  width="90">
			<template slot-scope="scope">
				<el-switch	v-model="scope.row.isAlive"		:disabled="!is_editable"></el-switch>
			</template>
		</el-table-column>
		<el-table-column  label="跳转类型"  width="150">
			<template slot-scope="scope">
				<el-select v-model="scope.row.jumpType" placeholder="请选择"	:disabled="!is_editable">
					<el-option		label="h5"	value="h5"></el-option>
					<el-option		label="app"	value="app"></el-option>
					<el-option		label="页面"	value="page"></el-option>
					<el-option		label="扫一扫"	value="scan"></el-option>
				</el-select>
			</template>
		</el-table-column>
		<el-table-column  label="跳转的h5">
			<template slot-scope="scope">
				<el-input v-model="scope.row.h5Path" placeholder="https://www.baidu.com......"	clearable
					:disabled="scope.row.jumpType != 'h5'||!is_editable"></el-input>
			</template>
		</el-table-column>
		<el-table-column  label="跳转的app的appId"  width="180">
			<template slot-scope="scope">
				<el-input v-model="scope.row.appId" placeholder="202212342124......"	clearable
					:disabled="scope.row.jumpType != 'app'||!is_editable"></el-input>
			</template>
		</el-table-column>
		<el-table-column  label="小程序的某个页面">
			<template slot-scope="scope">
				<el-input v-model="scope.row.pagePath" placeholder="/page/index/index.vue......"	clearable
					:disabled="scope.row.jumpType != 'page'||!is_editable"></el-input>
			</template>
		</el-table-column>
		<el-table-column  label="排序"	width="150"	v-if="is_editable">
			<template slot-scope="scope">
				<el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
				<el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
			</template>
		</el-table-column>
		<el-table-column  label="操作"  width="80"	v-if="is_editable">
			<template slot-scope="scope">
				<el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
			</template>
		</el-table-column>
	</el-table>
</div>
</template>

<script>
import image_choose from '../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue';
export default{
    components:{
		image_choose
	},
    props: {
		//输入的表内容
		input_list:{	type:String },	
		//表的最大长度
		list_max_length:{	type:Number, default:5}	,	
		//数据是否可编辑 
		is_editable:{type:Boolean,default:true}	
    },
    data() {
        return {
			// 这个是数据列表，在created那边有初始化，对input_list就是输入进来的String格式的列表进行json的转化，
			//转化为Array类型的列表
            list:[],
			is_first_watch:true,
			is_show_mask:true,
        }
    },
    methods:{
//对数据进行增删改查的操作
		//添加一行数据
        add(){
			if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
				this.$message.error("超过长度了,无法再添加");
			}else{
				this.list.push({isAlive:true,jumpType:'h5'})	//添加
			}
        },
		//排序的名次上升，指排序的下标上升
        row_up(index){
        	let temp = this.list[index] ;
        	this.$set(this.list,index,this.list[index-1]);
        	this.$set(this.list,index-1,temp);
        },
		//排序的名次下降，指排序的下标下降
        row_down(index){
        	let temp = this.list[index];
        	this.$set(this.list,index,this.list[index+1]);
        	this.$set(this.list,index+1,temp);
        },
		//删除一行的数据
        delete_row(index){
        	this.list.splice(index,1);
        },
		//清空所有的数据
        clear(){
        	this.list =[];
        },
//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
			this.list = JSON.parse(decodeURI(this.input_list)) ;
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(){
			const  intervalTimer = setInterval(
				()=>{
					if(this.input_list != undefined){
						this.initiailize_data();
						this.is_show_mask = false;
						clearInterval(intervalTimer);
					}
				},
				100
			)
		}
    },
    computed:{
		
	},
    created(){
		this.wait_util_not_undefined();
    },
	watch:{
		//对结果进行解码，上传给父组件
		list:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",encodeURI(JSON.stringify(newValue)));
			}
		}
	}
}
</script>

<style  lang="less" scoped>
.container{
	position: relative;
	min-width: 1200px;
	max-width: 1600px;
	max-height: 410px;
	overflow-y: auto;
}
.container	.mask{
	position: absolute;
	z-index: 200;
	background-color: white;
	opacity: 0.8;
	width: 100%;
	height: 100%;
	display: flex;	justify-content: center;	align-items: center;
	.loading_text{
		font-size: 23px;
	}
	.loading_icon{
		margin-left: 6px;
		width: 30px;		height: 30px;
		animation-name: rotate;
		animation-duration: 2s;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
	@keyframes rotate {
		0%		{	transform: rotate(0deg);	}
		50%		{	transform: rotate(180deg);	}
		100%		{	transform: rotate(360deg);	}
	}
}
.container	.el-table{
	border-radius: 10px;
}
</style>