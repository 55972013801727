<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="100"
          show-word-limit
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="域名" prop="serviceId">
        <el-select
          v-model="form.serviceId"
          clearable
          placeholder="请选择域名"
          class="serviceIdChoose"
        >
          <el-option
            v-for="item in serviceList"
            :key="item.id"
            :label="item.url"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="标识" prop="code">
        <el-input
          v-model="form.code"
          maxlength="100"
          show-word-limit
          placeholder="短链接后缀,有需要自定义标识则输入,放空随机生成"
        ></el-input>
      </el-form-item> -->
      <el-form-item label="跳转路径" prop="toUrl">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="form.toUrl"
          placeholder="请输入跳转路径,需要在路径前加上http或https"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["refreshData", "id", "visible", "serviceList"],
  name: "shortLink_add",
  data() {
    return {
      form: {},
      // 表格提交验证
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        serviceId: [
          { required: true, message: "请选择域名", trigger: "change" },
        ],
        toUrl: [{ required: true, message: "请输入跳转路径", trigger: "blur" }],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.getMesById();
    },
    async getMesById() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.get(
        "shortLink/getUpdateMesById?id=" + this.id
      );
      loading.close();
      let message = res.message;
      if (res.code != "200") {
        return this.$message.error(message);
      }
      this.form = message;
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.put("shortLink/update", this.form);
      loading.close();
      if (res.code != 200) {
        this.$message.error(res.message);
        return;
      }
      this.$message.success("操作成功");
      // 刷新页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    // 获取小程序名称与标识列表
    async getSmallRoutineList(e) {
      let res;
      switch (e) {
        case "applet1":
          res = await this.$http.get("/smallRoutine/getSmallNameCodeList");
          break;
        case "applet2":
          res = await this.$http.get("/smallRoutine2/getSmallNameCodeList");
          break;
        case "applet3":
          res = await this.$http.get("/smallRoutine3/getSmallNameCodeList");
          break;
        default:
          this.$message.error("获取小程序名称与标识列表,无效标识:" + e);
          return null;
      }
      return res.data.message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped>
.serviceIdChoose {
  width: 100%;
}
</style>
