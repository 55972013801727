<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="head_input">
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="setShowType1()"
                icon="el-icon-arrow-left"
              >
                返回
              </el-button>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
            >
              新增服务
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          label="服务名称"
          prop="service_name"
          width="200"
        ></el-table-column>
        <el-table-column
          label="服务code"
          prop="service_code"
          width="250"
        ></el-table-column>
        <el-table-column label="状态" prop="biz_status" width="120">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <div v-if="scope.row.biz_status == 'EDITING'">编辑中</div>
            <div v-else-if="scope.row.biz_status == 'AUDITING'">审核中</div>
            <div v-else-if="scope.row.biz_status == 'AUDIT_REJECT'">
              审核驳回
            </div>
            <div v-else-if="scope.row.biz_status == 'PROMOTION'">可推广</div>
            <div v-else-if="scope.row.biz_status == 'STOP_PROMOTION'">
              停止推广
            </div>
            <div v-else-if="scope.row.biz_status == 'INVALID'">失效</div>
            <div v-else-if="scope.row.biz_status == 'PUNISHED'">处罚</div>
            <div v-else>其他:{{ scope.row.biz_status }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 编辑信息 -->
            <!-- <el-tooltip
              effect="dark"
              content="编辑(未完成)"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="toUpdateMes(scope.row)"
              ></el-button>
            </el-tooltip> -->
            <el-tooltip
              effect="dark"
              content="查看详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="selectMes(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog title="新增服务" :visible.sync="addDialogVisible" width="50%">
      <thisAdd
        :refaushList="getList"
        :visible="addDialogVisible"
        :appId="appId"
      />
    </el-dialog>
    <!-- 编辑服务 -->
    <!-- <el-dialog title="编辑服务" :visible.sync="updateMes.visible" width="50%">
      <thisUpdate
        :refaushList="getList"
        :visible="updateMes.visible"
        :appId="appId"
        :serviceCode="updateMes.serviceCode"
      />
    </el-dialog> -->
    <!-- 详情 -->
    <el-drawer title="详情" size="50%" :visible.sync="messageMes.visible">
      <thisMessage :messageMes="messageMes.mes" />
    </el-drawer>
  </div>
</template>

<script>
import {
  listQuery,
  query,
  delete1,
} from "@/services/thirdParty/openAppService";
import thisAdd from "./thisAdd.vue";
// import thisUpdate from "./thisUpdate.vue";
import thisMessage from "./thisMessage.vue";
export default {
  components: {
    thisAdd,
    thisMessage,
  },
  props: ["setShowType1", "appId"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 查看详情
      messageMes: {
        visible: false,
        mes: {},
      },
      // 编辑时选中的serviceCode
      updateMes: {
        visile: false,
        serviceCode: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await listQuery({
        appId: this.appId,
        model: this.queryInfo,
      });
      loading.close();
      if (!res.success) {
        return this.$message.error("获取列表失败:" + res.err);
      }
      this.pageMes.list = res.data.records;
      this.pageMes.total = res.data.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 查看详情
    async selectMes(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await query({
        appId: this.appId,
        model: {
          serviceCode: e.service_code,
        },
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this.messageMes, "visible", true);
      this.$set(this.messageMes, "mes", res.data);
    },
    // 编辑
    toUpdateMes(e) {
      this.$set(this.updateMes, "visible", true);
      this.$set(this.updateMes, "serviceCode", e.service_code);
    },
    // 删除
    async deleteThis(e) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await delete1({
        appId: this.appId,
        model: {
          serviceCode: e.service_code,
        },
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .headButtonSingle {
      margin-right: 10px;
    }
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
