<template>
  <!-- 转盘奖励管理 -->
  <div class="div_home">
    <el-card>
      <el-form ref="ruleForm" label-width="auto">
        <el-form-item label="签到奖励" prop="pass">
          <el-button type="primary" @click="toSignInMes">
            签到奖励编辑
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 签到管理 -->
    <el-dialog
      title="签到管理"
      :visible.sync="luckDrawSignIn.visible"
      width="50%"
    >
      <smallRoutine3SignInLuckDrawSignIn ref="luckDrawSignInRef" />
    </el-dialog>
  </div>
</template>

<script>
import smallRoutine3SignInLuckDrawSignIn from "./smallRoutine3SignInLuckDrawSignIn.vue";
export default {
  components: {
    smallRoutine3SignInLuckDrawSignIn,
  },
  data() {
    return {
      // 签到管理
      luckDrawSignIn: {
        // 是否展示弹出框
        visible: false,
      },
    };
  },
  created() {},
  methods: {
    // 签到管理
    toSignInMes() {
      if (this.$refs.luckDrawSignInRef != undefined) {
        this.$refs.luckDrawSignInRef.refaushForm();
      }
      this.luckDrawSignIn.visible = true;
    },
  },
};
</script>

<style lang="less" scoped></style>
