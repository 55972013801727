<template>
  <div>
    <el-form :model="messageMes" ref="messageMesRef" label-width="auto">
      <el-form-item label="状态">
        {{ messageMes.status }}
      </el-form-item>
      <el-form-item label="name">
        {{ messageMes.name }}
      </el-form-item>
      <el-form-item label="appId">
        {{ messageMes.appId }}
      </el-form-item>
      <el-form-item label="service_code">
        {{ messageMes.service_code }}
      </el-form-item>
      <el-form-item label="拒绝原因">
        {{ messageMes.rejectReason }}
      </el-form-item>
      <el-divider content-position="left">其他</el-divider>
      <el-form-item
        v-for="(item, index) in messageMes.data"
        :key="'messageMesData' + index"
        :label="item.label"
      >
        {{ item.value }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "thisMessage",
  props: ["messageMes"],
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
