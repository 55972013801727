<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form-item label="客户" prop="customerId">
        <el-select v-model="addForm.customerId" placeholder="请选择">
          <el-option
            v-for="item in customerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告顺序" prop="sort">
        <el-input
          v-model="addForm.sort"
          placeholder="请输入广告顺序，值越小用户越早拉取，默认最大值99999999"
          maxlength="8"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入广告名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告文案" prop="copyWriting">
        <el-input
          v-model="addForm.copyWriting"
          placeholder="请输入广告文案"
          maxlength="10"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="副标题" prop="subtitle">
        <el-input
          v-model="addForm.subtitle"
          placeholder="请输入副标题"
          maxlength="10"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="按钮文字" prop="buttonText">
        <el-input
          v-model="addForm.buttonText"
          placeholder="请输入按钮文字"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-upload
          class="avatar-uploader"
          :action="$httpUrl + 'upload/uploadFile'"
          :show-file-list="false"
          :on-success="
            (value) => {
              iconUploadSuccess(value);
            }
          "
          :on-change="onChangeUpload"
          :before-upload="beforeAvatarUpload"
          :headers="headerObj"
        >
          <img class="avatar" v-if="addForm.icon" :src="addForm.icon" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="广告量" prop="quantity">
        <el-input
          v-model="addForm.quantity"
          placeholder="请输入广告量"
          maxlength="8"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="总价" prop="totalPrice">
        <el-input
          v-model="addForm.totalPrice"
          placeholder="请输入总价"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatTotalPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="单价" prop="unitPrice">
        <el-input
          v-model="addForm.unitPrice"
          placeholder="请输入单价"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatUnitPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序类型选择" prop="appletType">
        <small_routine_select
            :appId.sync="addForm.appId"
            :isAnyLevel="true"
            :batchClassId.sync="addForm.batchClassId"
            :appletType.sync ="addForm.appletType"
        >
        </small_routine_select>
      </el-form-item>
      <el-form-item label="用户佣金" prop="commission">
        <el-input
          v-model="addForm.commission"
          placeholder="请输入用户佣金"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatCommission"
        ></el-input>
      </el-form-item>
      <el-form-item label="浏览时间(秒)" prop="browseTime">
        <el-input
          v-model="addForm.browseTime"
          placeholder="请输入浏览时间"
          maxlength="4"
          show-word-limit
          clearable
          @input="priceFormatBrowseTime"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="jumpAppId">
        <el-input
          v-model="addForm.jumpAppId"
          placeholder="请输入小程序appId"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序path" prop="appletPath">
        <el-input
          v-model="addForm.appletPath"
          placeholder="请输入小程序path"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="h5_url" prop="h5Url">
        <el-input
          v-model="addForm.h5Url"
          placeholder="请输入h5_url"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="生活号id" prop="lifeNumber">
        <el-input
          v-model="addForm.lifeNumber"
          placeholder="请输入生活号id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告客户端" prop="advertisingClient">
        <el-select v-model="addForm.advertisingClient" placeholder="请选择">
          <el-option
            v-for="item in clientList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告投放起始时间" prop="startDate">
        <el-date-picker
          v-model="addForm.startDate"
          type="date"
          placeholder="广告投放起始时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="广告终止时间" prop="endDate">
        <el-date-picker
          v-model="addForm.endDate"
          type="date"
          placeholder="广告终止时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每天投放开始时间段" prop="startTime">
        <el-time-picker
          v-model="addForm.startTime"
          placeholder="每天投放开始时间段"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="每天投放结束时间段" prop="endTime">
        <el-time-picker
          v-model="addForm.endTime"
          placeholder="每天投放结束时间段"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="addForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <!-- 发放佣金 -->
      <el-form-item label="发放佣金" prop="commissionPayment">
        <el-switch v-model="addForm.commissionPayment"> </el-switch>
      </el-form-item>
      <el-form-item label="是否开启" prop="remark">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
import small_routine_select
  from "@/components/thirdParty/other/redpacket/menuPage/defaultConfig/data_table/small_routine_select.vue";

export default {
  name: "informationAdd",
  components:{
    small_routine_select
  },
  props: ["closeThis", "refaushList", "customerList", "clientList"],
  data() {
    return {
      addForm: {},
      // 表单验证
      addFormRules: {
        customerId: [
          { required: true, message: "请选择客户", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        copyWriting: [
          { required: true, message: "请输入广告文案", trigger: "blur" },
        ],
        buttonText: [
          { required: true, message: "请输入按钮文字", trigger: "blur" },
        ],
        icon: [{ required: true, message: "请上传图片", trigger: "blur" }],
        quantity: [
          { required: true, message: "请输入广告量", trigger: "blur" },
        ],
        totalPrice: [
          { required: true, message: "请输入总价", trigger: "blur" },
        ],
        unitPrice: [{ required: true, message: "请输入单价", trigger: "blur" }],
        commission: [
          { required: true, message: "请输入用户佣金", trigger: "blur" },
        ],
        browseTime: [
          { required: true, message: "请输入浏览时间", trigger: "blur" },
        ],
        advertisingClient: [
          { required: true, message: "请选择广告客户端", trigger: "blur" },
        ],
        appletType:[{required:true,message:'请输入类别',trigger:'blur'}],
        startDate: [
          {
            required: true,
            message: "请选择广告投放起始时间",
            trigger: "blur",
          },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
      appletTypeList:[]
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      //加载小程序类别选项
      this.getAppletTypes();
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let addForm = this.addForm;
        let advertisingClient = addForm.advertisingClient;
        if (1 == advertisingClient) {
          // h5
          if (addForm.h5Url == "") {
            return this.$message.error("请输入h5_url");
          }
        } else if (2 == advertisingClient) {
          // 支付宝
          if (addForm.appId == "") {
            return this.$message.error("请输入小程序appId与小程序path");
          }
        } else if (3 == advertisingClient) {
          // 生活号
          if (addForm.lifeNumber == "") {
            return this.$message.error("请输入生活号id");
          }
        } else {
          return this.$message.error("请选择广告客户端");
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const { data: res } = await this.$http.post(
        "/advertisementInformation/add",
        {
          ...this.addForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 金额正则表达式
    priceFormatTotalPrice(e) {
      this.addForm.totalPrice =
        this.addForm.totalPrice.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.addForm.totalPrice)) {
        this.addForm.totalPrice = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.addForm.totalPrice.length >= 2
      ) {
        this.addForm.totalPrice = this.addForm.totalPrice.replace(/0/, "");
      }
    },
    priceFormatUnitPrice(e) {
      this.addForm.unitPrice =
        this.addForm.unitPrice.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.addForm.unitPrice)) {
        this.addForm.unitPrice = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.addForm.unitPrice.length >= 2
      ) {
        this.addForm.unitPrice = this.addForm.unitPrice.replace(/0/, "");
      }
    },
    priceFormatCommission(e) {
      this.addForm.commission =
        this.addForm.commission.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.addForm.commission)) {
        this.addForm.commission = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.addForm.commission.length >= 2
      ) {
        this.addForm.commission = this.addForm.commission.replace(/0/, "");
      }
    },
    priceFormatBrowseTime(e) {
      this.addForm.browseTime =
        this.addForm.browseTime.match(/^\d*/g)[0] || null;
      if (isNaN(this.addForm.browseTime)) {
        this.addForm.browseTime = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.addForm.browseTime.length >= 2
      ) {
        this.addForm.browseTime = this.addForm.browseTime.replace(/0/, "");
      }
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    // 图片上传成功
    iconUploadSuccess(e) {
      if (e.code != 200) {
        return this.$message.error("图片上传失败");
      }
      this.addForm.icon = e.message;
    },
    onChangeUpload() {
      this.showLoading = !this.showLoading;
    },
    // 获取小程序种类列表
    async getAppletTypes(){
      const {data:res} = await this.$http.get(
        "/thirdPartySmallRoutineType/getAllTypeWithAllOption"
      );
      if('200'!=res.code){
        return this.$message.error(res.message);
      }
      this.appletTypeList = res.message;
    },
  },
  created() {
    this.getAppletTypes();
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader {
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
