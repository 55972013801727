<template>
  <div>
    <list />
  </div>
</template>

<script>
import list from "./list.vue";
export default {
  name: "template",
  components: {
    list,
  },
  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
