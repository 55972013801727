<template>
  <div>
    <div class="switch">
      <span>生活号：</span>
      <el-switch v-model="isShowLifeId"></el-switch>
    </div>
    <div class="switch">
      <span>用户关注生活号后才允许后续操作：</span>
      <el-switch v-model="isContinueOnlyUserFollowLifeAll"></el-switch>
    </div>
    <div class="switch">
      <div>生活号列表：</div>
      <life_id_list_editor	:input_list.sync="lifeIdJsonArray" style="margin-top: 10px"></life_id_list_editor>
    </div>


<!--    <div>-->
<!--      <span class="text">生活号列表：</span>-->
<!--      <el-button type="primary" @click="add" class="buttonAddClear">添加</el-button>-->
<!--      <el-button type="primary" @click="clear" class="buttonAddClear">清空</el-button>-->
<!--      <el-table	:data="list">-->
<!--        <el-table-column  label="id号"  width="400">-->
<!--          <template slot-scope="scope">-->
<!--            <el-input v-model="scope.row.id" placeholder="id号"	clearable></el-input>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column  label="排序"	width="150">-->
<!--          <template slot-scope="scope"	>-->
<!--            <el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>-->
<!--            <el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column  label="操作"  width="80">-->
<!--          <template slot-scope="scope"	>-->
<!--            <el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>-->
<!--          </template>-->
<!--        </el-table-column>-->
<!--      </el-table>-->
<!--    </div>-->

    <el-button icon="el-icon-phone" @click="useDeploy(1)" round class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button icon="el-icon-phone" @click="useDeploy(2)" round class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>
import life_id_list_editor from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/life_id_list_editor.vue";

export default {
  components: {
    life_id_list_editor
  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      isShowLifeId: false,
      isContinueOnlyUserFollowLifeAll: false,
      lifeIdJsonArray: "[]",

      loading: false,

      // list:[],
      // list_max_length: 5,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getThatsLife() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getThatsLife",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.isShowLifeId = res.message.isShowLifeId;
        this.isContinueOnlyUserFollowLifeAll = res.message.isContinueOnlyUserFollowLifeAll;
        this.lifeIdJsonArray = res.message.lifeIdJsonArray;

        // if (JSON.parse(decodeURI(res.message.lifeIdJsonArray)) == null) {
        //   this.lifeIdJsonArray = [];
        // } else {
        //   // 解码
        //   this.lifeIdJsonArray = JSON.parse(decodeURI(res.message.lifeIdJsonArray));
        // }
      }
    },
    // 更新数据接口
    async useDeploy(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      // let lifeIdJsonArray = "";
      // if (!this.isShowLifeId || this.list.length === 0) {
      //   lifeIdJsonArray = [];
      // }
      // 编码
      // lifeIdJsonArray = encodeURI(JSON.stringify(this.list));
      // 解码
      // console.log("解码", JSON.parse(decodeURI(lifeIdJsonArray)));

      //参数：checkedCities或batchClassList（选中的第三方列表或选中的类别列表）、isShowLifeId（是否展示 false->0）、lifeIdJsonArray（生活号列表）
      const updateThatsLifeVo = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        isShowLifeId: this.isShowLifeId,
        isContinueOnlyUserFollowLifeAll: this.isContinueOnlyUserFollowLifeAll,
        lifeIdJsonArray: this.lifeIdJsonArray
        // lifeIdJsonArray: lifeIdJsonArray
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/thatsLife",
          updateThatsLifeVo
      );
      if (200 === res.code) {
        this.$message.success("「生活号」配置成功");
      } else {
        this.$message.error("「生活号」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
    //添加一行数据
    // add(){
    //   if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
    //     this.$message.error("超过长度了,无法再添加");
    //   }else{
    //     this.list.push({sort:this.sort_default})	//添加
    //   }
    // },
    //清空所有的数据
    // clear(){
    //   this.list = [];
    // },
    // //排序的名次上升，指排序的下标上升
    // row_up(index){
    //   let temp = this.list[index] ;
    //   this.$set(this.list,index,this.list[index-1]);
    //   this.$set(this.list,index-1,temp);
    // },
    // //排序的名次下降，指排序的下标下降
    // row_down(index){
    //   let temp = this.list[index] ;
    //   this.$set(this.list,index,this.list[index+1]);
    //   this.$set(this.list,index+1,temp);
    // },
    // //删除一行的数据
    // delete_row(index){
    //   this.list.splice(index,1);
    // },
  },
  computed:{
    //求出新的一行的排序值
    // sort_default(){
    //   if(this.list.length == 0){
    //     return 0;
    //   }else{
    //     let max = this.list[0].sort ;
    //     this.list.forEach(	(item)=>{		if(item.sort>max)	max=item.sort;	}	)
    //     return max+1;
    //   }
    // },
  },
  mounted() {
    this.getThatsLife();
  }
}

</script>

<style scoped lang="less">
.switch{
  margin-bottom: 20px;
}
.buttonAddClear{
  margin-bottom: 20px;
}
.buttonUseDeploy{
  margin-top: 20px;
  color: #fff;
  background-color: #4e72b8;
}
.xcxkf{
  margin-top: 20px;
}
</style>