<template>
  <div>
    <el-form label-width="70px">
      <el-form-item label="等额本息" prop="equalPrincipalAndInterest">
        <el-input
          v-model="updateMes.equalPrincipalAndInterest"
          placeholder="等额本息"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
        <!-- <el-switch
          class="elSwitch"
          v-model="updateMes.equalPrincipalAndInterestStatus"
        ></el-switch> -->
      </el-form-item>
      <el-form-item label="等额本金" prop="equalPrincipal">
        <el-input
          v-model="updateMes.equalPrincipal"
          placeholder="等额本金"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
        <!-- <el-switch
          class="elSwitch"
          v-model="updateMes.equalPrincipalStatus"
        ></el-switch> -->
      </el-form-item>
      <el-form-item label="等本等息" prop="equalPrincipalAndEqualInterest">
        <el-input
          v-model="updateMes.equalPrincipalAndEqualInterest"
          placeholder="等本等息"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
        <!-- <el-switch
          class="elSwitch"
          v-model="updateMes.equalPrincipalAndEqualInterestStatus"
        ></el-switch> -->
      </el-form-item>
      <el-form-item label="先息后本" prop="interestBeforeCapital">
        <el-input
          v-model="updateMes.interestBeforeCapital"
          placeholder="先息后本"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
        <!-- <el-switch
          class="elSwitch"
          v-model="updateMes.interestBeforeCapitalStatus"
        ></el-switch> -->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "messageTemplateServiceLoanUpdate",
  props: ["code", "visible"],
  data() {
    return {
      updateMes: {},
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    async init() {
      let { data: res } = await this.$http.get(
        `/smallRoutineServiceMes/getByCode?code=` + this.code
      );
      if (res.code != "200") {
        return this.$message.error(res.message);
      }
      this.updateMes = res.message;
    },
    async submitForm() {
      let updateMes = this.updateMes;
      let { data: res } = await this.$http.put(
        `/smallRoutineServiceMes/updateMes`,
        {
          ...updateMes,
        }
      );
      if (res.code == "200") {
        this.$message.success("编辑成功");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 80%;
}
.elSwitch {
  padding-left: 30px;
}
</style>
