<template>
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="configuration"
        ref="addFormRed"
        label-width="auto"
        :rules="formRules"
      >
        <el-divider content-position="left">集分宝配置</el-divider>
        <!-- 预算库 -->
        <el-form-item label="预算库" prop="budgetCode">
          <el-input
            v-model="configuration.budgetCode"
            placeholder="请输入签约商户的集分宝的预算库"
            clearable
            maxlength="200"
          ></el-input>
        </el-form-item>
        <el-form-item label="预算有效期" prop="validity">
          <el-date-picker
            v-model="configuration.validity"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="集分宝数量" prop="number">
          <el-input
            type="number"
            v-model="configuration.number"
            placeholder="请输入集分宝数量"
            clearable
            maxlength="100"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "alipayUserAlipayPointSend",
  data() {
    return {
      configuration: {
        // 预算库
        budgetCode: "",
      },
      // 表单验证
      formRules: {
        budgetCode: [
          {
            required: true,
            message: "请输入签约商户的集分宝的预算库",
            trigger: "blur",
          },
        ],
        validity: [
          { required: true, message: "请选择预算有效期", trigger: "blur" },
        ],
        number: [
          { required: true, message: "请输入集分宝数量", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await this.$http.get(
        "configurationAlipayUserAlipayPointSend/get"
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.configuration = message;
    },
    add() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) return;
        let configuration = this.configuration;
        //发起请求
        const { data: res } = await this.$http.put(
          "configurationAlipayUserAlipayPointSend/update",
          configuration
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
