// 接口请求地址
const path = 'poiCode/getAll';
import axios from 'axios'

/**
 * 获取poi分类表
 * @param {*} params
 */
export async function getAllPoi() {
  const { data: res } = await axios.get(
    path,
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取poi分类表失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}