<template>
  <div>
    <el-form label-width="70px">
      <!-- 公众号id -->
      <el-form-item label="公众号id">
        <el-input
          v-model="lifeId"
          placeholder="请输入关联的生活号id"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="editLifeRelationMes">确 定</el-button>
    </span>
  </div>
</template>

<script>
export default {
  name: "smallRoutine3LifeNumber",
  props: ["smallId"],
  data() {
    return {
      lifeId: "",
    };
  },

  mounted() {},

  created() {
    this.refaushForm(this.smallId);
  },
  methods: {
    // 刷新编辑对象
    async refaushForm(id) {
      const { data: res } = await this.$http.get(
        "smallRoutine3/getLifeIdById?id=" + id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.lifeId = res.message;
    },
    async editLifeRelationMes() {
      let lifeId = this.lifeId;
      let smallId = this.smallId;
      if (null == smallId || "" == smallId || undefined == smallId) {
        return this.$message.error("操作失败,小程序id丢失，请刷新后重试");
      }
      const { data: res } = await this.$http.put(
        "smallRoutine3/updateLifeIdById",
        {
          smallId: smallId,
          lifeId: lifeId,
        }
      );
      // 返回结果
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
