// 接口请求地址
const path = 'thirdPartyAliOpenAppService/';
import axios from 'axios'

/**
 * 服务schema下发
 * @param {*} params
 */
export async function schemaQuery(data = {}) {
  const { data: res } = await axios.post(
    path + 'schemaQuery',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 服务提报申请
 * @param {*} params
 */
 export async function apply(data = {}) {
  const { data: res } = await axios.post(
    path + 'apply',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 服务schema下发
 * @param {*} params
 */
 export async function listQuery(data = {}) {
  const { data: res } = await axios.post(
    path + 'listQuery',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 服务查询
 * @param {*} params
 */
 export async function query(data = {}) {
  const { data: res } = await axios.post(
    path + 'query',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 服务失效接口
 * @param {*} params
 */
 export async function delete1(data = {}) {
  const { data: res } = await axios.post(
    path + 'delete',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取所有可推广的服务列表
 */
 export async function getAllPromotionServices(appId) {
  const { data: res } = await axios.get(
    path + 'getAllPromotionServices?appId='+appId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


