<template>
  <div class="gaoDeLinShi_main">
    <el-card>
      <div class="gaoDeLinShi_title">
        <div>
          <label class="gaoDeLinShi_title_text">类型:</label>
          <el-select v-model="queryInfo2.labelType" @change="labelTypeChange">
            <el-option
              v-for="item in labelType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div>
          <label class="gaoDeLinShi_title_text">状态:</label>
          <el-select v-model="queryInfo2.status" clearable placeholder="全部">
            <el-option key="-1" label="全部" value=""> </el-option>
            <el-option key="input_status_1" label="开启" value="1"> </el-option>
            <el-option key="input_status_0" label="关闭" value="0"> </el-option>
          </el-select>
        </div>
        <el-button
          class="headButtonSingle"
          type="primary"
          @click="search()"
          icon="el-icon-search"
          >搜索
        </el-button>
        <el-button
          class="headButtonSingle"
          type="primary"
          @click="clearSearch()"
          icon="el-icon-refresh"
          >重置
        </el-button>
        <el-button
          class="headButtonSingle"
          type="primary"
          @click="addDialogVisible = true"
          icon="el-icon-plus"
          >新增
        </el-button>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.img" width="60" height="60" class="head_pic" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name" width="200"></el-table-column>
        <el-table-column label="跳转类型" width="100">
          <template slot-scope="scope" v-if="queryInfo.labelType != 3">
            <template v-if="scope.row.toType == 1">h5</template>
            <template v-if="scope.row.toType == 2">服务</template>
            <template v-if="scope.row.toType == 3">小程序</template>
          </template>
        </el-table-column>
        <el-table-column label="排序" prop="sort" width="200"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="广告状态"
          prop="status"
          v-if="queryInfo.labelType != 3"
        >
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.advertisementStatus"
              @change="advertisementStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>

        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 新增信息 -->
    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%">
      <add
        ref="addRef"
        :refaushList="getList"
        :visible="addDialogVisible"
        :serviceList="serviceList"
        :codeMes="code"
        :labelType="queryInfo.labelType"
      />
    </el-dialog>
    <!-- 编辑信息 -->
    <el-dialog title="编辑" :visible.sync="updateMes.visible" width="50%">
      <update
        ref="updateRef"
        :refaushList="getList"
        :serviceList="serviceList"
        :visible="updateMes.visible"
        :updateId="updateMes.id"
        :labelType="queryInfo.labelType"
      />
    </el-dialog>
  </div>
</template>

<script>
import add from "./add.vue";
import update from "./update.vue";
import {
  list,
  updateState,
  del,
  updateAdvertisementState,
} from "@/services/tiktok.js";
export default {
  name: "gaoDeTemplate",
  props: ["showIndex", "code", "visible"],
  components: {
    add,
    update,
  },
  watch: {
    visible(e) {
      if (e) {
        // 每次打开组件初始化
        this.init(this.code);
      }
    },
  },
  data() {
    return {
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        labelType: 1, // 标签类型
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        labelType: 1, // 标签类型
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 页面内容
      pageMes: {},
      labelType: [
        {
          label: "菜单",
          value: 1,
        },
        {
          label: "轮播图",
          value: 2,
        },
        {
          label: "广告位展示图",
          value: 3,
        },
      ],
      // 新增
      addDialogVisible: false,
      // 编辑
      updateMes: {
        visible: false,
        id: "",
      },
      // 服务列表
      serviceList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      // 获取列表
      this.clearSearch();
      // 获取服务列表
      this.getServiceList();
    },
    // 初始化查询条件
    initQueryInfo() {},
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await list(this.queryInfo);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this.pageMes, "total", res.data.total);
      this.$set(this.pageMes, "list", res.data.records);
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        code: this.code,
        labelType: 1, // 标签类型
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
      this.getList();
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 编辑
    showEditDialog(e) {
      this.updateMes.id = e.id;
      this.updateMes.visible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const res = await del(id);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async stateChange(e) {
      const res = await updateState({
        id: e.id,
        status: e.status,
      });
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    // 修改广告状态
    async advertisementStateChange(e) {
      const res = await updateAdvertisementState({
        id: e.id,
        status: e.advertisementStatus,
      });
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    // 获取服务列表
    async getServiceList() {
      const { data: res } = await this.$http.get(
        "/smallRoutineService/getAllServiceIdAndName"
      );
      if ("200" != res.code) {
        return this.$message.error("获取服务列表失败:" + res.message);
      }
      this.serviceList = res.message;
    },
    // 类型变更
    labelTypeChange() {
      this.search();
    },
  },
};
</script>

<style lang="less" scoped>
.gaoDeLinShi_main {
  .gaoDeLinShi_title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .gaoDeLinShi_title_text {
      margin: 0 10px 0 20px;
    }
    .headButtonSingle {
      margin-left: 10px;
    }
  }
}
</style>
