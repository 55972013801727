<template>
  <div class="div_home">
    <!-- <div class="div_title">用户列表</div> -->
    <!-- 用户主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容(跳转地址)"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="小程序名称" prop="name"></el-table-column>
        <el-table-column label="小程序标识" prop="code"></el-table-column>
        <el-table-column label="类型" prop="typeName"></el-table-column>
        <el-table-column label="图片">
          <template slot-scope="scope">
            <img :src="scope.row.img" width="60" height="60" class="head_pic" />
          </template>
        </el-table-column>
        <el-table-column label="跳转地址" prop="src"></el-table-column>
        <el-table-column label="名称" prop="mes"></el-table-column>
        <el-table-column
          label="所属公司"
          prop="companyName"
          v-if="'1' == roleGrade"
        ></el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 修改 -->
    <el-dialog title="编辑" :visible.sync="editDialogVisible" width="50%">
      <SyncLabelUpdate
        ref="SyncLabelUpdate"
        :updateParameter="updateMes"
        :refreshList="getList"
      />
    </el-dialog>
  </div>
</template>
<script>
import SyncLabelUpdate from "./syncLabelUpdate.vue";
export default {
  components: {
    SyncLabelUpdate,
  },
  async created() {
    this.getList();
  },
  data() {
    return {
      roleGrade: "", //角色等级
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 标签列表
      total: 0, //总记录数
      //显示/隐藏修改用户栏
      editDialogVisible: false,
      updateMes: {
        id: "",
        code: "",
      },
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取表格内容
    async getList() {
      const { data: res } = await this.$http.get(
        "smallRoutineLabel/getLabelList",
        {
          params: this.queryInfo,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.tableList = res.message.records;
      this.total = res.message.total;
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getNewList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    //更改用户信息对话框
    //显示对话
    async showEditDialog(e) {
      this.updateMes = e;
      //显示对话框
      this.editDialogVisible = true;
      if (null != this.$refs.SyncLabelUpdate) {
        // 非首次加载组件
        this.$refs.SyncLabelUpdate.refreshMes(e);
      }
    },
    SyncLabelUpdate(ref) {
      this.SyncLabelUpdate = ref;
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
