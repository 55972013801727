<template>
  <div>
    <el-form ref="dataModificationRed" label-width="70px">
      <el-divider content-position="left">轮播图</el-divider>
      <div class="small_update_list">
        <!-- 新增 -->
        <div class="small_update_single">
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, newRotationImg);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :headers="headerObj"
              :data="{
                type: 'rotationImg',
                code: dataModificationCode,
              }"
            >
              <img
                class="small_update_single_img avatar"
                v-if="newRotationImg.img"
                :src="newRotationImg.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newRotationImg.src"
                placeholder="请输入跳转路径"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newRotationImg.mes"
                placeholder="请输入名称"
                maxlength="10"
                show-word-limit
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button
                type="primary"
                style="width: 100%"
                @click="toAddRotationImg('rotationImg')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <!-- 已存在的列表 -->
        <div
          class="small_update_single"
          v-for="(item, index) in dataModificationMes.rotationImgList"
          :key="item.id"
        >
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, item);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :data="{
                id: item.id,
                type: 'rotationImg',
                code: dataModificationCode,
              }"
              :headers="headerObj"
            >
              <img
                class="small_update_single_img avatar"
                v-if="item.img"
                :src="item.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.src"
                placeholder="请输入跳转路径"
                @focus="smallUpdateInputFoncus('rotationImg', 'src', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>

            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.mes"
                placeholder="请输入名称"
                maxlength="10"
                show-word-limit
                @focus="smallUpdateInputFoncus('rotationImg', 'mes', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button
                icon="el-icon-arrow-left"
                :disabled="index == 0"
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.rotationImgList,
                    index,
                    -1
                  )
                "
              ></el-button>
              <!-- 删除 -->
              <el-tooltip
                effect="dark"
                content="删除"
                placement="top-start"
                :enterable="false"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="
                    deleteThisLabel(
                      dataModificationMes.rotationImgList,
                      index,
                      item.id
                    )
                  "
                  >删除</el-button
                >
              </el-tooltip>
              <el-button
                icon="el-icon-arrow-right"
                :disabled="
                  index == dataModificationMes.rotationImgList.length - 1
                "
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.rotationImgList,
                    index,
                    1
                  )
                "
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <el-divider content-position="left">菜单列表</el-divider>
      <div class="small_update_list">
        <!-- 新增 -->
        <div class="small_update_single">
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, newMenuList);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :headers="headerObj"
              :data="{
                type: 'menuList',
                code: dataModificationCode,
              }"
            >
              <img
                class="small_update_single_img avatar"
                v-if="newMenuList.img"
                :src="newMenuList.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newMenuList.src"
                placeholder="请输入跳转路径"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newMenuList.mes"
                placeholder="请输入菜单名称"
                maxlength="5"
                show-word-limit
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button
                type="primary"
                style="width: 100%"
                @click="toAddRotationImg('menuList')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <!-- 已存在的列表 -->
        <div
          class="small_update_single"
          v-for="(item, index) in dataModificationMes.menuList"
          :key="item.id"
        >
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, item);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :data="{
                id: item.id,
                type: 'menuList',
                code: dataModificationCode,
              }"
              :headers="headerObj"
            >
              <img
                class="small_update_single_img avatar"
                v-if="item.img"
                :src="item.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.src"
                placeholder="请输入跳转路径"
                @focus="smallUpdateInputFoncus('menuList', 'src', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.mes"
                placeholder="请输入菜单名称"
                maxlength="5"
                show-word-limit
                @focus="smallUpdateInputFoncus('menuList', 'mes', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>

            <div class="small_update_single_mes_button">
              <el-button
                icon="el-icon-arrow-left"
                :disabled="index == 0"
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.menuList,
                    index,
                    -1
                  )
                "
              ></el-button>
              <!-- 删除 -->
              <el-tooltip
                effect="dark"
                content="删除"
                placement="top-start"
                :enterable="false"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="
                    deleteThisLabel(
                      dataModificationMes.menuList,
                      index,
                      item.id
                    )
                  "
                  >删除
                </el-button>
              </el-tooltip>
              <el-button
                icon="el-icon-arrow-right"
                :disabled="index == dataModificationMes.menuList.length - 1"
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.menuList,
                    index,
                    1
                  )
                "
              ></el-button>
            </div>
          </div>
        </div>
      </div>
      <el-divider content-position="left">图文列表</el-divider>
      <el-form-item label="标题">
        <el-input
          v-model="dataModificationMes.imageTextTitle"
          maxlength="100"
          show-word-limit
          placeholder="请输入图文列表标题"
          @focus="
            smallUpdateInputFoncus(
              'imageTextTitle',
              'imageTextTitle',
              dataModificationMes.imageTextTitle
            )
          "
          @blur="smallUpdateInputBlur(dataModificationMes)"
        ></el-input>
      </el-form-item>
      <div class="small_update_list">
        <!-- 新增 -->
        <div class="small_update_single">
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, newTextImage);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :headers="headerObj"
              :data="{
                type: 'imageText',
                code: dataModificationCode,
              }"
            >
              <img
                class="small_update_single_img avatar"
                v-if="newTextImage.img"
                :src="newTextImage.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newTextImage.src"
                placeholder="请输入跳转路径"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-model="newTextImage.mes"
                placeholder="请输入名称"
                maxlength="10"
                show-word-limit
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button
                type="primary"
                style="width: 100%"
                @click="toAddRotationImg('imageText')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <!-- 已存在的列表 -->
        <div
          class="small_update_single"
          v-for="(item, index) in dataModificationMes.imageTextList"
          :key="item.id"
        >
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="$httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, item);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :data="{
                id: item.id,
                type: 'imageText',
                code: dataModificationCode,
              }"
              :headers="headerObj"
            >
              <img
                class="small_update_single_img avatar"
                v-if="item.img"
                :src="item.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.src"
                placeholder="请输入跳转路径"
                @focus="smallUpdateInputFoncus('imageText', 'src', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-model="item.mes"
                placeholder="请输入名称"
                maxlength="10"
                show-word-limit
                @focus="smallUpdateInputFoncus('imageText', 'mes', item)"
                @blur="smallUpdateInputBlur(item)"
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button
                icon="el-icon-arrow-left"
                :disabled="index == 0"
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.imageTextList,
                    index,
                    -1
                  )
                "
              ></el-button>
              <!-- 删除 -->
              <el-tooltip
                effect="dark"
                content="删除"
                placement="top-start"
                :enterable="false"
              >
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  @click="
                    deleteThisLabel(
                      dataModificationMes.imageTextList,
                      index,
                      item.id
                    )
                  "
                  >删除</el-button
                >
              </el-tooltip>
              <el-button
                icon="el-icon-arrow-right"
                :disabled="
                  index == dataModificationMes.imageTextList.length - 1
                "
                @click="
                  smallUpdateMoveLocation(
                    dataModificationMes.imageTextList,
                    index,
                    1
                  )
                "
              ></el-button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import {
  addLabel,
  getListBySmallRoutineId,
  updateSrcById,
  delLabel,
} from "@/services/thirdParty/smallRoutine/applet1/smallRoutineLabel.js";
export default {
  name: "dataModification",
  props: ["showSingleId"],
  data() {
    return {
      dataModificationLoading: false, // 加载中
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      dataModificationCode: "",
      // 小程序页面展示数据
      dataModificationMes: {},
      // 页面展示管理，轮播图新增
      newRotationImg: {
        img: "",
        src: "",
        mes: "",
      },
      // 页面展示管理，菜单列表新增
      newMenuList: {
        img: "",
        src: "",
        mes: "",
      },
      // 页面展示管理，图文列表新增
      newTextImage: {
        img: "",
        src: "",
        mes: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.dataModification();
    },
    // 加载页面数据
    async dataModification() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getListBySmallRoutineId(this.showSingleId);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.dataModificationMes = res.data;
    },
    onChangeUpload() {
      this.dataModificationLoading = !this.dataModificationLoading;
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    // 页面数据编辑点击输入框获
    async smallUpdateInputFoncus(type, srcOrMes, e) {
      this.smallUpdateInputFoncussrcOrMes = srcOrMes;
      if ("src" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.src;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("mes" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.mes;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("name" == srcOrMes) {
        this.smallUpdateInputFoncusMes = e.name;
        this.smallUpdateInputFoncusId = e.id;
      } else if ("imageTextTitle" == srcOrMes) {
        // 图文列表标题
        this.smallUpdateInputFoncusMes = e;
      } else {
        return this.$message.warning("数据有误，请刷新后重试");
      }
      this.smallUpdateInputFoncusType = type;
    },
    // 轮播图文件上传成功
    handleAvatarSuccess(file, item) {
      this.$set(item, "img", file.message.src);
      // item.img = file.message.src;
    },
    // 轮播图点击新增
    async toAddRotationImg(type) {
      let img;
      let src;
      let mes;
      let status;
      let name;
      if (type == "rotationImg") {
        // 轮播图
        let newRotationImg = this.newRotationImg;
        img = newRotationImg.img;
        src = newRotationImg.src;
        mes = newRotationImg.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        }
      } else if (type == "menuList") {
        // 菜单列表
        let newMenuList = this.newMenuList;
        img = newMenuList.img;
        src = newMenuList.src;
        mes = newMenuList.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        } else if (null == mes || "" == mes || undefined == mes) {
          return this.$message.warning("名称不能为空");
        }
      } else if (type == "imageText") {
        // 图文列表
        let newTextImage = this.newTextImage;
        img = newTextImage.img;
        src = newTextImage.src;
        mes = newTextImage.mes;
        if (null == img || "" == img || undefined == img) {
          return this.$message.warning("请先上传图片");
        } else if (null == src || "" == src || undefined == src) {
          return this.$message.warning("跳转路径不能为空");
        }
      } else {
        return this.$message.warning("类型有误，请刷新后重试");
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await addLabel({
        smallRoutineId: this.showSingleId,
        img: img,
        src: src,
        mes: mes,
        type: type,
        status: status,
        name: name,
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      // 轮播图数组插入新增数据
      let dataModificationMes = this.dataModificationMes;
      let labelList = [];
      if ("rotationImg" == type) {
        // 轮播图
        // 拼接函数(索引位置, 要删除元素的数量, 元素)
        labelList = dataModificationMes.rotationImgList;
        this.newRotationImg = {};
      } else if ("menuList" == type) {
        labelList = dataModificationMes.menuList;
        this.newMenuList = {};
      } else if ("imageText" == type) {
        labelList = dataModificationMes.imageTextList;
        this.newTextImage = {};
      }
      labelList.splice(0, 0, res.data);
    },
    // 小程序页面编辑点击input失去焦点触发
    async smallUpdateInputBlur(e) {
      let smallUpdateInputFoncussrcOrMes = this.smallUpdateInputFoncussrcOrMes;
      let newMes;
      if (smallUpdateInputFoncussrcOrMes == "src") {
        newMes = e.src;
      } else if (smallUpdateInputFoncussrcOrMes == "mes") {
        newMes = e.mes;
      } else if (smallUpdateInputFoncussrcOrMes == "imageTextTitle") {
        newMes = e.imageTextTitle;
      } else if (smallUpdateInputFoncussrcOrMes == "name") {
        newMes = e.name;
      } else {
        return this.$message.warning("数据类型有误，请刷新后重试");
      }
      let oldMes = this.smallUpdateInputFoncusMes;
      if (null == newMes || "" == newMes || undefined == newMes) {
        this.$message.warning("不能为空");
        if (smallUpdateInputFoncussrcOrMes == "src" && e.src != oldMes) {
          e.src = oldMes;
        } else if (smallUpdateInputFoncussrcOrMes == "mes" && e.mes != oldMes) {
          e.mes = oldMes;
        } else if (
          smallUpdateInputFoncussrcOrMes == "imageTextTitle" &&
          e.name != oldMes
        ) {
          e.imageTextTitle = oldMes;
        } else if (smallUpdateInputFoncussrcOrMes == "name") {
          e.name = e.oldMes;
        }
        return;
      }
      if (
        null == smallUpdateInputFoncussrcOrMes ||
        "" == smallUpdateInputFoncussrcOrMes ||
        undefined == smallUpdateInputFoncussrcOrMes
      ) {
        e.src = oldMes;
        return this.$message.warning("数据有误，请刷新后重试");
      }
      if (newMes == oldMes) {
        // 无修改
        return;
      }
      let that = this;
      // 有修改
      let res = await updateSrcById({
        smallRoutineId: this.showSingleId,
        id: that.smallUpdateInputFoncusId,
        mes: newMes,
        type: smallUpdateInputFoncussrcOrMes,
        updateType: that.smallUpdateInputFoncusType,
      });
      if (!res.success) {
        if (smallUpdateInputFoncussrcOrMes == "imageTextTitle") {
          e.imageTextTitle = oldMes;
        } else {
          e.src = oldMes;
        }
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 删除小程序label信息
    async deleteThisLabel(list, index, id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      // 有修改
      let res = await delLabel(id);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      // 从inedx的位置开始向后删除1个元素
      list.splice(index, 1);
      this.$message.success("删除成功");
    },
  },
};
</script>

<style lang="less" scoped>
// 小程序页面展示编辑
.small_update_list {
  display: flex;
  flex-wrap: wrap;
  .small_update_single {
    // 单个对象
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: 280px;
    margin-left: 10px;
    // 图片
    .small_update_single_img_div {
      /deep/.el-upload {
        width: 100% !important;
        height: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px !important;
      }
      .small_update_single_img {
        width: 100%;
        height: 100%;
      }
    }
    // 图片下信息
    .small_update_single_mes {
      width: 100%;
      .small_update_single_mes_input {
        margin: 5px 5px;
      }
      .small_update_single_mes_button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 5px 5px 5px;
      }
    }
  }
}
</style>
