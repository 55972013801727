<script>
import * as echarts from "echarts"

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    xAxis: {
      default: () => ({type: 'category', data: []}),
    },
    yAxis: {
      default: () => ({type: 'value'}),
    },
    series: {
      default: () => ([{data: [], type: 'line', label: {show: true}}]),
    }
  },
  methods: {
    // 初始化折线图
    initLineChart() {
      const lineChart = echarts.init(document.getElementById(this.id))
      lineChart.setOption({
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: this.series,
      })
      lineChart.resize()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.initLineChart()
    })
  }
}
</script>

<template>
  <div :id="id" class="line-chart" style="width: 960px; height: 300px;"></div>
</template>

<style scoped lang="less">

</style>