<template>
  <div class="div_home">
    <el-form
      :model="formMes"
      :rules="formRules"
      ref="formRef"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 短信签名 -->
      <el-form-item label="短信签名" prop="signName">
        <el-input
          v-model="formMes.signName"
          placeholder="请输入短信签名"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 模板CODE -->
      <el-form-item label="模板CODE" prop="templateCode">
        <el-input
          v-model="formMes.templateCode"
          placeholder="请输入模板CODE"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toSave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "sendSmsConfiguration",
  props: ["type", "visible"],
  watch: {
    visible(e) {
      if (e) {
        this.getMes(this.type);
      }
    },
  },
  data() {
    return {
      showLoading: false,
      formMes: {},
      // 表单验证
      formRules: {
        signName: [
          { required: true, message: "请输入短信签名", trigger: "blur" },
        ],
        templateCode: [
          { required: true, message: "请输入模板CODE", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes(this.type);
    },
    // 获取数据
    async getMes(type) {
      // 根据id获取数据
      this.showLoading = true;
      const { data: res } = await this.$http.get(
        "/filmSendSmsConfiguration/getMes?type=" + type
      );
      this.showLoading = false;
      let message = res.message;
      if (res.code != 200) {
        return this.$message.error(message);
      }
      this.formMes = message;
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.get("/turnTableReward/list", {
        params: { ...this.queryInfo },
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes = res.message;
    },

    // 点击提交
    toSave() {
      this.$refs["formRef"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 保存
    async save() {
      this.showLoading = true;
      const { data: res } = await this.$http.put(
        "/filmSendSmsConfiguration/updateMes",
        {
          ...this.formMes,
        }
      );
      this.showLoading = false;
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  .buttomButton {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
