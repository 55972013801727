<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页跳转H5</el-divider>
      <!-- 名称 -->
      <el-form-item label="跳转h5" prop="jumpToH5">
        <el-input
          v-model="updateForm.jumpToH5"
          placeholder="请输入跳转h5"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="开启跳转" prop="jumpToH5Status">
        <el-switch
          v-model="updateForm.jumpToH5Status"
          @change="swiperChange(1, $event)"
        >
        </el-switch>
      </el-form-item>
      <el-divider content-position="left">首页跳转小程序</el-divider>
      <!-- 名称 -->
      <el-form-item label="小程序appId" prop="jumpToSmallRoutineAppId">
        <el-input
          v-model="updateForm.jumpToSmallRoutineAppId"
          placeholder="请输入跳转小程序appId"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序path" prop="jumpToSmallRoutinePath">
        <el-input
          v-model="updateForm.jumpToSmallRoutinePath"
          placeholder="请输入跳转小程序页面路径"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="开启跳转" prop="jumpToSmallRoutine">
        <el-switch
          v-model="updateForm.jumpToSmallRoutine"
          @change="swiperChange(2, $event)"
        >
        </el-switch>
      </el-form-item>
      <el-divider content-position="left">淘宝商品管理</el-divider>
      <!-- 是否开启 -->
      <el-form-item label="商品/云码接口" prop="commodityInterfaceStatus">
        <el-switch v-model="updateForm.commodityInterfaceStatus"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallRoutine2Update",
  props: ["updateCode", "refaushList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {},
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm(this.updateCode);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.warning(
            "首页自动跳转H5开关开启时跳转的h5路径不能为空"
          );
        }
        if (
          updateForm.jumpToSmallRoutine &&
          this.isnull(updateForm.jumpToSmallRoutineAppId)
        ) {
          return this.$message.warning(
            "首页自动跳转小程序开关开启时跳转的小程序appId不能为空"
          );
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.post(
        "/smallRoutine2Configuration/updateConfiguration",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(id) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "smallRoutine2Configuration/getConfiguration?smallRoutine2Code=" + id
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
    // 跳转小程序或h5开关
    swiperChange(e, s) {
      if (s) {
        if (e == 1) {
          // 跳转h5，关闭跳转小程序
          this.updateForm.jumpToSmallRoutine = false;
        } else if (e == 2) {
          // 跳转小程序，关闭h5
          this.updateForm.jumpToH5Status = false;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
