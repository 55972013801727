<template>
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="configuration"
        ref="addFormRed"
        label-width="auto"
        :rules="formRules"
      >
        <el-divider content-position="left">电影配置</el-divider>
        <el-form-item label="电影域名配置" prop="url">
          <el-input
            v-model="configuration.url"
            placeholder="请输入电影域名配置"
            clearable
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item label="应用id" prop="appId">
          <el-input
            v-model="configuration.appId"
            placeholder="请输入应用id"
            clearable
            maxlength="10"
          ></el-input>
        </el-form-item>
        <el-form-item label="代理id" prop="agentId">
          <el-input
            v-model="configuration.agentId"
            placeholder="请输入agentId"
            clearable
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item label="secret" prop="secret">
          <el-input
            v-model="configuration.secret"
            placeholder="请输入secret"
            clearable
            maxlength="255"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="tel">
          <el-input
            v-model="configuration.tel"
            placeholder="请输入手机号"
            clearable
            maxlength="11"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否按市场价出售" prop="marketValue">
          <el-switch v-model="configuration.marketValue"> </el-switch>
        </el-form-item>
        <!-- <el-divider content-position="left">电影票票价增加</el-divider>
        <el-form-item label="百分比(优先)" prop="increasePricePercentage">
          <el-input
            v-model="configuration.increasePricePercentage"
            placeholder="请输入电影票票价增加百分比"
            clearable
            maxlength="10"
            @input="priceFormatTotalPricePercentage"
          ></el-input>
        </el-form-item>
        <el-form-item label="指定金额" prop="increasePriceFixed">
          <el-input
            v-model="configuration.increasePriceFixed"
            placeholder="请输入电影票票价增加指定金额"
            clearable
            maxlength="10"
            @input="priceFormatTotalPriceFixed"
          ></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="add">保 存</el-button>
        </el-form-item>
        <el-divider content-position="left">其他功能</el-divider>
        <el-form-item>
          <el-button type="primary" @click="getBalance">余额查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getSendSmsConfiguration(1)">
            出票成功短信管理
          </el-button>
          <el-button type="primary" @click="getSendSmsConfiguration(0)">
            出票失败短信管理
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 余额详情 -->
    <el-drawer
      title="余额详情"
      :visible.sync="balanceMessageVisible"
      direction="rtl"
      size="60%"
      :with-header="false"
    >
      <balanceMes ref="balanceMesRef" />
    </el-drawer>
    <!-- 底部菜单管理 -->
    <el-dialog
      title="底部菜单管理"
      :visible.sync="sendSmsConfiguration.visible"
      width="50%"
    >
      <sendSmsConfiguration
        :visible="sendSmsConfiguration.visible"
        :type="sendSmsConfiguration.type"
        ref="sendSmsConfigurationRef"
      />
    </el-dialog>
  </div>
</template>

<script>
import balanceMes from "../film/balanceMes.vue";
import sendSmsConfiguration from "../configuration/sendSmsConfiguration/sendSmsConfiguration.vue";
export default {
  components: {
    balanceMes,
    sendSmsConfiguration,
  },
  name: "alipayUserAlipayPointSend",
  data() {
    return {
      configuration: {},
      // 表单验证
      formRules: {
        url: [
          {
            required: true,
            message: "请输入电影域名配置",
            trigger: "blur",
          },
        ],
        appId: [
          {
            required: true,
            message: "请输入电影应用id",
            trigger: "blur",
          },
        ],
        agentId: [
          {
            required: true,
            message: "请输入电影代理Id",
            trigger: "blur",
          },
        ],
        secret: [
          {
            required: true,
            message: "请输入电影secret",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
        ],
      },
      // 余额查询
      balanceMessageVisible: false,
      // 电影短信管理
      sendSmsConfiguration: {
        visible: false,
        type: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await this.$http.get("filmConfiguration/getMes");
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.configuration = message;
    },
    add() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) return;
        let configuration = this.configuration;
        //发起请求
        const { data: res } = await this.$http.put(
          "filmConfiguration/updateMes",
          configuration
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
      });
    },
    // 余额查询
    async getBalance() {
      if (this.$refs.balanceMesRef != undefined) {
        this.$refs.balanceMesRef.init();
      }
      this.balanceMessageVisible = true;
    },
    // 短信配置管理
    getSendSmsConfiguration(type) {
      this.sendSmsConfiguration.type = type;
      this.sendSmsConfiguration.visible = true;
    },
    // 金额正则表达式
    priceFormatTotalPriceFixed(e) {
      this.configuration.increasePriceFixed =
        this.configuration.increasePriceFixed.match(/^\d*(\.?\d{0,2})/g)[0] ||
        null;
      if (isNaN(this.configuration.increasePriceFixed)) {
        this.configuration.increasePriceFixed = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.configuration.increasePriceFixed.length >= 2
      ) {
        this.configuration.increasePriceFixed =
          this.configuration.increasePriceFixed.replace(/0/, "");
      }
    },
    priceFormatTotalPricePercentage(e) {
      this.configuration.increasePricePercentage =
        this.configuration.increasePricePercentage.match(
          /^\d*(\.?\d{0,2})/g
        )[0] || null;
      if (isNaN(this.configuration.increasePricePercentage)) {
        this.configuration.increasePricePercentage = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.configuration.increasePricePercentage.length >= 2
      ) {
        this.configuration.increasePricePercentage =
          this.configuration.increasePricePercentage.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
