<template>
    <div		class="container">
        <el-button  class="button" type="primary" @click="save_slogan">保存</el-button>
        <el-form    label-position="left"    label-width="150px">
            <el-form-item label="给用户钱的提示语">
                <el-input v-model="top_left_slogan" placeholder="请输入给用户钱的提示语"></el-input> 
            </el-form-item>
        </el-form>
    </div>
    </template>
    
    <script>
    import axios from 'axios';
    import store from '@/store/index.js';
    import { handleResponse } from '@/services/wCommonFunction.js'
    export default{
        components:{
            
        },
        props: {

        },
        data() {
            return {
                top_left_slogan:''
            }
        },
        methods:{
            //根据输入进来的数据 处理
            async initiailize_data(){
                let res_slogan = await axios.get(store.state.global_base_url.main + 'redpacket/swiperPage/getRewardPrompt');
                this.top_left_slogan = handleResponse(res_slogan);
            },
            //保存
            async    save_slogan(){
                let res = await axios({
                    method:"PUT",
                    url:store.state.global_base_url.main+"redpacket/swiperPage/setRewardPrompt/"+this.top_left_slogan,
                });
                let res_data = handleResponse(res);
                if(res_data){
                    this.$message.success("成功保存");
                    this.initiailize_data();
                }else{
                    this.$message.error("失败");
                }
            },
        },
        computed:{
            
        },
        created(){
            this.initiailize_data();
        },
        watch:{
            
        }
    }
    </script>
    
    <style  lang="less" scoped>
    .container{
        position: relative;
        min-width: 200px;
        max-width: 1000px;
        .button{
            margin-top: 20px;
        }
    }
    .container	.el-table{
        border-radius: 10px;
        max-height: 410px;
        overflow-y: auto;
    }
    </style>