<!-- 这个是用来做 自定义广告第一条 类别选择  -->
<template>
<el-select :value="type" placeholder="请选择"  @change="chooseType" >
    <el-option  v-for="item in advertiseTypeOptions"  :key="item.key"  :label="item.name"  :value="item.key">
    </el-option>
</el-select>
</template>

<script>
    import axios from 'axios';
    import { mapState } from 'vuex';
export default{
    components:{
        
    },
    props: {
        type:{
            type:String,
        }
    },
    data() {
        return {
            advertiseTypeOptions:null,
            advertiseType: null
        }
    },
    methods:{
        async    initialize_data(){
            const {data:{message:res}} =    await axios({
                url:this.httpUrl + 'tb-third-party-wang-redpacket-custom-advertise/list'
            }) ;
            this.advertiseTypeOptions  = res;
        },
        chooseType(choose_key){
            this.$emit("update:type",choose_key);
        }
    },
    computed:{
        ...mapState({
			httpUrl:state=>state.global_base_url.main
		})
    },
    created(){
        setTimeout(
            this.initialize_data,
            0
        );
    },
}
</script>

<style  lang="less" scoped>

</style>