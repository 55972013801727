<template>
  <div class="view_main" v-loading="loading">
    订单详情
    <el-divider></el-divider>
    <!-- 电影详情 -->
    <div class="view_filmMes" v-if="fromMes.orderThirdInfo != undefined">
      <!-- 图片 -->
      <div class="view_filmMes_img">
        <img class="filmImg" :src="fromMes.orderThirdInfo.film_img" />
      </div>
      <!-- 信息 -->
      <div class="view_filmMes_mes">
        <div
          class="view_filmMes_mes_sinele"
          v-if="fromMes.filmCallBack != undefined"
        >
          <span class="single_title">佣金：</span>
          <span class="single_mes">{{ fromMes.filmCallBack.rebate }}</span>
        </div>
        <div class="view_filmMes_mes_sinele">
          <span class="single_title">电影名：</span>
          <span class="single_mes">{{ fromMes.orderThirdInfo.film_name }}</span>
        </div>
        <div class="view_filmMes_mes_sinele">
          <span class="single_title">影院：</span>
          <span class="single_mes">
            {{ fromMes.orderThirdInfo.cinema_name }}
          </span>
        </div>
        <div class="view_filmMes_mes_sinele">
          <span class="single_title">订单编号 ：</span>
          <span class="single_mes">
            {{ fromMes.orderThirdInfo.order_number }}
          </span>
        </div>
        <div class="view_filmMes_mes_sinele">
          <span class="single_title">影厅：</span>
          <span class="single_mes">{{ fromMes.orderThirdInfo.hall_name }}</span>
        </div>
        <div class="view_filmMes_mes_sinele">
          <span class="single_title">开场时间：</span>
          <span class="single_mes">{{ fromMes.orderThirdInfo.show_time }}</span>
        </div>
        <div
          class="view_filmMes_mes_sinele"
          v-if="
            fromMes.orderThirdInfo != undefined &&
            fromMes.orderThirdInfo.seat_data != undefined
          "
        >
          <span class="single_title">座位号：</span>
          <div class="div_seatList">
            <span
              class="single_seat"
              v-for="(item, index) in fromMes.orderThirdInfo.seat_data"
              :key="'single_seat_' + index"
            >
              {{ item.position_seat }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="div_film_other" v-if="fromMes.filmMessage != undefined">
      <el-divider content-position="left">下单结果</el-divider>
      <div>{{ fromMes.filmMessage }}</div>
    </div>
    <div class="div_film_other" v-if="fromMes.filmMessage != undefined">
      <el-divider content-position="left">订单状态</el-divider>
      <div v-if="fromMes.orderThirdInfo.handle_step == -2">出票失败</div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == -1">支付超时</div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == 0">待支付</div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == 1">
        已支付，支付成功五分钟后进入备票仓
      </div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == 2">
        出票中，等待后台备票
      </div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == 3">已出票</div>
      <div v-else-if="fromMes.orderThirdInfo.handle_step == 4">已结算</div>
      <div v-else>{{ fromMes.orderThirdInfo.handle_step }}</div>
    </div>

    <div class="div_film_other" v-if="fromMes.filmCallBack != undefined">
      <el-divider content-position="left">出票回调</el-divider>
      <div>
        {{ fromMes.filmCallBack.message }}
      </div>
    </div>
    <!-- <div
      class="div_film_other"
      v-if="
        fromMes.aliPayCallBack != undefined &&
        fromMes.aliPayCallBack.message != undefined
      "
    >
      <el-divider content-position="left">支付宝支付回调</el-divider>
      <div>
        {{ fromMes.aliPayCallBack.message }}
      </div>
    </div> -->
    <div class="div_film_other" v-if="fromMes.aliTransferRecord != undefined">
      <el-divider content-position="left">退款结果</el-divider>
      <div>
        {{ fromMes.aliTransferRecord.subMsg }}
      </div>
      <div v-if="fromMes.aliTransferRecord.orderId != undefined">
        订单号：{{ fromMes.aliTransferRecord.orderId }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "orderMes",
  props: ["id"],
  data() {
    return {
      fromMes: {},
      loading: false,
    };
  },
  created() {
    this.init(this.id);
  },
  methods: {
    async init(id) {
      this.loading = true;
      const { data: res } = await this.$http.get(
        "/filmOrder/getMesById?id=" + id
      );
      this.loading = false;
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.fromMes = res.message;
    },
  },
};
</script>

<style lang="less" scoped>
.view_main {
  padding: 24px;
  .view_filmMes {
    display: flex;
    .view_filmMes_img {
      padding-right: 50px;
      .filmImg {
        height: 343px;
        widows: 480px;
      }
    }
    .view_filmMes_mes {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .view_filmMes_mes_sinele {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        .single_title {
          white-space: nowrap;
          min-width: 100px;
          text-align: right;
          padding-right: 15px;
        }
        .single_mes,
        .single_seat {
          color: rgba(0, 0, 0, 0.45);
        }
        .single_seat {
          white-space: nowrap;
          padding-right: 10px;
        }
      }
    }
  }
}
</style>
