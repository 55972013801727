// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineServiceRel/';

/**
 * 分页查询小程序与服务关联表
 * @param {*} params
 */
export async function getRelList(data={}) { 
  const { data: res } = await axios.get(
    path + 'getRelList',
    {
      params: { 
        ...data
       },
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "查询小程序与服务关联表失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


/**
 * 修改展示状态
 * @param {*} params
 */
 export async function updateShowStatus(data={}) { 
  const { data: res } = await axios.put(
    path + 'updateShowStatus',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "修改展示状态失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

