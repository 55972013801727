<template>
  <div class="uploadImg">
    <el-upload
      class="avatar-uploader"
      ref="uploadImg"
      :action="this.$httpUrl + 'upload/uploadFile'"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :headers="headerObj"
    >
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: "uploadImg",
  props: ["chooseImg"],
  data() {
    return {
      headerObj: {},
      imageUrl: "",
    };
  },
  created() {
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
  },
  methods: {
    // 父组件关闭当前页面
    clearMes() {
      // 清除已上传的图片
      this.imageUrl = "";
    },
    handleAvatarSuccess(res) {
      if (res.code == 200) {
        this.imageUrl = res.message;
        this.chooseImg(res.message);
      }
    },
    // 修改图片路径
    changeImageUrl(imageUrl) {
      this.imageUrl = imageUrl;
    },
    beforeAvatarUpload(file) {
      // 允许文件上传的最大M
      const maxSize = 2;
      // 允许上传的文件格式
      const fileType = ["image/jpeg", "image/png"];
      // 允许上传的文件大小
      const fileSize = file.size / 1024 / 1024 < maxSize;
      if (!fileSize) {
        this.$message.error("上传头像图片大小不能超过 " + maxSize + "MB!");
        return false;
      }
      if (fileType.indexOf(file.type) != -1) {
        return true;
      } else {
        this.$message.error("上传图片只能是 JPG 或 PNG 格式!");
        return false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  border: 1px solid #409eff;
}
.avatar-uploader,
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
img {
  width: 100%;
  height: 100%;
}
</style>
