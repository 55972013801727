// 配置搜索直达
const path = 'alipayOpenSearchBoxActivity/';
import axios from 'axios'

/**
 * 批量查询
 */
export async function batchQuery(data={}) {
  const { data: res } = await axios.post(
    path + 'batchQuery',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 查询详情
 */
export async function query(data={}) {
  const { data: res } = await axios.post(
    path + 'query',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 申请创建
 */
export async function apply(data={}) {
  const { data: res } = await axios.post(
    path + 'apply',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

