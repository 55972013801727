// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineMessageTemplate/';

/**
 * 小程序消息模板列表查询
 * @param {*} params
 */
export async function getMessageTemplateList(data={}) {
  const { data: res } = await axios.get(
    path + 'list',
    {
      params: { 
        ...data
       },
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "小程序消息模板列表查询失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


/**
 * 新增小程序消息模板
 * @param {*} params
 */
 export async function addMessageTemplate(data={}) {
  const { data: res } = await axios.post(
    path + 'add',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


/**
 * 修改状态
 * @param {*} params
 */
 export async function updateStateMessageTemplate(data={}) {
  const { data: res } = await axios.put(
    path + 'updateState',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}



/**
 * 删除
 * @param {*} params
 */
 export async function delMessageTemplate(id) {
  const { data: res } = await axios.delete(
    path + 'del?id='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据id获取数据
 * @param {*} params
 */
 export async function getUpdateMesById(id) {
  const { data: res } = await axios.get(
    path + 'getUpdateMesById?id='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


/**
 * 修改
 * @param {*} params
 */
 export async function updateMessageTemplate(data={}) {
  const { data: res } = await axios.put(
    path + 'update',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
