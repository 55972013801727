<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="100"
          show-word-limit
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="后台路径" prop="serviceId">
        <el-select
          v-model="form.serviceId"
          clearable
          placeholder="请选择后台路径"
          class="serviceIdChoose"
        >
          <el-option
            v-for="item in serviceList"
            :key="item.id"
            :label="item.url"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getAllMes } from "@/services/shortLink/shortLinkService";
import { smallRoutineTemplateCreateShortLink } from "@/services/shortLink/shortLink";
export default {
  props: ["id", "createShortLinkSuccess"],
  name: "createShortLink",
  data() {
    return {
      form: {
        name: "",
        serviceId: "",
      },
      // 表格提交验证
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        serviceId: [
          { required: true, message: "请选择后台路径", trigger: "change" },
        ],
      },
      // 后台地址列表
      serviceList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await getAllMes();
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
      }
      let serviceList = res.data;
      this.serviceList = serviceList;
      if (serviceList.length > 0) {
        this.$set(this.form, "serviceId", serviceList[0].id);
      }
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await smallRoutineTemplateCreateShortLink({
        templateId: this.id,
        ...this.form,
      });
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.createShortLinkSuccess(res.data);
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped>
.serviceIdChoose {
  width: 100%;
}
</style>
