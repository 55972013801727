<template>
  <div class="div_home">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo home_menu"
      mode="horizontal"
      background-color="#373d41"
      text-color="#ffffff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="1">&nbsp;&nbsp;提现记录&nbsp;&nbsp;</el-menu-item>
      <el-menu-item index="2">余额变更记录</el-menu-item>
    </el-menu>
    <withdrawalRecordLog
      v-show="activeIndex == '1'"
      ref="withdrawalRecordLog"
    />
    <moneyLog v-show="activeIndex == '2'" ref="moneyLog" />
  </div>
</template>

<script>
import moneyLog from "./userMoneyLog.vue";
import withdrawalRecordLog from "./withdrawalRecordLog.vue";
export default {
  components: { moneyLog, withdrawalRecordLog },
  data() {
    return {
      // 默认展示模块
      activeIndex: "1",
      // 小程序列表
      smallList: [],
      // 唯一标识列表
      smallCodeList: [],
    };
  },
  created() {
    // 获取小程序唯一标识列表
    this.getRoutineCodeList();
  },
  methods: {
    // 顶部菜单变化
    handleSelect(e) {
      this.activeIndex = e + "";
    },
    // 获取小程序唯一标识列表
    async getRoutineCodeList() {
      const { data: res } = await this.$http.get(
        "/smallRoutine/getSmallNameCodeList"
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      let mes = res.message;
      let length = mes.length;
      if (length == 0) {
        return this.$message.error("您当前暂无管理的小程序");
      }
      this.smallList = res.message;
      let smallCodeList = [];
      for (let i = 0; i < length; i++) {
        smallCodeList.push(mes[i].code);
      }
      this.smallCodeList = smallCodeList;
      this.$refs.withdrawalRecordLog.initSmallRoutine({
        list: this.smallList,
        codeList: this.smallCodeList,
      });
      this.$refs.moneyLog.initSmallRoutine({
        list: this.smallList,
        codeList: this.smallCodeList,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  height: 99%;
  .home_menu {
    position: fixed;
    top: 0;
  }
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .div_input {
    display: flex;
    align-items: center;
    .div_input_text {
      white-space: nowrap;
      padding-right: 3px;
    }
  }
}
</style>
