<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.name"
                clearable
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toMenuTwo()"
            >
              返回二级菜单
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.img" width="60" height="60" class="head_pic" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="是否售卖" prop="saleText"></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog
      title="添加商品"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <bottomCommodityAdd
        :refaushList="getList"
        :parentId="parentId"
        :menuOneId="menuOneId"
        ref="addRef"
      />
    </el-dialog>
    <!-- 编辑信息 -->
    <el-dialog
      title="编辑商品"
      :visible.sync="updateDialogVisible"
      width="50%"
      @close="updateDialogClose"
    >
      <bottomCommodityUpdate
        :refaushList="getList"
        :updateId="updateId"
        :parentId="parentId"
        :menuOneId="menuOneId"
        ref="updateRef"
      />
    </el-dialog>
  </div>
</template>

<script>
import bottomCommodityAdd from "./add.vue";
import bottomCommodityUpdate from "./update.vue";
export default {
  name: "bottomCommodityCommdity",
  components: {
    bottomCommodityAdd,
    bottomCommodityUpdate,
  },
  data() {
    return {
      // 一级菜单id
      menuOneId: "",
      // 二级菜单id
      parentId: "",
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 是否展示编辑弹窗
      updateDialogVisible: false,
      // 编辑的对象id
      updateId: {},
    };
  },
  created() {
    // 获取当前需要展示的页码及条件(如果是消息模板返回的时候有值，没有则默认从第一页开始)
    this.getQueryInfo();
    this.getList();
  },
  methods: {
    // 获取表格列表查询条件
    getQueryInfo() {
      // 二级菜单
      let parentId = window.sessionStorage.getItem(
        "smallRoutine3BottomMenuTwoId"
      );
      let menuOneId = window.sessionStorage.getItem(
        "smallRoutine3BottomMenuOneId"
      );
      if (this.isNull(parentId) || this.isNull(menuOneId)) {
        let that = this;
        this.$message.error("参数丢失");
        return setTimeout(function () {
          that.$router.push({
            path: "/",
          });
        }, 1000);
      }
      this.parentId = parentId;
      this.menuOneId = menuOneId;
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post(
        "/smallRoutine3BottomCommodity/list",
        {
          ...this.queryInfo,
          parentId: this.parentId,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 关闭新增弹窗
    addDialogClose() {
      this.$refs.addRef.resetForm();
    },
    // 关闭编辑弹窗
    updateDialogClose() {
      this.updateId = "";
      this.$refs.updateRef.resetForm();
    },
    // 编辑
    showEditDialog(e) {
      if (this.$refs.updateRef == undefined) {
        this.updateId = e.id;
      } else {
        this.$refs.updateRef.refaushForm(e.id);
      }
      this.updateDialogVisible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "smallRoutine3BottomCommodity/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const { data: res } = await this.$http.put(
        `/smallRoutine3BottomCommodity/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 返回二级菜单
    toMenuTwo() {
      window.sessionStorage.removeItem("smallRoutine3BottomMenuTwoId");
      this.$router.push({
        path: "/smallRoutine3BottomCommodityMenuTwo",
      });
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
}
</style>
