<template>
<div		class="container">
    <div	class="navigator_container">
		<div	class="block"	v-for="(item,index) in digestList"		:key="index"		
			:class="current_index == index ? 'block_choosed':''"	
			@click="choose_sort(index)">
			<div	class="title">
				{{item.groupName}}  
			</div> 
		</div>            
    </div>
</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { handleResponse } from '@/services/wCommonFunction.js'
export default{
    components:{
        
    },
    props: {
        
    },
    data() {
        return {
            // 这个是数据列表，在created那边有初始化
			//先加载这个粗略版本的，后续加载完整版的
			digestList:[],
            // 转化为Array类型的列表
            list_promise:null,
			list_promise_resolve_fn:null,
			//是否正在加载详细列表
			is_loading_list:true,
			// 目前选择了哪一项列表中的项目
            current_index:null,
        }
    },
    methods:{
       	async choose_sort(index){		//选中某一类
			this.current_index = index ;
            let current_value = (await this.list_promise)[index];
            let choose_prop = ['logoImagePathList','digestList','describeList'];
            let output = {};
            choose_prop.forEach(e => {
                let prop_list = JSON.parse(current_value[e]);
                let prop_list_length = prop_list.length;
                if(prop_list_length == 0){
                    output[e] = null;
                } else{
                    output[e] = prop_list[(new Date().getMilliseconds()) % prop_list_length] ;
                }
            });
            this.$emit("overideInfo",output);
		},
        
    },
    computed:{
        ...mapState({
            httpUrl:state=>state.global_base_url.main
        }),
		block_class:(index)=>{
			let output = "";
			if(this.current_index === index){
				output += "block_choosed "
			}
			if(this.is_loading_list){
				output += "block_loading "
			}
			return output;
		}
    },
    created(){
		this.list_promise = new Promise((resolve)=>{
			this.list_promise_resolve_fn = resolve;
		})
		//加载粗略的数据
        axios({
            url:this.httpUrl + 'tb-third-party-small-routine-audit-template/listDigest'
        })
        .then(res=>{
            this.digestList = handleResponse(res);
        })
		//加载细致的数据
		axios({
            url:this.httpUrl + 'tb-third-party-small-routine-audit-template/list'
        })
        .then(res=>{
            let data = handleResponse(res);
			this.list_promise_resolve_fn(data);
			this.is_loading_list = false;
        })
    },
    watch:{
        
    }
}
</script>
    
<style  lang="less" scoped>
.navigator_container{
	display: flex;	flex-wrap: wrap;
	padding-bottom:2px;
    margin-bottom: 50px;
	.block{
		min-width: 180px;	height: 40px;		
		border-radius: 4px;
		margin-top: 10px;	margin-right: 18px;
		display: flex;	flex-direction: column;
        align-items: center;	
		position: relative;
		cursor: pointer; 
		background-color: #eee;
		border:1px solid #eee;
		transition: background-color 0.5s;
		.title{
			margin-top: 10px;		margin-left: 10px;
			width: 160px;		height: 20px;
			color: #444;
			display: flex;	justify-content: space-between;
            align-items: center;	
		}
	}
	.block_choosed{
		background-color: #409EFF;	
		color: white;
		.title{
			color: white;
		}
	}
	.block_loading{	//正在加载的状态
		cursor: not-allowed;
	}
}
	
	/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
	::-webkit-scrollbar {
		width: 0.75rem;
		height: 0.75rem;
		background-color: #F5F5F5;
	}
	
	/*定义滚动条轨道 内阴影+圆角*/
	::-webkit-scrollbar-track {
		// box-shadow: inset 0 0 0.375rem #999;
		border-radius: 0.625rem;
		background-color: #F5F5F5;
	}
	
	/*定义滑块 内阴影+圆角*/
	::-webkit-scrollbar-thumb {
		border-radius: 0.625rem;
		box-shadow: inset 0 0 0.375rem #ccc;
		background-color: #aaa;
	}

</style>