<template>
  <div>
    <el-form
      class="loginForm"
      :model="formMes"
      ref="ruleForm"
      label-width="100px"
    >
      <el-divider content-position="left">新人红包配置</el-divider>
      <!-- 基础配置 -->
      <div>
        <el-form-item label="展示口令红包" prop="showPasswordRedEnvelope">
          <el-switch v-model="formMes.showPasswordRedEnvelope"></el-switch>
        </el-form-item>
        <el-form-item label="展示顶部图" prop="showTitleImg">
          <el-switch v-model="formMes.showTitleImg"></el-switch>
        </el-form-item>
        <el-form-item label="展示表单" prop="showFormTable">
          <el-switch v-model="formMes.showFormTable"></el-switch>
        </el-form-item>
        <el-form-item label="页面标题" prop="title">
          <el-input
            maxlength="10"
            show-word-limit
            clearable
            v-model="formMes.title"
            placeholder="页面标题，放空默认后台管理小程序名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="顶部图片" prop="exhibit">
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="uploadExhibitFileChange"
          >
            <img
              class="div_main_img"
              v-if="exhibitMes.img"
              :src="exhibitMes.img"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="展示详情图" prop="enlarged">
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="uploadEnlargedFileChange"
          >
            <img
              class="div_main_img"
              v-if="enlargedMes.img"
              :src="enlargedMes.img"
            />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </div>
      <el-divider content-position="left">复制口令</el-divider>
      <div>
        <el-form-item label="复制口令" prop="copyCode">
          <el-switch v-model="formMes.copyCode"></el-switch>
        </el-form-item>
        <el-form-item label="复制按钮文字" prop="copyCodeText">
          <el-input
            maxlength="100"
            show-word-limit
            clearable
            v-model="formMes.copyCodeText"
            placeholder="请输入复制按钮文字,默认：复制口令支付宝首页搜索领红包"
          ></el-input>
        </el-form-item>
        <el-form-item label="按钮背景颜色" prop="copyCodeBackColor">
          <el-color-picker
            v-model="formMes.copyCodeBackColor"
          ></el-color-picker>
        </el-form-item>
        <el-form-item label="按钮文字颜色" prop="copyCodeTextColor">
          <el-color-picker
            v-model="formMes.copyCodeTextColor"
          ></el-color-picker>
        </el-form-item>
        <el-form-item label="复制内容" prop="copyCodeMes">
          <el-input
            maxlength="100"
            show-word-limit
            clearable
            v-model="formMes.copyCodeMes"
            placeholder="请输入点击复制口令复制内容"
          ></el-input>
        </el-form-item>
      </div>
      <!-- 输入栏列表 -->
      <div class="main_table">
        <el-divider content-position="left">输入栏列表</el-divider>
        <div class="main_table_button">
          <el-button
            v-show="!updateLabelTable"
            class="main_table_title_button_add"
            type="primary"
            size="small"
            @click="toUpdateLabelTable"
          >
            编辑标签
          </el-button>
          <div v-show="updateLabelTable">
            <el-button
              class="main_table_title_button_add"
              type="primary"
              size="small"
              @click="updateLabelTableCancel"
            >
              取消编辑
            </el-button>
            <el-button
              class="main_table_title_button_add"
              type="primary"
              icon="el-icon-plus"
              size="small"
              @click="addLabel()"
            >
              新增列
            </el-button>
            <el-button
              class="main_table_title_button_add"
              type="primary"
              size="small"
              @click="updateLabelTableSave"
            >
              完成编辑
            </el-button>
          </div>
        </div>
        <el-form-item v-show="!labelTableMes.update" label="当前表单名称">
          {{ null == labelTableMes.name ? "空" : labelTableMes.name }}
        </el-form-item>
        <el-form-item v-show="labelTableMes.update" label="新表单名称">
          <el-input
            maxlength="10"
            show-word-limit
            v-model="labelTableMes.name"
            placeholder="新表单名称"
          ></el-input>
        </el-form-item>

        <el-table :data="labelTableMes.list" border stripe max-height="500">
          <el-table-column type="index" fixed></el-table-column>
          <el-table-column label="标签名称" prop="appId">
            <template slot-scope="scope">
              <el-input
                :disabled="!updateLabelTable"
                maxlength="10"
                show-word-limit
                v-model="scope.row.label"
                placeholder="标签名称"
                @focus="labelFocus(scope.row)"
                @blur="labelBlur(scope.row)"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="是否必填" prop="required">
            <!-- 作用域插槽 -->
            <template slot-scope="scope">
              <!-- {{scope.row}}每一行封装的数据 -->
              <el-switch
                v-model="scope.row.required"
                :disabled="!updateLabelTable"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="类型" prop="required">
            <!-- 作用域插槽 -->
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.type"
                placeholder="输入栏类型"
                :disabled="!updateLabelTable"
              >
                <el-option
                  v-for="item in labelTableMes.typeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="操作" fixed="right">
            <template slot-scope="scope">
              <div v-show="updateLabelTable">
                <el-button
                  icon="el-icon-caret-top"
                  size="mini"
                  :disabled="scope.$index == 0"
                  @click="updateLabelSort(scope.$index, -1)"
                  slot="reference"
                ></el-button>
                <el-button
                  icon="el-icon-caret-bottom"
                  size="mini"
                  :disabled="scope.$index == labelTableMes.list.length - 1"
                  @click="updateLabelSort(scope.$index, 1)"
                  slot="reference"
                ></el-button>
                <!-- 删除 -->
                <!-- <el-popconfirm
                    title="是否删除该数据"
                    icon="el-icon-info"
                    icon-color="red"
                    @confirm="deleteThis(scope.$index)"
                  > -->
                <el-button
                  class="table_button_del"
                  type="danger"
                  icon="el-icon-delete"
                  size="mini"
                  slot="reference"
                  @click="deleteThis(scope.$index)"
                ></el-button>
                <!-- </el-popconfirm> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-form-item class="form_button">
        <el-button type="primary" @click="submitForm('ruleForm')">
          保存
        </el-button>
      </el-form-item>
    </el-form>
    <!-- <el-dialog title="表单名称" :visible.sync="labelTableMes.visible">
      <el-input v-model="labelTableMes.name"></el-input>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveNewLabelTableName">
          确 定
        </el-button>
      </div>
    </el-dialog> -->
  </div>
</template>
<script>
import {
  getDefaultConfiguration,
  updateNoFile as updateNoFileDefault,
  updateExhibitFile as updateExhibitFileDefault,
  updateEnlargedFile as updateEnlargedFileDefault,
  updateAllFile as updateAllFileDefault,
} from "@/services/redEnvelopeForNewcomers/configurationDefault.js";
import {
  getConfiguration,
  updateNoFile,
  updateExhibitFile,
  updateEnlargedFile,
  updateAllFile,
} from "@/services/redEnvelopeForNewcomers/configuration.js";
export default {
  name: "defaultConfiguration",
  props: ["visible", "appId"],
  data() {
    return {
      formMes: {},
      // 顶部图
      exhibitMes: {},
      // 展示图
      enlargedMes: {},
      // 标签列表编辑状态
      updateLabelTable: false,
      // 标签列表
      oldTableList: [],
      // 获取焦点的input值
      oldInputMes: "",
      // 标签表单信息
      labelTableMes: {
        update: false,
        visible: false,
        name: "",
        list: [],
        // 初始化标签列表备份
        listBackups: [],
        // 默认新增信息
        newLabelMes: {
          required: true,
          label: "",
          type: "1",
        },
        // 标签类型
        typeList: [
          {
            label: "字符串",
            value: "1",
          },
          {
            label: "日期",
            value: "2",
          },
          {
            label: "地区",
            value: "3",
          },
        ],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  computed: {
    isDefault() {
      // 是否是默认配置
      return this.$publicJs.isNull(this.appId);
    },
  },
  mounted() {
    this.init();
  },

  methods: {
    async init() {
      this.$set(this, "exhibitMes", {});
      this.$set(this, "enlargedMes", {});
      let res;
      if (this.isDefault) {
        // 默认配置
        res = await this.$publicJs.request(this, getDefaultConfiguration);
      } else {
        // 小程序配置
        res = await this.$publicJs.request(this, getConfiguration, this.appId);
      }
      if (!res.success) {
        return this.$message.error(res.err);
      }
      const data = res.data;
      if (!this.$publicJs.isNull(data.exhibit)) {
        this.$set(this.exhibitMes, "img", data.exhibit);
      }
      if (!this.$publicJs.isNull(data.enlarged)) {
        this.$set(this.enlargedMes, "img", data.enlarged);
      }
      this.$set(this.labelTableMes, "update", false);
      this.$set(this.labelTableMes, "name", data.labelName);
      this.$set(this.labelTableMes, "list", data.labelList);
      delete data.exhibit;
      delete data.enlarged;
      delete data.labelName;
      delete data.labelList;
      this.$set(this, "formMes", data);
      // 拷贝初始化表格
      const tableList = this.labelTableMes.list;
      let oldTableList = [];
      for (let i = 0; i < tableList.length; i++) {
        const single = tableList[i];
        oldTableList.push({ ...single });
      }
      this.$set(this, "oldTableList", oldTableList);
    },
    // 提交修改
    async submitForm() {
      const updateLabelTable = this.updateLabelTable;
      if (updateLabelTable) {
        return this.$message.error("请先保存标签列表编辑");
      }
      let formMes = { ...this.formMes };
      formMes.appId = this.appId;
      let labelTableMes = this.labelTableMes;
      if (labelTableMes.update) {
        // 标签列表有修改
        let tableList = this.labelTableMes.list;
        if (tableList.length == 0) {
          return this.$message.error("默认标签列表不能为空");
        }
        if (!this.checkLabelListMes(tableList)) {
          return;
        }
        formMes["labelList"] = tableList;
        formMes["labelName"] = labelTableMes.name;
      }
      let exhibitMes = this.exhibitMes;
      let enlargedMes = this.enlargedMes;
      const fileRawExhibitMes = exhibitMes.fileRaw;
      const fileRawEnlargedMes = enlargedMes.fileRaw;
      let hasFile =
        !this.$publicJs.isNull(fileRawExhibitMes) ||
        !this.$publicJs.isNull(fileRawEnlargedMes);
      const formData = new FormData();
      let pathSrc;
      if (this.isDefault) {
        pathSrc = updateNoFileDefault;
      } else {
        pathSrc = updateNoFile;
      }
      if (
        !this.$publicJs.isNull(fileRawExhibitMes) &&
        !this.$publicJs.isNull(fileRawEnlargedMes)
      ) {
        if (this.isDefault) {
          pathSrc = updateAllFileDefault;
        } else {
          pathSrc = updateAllFile;
        }
        formData.append("exhibit", fileRawExhibitMes);
        formData.append("enlarged", fileRawEnlargedMes);
      } else if (!this.$publicJs.isNull(fileRawExhibitMes)) {
        if (this.isDefault) {
          pathSrc = updateExhibitFileDefault;
        } else {
          pathSrc = updateExhibitFile;
        }
        formData.append("exhibit", fileRawExhibitMes);
      } else if (!this.$publicJs.isNull(fileRawEnlargedMes)) {
        if (this.isDefault) {
          pathSrc = updateEnlargedFileDefault;
        } else {
          pathSrc = updateEnlargedFile;
        }
        formData.append("enlarged", fileRawEnlargedMes);
      }
      formMes.labelListStr = JSON.stringify(formMes.labelList);
      const formDatakeys = Object.keys(formMes);
      for (let i = 0; i < formDatakeys.length; i++) {
        const key = formDatakeys[i];
        if ( this.$publicJs.isNull(formMes[key])) {
		  console.log("key:",key,"value",formMes[key]);
          formMes[key] = "";
        }
      }
      let res;
      if (!hasFile) {
        // 无文件
        res = await this.$publicJs.request(this, pathSrc, formMes);
      } else {
        // 有文件
        const formDatakeys = Object.keys(formMes);
        for (let i = 0; i < formDatakeys.length; i++) {
          const key = formDatakeys[i];
          let mes = formMes[key];
          if (key == "labelList") {
            continue;
          }
          formData.append(key, mes);
        }
        res = await this.$publicJs.request(this, pathSrc, formData);
      }
      if (!res.success) {
        return this.$message.error(res.err);
      }
      const tableList = this.labelTableMes.list;
      let oldTableList = [];
      for (let i = 0; i < tableList.length; i++) {
        const single = tableList[i];
        oldTableList.push({ ...single });
      }
      this.$set(this, "oldTableList", oldTableList);
      this.$set(this.labelTableMes, "update", false);
      this.$message.success(res.data);
    },
    // 获取标签列表
    checkLabelListMes(tableList) {
      if (null == tableList || undefined == tableList) {
        tableList = this.labelTableMes.list;
      }
      for (let i = 0; i < tableList.length; i++) {
        const single = tableList[i];
        if (this.$publicJs.isNull(single.label)) {
          this.$message.error("第" + (i + 1) + "行的标签内容不能为空");
          return false;
        }
      }
      return true;
    },
    // 增加表格列
    addLabel() {
      let tableList = this.labelTableMes.list;
      if (!this.checkLabelListMes(tableList)) {
        return;
      }
      tableList.push({ ...this.labelTableMes.newLabelMes });
      this.$set(this.labelTableMes, "list", tableList);
    },
    // 删除列
    deleteThis(index) {
      let tableList = this.labelTableMes.list;
      tableList.splice(index, 1);
      this.$set(this.labelTableMes, "list", tableList);
    },
    // 修改顺序
    updateLabelSort(index, e) {
      let tableList = this.labelTableMes.list;
      tableList.splice(index + e, 0, tableList.splice(index, 1)[0]);
      this.$set(this.labelTableMes, "list", tableList);
    },
    // 编辑标签
    toUpdateLabelTable() {
      this.$set(this.labelTableMes, "listBackups", [
        ...this.labelTableMes.list,
      ]);
      this.$set(this, "updateLabelTable", true);
    },
    // 取消编辑
    updateLabelTableCancel() {
      this.$set(this.labelTableMes, "list", [
        ...this.labelTableMes.listBackups,
      ]);
      this.$set(this, "updateLabelTable", false);
    },
    // 完成编辑
    updateLabelTableSave() {
      const tableList = this.labelTableMes.list;
      for (let i = 0; i < tableList.length; i++) {
        const single = tableList[i];
        if (this.$publicJs.isNull(single.label)) {
          return this.$message.error("标签名称不能为空");
        }
      }
      // 标签内容有变更
      if (this.isTableListChange()) {
        // 展示标签名称输入框
        // this.$set(this.labelTableMes, "visible", true);
        this.$set(this.labelTableMes, "update", true);
      }
      this.$set(this, "updateLabelTable", false);
    },
    // 确定表单名称
    saveNewLabelTableName() {
      let labelTableMes = this.labelTableMes;
      if (this.$publicJs.isNull(labelTableMes.name)) {
        this.$set(this.labelTableMes, "name", "空");
      }
      // this.$set(this.labelTableMes, "visible", false);
    },
    // 失去焦点
    labelBlur(e) {
      let label = e.label;
      if (this.$publicJs.isNull(label)) {
        let oldInputMes = this.oldInputMes;
        if (this.$publicJs.isNull(oldInputMes)) {
          return;
        } else {
          this.$message.warning("标签名称不能为空");
          this.$set(e, "label", oldInputMes);
        }
      }
    },
    // 获取焦点
    labelFocus(e) {
      this.$set(this, "oldInputMes", e.label);
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadExhibitFileChange(e) {
      this.$set(this.exhibitMes, "img", e.url);
      this.$set(this.exhibitMes, "fileRaw", e.raw);
    },
    uploadEnlargedFileChange(e) {
      this.$set(this.enlargedMes, "img", e.url);
      this.$set(this.enlargedMes, "fileRaw", e.raw);
    },
    // 获取表格是否有更改
    isTableListChange() {
      const oldTableList = this.oldTableList;
      const tableList = this.labelTableMes.list;
      const length = tableList.length;
      const oldLength = oldTableList.length;
      if (length != oldLength) {
        return true;
      }
      if (length == 0) {
        return false;
      }
      let keys = Object.keys(tableList[0]);
      for (let i = 0; i < length; i++) {
        const s = tableList[i];
        const os = oldTableList[i];
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          if (s[key] != os.label) {
            return true;
          }
        }
        // if (s.label != os.label || s.required != os.required) {
        //   return true;
        // }
      }
      return false;
    },
  },
};
</script>

<style lang="less" scoped>
.div_main_img {
  height: 100%;
  width: 100%;
}
.table_button_del {
  margin-left: 10px;
}
.main_table {
  .main_table_button {
    margin-bottom: 20px;
  }
}
.form_button {
  margin-top: 20px;
}
.main_table_title_button_add {
  margin-left: 10px;
}
</style>
