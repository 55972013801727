<template>
  <div class="div_main">
    <el-card>
      <el-form ref="updateFormRed" label-width="auto">
        <el-form-item label="图片" prop="img">
          <el-upload
            class="avatar-uploader"
            list-type="picture-card"
            :auto-upload="false"
            action="#"
            :show-file-list="false"
            :on-change="uploadFileChange"
          >
            <img class="div_main_img" v-if="formMes.img" :src="formMes.img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <!-- 是否开启 -->
        <el-form-item label="开启" prop="passwordRedEnvelopeStatus">
          <el-switch v-model="formMes.passwordRedEnvelopeStatus"> </el-switch>
        </el-form-item>
        <!-- 文字 -->
        <el-form-item label="图片文字" prop="passwordRedEnvelopeText">
          <el-input
            maxlength="10"
            show-word-limit
            clearable
            v-model="formMes.passwordRedEnvelopeText"
            placeholder="请输入图片上的文字，没有默认：输口令 小程序名称"
          ></el-input>
        </el-form-item>
        <!-- 标题 -->
        <el-form-item label="页面标题" prop="passwordRedEnvelopeTitle">
          <el-input
            maxlength="10"
            show-word-limit
            clearable
            v-model="formMes.passwordRedEnvelopeTitle"
            placeholder="请输入口令红包页面标题，没有默认小程序名称"
          ></el-input>
        </el-form-item>
        <!-- 口令 -->
        <el-form-item label="口令" prop="passwordRedEnvelopeCode">
          <el-input
            maxlength="50"
            show-word-limit
            clearable
            v-model="formMes.passwordRedEnvelopeCode"
            placeholder="小程序专用口令，放空默认:qianmanwu"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <div class="buttomButton">
        <el-button type="primary" @click="tosave">确 定</el-button>
      </div>
    </el-card>
  </div>
</template>
<script>
import {
  getMes,
  updateNoFile,
  updateHasFile,
} from "@/services/passwordRedEnvelopeDefault/default.js";

export default {
  props: ["showSingleId", "visible"],
  name: "smallroutineConfiguration",
  data() {
    return {
      formMes: {
        img: "",
        fileRaw: null,
        status: false,
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
    // 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
    uploadFileChange(e) {
      this.$set(this.formMes, "img", e.url);
      this.$set(this.formMes, "fileRaw", e.raw);
    },
    async getMes() {
      const res = await this.$publicJs.request(this, getMes);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "formMes", res.data);
      this.$set(this.formMes, "img", res.data.passwordRedEnvelopeImg);
    },
    async tosave() {
      const formMes = this.formMes;
      const img = formMes.img;
      const status = formMes.status;
      if (status && this.$publicJs.isNull(img)) {
        return this.$message.error("状态开启后图片不能为空");
      }
      let fileRaw = formMes.fileRaw;
      let res;
      if (this.$publicJs.isNull(fileRaw)) {
        res = await this.$publicJs.request(this, updateNoFile, formMes);
      } else {
        const formData = new FormData();
        const formDatakeys = Object.keys(formMes);
        for (let i = 0; i < formDatakeys.length; i++) {
          const key = formDatakeys[i];
          formData.append(key, formMes[key]);
        }
        formData.append("file", fileRaw);
        res = await this.$publicJs.request(this, updateHasFile, formData);
      }
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success(res.data);
      this.$set(this.formMes, "fileRaw", null);
    },
    async save() {},
  },
};
</script>

<style lang="less" scoped>
.div_main {
  .div_main_img {
    height: 100%;
    width: 100%;
  }
}
</style>
