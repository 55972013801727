// 接口请求地址
const path = 'redEnvelopeForNewcomers/';
import axios from 'axios'

/**
 * 获取默认配置
 */
export async function getMes(appId) {
  const { data: res } = await axios.get(
    path + 'getMes?appId='+appId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取默认配置失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑(无文件)
 */
 export async function updateNoFile(data={}) {
  const { data: res } = await axios.post(
    path + 'updateNoFile',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "编辑失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑(单顶部图片)
 */
 export async function updateExhibitFile(data={}) {
  const { data: res } = await axios.post(
    path + 'updateExhibitFile',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "编辑失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑(单放大展示图)
 */
 export async function updateEnlargedFile(data={}) {
  const { data: res } = await axios.post(
    path + 'updateEnlargedFile',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "编辑失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑与图片
 */
 export async function updateAllFile(data={}) {
  const { data: res } = await axios.post(
    path + 'updateAllFile',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "编辑失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取表单列表
 */
 export async function getFormList(appId) {
  const { data: res } = await axios.get(
    path + 'getFormList?appId='+appId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}
