<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">影院名称:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.cinemaName"
                clearable
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">创建时间:</label>
              <el-date-picker
                v-model="queryInfo2.createTime"
                :picker-options="pickerOptions"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>

            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
          </div>
        </el-row>
        <el-row class="elRow" :gutter="25">
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-checkbox-group
                v-model="queryInfo2.orderStatus"
                @change="checkUpdate"
              >
                <el-checkbox label="-2">退款</el-checkbox>
                <el-checkbox label="0">待支付</el-checkbox>
                <el-checkbox label="1">已支付</el-checkbox>
                <el-checkbox label="2">出票中</el-checkbox>
                <el-checkbox label="3">已出票</el-checkbox>
                <el-checkbox label="4">已结算</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="小程序唯一标识(普通小程序)"
          prop="code"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="小程序appId(第三方)"
          prop="appId"
          min-width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNumber"
          min-width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="影院名称"
          prop="cinemaName"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="电影名称"
          prop="filmName"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="订单金额"
          prop="totalAmount"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="分佣总佣金"
          prop="rebate"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="开场时间"
          prop="showTime"
          min-width="160"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="订单状态"
          prop="handleStep"
          min-width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <template
              v-if="
                scope.row.handleStep == -2 || !isNull(scope.row.filmRefundId)
              "
            >
              出票失败，退款
            </template>
            <template v-else-if="scope.row.handleStep == -1">
              支付超时
            </template>
            <template v-else-if="scope.row.handleStep == 0"> 待支付 </template>
            <template v-else-if="scope.row.handleStep == 1"> 已支付 </template>
            <template v-else-if="scope.row.handleStep == 2"> 出票中 </template>
            <template v-else-if="scope.row.handleStep == 3"> 已出票 </template>
            <template v-else-if="scope.row.handleStep == 4"> 已结算 </template>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          prop="gmtCreate"
          min-width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="150">
          <template slot-scope="scope">
            <!-- 查看 -->
            <el-tooltip
              effect="dark"
              content="详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-more"
                size="mini"
                @click="seeMessage(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 订单详情 -->
    <el-drawer
      title="订单详情"
      :visible.sync="orderMessage.visible"
      direction="rtl"
      size="60%"
      :with-header="false"
    >
      <orderMes :id="orderMessage.id" ref="orderMesRef" />
    </el-drawer>
  </div>
</template>

<script>
import orderMes from "./orderMes.vue";
export default {
  components: {
    orderMes,
  },
  data() {
    return {
      // 搜索内容
      queryInfo: {
        nowDate: new Date(),
        orderStatus: ["1", "2", "3", "4"],
        //查询信息
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容(点击搜索后将queryInfo值赋予该对象)
      queryInfo2: {
        nowDate: new Date(),
        orderStatus: ["1", "2", "3", "4"],
        // 订单创建时间
        createTime: [],
        //查询信息
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 订单详情
      orderMessage: {
        visible: false,
        id: "",
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    checkUpdate() {},
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post("/filmOrder/list", {
        ...this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 编辑
    seeMessage(e) {
      let orderMessage = this.orderMessage;
      if (this.$refs.orderMesRef != undefined) {
        this.$refs.orderMesRef.init(e.id);
      }
      orderMessage.id = e.id;
      orderMessage.visible = true;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  .elRow {
    margin-top: 10px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
}
</style>
