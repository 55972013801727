<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogOpen()"
              >添加商品</el-button
            >
          </el-col>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column prop="images" label="图片" width="180">
          <template slot-scope="scope">
            <img class="commodityImg" :src="scope.row.src" style="" />
          </template>
        </el-table-column>
        <el-table-column label="商品名称" prop="name"></el-table-column>
        <el-table-column label="价格" prop="price"></el-table-column>
        <el-table-column label="分类" prop="typeName"></el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="updateDialogOpen(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="添加商品"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <commodityAdd ref="commodityAdd" :refreshData="getList" />
    </el-dialog>
    <el-dialog
      title="编辑商品"
      :visible.sync="updateDialogVisible"
      width="50%"
      @close="updateDialogClose"
    >
      <CommodityUpdate
        ref="commodityUpdate"
        :refreshData="getList"
        :updateId="updateId"
      />
    </el-dialog>
  </div>
</template>

<script>
import commodityAdd from "./Commodity_add.vue";
import CommodityUpdate from "./Commodity_update.vue";
export default {
  name: "WorkspaceJsonCommodity",
  components: {
    commodityAdd,
    CommodityUpdate,
  },
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      //   商品列表
      pageMes: {
        list: [],
        total: 0,
      },
      addDialogVisible: false,
      updateDialogVisible: false,
      updateId: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 搜索内容
    async getList() {
      const { data: res } = await this.$http.get("/commodity/list", {
        params: this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 打开添加
    addDialogOpen() {
      this.addDialogVisible = true;
    },
    // 关闭添加
    addDialogClose() {
      this.$refs.commodityAdd.clostThis();
    },
    // 打开编辑
    updateDialogOpen(e) {
      this.updateId = e.id;
      this.updateDialogVisible = true;
      if (null != this.$refs.commodityUpdate) {
        // 非首次加载组件
        this.$refs.commodityUpdate.refreshMes(this.updateId);
      }
    },
    // 关闭编辑
    updateDialogClose() {
      this.updateMes = {};
      // this.$refs.commodityUpdate.clostThis();
    },
    // 状态变更
    async stateChange(e) {
      const { data: res } = await this.$http.put(
        `/commodity/updateStatus?id=${e.id}&status=${e.status}`
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error("操作失败:" + res.message);
      }
      this.$message.success("操作成功");
    },
    // 删除商品
    async deleteThis(id) {
      const result = await this.$confirm(
        "此操作将永久删除该商品,是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancleButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "commodity/delete?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
}
</style>
