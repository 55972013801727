<template>
  <div>
    <!-- 首页展示内容 -->
    <index v-show="indexVisible" :showMessage="showMessage" />
    <!-- 其他内容 -->
    <gaoDeTemplate
      v-show="!indexVisible"
      :visible="!indexVisible"
      :code="code"
      :showIndex="showIndex"
    />
  </div>
</template>

<script>
import index from "./index.vue";
import gaoDeTemplate from "./gaoDeTemplate/gaoDeTemplate.vue";
export default {
  name: "smallRoutine",
  components: {
    index,
    gaoDeTemplate,
  },
  data() {
    return {
      indexVisible: true,
      code: "",
    };
  },
  methods: {
    showIndex() {
      this.indexVisible = true;
    },
    showMessage(e) {
      this.indexVisible = false;
      this.code = e;
    },
  },
};
</script>

<style lang="scss" scoped></style>
