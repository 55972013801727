<template>
  <div>
    <el-form
      :model="addForm"
      ref="addFormRed"
      :rules="formRules"
      label-width="auto"
    >
      <!-- 图片 -->
      <el-form-item label="图片" prop="imageUrl">
        <el-upload
          ref="upload"
          :auto-upload="false"
          class="avatar-uploader"
          :action="$httpUrl + 'aliOpenMiniNiniAppBrand/upload'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="uploadChange"
          :data="{
            appId: this.appId,
            name: this.addForm.name,
          }"
          :headers="headers"
        >
          <img v-if="imageUrl" :src="imageUrl" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="200"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd"> 确 定 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "visible", "appId"],
  data() {
    return {
      imageUrl: "",
      addForm: {
        name: "",
      },
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "change" }],
      },
      headers: { Authorization: window.sessionStorage.getItem("token") },
      loading: {},
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.imageUrl = "";
      this.$refs["addFormRed"].resetFields();
      // 清除文件历史记录
      this.$refs.upload.clearFiles();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let imageUrl = this.imageUrl;
        if (imageUrl == "") {
          return this.$message.error("请选择上传的图片");
        }
        this.loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        this.$refs.upload.submit();
      });
    },
    // 图片上传
    handleAvatarSuccess(res) {
      this.loading.close();
      //上传成功之后清除历史记录
      this.$refs.upload.clearFiles();
      if (res.code != 200) {
        return this.$message.error("文件上传失败：" + res.message);
      }
      this.$message.success("成功");
      let that = this;
      setTimeout(function () {
        that.imageUrl = "";
        that.$refs["addFormRed"].resetFields();
        // that.resetForm();
      }, 100);
      this.refaushList();
    },
    // 图片格式与大小校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG || isGIF) && isLt2M;
    },
    // 选择图片
    uploadChange(file, fileList) {
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.upload.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      this.imageUrl = url;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
    // 排序正则表达式
    sortFormat(e) {
      this.addForm.sort = this.addForm.sort.match(/^\d*(\d{0,0})/g)[0] || null;
      if (isNaN(this.addForm.sort)) {
        this.addForm.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.addForm.sort.length >= 2) {
        this.addForm.sort = this.addForm.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
