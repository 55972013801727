<template>
  <!-- 签到抽奖 -->
  <div>
    <el-divider content-position="left">签到抽奖(集分宝个数)</el-divider>
    <el-form
      ref="form"
      :model="updateForm"
      class="demo-ruleForm"
      label-position="left"
      label-width="80px"
    >
      <el-form-item label="奖励1" prop="one">
        <el-input
          type="number"
          v-model="updateForm.one"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励2" prop="one">
        <el-input
          type="number"
          v-model="updateForm.two"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励3" prop="one">
        <el-input
          type="number"
          v-model="updateForm.three"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励4" prop="one">
        <el-input
          type="number"
          v-model="updateForm.four"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励5" prop="one">
        <el-input
          type="number"
          v-model="updateForm.five"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励6" prop="one">
        <el-input
          type="number"
          v-model="updateForm.six"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励7" prop="one">
        <el-input
          type="number"
          v-model="updateForm.seven"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
      <el-form-item label="奖励8" prop="one">
        <el-input
          type="number"
          v-model="updateForm.eight"
          placeholder="请输入集分宝个数,没有则为未中奖"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallRoutine3SignInLuckDraSignIn",
  data() {
    return {
      updateForm: {
        id: "",
        list: [],
      },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm();
  },
  methods: {
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/smallRoutine3LuckDraw/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 刷新编辑对象
    async refaushForm() {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "smallRoutine3LuckDraw/getTWOSignIn"
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
