<template>
<div>
    <el-form-item label="是否开启粉丝群" prop="isShowFanGroup">
        <el-switch v-model="isShowFanGroup_local" :disabled="is_fanGroupTemplateId_blank && !isShowFanGroup"> </el-switch>
        <div    class="warn"   v-if="is_fanGroupTemplateId_blank">请先填写粉丝群模板id，再开启粉丝群组件</div> 
    </el-form-item>
    <!-- <el-form-item label="粉丝群模板id" prop="fanGroupTemplateId">
        <el-input v-model="fanGroupTemplateId_local" placeholder="例如：MC270be88ce8794cb592eb7c79b0115c60 ，不要引号">
            <el-popover
                slot="suffix"
                placement="top"
                width="400"
                trigger="click">
                <el-image
                    style="height: 160px"
                    :src="demo_picture"
                    :preview-src-list="[demo_picture]"
                    fit="scale-down"></el-image>
                <i class="el-icon-view" slot="reference"></i>
            </el-popover>
        </el-input>
    </el-form-item> -->
    <!-- <el-form-item label="粉丝群坐上角标语" prop="fanGroupTopLeftSlogan">
        <el-input v-model="fanGroupTopLeftSlogan_local" placeholder="例如：最高99元现金红包">
        </el-input>
    </el-form-item> -->
</div>
</template>

<script>
export default{
    components:{
		
    },
    props: {
        //是否展示粉丝群组件
		isShowFanGroup:{
            type:Boolean,
            default:false,
        },
        //粉丝群组件模板id号
        fanGroupTemplateId:{
            type:String,
            default:""
        },
        fanGroupTopLeftSlogan:{
            type:String,
            default:""
        },
        //数据是否加载完成
        is_load_success:{
            type:Boolean,
        }
    },
    data() {
        return {
            isShowFanGroup_local:this.isShowFanGroup,
            fanGroupTemplateId_local:this.fanGroupTemplateId,
            fanGroupTopLeftSlogan_local:this.fanGroupTopLeftSlogan,
            demo_picture:require("@/assets/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/fan_group_demo.png")
        }
    },
    methods:{

    },
    computed:{
		is_fanGroupTemplateId_blank(){
            // return (this.fanGroupTemplateId_local===""||this.fanGroupTemplateId_local==null||this.fanGroupTemplateId_local==undefined);
            return false;
        }
	},
    created(){
		
    },
	watch:{
		isShowFanGroup_local:function(newValue){
            this.$emit("update:isShowFanGroup",newValue);
        },
        fanGroupTemplateId_local:function(newValue){
            this.$emit("update:fanGroupTemplateId",newValue);
        },
        fanGroupTopLeftSlogan_local:function(newValue) {
            this.$emit("update:fanGroupTopLeftSlogan",newValue);
        }
	}   
}
</script>

<style  lang="less" scoped>
	.warn{
        color: red;
    }
</style>