<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="物料id" prop="materialId">
        <el-input
          v-model="addForm.materialId"
          placeholder="请输入物料id"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="是否开启" prop="status">
        <el-switch v-model="addForm.status"></el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "materialManagementAdd",
  props: ["refaushList", "getMaterialList"],
  data() {
    return {
      addForm: {
        name: "",
        materialId: "",
        status: true,
      },
      // 表单验证
      addFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        materialId: [
          { required: true, message: "请输入物料id", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const { data: res } = await this.$http.post(
        "/taoBaoCommodityMaterial/add",
        {
          ...this.addForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      // 刷新列表
      this.refaushList();
      // 刷新物料列表
      this.getMaterialList();
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
