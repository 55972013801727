<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input
                placeholder="请输入名称"
                v-model="queryInfo2.name"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select v-model="queryInfo2.status" placeholder="全部">
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toAdd()"
              icon="el-icon-plus"
            >
              新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="名称" prop="name" fixed></el-table-column>
        <el-table-column label="后台路径" prop="url" fixed></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>
        <el-table-column label="状态" prop="status" fixed="right" width="80">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showUpdate(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="新增" :visible.sync="addMesVisible" width="50%">
      <shortLink_add :refreshData="getList" />
    </el-dialog>
    <el-dialog title="编辑" :visible.sync="updateMes.visible" width="50%">
      <shortLink_update
        :refreshData="getList"
        :id="updateMes.id"
        :visible="updateMes.visible"
      />
    </el-dialog>
  </div>
</template>

<script>
import { list, updateState, del } from "@/services/shortLink/shortLinkService";
import shortLink_add from "./add";
import shortLink_update from "./update.vue";
export default {
  components: {
    shortLink_add,
    shortLink_update,
  },
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        name: "",
        status: "1",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        name: "",
        status: "1",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
        current: -1,
        pages: -1,
      },
      // 新增
      addMesVisible: false,
      // 编辑
      updateMes: {
        visible: false,
        id: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.getList();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        status: "1",
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await list(this.queryInfo);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      // 列表内容
      this.pageMes.list = res.data.records;
      // 总数
      this.pageMes.total = res.data.total;
    },
    // 新增
    toAdd() {
      this.addMesVisible = true;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 编辑
    showUpdate(id) {
      this.updateMes.id = id;
      this.updateMes.visible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await del(id);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await updateState({
        id: e.id,
        status: e.status,
      });
      loading.close();
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    // 打开详情
    showAppletMessage() {},
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
}
</style>
