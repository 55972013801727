<template>
  <div class="div_home">
    <el-card class="main_card">
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">小程序列表:</label>
              <el-select v-model="queryInfo.code" clearable placeholder="全部">
                <el-option
                  v-for="item in smallRoutineList"
                  :key="item.code"
                  :label="item.name + '(' + item.code + ')'"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">时间:</label>
              <el-date-picker
                v-model="queryInfo.createTime"
                :picker-options="pickerOptions"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>

            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
          </div>
        </el-row>
      </div>
      <div class="div_mes">
        <div class="div_mes_single">
          <div class="div_mes_single_header">
            <div class="meta">
              <span> 营业额 </span>
              <el-tooltip
                class="item"
                effect="dark"
                content="用户付款总价和"
                placement="top"
              >
                <span class="metaIcon iconfont icon-tishishuoming"> </span>
              </el-tooltip>
            </div>
            <div class="total">
              <span>￥{{ message.finalPrice }}</span>
            </div>
          </div>
          <!-- <div class="div_mes_single_content">
            昨日营业额
            <span class="div_mes_single_content_mes">￥0.00 </span>
          </div>
          <div class="div_mes_single_footer">
            今日营业额
            <span class="div_mes_single_content_mes">￥0.00</span>
          </div> -->
        </div>
        <div class="div_mes_single">
          <div class="div_mes_single_header">
            <div class="meta">
              <span> 订单数 </span>
              <el-tooltip
                class="item"
                effect="dark"
                content="用户付款订单和"
                placement="top"
              >
                <span class="metaIcon iconfont icon-tishishuoming"> </span>
              </el-tooltip>
            </div>
            <div class="total">
              <span>{{ message.ticketNum }}</span>
            </div>
          </div>
          <!-- <div class="div_mes_single_content">
            昨日订单数
            <span class="div_mes_single_content_mes">0</span>
          </div>
          <div class="div_mes_single_footer">
            今日订单数
            <span class="div_mes_single_content_mes">0</span>
          </div> -->
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 搜索内容
      queryInfo: {},
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 信息
      message: {},
      // 小程序列表
      smallRoutineList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      this.getAllSmallRoutineList();
      this.getMes();
    },
    // 搜索
    search() {
      this.getMes();
    },
    // 获取小程序列表
    async getAllSmallRoutineList() {
      const { data: res } = await this.$http.get(
        "/smallRoutine3/getAllSmallRoutineNameCode"
      );
      if ("200" != res.code) {
        return this.$message.error("获取小程序列表失败:" + res.message);
      }
      this.smallRoutineList = res.message;
    },
    async getMes() {
      const { data: res } = await this.$http.post("/filmOrder/statistics", {
        ...this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取统计失败:" + res.message);
      }
      this.message = res.message;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
  },
};
</script>

<style lang="less" scoped>
@fontSize14: 14px;
.div_home {
  .main_card {
    background-color: #e9eef3;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_mes {
    display: flex;
    flex-wrap: wrap;
    padding-top: 10px;
    border-top: 1px solid #ccc;
    .div_mes_single {
      min-width: 15%;
      background-color: #fff;
      padding: 20px 24px 20px;
      position: relative;
      font-size: @fontSize14;
      color: rgba(0, 0, 0, 0.45);
      margin-right: 20px;
      .div_mes_single_header {
        .meta {
          line-height: 22px;
          display: flex;
          align-content: center;
          justify-content: space-between;
        }
        .total {
          font-size: 30px;
          color: #000;
          padding-top: 10px;
          line-height: 38px;
        }
      }
      .div_mes_single_content {
        padding: 20px 0;
        color: rgba(0, 0, 0, 0.65);
        .div_mes_single_content_mes {
          padding-right: 10px;
        }
      }
      .div_mes_single_footer {
        border-top: 1px solid #e8e8e8;
      }
    }
  }
}
</style>
