<template>
  <div class="div_home">
    <openSearchBox
      v-if="showType == '1'"
      :setShowType1="setShowType1"
      :appId="appId"
      :toOperationManagement="toOperationManagement"
    />
    <!-- 运营管理 -->
    <operationManagement
      v-else-if="showType == '2'"
      :setShowList="setShowList"
      :boxId="operationManagementBoxId"
      :appId="appId"
      :showSingleMes="showSingleMes"
    />
  </div>
</template>

<script>
import openSearchBox from "./openSearchBox.vue";
import operationManagement from "./operationManagement";
export default {
  components: {
    openSearchBox,
    operationManagement,
  },
  props: ["setShowType1", "appId", "showSingleMes"],
  data() {
    return {
      showType: "1",
      // 运营管理
      operationManagementBoxId: "",
    };
  },
  methods: {
    // 运营管理
    toOperationManagement(box_id) {
      this.$set(this, "showType", "2");
      this.$set(this, "operationManagementBoxId", box_id);
    },
    // 显示直达列表
    setShowList() {
      this.$set(this, "showType", "1");
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .headButtonSingle {
      margin-right: 10px;
    }
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
