<template>
  <div>
    <el-button type="primary" @click="init"> 刷新 </el-button>
    <div class="div_url">
      <el-link type="primary" @click="toCard">
        {{ urlMes.type == 1 ? url : urlMes.qrUrl }}
      </el-link>
    </div>
    <iframe
      v-if="url != ''"
      :src="url"
      frameborder="0"
      width="100%"
      height="500px"
    ></iframe>
  </div>
</template>

<script>
import { getCodeByTemplateId } from "@/services/thirdParty/thirdPartyTemplateAuthorizationRel";
import { getByOutBizNo } from "@/services/thirdParty/thirdSmallRoutineAuthorizationNotice";
export default {
  name: "qrCode",
  props: ["urlMes", "visible", "id", "appId"],
  data() {
    return {
      uuid: "",
      url: "",
      // 定时器id
      setIntervalId: "",
    };
  },
  watch: {
    "urlMes.url"() {
      this.init();
    },
    visible(e) {
      if (e) {
        this.checkCode();
      } else {
        clearInterval(this.setIntervalId);
      }
    },
  },
  // 创建后
  created() {
    this.checkCode();
  },
  // 销毁前
  // beforeDestroy() {
  //   console.log("123");
  // },
  methods: {
    async init() {
      // 获取唯一值
      let urlMes = await this.urlMes;
      // let uuid = await this.getCode();
      // uuid: 模板id_唯一值
      let uuid = this.appId + "_" + this.getUID();
      this.$set(this, "uuid", uuid);
      this.$set(this, "url", urlMes.url + uuid);
      // console.log(this.url);
      // this.$set(this, "uuid", uuid);
    },
    // 普通链接获取授权码
    async getCode() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getCodeByTemplateId(this.id);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      return res.data;
    },
    getUID2() {
      // 获取唯一值
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getUID() {
      let uuid = this.getUID2();
      return uuid.replace(/-/g, "");
    },
    // 打开结果里的链接
    toCard() {
      let url = this.url;
      if (this.isNull(url)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      // 打开网页
      window.open(url);
      // 生成新的唯一标识
      this.init();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // 刷新
    flushMes() {
      let urlMes = this.urlMes;
      let type = urlMes.type;
      console.log(type);
      if (type == 2) {
        this.$set(this, "url", "");
        let that = this;
        setTimeout(function () {
          that.$set(that, "url", urlMes.url);
        }, 500);
      } else {
        this.init();
      }
    },
    // 校验code是否已被授权
    checkCode() {
      let that = this;
      let setIntervalId = setInterval(async function () {
        let uuid = that.uuid;
        let res = await getByOutBizNo(uuid);
        let data = res.data;
        if (res.success && data != null) {
          that.init();
          that.$emit("authorizationCompletion", data);
        }
        // console.log(res);
      }, 5000);
      this.$set(this, "setIntervalId", setIntervalId);
    },
  },
};
</script>

<style lang="less" scoped>
.div_url {
  display: flex;
  align-content: center;
  justify-content: center;
}
</style>
