<template>
  <div>
    <el-form :model="updateMes" label-width="70px">
      <!-- 公众号id -->
      <el-form-item label="公众号id">
        <el-input
          v-model="updateMes.lifeId"
          placeholder="请输入关联的生活号id"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <span slot="footer" class="dialog-footer">
      <el-button @click="lifeRelationClose">取 消</el-button>
      <el-button type="primary" @click="editLifeRelationMes">确 定</el-button>
    </span>
  </div>
</template>

<script>
import {
  getLifeIdBySmallRoutineId,
  updateLifeIdBySmallRoutineId,
} from "@/services/thirdParty/smallRoutine/applet1/lifeIdBySmallRoutineId.js";

export default {
  name: "lifeRelationVisible",
  props: ["visible", "updateId", "lifeRelationClose"],
  data() {
    return {
      updateMes: {},
    };
  },
  created() {
    this.init();
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  methods: {
    async init() {
      let res = await getLifeIdBySmallRoutineId(this.updateId);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.updateMes = res.data;
    },
    // 确定修改
    async editLifeRelationMes() {
      let res = await updateLifeIdBySmallRoutineId(this.updateMes);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
