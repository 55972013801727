// 接口请求地址
const path = 'smallRoutineService/';
import axios from 'axios'

/**
 * 获取服务列表失败
 * @param {*} params
 */
export async function getAllServiceIdAndName() {
  const { data: res } = await axios.get(
    path + 'getAllServiceIdAndName'
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取服务列表失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

