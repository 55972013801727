
<template>
  <div>
    <el-form :model="updateForm"	label-width="auto">
      <el-form-item label="最终首页跳转h5" class="text">
        <el-input v-model="updateForm.jumpToH5" placeholder="请输入跳转h5"></el-input>
      </el-form-item>
      <el-form-item label="最终跳转h5" class="text">
        <el-switch v-model="updateForm.jumpToH5Status"></el-switch>
      </el-form-item>
      <el-form-item label="第一次跳转h5" class="text">
        <el-switch v-model="updateForm.isPreJumpToH5"></el-switch>
      </el-form-item>
      <el-form-item label="第一次跳转h5" class="text">
        <el-button type="primary" @click="add">添加</el-button>
        <el-button type="primary" @click="clear">清空</el-button>
        <div style="margin-top: 20px"></div>
        <el-table	:data="list">
          <el-table-column  label="网址(从上往下依次跳转)"  width="220">
            <template slot-scope="scope">
              <el-input v-model="scope.row.h5" placeholder="网址"	clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column  label="跳转类型"  width="90">
            <template slot-scope="scope">
              <el-select v-model="scope.row.type" placeholder="请选择">
                <el-option		label="h5"	value="h5"></el-option>
                <el-option		label="app"	value="app"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column  label="延迟-毫秒"  width="90">
            <template slot-scope="scope">
              <el-input v-model="scope.row.delay" placeholder="延迟时间"	></el-input>
            </template>
          </el-table-column>
          <el-table-column  label="备注"  width="150">
            <template slot-scope="scope">
              <el-input v-model="scope.row.desc" placeholder="备注"	clearable></el-input>
            </template>
          </el-table-column>
          <el-table-column  label="排序"	width="150">
            <template slot-scope="scope"	>
              <el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
              <el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
            </template>
          </el-table-column>
          <el-table-column  label="操作"  width="80">
            <template slot-scope="scope"	>
              <el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
    <el-button icon="el-icon-d-arrow-right" plain class="custom-color" round @click="jumpToH5(1)" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button  icon="el-icon-d-arrow-right" plain class="custom-color" round @click="jumpToH5(2)" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>

export default {
  components: {

  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        batchClassList: [],
        jumpToH5: "",
        jumpToH5Status: false,
        isPreJumpToH5: false,
        preJumpToH5: "",
      },
      list:[], //preJumpToH5 字段的数据解码后显示在页面上的列表
      list_max_length: 5,
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getJumpToH5() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getJumpToH5",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.jumpToH5 = res.message.jumpToH5;
        this.updateForm.jumpToH5Status = res.message.jumpToH5Status;
        this.updateForm.isPreJumpToH5 = res.message.isPreJumpToH5;
        this.list = JSON.parse(decodeURI(res.message.preJumpToH5));
      }
    },
    // 更新数据接口
    async jumpToH5(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      if (is === 1) {
        this.updateForm.batchClassList = this.batchClassList;
        this.updateForm.tbThirdPartySmallRoutineType = null;
      } else {
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        this.updateForm.batchClassList = null;
      }

      // 对list编码
      this.updateForm.preJumpToH5 = encodeURI(JSON.stringify(this.list));
      // console.log("传递的数据：", this.updateForm)
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/jumpToH5",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「首页自动跳转」配置成功");
      } else {
        this.$message.error("「首页自动跳转」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
    //添加一行数据
    add(){
      if(this.list_max_length == this.list.length)	{	//如果长度超过了，就不要再添加了
        this.$message.error("超过长度了,无法再添加");
      }else{
        this.list.push({delay:2000,type:'h5'})	//添加
      }
    },
    //排序的名次上升，指排序的下标上升
    row_up(index){
      let temp = this.list[index] ;
      this.$set(this.list,index,this.list[index-1]);
      this.$set(this.list,index-1,temp);
    },
    //排序的名次下降，指排序的下标下降
    row_down(index){
      let temp = this.list[index] ;
      this.$set(this.list,index,this.list[index+1]);
      this.$set(this.list,index+1,temp);
    },
    //删除一行的数据
    delete_row(index){
      this.list.splice(index,1);
    },
    //清空所有的数据
    clear(){
      this.list =[];
    },
  },
  computed:{

  },
  mounted() {
    this.getJumpToH5()
  }
}
</script>

<style scoped lang="less">
  .text{
    font-size: 30px;
  }
  .custom-color {
    color: #fff;
    background-color: #4e72b8;
  }
</style>