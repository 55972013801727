// 接口请求地址
const path = 'thirdParty2/';
import axios from 'axios'

/**
 * 小程序驳回审核列表重新提交审核
 */
export async function resubmitForReview() {
  const { data: res } = await axios.get(
    path + 'resubmitForReview'
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}