<template>
  <div>
    <list v-show="showMes.type == 1" @showExamine="showExamine" />
    <examine
      v-if="showMes.type == 2"
      :showList="showList"
      :showSingleMes="showMes.mes"
      :qrCreate="true"
    />
  </div>
</template>

<script>
import list from "./list.vue";
import examine from "../thirdParty/smallRoutine/examine.vue";
import { upload } from "@/services/thirdParty/smallRoutine/smallRoutineExamine";

import axios from 'axios'
export default {
  components: {
    list,
    examine,
  },
  props: [],
  data() {
    return {
      showMes: {
        type: 1,
        mes: {},
      },
    };
  },
  methods: {
    async  showList() {
      this.$set(this.showMes, "type", 1);
      this.$set(this.showMes, "mes", {});
    },
    // 跳转提交审核
    async showExamine(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await upload(e.appId);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
      }
      this.$set(this.showMes, "type", 2);
      this.$set(this.showMes, "mes", e);
    },
  },
};
</script>

<style lang="less" scoped></style>
