<template>
    <div class="main_container">
		<table_template_main :column_name="columnName" class_name="tb-third-party-wang-lucard-card"	:base_url="httpUrl"
			:page_function="page_function"	:edit_function="edit_function"	:delete_function="delete_function"	:add_function="add_function"></table_template_main>
    </div>
</template>

<script>
	import axios from 'axios';
	import table_template_main from '@/components/template/wang/table_template_main_new'
export default{
    components:{
        table_template_main
    },
    props: {
        
    },
    data() {
        return {
			columnName:[	//每一列的数据
				{label:"标题",prop:"title",map_type:'none',width:120},
				{label:"第一句话",prop:"desc1",map_type:'none',width:120},
				{label:"第二句话",prop:"desc2",map_type:'none',width:120},
				{label:"第三句话",prop:"desc3",map_type:'none',width:120},
				{label:"左下角文字",prop:"leftBottomText",map_type:'none',width:100},
				{label:"右上角文字",prop:"rightTopText",map_type:'none',width:100},
				{label:"等待时间",prop:"waitingTime",map_type:'none',width:60 },
				{label:"跳转类型",prop:"jumpType",map_type:'base',map:{'h5':'h5','api':'api','webview':'webview'},width:100},
				{label:"是否有提示",prop:"isShowNotification",map_type:'boolean',map:{'false':'无','true':'有'} ,width:100},
				{label:"h5路径",prop:"h5Path",map_type:'none',width:250},
				{label:"产品码",prop:"posterCode",map_type:'none',width:280 },
				{label:"排序",prop:"sort",map_type:'none',width:100},
				{label:"图片",prop:"imagePath",map_type:'image',},	
			],
			httpUrl:this.$httpUrl
        }
    },
    methods:{
		async	page_function(base_url,class_name,page_index){
			let	url = base_url+class_name + '/page?current=' + page_index ;
			const result = await	axios.get(url)
			return result.data;
		},
		//	编辑某行
		async	edit_function(base_url,class_name,row){
			const	result =	await	axios.put(base_url+class_name+'/update',row);
			if( result.data ){
				this.$message({	message: '更改成功',type: 'success', center: true});
			}else{
				this.$message({	message: '更改失败',type: 'error', center: true	});
			}
		},
		// 添加某行
		async	add_function(base_url,class_name,row){
			const	result	=	await	axios.post(base_url+class_name+'/add',row)	;
			console.log(result.data)
			if(result.data) {
				this.$message({ message: '添加成功',type: 'success', center: true});
			}else{
				this.$message({ message: '添加失败,请检查是否填写posterCode',type: 'error', center: true});
			}
		},
		//删除某一行的数据,用它的id号 
		delete_function(base_url,class_name,row){
			let axiosUrl = base_url+class_name+'/del/' + row.id ;
			axios.delete(axiosUrl)
			.then(response=>{
				// console.log(response.data.message)
				if(response.data){
					this.$message({ message: '删除成功',type: 'success', center: true});
				}else{
					this.$message({ message: '删除失败',type: 'error', center: true});
				}
			})
		}
    },
    computed:{
		
	},
    created(){
    },
}
</script>

<style  lang="less" scoped>
.main_container{
		height: 900rpx;
}
</style>