<!-- 
    这个是用来做 显示数据的表格的
-->
<template>
<div    class="row">
    <span   class="appName">{{ appName+':' }}</span>
    <div >
        <el-popover	width="500"	trigger="hover"	:content="service_url">
            <span slot="reference">{{service_url.slice(0,50)+'...'}}</span>
        </el-popover>
    </div>
    <el-popover
        placement="top-start"
        width="300"
        trigger="hover">
        <VueQr   :text="service_url"    v-if="service_url" ></VueQr>
        <el-button slot="reference">二维码</el-button>
    </el-popover>
    <el-button  @click="copyText(service_url);">复制链接</el-button>
    <el-button  :disable="true">服务提报xml填写</el-button>
</div>
</template>
        
<script>
import axios from 'axios';
import VueQr from 'vue-qr'
import { mapState } from 'vuex';
import * as utility from '@/services/wCommonFunction.js'
export default{
    components:{
        VueQr 
    },
    props: {
        //小程序id号
        appId:{
            type:String
        },
        //小程序名字
        appName:{
            type:String
        },
        //这一行的数据
        row:{
            type:Object,
        },
        //每个类别的对应的处理方法
        pageType_path_handler:{
            type:Object
        },
        bigType:{
            type:String,
        },
        generate_page_path_fn:{
            type:Function,
        },
        smallType:{
            type:String
        },
        customer_service_faq: {
          type: Array,
          default: () => []
        }
    },
    data() {
        return {
            //生成的路径
            service_url:""
        }
    },
    methods:{
        generateServiceAddress(appId,bigType,pageType_path_handler,row,smallType){
            let page_url = this.generate_page_path_fn(row,pageType_path_handler,bigType,smallType);
            // let faq_ids = this.customer_service_faq.map(faq => faq.id); // 提取 customer_service_faq 的 id 数组
            let faq_param = `&ids=${JSON.stringify(this.customer_service_faq)}`;
            console.log(faq_param)
            this.service_url = "alipays://platformapi/startapp?appId="+appId+
                "&page="+encodeURIComponent(page_url + faq_param); // 将id附加到URL中;
            this.copyText(this.service_url);
        },
        // 复制输入的内容到剪切板上面
        copyText(text){
            if(text==""){
                this.$message.error("");
            }
            const textArea = document.createElement('textArea');
            textArea.value = text;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand('copy');
            document.body.removeChild(textArea);
            this.$message.success("已经复制完毕");
        },

    },
    computed:{
        ...mapState({
            global_url:state=>state.global_base_url.main
        })
    },
    watch:{
        appId:function(newV,oldV){
            if(!newV){
                return ;
            }
            this.generateServiceAddress(this.appId,this.bigType,this.pageType_path_handler,this.row,this.smallType);
        },
        row(){
            this.generateServiceAddress(this.appId,this.bigType,this.pageType_path_handler,this.row,this.smallType);
        }
    },
    created(){

    },
}
</script>
        
<style  lang="less" scoped>
.row{
    display: flex;
    align-items: center;
    .appName{
        min-width: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>