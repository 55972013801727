// 接口请求地址
const path = 'thirdPartyTemplateAuthorizationRel/';
import axios from 'axios'

/**
 * 根据模板id获取授权唯一标识
 */
export async function getCodeByTemplateId(templateId) {
  const { data: res } = await axios.get(
    path + 'getCodeByTemplateId?templateId='+templateId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取授权唯一标识失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

