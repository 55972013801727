export default [
    {
        label: '餐饮',
        value: '餐饮',
        children: [
            {
                label: '餐饮',
                value: '餐饮',
                children: [
                    {label: '预定', value: 'C000003264'},
                ]
            }
        ]
    },
    {
        label: '售后和体验',
        value: '售后和体验',
        children: [
            {label: '客服中心', value: 'C101330469'},
        ]
    }
]