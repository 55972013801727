<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="100"
          show-word-limit
          placeholder="请输入名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="后台路径" prop="url">
        <el-input
          v-model="form.url"
          maxlength="200"
          show-word-limit
          placeholder="请输入后台路径"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
        <!-- <el-button @click="resetForm('formRef')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { add } from "@/services/shortLink/shortLinkService";
export default {
  props: ["refreshData"],
  name: "add",
  data() {
    return {
      form: {
        name: "",
        url: "",
        status: true,
      },
      // 表格提交验证
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        url: [{ required: true, message: "请输入后台路径", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await add(this.form);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新商品页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
