<template>
  <div>
    <div class="buton_top" v-if="urlMes.type == 1">
      <el-button :type="showType == 1 ? 'success' : ''" @click="showType = 1">
        展示二维码
      </el-button>
      <el-button
        :type="showType == 2 ? 'success' : ''"
        @click="createShortLink"
      >
        生成短链接
      </el-button>
    </div>
    <!-- 二维码模块 -->
    <qrCode
      v-show="showType == 1"
      :id="id"
      :urlMes="urlMes"
      :visible="visible"
      :appId="appId"
      @authorizationCompletion="authorizationCompletion"
    />
    <!-- 新建短链 -->
    <createShortLink
      v-show="showType == 2"
      :id="id"
      :createShortLinkSuccess="createShortLinkSuccess"
    />
  </div>
</template>

<script>
import qrCode from "./offlineLink/qrCode.vue";
import createShortLink from "./offlineLink/createShortLink.vue";
import { getShortLinkByTemplateId } from "@/services/shortLink/shortLink";
export default {
  name: "offlineLink",
  components: {
    qrCode,
    createShortLink,
  },
  props: ["url", "visible", "id", "appId"],
  data() {
    return {
      showType: 0,
      // 线下授权链接
      urlMes: {
        // 1：普通授权链接
        // 2：短链
        type: 1,
        // 授权链接
        url: "",
        // 短链
        qrUrl: "",
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getShortLinkById();
      this.$set(this, "showType", 1);
    },
    async getShortLinkById() {
      // 根据模板获取短链
      let res = await getShortLinkByTemplateId(this.id);
      this.$set(this.urlMes, "url", this.url);
      if (res.success) {
        this.$set(this.urlMes, "type", 2);
        this.$set(this.urlMes, "qrUrl", res.data);
      } else {
        this.$set(this.urlMes, "type", 1);
      }
    },
    // 展示生成短链模块
    async createShortLink() {
      this.showType = 2;
    },
    createShortLinkSuccess(e) {
      this.$set(this, "showType", 1);
      this.$set(this.urlMes, "url", e);
      this.$set(this.urlMes, "type", 2);
    },
    // 用户扫码完返回信息
    authorizationCompletion(e) {
      this.$emit("authorizationCompletion", e);
    },
  },
};
</script>

<style lang="less" scoped>
.buton_top {
  padding-bottom: 20px;
}
</style>
