// ———— 短链后台 ————
// import {httpUrl_shortLink} from "./shortLinkConfig";
// 接口请求地址
const path = 'shortLinkService/';
import axios from 'axios'


/**
 * 新增
 */
export async function add(e) {
  let {data:res} = await axios.post(path+"add",e).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 列表查询
 */
export async function list(e) {
  let {data:res} = await axios.get(path+"list",
  {
    params: e,
  }
  ).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改状态
 */
 export async function updateState(e) {
  let {data:res} = await axios.put(path+"updateState",{
    ...e
  }).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据id获取数据
 */
 export async function getUpdateMesById(id) {
  let {data:res} = await axios.get(path+"getUpdateMesById?id="+id).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
/**
 * 修改
 */
 export async function update(e) {
  let {data:res} = await axios.put(path+"update",{
    ...e
  }).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改
 */
 export async function del(id) {
  let {data:res} = await axios.delete(path+"del?id="+id).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取所有后台列表
 */
 export async function getAllMes() {
  let {data:res} = await axios.get(path+"getAllMes").then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


