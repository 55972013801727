// 接口请求地址
const path = 'aliPayOpenSearchBaseOrder/';
import axios from 'axios'

/**
 * 提交关键字申请
 * @param {*} params
 */
export async function modify(data = {}) {
  const { data: res } = await axios.post(
    path + 'modify',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 申请单批量查询
 * @param {*} params
 */
 export async function batchQuery(data = {}) {
  const { data: res } = await axios.post(
    path + 'batchQuery',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 查询申请审核状态
 * @param {*} params
 */
 export async function query(data = {}) {
  const { data: res } = await axios.post(
    path + 'query',
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


