<template>
  <!-- 转盘奖励内容编辑 -->
  <div>
    <el-form
      ref="form"
      :model="updateForm"
      class="demo-ruleForm"
      label-position="left"
      label-width="80px"
    >
      <el-form-item label="内容1">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[0]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容2">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[1]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容3">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[2]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容4">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[3]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容5">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[4]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容6">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[5]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容7">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[6]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容8">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.mes[7]"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="save" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: [],
  name: "turntableRewardJiFenBao",
  data() {
    return {
      updateForm: {
        type: "",
        title: [],
        mes: [],
      },
      disabled: false,
      type: "jiFenBao",
    };
  },
  created() {
    this.refaushForm();
  },
  methods: {
    // 发起请求
    async save() {
      let updateForm = this.updateForm;
      this.disabled = true;
      const { data: res } = await this.$http.put("/turnTableReward/updateMes", {
        ...updateForm,
      });
      this.disabled = false;
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 刷新编辑对象
    async refaushForm() {
      // 获取转盘奖励内容
      const { data: res } = await this.$http.get(
        "turnTableReward/getByType?type=" + this.type
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
