<template>
  <div>
    <el-form :model="messageMes" ref="messageMesRef" label-width="auto">
      <el-form-item label="配置id">
        {{ messageMes.box_id }}
      </el-form-item>
      <el-form-item label="配置状态">
        <div v-if="messageMes.box_status == 'INITIAL'">初始</div>
        <div v-else-if="messageMes.box_status == 'ONLINE'">已上架</div>
        <div v-else-if="messageMes.box_status == 'EXPIRE'">已失效</div>
        <div v-else-if="messageMes.box_status == 'OFFLINE'">已下架</div>
        <div v-else>其他:{{ messageMes.box_status }}</div>
      </el-form-item>
      <el-form-item
        label="默认触发词"
        v-if="messageMes.default_keywords.length > 0"
      >
        <div
          v-for="(item, index) in messageMes.default_keywords"
          :key="'default_keywords_' + index"
        >
          {{ item }}
        </div>
      </el-form-item>
      <!-- 基础信息模块 -->
      <template v-if="!this.isNull(messageMes.basic_info_module)">
        <el-divider content-position="left">基础信息模块</el-divider>
        <el-form-item label="模块配置ID">
          {{ messageMes.basic_info_module.module_id }}
        </el-form-item>
        <el-form-item label="状态">
          <div v-if="messageMes.basic_info_module.status == 'INITIAL'">
            初始
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'AUDIT'">
            审核中
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'CANCEL'">
            已取消
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'ONLINE'">
            已上架
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'REJECT'">
            驳回
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'OFFLINE'">
            已下架
          </div>
          <div v-else-if="messageMes.basic_info_module.status == 'EXPIRE'">
            已失效
          </div>
          <div v-else>其他:{{ messageMes.basic_info_module.status }}</div>
        </el-form-item>
        <el-form-item label="申请单号">
          {{ messageMes.basic_info_module.apply_no }}
        </el-form-item>
        <el-form-item label="修改时间">
          {{ messageMes.basic_info_module.gmt_modified }}
        </el-form-item>
        <el-form-item
          label="审核失败原因"
          v-if="messageMes.basic_info_module.fail_reason != null"
        >
          {{ messageMes.basic_info_module.fail_reason }}
        </el-form-item>
        <el-form-item label="介绍，已生效的数据">
          {{ messageMes.basic_info_module.box_desc }}
        </el-form-item>
        <el-form-item label="介绍，最近一次审核内容">
          {{ messageMes.basic_info_module.latest_audit_box_desc }}
        </el-form-item>
      </template>
      <!-- 关键词模块 -->
      <template v-if="!this.isNull(messageMes.keyword_module)">
        <el-divider content-position="left">关键词模块</el-divider>
        <el-form-item label="模块配置ID">
          {{ messageMes.keyword_module.module_id }}
        </el-form-item>
        <el-form-item label="模块类型">
          <div
            v-if="messageMes.keyword_module.module_type == 'BOX_EXCLUSIVE_BASE'"
          >
            基础信息
          </div>
          <div
            v-else-if="
              messageMes.keyword_module.module_type == 'BOX_EXCLUSIVE_KEYWORD'
            "
          >
            关键词
          </div>
          <div
            v-else-if="
              messageMes.keyword_module.module_type == 'BOX_EXCLUSIVE_FUNCTIONS'
            "
          >
            功能服务
          </div>
          <div
            v-else-if="
              messageMes.keyword_module.module_type == 'BOX_EXCLUSIVE_ACCOUNTS'
            "
          >
            关联账号
          </div>
          <div
            v-else-if="
              messageMes.keyword_module.module_type == 'BOX_ATMOSPHERE_IMAGE'
            "
          >
            氛围图
          </div>
          <div v-else>其他:{{ messageMes.keyword_module.module_type }}</div>
        </el-form-item>
        <el-form-item label="申请单号">
          {{ messageMes.keyword_module.apply_no }}
        </el-form-item>
        <el-form-item label="修改时间">
          {{ messageMes.keyword_module.gmt_modified }}
        </el-form-item>
        <el-form-item label="状态">
          <div v-if="messageMes.keyword_module.status == 'INITIAL'">初始</div>
          <div v-else-if="messageMes.keyword_module.status == 'AUDIT'">
            审核中
          </div>
          <div v-else-if="messageMes.keyword_module.status == 'CANCEL'">
            已取消
          </div>
          <div v-else-if="messageMes.keyword_module.status == 'ONLINE'">
            已上架
          </div>
          <div v-else-if="messageMes.keyword_module.status == 'REJECT'">
            驳回
          </div>
          <div v-else-if="messageMes.keyword_module.status == 'OFFLINE'">
            已下架
          </div>
          <div v-else-if="messageMes.keyword_module.status == 'EXPIRE'">
            已失效
          </div>
          <div v-else>其他:{{ messageMes.keyword_module.status }}</div>
        </el-form-item>
        <el-form-item
          label="审核失败原因"
          v-if="messageMes.keyword_module.fail_reason != null"
        >
          {{ messageMes.keyword_module.fail_reason }}
        </el-form-item>
        <el-form-item label="自定义触发词(生效中)">
          <div
            v-for="(item, index) in messageMes.keyword_module.keywords"
            :key="'keyword_module_keywords_' + index"
          >
            {{ item }}
          </div>
        </el-form-item>
        <el-form-item label="自定义触发词(最近一次审核内容)">
          <div
            v-for="(item, index) in messageMes.keyword_module
              .latest_audit_keywords"
            :key="'latest_audit_keywords_keywords_' + index"
          >
            {{ item }}
          </div>
        </el-form-item>
      </template>
      <!-- 服务模块 -->
      <template v-if="!this.isNull(messageMes.service_module)">
        <el-divider content-position="left">服务模块</el-divider>
        <el-form-item label="模块配置ID">
          {{ messageMes.service_module.module_id }}
        </el-form-item>
        <el-form-item label="模块类型">
          <div
            v-if="messageMes.service_module.module_type == 'BOX_EXCLUSIVE_BASE'"
          >
            基础信息
          </div>
          <div
            v-else-if="
              messageMes.service_module.module_type == 'BOX_EXCLUSIVE_KEYWORD'
            "
          >
            关键词
          </div>
          <div
            v-else-if="
              messageMes.service_module.module_type == 'BOX_EXCLUSIVE_FUNCTIONS'
            "
          >
            功能服务
          </div>
          <div
            v-else-if="
              messageMes.service_module.module_type == 'BOX_EXCLUSIVE_ACCOUNTS'
            "
          >
            关联账号
          </div>
          <div
            v-else-if="
              messageMes.service_module.module_type == 'BOX_ATMOSPHERE_IMAGE'
            "
          >
            氛围图
          </div>
          <div v-else>其他:{{ messageMes.service_module.module_type }}</div>
        </el-form-item>
        <div
          v-for="(item, index) in messageMes.service_module.service_infos"
          :key="'service_infos_' + index"
        >
          <el-form-item> ———— 服务信息列表{{ index + 1 }} ———— </el-form-item>
          <el-form-item label="服务编码">
            {{ item.service_code }}
          </el-form-item>
          <el-form-item label="服务名称">
            {{ item.service_name }}
          </el-form-item>
          <el-form-item label="服务类目编码">
            {{ item.service_category_code }}
          </el-form-item>
          <el-form-item label="服务类目名称">
            {{ item.service_category_name }}
          </el-form-item>
        </div>
      </template>
      <!-- 账号模块 -->
      <template v-if="!this.isNull(messageMes.account_module)">
        <el-divider content-position="left">账号模块</el-divider>
        <el-form-item label="模块配置ID">
          {{ messageMes.account_module.module_id }}
        </el-form-item>
        <el-form-item label="模块类型">
          <div
            v-if="messageMes.account_module.module_type == 'BOX_EXCLUSIVE_BASE'"
          >
            基础信息
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_KEYWORD'
            "
          >
            关键词
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_FUNCTIONS'
            "
          >
            功能服务
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_ACCOUNTS'
            "
          >
            关联账号
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_ATMOSPHERE_IMAGE'
            "
          >
            氛围图
          </div>
          <div v-else>其他:{{ messageMes.account_module.module_type }}</div>
        </el-form-item>
        <div
          v-for="(item, index) in messageMes.account_module.app_infos"
          :key="'account_module_' + index"
        >
          <el-form-item> ———— 应用信息列表{{ index + 1 }} ———— </el-form-item>
          <el-form-item label="应用类型">
            <div v-if="item.app_type == 'TINY_APP'">小程序</div>
            <div v-else-if="item.app_type == 'PUBLIC_APP'">生活号</div>
            <div v-else-if="item.app_type == 'SHOP_APP'">门店应用</div>
            <div v-else>其他:{{ item.app_type }}</div>
          </el-form-item>
          <el-form-item label="应用ID">
            {{ item.relate_appid }}
          </el-form-item>
          <el-form-item label="应用名称">
            {{ item.app_name }}
          </el-form-item>
        </div>
      </template>
      <!-- 已生效氛围图	 -->
      <template v-if="!this.isNull(messageMes.account_module)">
        <el-divider content-position="left">已生效氛围图</el-divider>
        <el-form-item label="模块配置ID">
          {{ messageMes.account_module.module_id }}
        </el-form-item>
        <el-form-item label="模块类型">
          <div
            v-if="messageMes.account_module.module_type == 'BOX_EXCLUSIVE_BASE'"
          >
            基础信息
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_KEYWORD'
            "
          >
            关键词
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_FUNCTIONS'
            "
          >
            功能服务
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_EXCLUSIVE_ACCOUNTS'
            "
          >
            关联账号
          </div>
          <div
            v-else-if="
              messageMes.account_module.module_type == 'BOX_ATMOSPHERE_IMAGE'
            "
          >
            氛围图
          </div>
          <div v-else>其他:{{ messageMes.account_module.module_type }}</div>
        </el-form-item>
        <el-form-item
          label="申请单号"
          v-if="!this.isNull(messageMes.account_module.apply_no)"
        >
          {{ messageMes.account_module.apply_no }}
        </el-form-item>
        <el-form-item
          label="修改时间"
          v-if="!this.isNull(messageMes.account_module.gmt_modified)"
        >
          {{ messageMes.account_module.gmt_modified }}
        </el-form-item>
        <el-form-item
          label="状态"
          v-if="!this.isNull(messageMes.account_module.status)"
        >
          <div v-if="messageMes.account_module.status == 'INITIAL'">初始</div>
          <div v-else-if="messageMes.account_module.status == 'AUDIT'">
            审核中
          </div>
          <div v-else-if="messageMes.account_module.status == 'CANCEL'">
            已取消
          </div>
          <div v-else-if="messageMes.account_module.status == 'ONLINE'">
            已上架
          </div>
          <div v-else-if="messageMes.account_module.status == 'REJECT'">
            驳回
          </div>
          <div v-else-if="messageMes.account_module.status == 'OFFLINE'">
            已下架
          </div>
          <div v-else-if="messageMes.account_module.status == 'EXPIRE'">
            已失效
          </div>
          <div v-else>其他：{{ messageMes.account_module.status }}</div>
        </el-form-item>
        <el-form-item
          label="审核失败原因"
          v-if="!this.isNull(messageMes.account_module.fail_reason)"
        >
          {{ messageMes.account_module.fail_reason }}
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "thisMessage",
  props: ["messageMes"],
  data() {
    return {};
  },
  created() {
    console.log(this.messageMes);
  },
  methods: {
    isNull(e) {
      return e == null || e == undefined;
    },
  },
};
</script>

<style lang="less" scoped></style>
