<template>
  <div class="div_main">
			
		<el-divider content-position="left"	class="divider">底下菜单列表的配置</el-divider>
		<menu_table></menu_table>
  </div>
</template>
<script>
import axios from "axios";
import menu_table from './component/default_menu_table.vue'

export default {
  props: ["appId", "visible"],
  name: "smallroutineConfiguration",
	components:{
		menu_table
	},
  data() {
		return {
			headers: { Authorization: window.sessionStorage.getItem("token") },
			formMes: {},
			is_show_detail:false,
		};
	},
	watch: {
		
	},
	created() {
		
	},
	methods: {
		
		
  },
};
</script>

<style lang="less" scoped>
.div_main{
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	// display: flex;	flex-direction: column;	
	.buttomButton{
		margin-top: 20px;
	}
	.form2_row{
		display: flex;	flex-direction: row;
	}
	.divider{	//分割器
		margin-top: 70px;		margin-bottom: 20px;
	}
}
</style>
