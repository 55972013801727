<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <!-- 跳转h5 -->
      <el-form-item label="跳转h5" prop="jumpToH5">
        <el-input
          v-model="updateForm.jumpToH5"
          placeholder="请输入跳转h5"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch
          v-model="updateForm.jumpToH5Status"
          @change="jumpToH5StatusUpdate"
        >
        </el-switch>
      </el-form-item>
      <!-- 跳转电影小程序 -->
      <el-form-item label="跳转电影" prop="jumpToFilmStatus">
        <el-switch
          v-model="updateForm.jumpToFilmStatus"
          @change="jumpToFilmStatusUpdate"
        >
        </el-switch>
      </el-form-item>
      <!-- 审核页面轮播图 -->
      <el-form-item label="审核页面轮播图" prop="examineHeaderImg">
        <el-switch v-model="updateForm.examineHeaderImg"> </el-switch>
      </el-form-item>
      <el-divider content-position="left">其他功能</el-divider>
      <!-- 灯火广告 -->
      <el-form-item label="灯火广告" prop="lightAdStatus">
        <el-switch v-model="updateForm.lightAdStatus"> </el-switch>
      </el-form-item>
      <!-- 签到 -->
      <el-form-item label="签到" prop="signInStatus">
        <el-switch v-model="updateForm.signInStatus"> </el-switch>
      </el-form-item>
      <!-- 底部商品 -->
      <el-form-item label="底部商品" prop="bottomCommodityStatus">
        <el-switch v-model="updateForm.bottomCommodityStatus"> </el-switch>
      </el-form-item>
      <el-divider content-position="left">菜单管理</el-divider>
      <!-- 吃 -->
      <el-form-item
        label="吃"
        prop="menuButtonEatStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.menuButtonEatStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateMenuButton(1)"
        >
          菜单管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="喝"
        prop="menuButtonDrinkStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.menuButtonDrinkStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateMenuButton(2)"
        >
          菜单管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="玩"
        prop="menuButtonPlayStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.menuButtonPlayStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateMenuButton(3)"
        >
          菜单管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="乐"
        prop="menuButtonHappyStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.menuButtonHappyStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateMenuButton(4)"
        >
          菜单管理
        </el-button>
      </el-form-item>
      <el-divider content-position="left">服务管理</el-divider>
      <!-- 吃 -->
      <el-form-item
        label="吃"
        prop="luckDrawEatStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.luckDrawEatStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateLuckDraw(1)"
        >
          转盘内容管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="喝"
        prop="luckDrawDrinkStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.luckDrawDrinkStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateLuckDraw(2)"
        >
          转盘内容管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="玩"
        prop="luckDrawPlayStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.luckDrawPlayStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateLuckDraw(3)"
        >
          转盘内容管理
        </el-button>
      </el-form-item>
      <el-form-item
        label="乐"
        prop="luckDrawHappyStatus"
        style="white-space: nowrap"
      >
        <el-switch v-model="updateForm.luckDrawHappyStatus"> </el-switch>
        <el-button
          class="button_luckDraw"
          type="success"
          @click="updateLuckDraw(4)"
        >
          转盘内容管理
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
    <!-- 吃喝玩乐转盘内容管理 -->
    <el-dialog
      title="转盘内容管理"
      :visible.sync="luckDrawService.visible"
      width="50%"
      append-to-body
    >
      <smallRoutine3SignInLuckDrawEatService
        :type="luckDrawService.type"
        :code="smallRoutineCode"
        ref="luckDrawSignInRef"
      />
    </el-dialog>
    <!-- 吃喝玩乐菜单按钮内容管理 -->
    <el-dialog
      title="按钮内容管理"
      :visible.sync="menuButton.visible"
      width="50%"
      append-to-body
    >
      <smallRoutine3ConfiguraterMenuButton
        :type="menuButton.type"
        :code="smallRoutineCode"
        ref="menuButtonRef"
      />
    </el-dialog>
  </div>
</template>

<script>
import smallRoutine3SignInLuckDrawEatService from "./smallRoutine3LuckDraw/smallRoutine3SignInLuckDrawEatService.vue";
import smallRoutine3ConfiguraterMenuButton from "./smallRoutine3ConfiguraterMenuButton.vue";
export default {
  components: {
    smallRoutine3SignInLuckDrawEatService,
    smallRoutine3ConfiguraterMenuButton,
  },
  name: "smallRoutine3Configurater",
  props: ["updateCode", "refaushList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {},
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
      // 吃喝玩乐转盘奖励管理
      luckDrawService: {
        visible: false,
        type: "",
      },
      // 吃喝玩乐菜单管理
      menuButton: {
        visible: false,
        type: "",
      },
      // 当前查看的小程序唯一标识
      smallRoutineCode: "",
    };
  },
  created() {
    this.refaushForm(this.updateCode);
  },
  methods: {
    jumpToH5StatusUpdate(e) {
      if (e) {
        this.updateForm.jumpToFilmStatus = false;
      }
    },
    jumpToFilmStatusUpdate(e) {
      if (e) {
        this.updateForm.jumpToH5Status = false;
      }
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转开关开启时跳转的h5路径不能为空"
          );
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.post(
        "/smallRoutine3Configuration/updateConfiguration",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(code) {
      this.smallRoutineCode = code;
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "smallRoutine3Configuration/getConfiguration?code=" + code
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
    // 吃喝玩乐服务转盘内容编辑
    updateLuckDraw(e) {
      if (this.$refs.luckDrawSignInRef == undefined) {
        this.luckDrawService.type = e;
      } else {
        this.$refs.luckDrawSignInRef.refaushForm(e, this.smallRoutineCode);
      }
      this.luckDrawService.visible = true;
    },
    // 吃喝玩乐按钮编辑
    updateMenuButton(e) {
      if (this.$refs.menuButtonRef == undefined) {
        this.menuButton.type = e;
      } else {
        this.$refs.menuButtonRef.refaushForm(e, this.smallRoutineCode);
      }
      this.menuButton.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button_luckDraw {
  margin-left: 10px;
}
</style>
