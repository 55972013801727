<template>
    <div class="div_main">
      <type_picker    @smallType="handler_new_small_type"
                      @pagePath="e=>pagePath=e"
                      @bigType="handler_new_big_type"
                      @selectedSmallTypes="handleSelectedSmallTypes"
                      @smallTypeOption="handleSmallTypeOption"></type_picker>
		  <el-divider content-position="left">频繁的问题的词条</el-divider>
			<table_template	:is_show_selection="true"
                       :column_name="faq_column_name"
                       :base_url="httpUrl"
                       class_name="tb-third-party-wang-customer-service-faq"
                       :page_function="customPageFunction"
                       ref="tableTemplate"></table_template>
    </div>
</template>

<script>
	import table_template from '../../../template/wang/table_template_main_new.vue'
  import type_picker from "../redpacket/menuPage/defaultConfig/type_picker.vue";
  import axios from "axios";
export default{
    components:{
        type_picker,
        table_template
    },
    props: {
        
    },
    data() {
        return {
			httpUrl:this.$httpUrl,
            faq_column_name:[
				      {label:"标题",prop:"title",map_type:'none',width:360},
				      {label:"内容",prop:"describeTxt",map_type:'textarea'},
              {label:"类别",prop:"pageType",map_type: 'select',map:[]}
			      ],
          // 页面类别,决定了词条的数据
          pageType:null,   //当前小类
          bigType:null,
          pagePath:null,
          selectedTypes: [], // 用来存储 selectedTypes 的数组
        }
    },
    methods:{
      //如果有更换新的类型 ，更新数据 和表格类型
      handler_new_small_type(smallType){
        this.pageType = smallType ;
        if(smallType == null){
          this.$refs.tableTemplate.table_data.show = [];
        }else{
          this.$refs.tableTemplate.initialize_data();
        }
      },
      handler_new_big_type(bigType){
        this.bigType = bigType ;
      },
      handleSelectedSmallTypes(selectedTypes) {
        this.selectedTypes = selectedTypes;
      },
      handleSmallTypeOption(smallTypeOption) {
        const categoryCol = this.faq_column_name.find(col => col.prop === 'pageType');
        if (categoryCol) {
          categoryCol.map = smallTypeOption;
        }
      },
      async customPageFunction(httpUrl, class_name, page_index) {
        if(this.pageType){
          const response = await axios.get(`${httpUrl}${class_name}/page`, {
            params: {
              current: page_index,
              pageType: this.pageType
            }
          });
          return response.data.message;
        }else {
          return { total: 0, records: [] };
        }
      }
    },
    computed:{
			
	},
    created(){
      if (this.pageType) {
        this.$refs.tableTemplate.initialize_data();
      }
    },
}
</script>

<style  lang="less" scoped>
.div_main{
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	.divider{	//分割器
		margin-top: 70px;		margin-bottom: 20px;
	}
}

</style>