<template>
  <div>
    <el-form
      :model="form"
      :rules="formRules"
      ref="addFormRed"
      label-width="auto"
    >
      <el-form-item label="生成数量" prop="number">
        <el-input
          v-model="form.number"
          placeholder="请输入生成数量"
          maxlength="3"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd"> 确 定 </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuOneAdd",
  props: ["refaushList", "activityId"],
  data() {
    return {
      form: {
        number: "",
        activityId: "",
      },
      // 表单验证
      formRules: {
        number: [
          { required: true, message: "请输入生成数量", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let form = this.form;
      form.activityId = this.activityId;
      const { data: res } = await this.$http.post(
        "/alipayMarketingActivity/orderVoucherCodeDeposit",
        form
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
