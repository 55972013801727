// 接口请求地址
const path = 'thirdPartySmallRoutine/add';
import axios from 'axios'

/**
 * 第三方商家小程序新增
 * @param {*} params
 */
export async function smallroutineAdd(data = {}) {
  const { data: res } = await axios.post(
    path,
    {
      ...data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}