<template>
    <div		class="container">
        <el-button type="primary" @click="add">添加</el-button>
        <el-button type="primary" @click="clear">清空</el-button>
        <el-button type="primary" @click="save">保存</el-button>
        <el-table	:data="list">
            <el-table-column  label="内容"  width="500">
                <template slot-scope="scope">
                    <el-input v-model="list[scope.$index]" placeholder="请填写消息，例如 某某用户192....9891获取了"	clearable	
                        maxlength="30"></el-input>
					<!-- <title v-model="list[scope.$index]"></title> -->
                </template>
            </el-table-column>
            <el-table-column  label="排序"	width="150">
                <template slot-scope="scope">
                    <el-button type="primary" @click="row_up(scope.$index)"	:disabled="scope.$index==0"	size="mini">向上</el-button>
                    <el-button type="primary" @click="row_down(scope.$index)" :disabled="scope.$index==list.length-1"	size="mini">向下</el-button>
                </template>
            </el-table-column>
            <el-table-column  label="操作"  width="80">
                <template slot-scope="scope">
                    <el-button type="primary" @click="delete_row(scope.$index)" size="mini">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-button  class="button" type="primary" @click="save_slogan">保存</el-button>
        <el-form    label-position="left"    label-width="120px">
            <el-form-item label="左上角标语">
                <el-input v-model="top_left_slogan" placeholder="请输入左上角标语"></el-input> 
            </el-form-item>
        </el-form>
        <messageCarouselapp></messageCarouselapp>
    </div>
	
    </template>
    
    <script>
    import axios from 'axios';
    import store from '@/store/index.js';
    import { handleResponse } from '@/services/wCommonFunction.js'
	import messageCarouselapp from './messageCarouselapp.vue'
    export default{
        components:{
            messageCarouselapp,
        },
        props: {

        },
        data() {
            return {
                // 这个是数据列表，在created那边有初始化，对input_list就是输入进来的String格式的列表进行json的转化
                // 转化为Array类型的列表
                list:[],
                top_left_slogan:''
            }
        },
        methods:{
    //对数据进行增删改查的操作
            //添加一行数据
            add(){
                this.list.push("");	//添加
            },
            //排序的名次上升，指排序的下标上升
            row_up(index){
                let temp = this.list[index];
                this.$set(this.list,index,this.list[index-1]);
                this.$set(this.list,index-1,temp);
            },
            //排序的名次下降，指排序的下标下降
            row_down(index){
                let temp = this.list[index];
                this.$set(this.list,index,this.list[index+1]);
                this.$set(this.list,index+1,temp);
            },
            //删除一行的数据
            delete_row(index){
                this.list.splice(index,1);
            },
            //清空所有的数据
            clear(){
                this.list =[];
            },
    //下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
            //根据输入进来的数据 处理
            async initiailize_data(){
                //先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
                //后对于JSON格式经行 转化
                let res = await axios.get(store.state.global_base_url.main+"MessageCarousel/getDefault"); 
                let data = handleResponse(res);
                this.list = JSON.parse(data);

                let res_slogan = await axios.get(store.state.global_base_url.main + 'fanGroupTemplateId/getSlogan')
                this.top_left_slogan = handleResponse(res_slogan);
                
            },
            async save(){
                let req_data = {
                    json:JSON.stringify(this.list)
                }
                let res = await axios({
                    method:"PUT",
                    url:store.state.global_base_url.main+"MessageCarousel/setDefault",
                    data:req_data,
                })
                let res_data = handleResponse(res);
                if(res_data){
                    this.$message.success("成功保存");
                    this.initiailize_data();
                }else{
                    this.$message.error("失败")
                }
            },
            async    save_slogan(){
                let res = await axios({
                    method:"PUT",
                    url:store.state.global_base_url.main+"fanGroupTemplateId/setSlogan/"+this.top_left_slogan,
                })
                let res_data = handleResponse(res);
                if(res_data){
                    this.$message.success("成功保存");
                    this.initiailize_data();
                }else{
                    this.$message.error("失败")
                }
            }
        },
        computed:{
            
        },
        created(){
            this.initiailize_data();
        },
        watch:{

        }
    }
    </script>
    
    <style  lang="less" scoped>
    .container{
        position: relative;
        min-width: 200px;
        max-width: 1000px;
		margin-bottom: 200px;
        .button{
            margin-top: 20px;
        }
    }
    .container	.el-table{
        border-radius: 10px;
        max-height: 410px;
        overflow-y: auto;
    }
    </style>