<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加服务
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="页面路径" prop="src"></el-table-column>
        <el-table-column label="唯一标识" prop="code"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 内容编辑 -->
            <el-tooltip
              effect="dark"
              content="内容编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-tools"
                size="mini"
                @click="updateThisMes(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 上移 -->
            <el-tooltip
              effect="dark"
              content="上移"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                :disabled="scope.$index == 0"
                type="primary"
                icon="el-icon-caret-top"
                size="mini"
                @click="positionMovement(scope, -1)"
              ></el-button>
            </el-tooltip>
            <!-- 下移 -->
            <el-tooltip
              effect="dark"
              content="下移"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                :disabled="scope.$index + 1 == tableList.length"
                type="primary"
                icon="el-icon-caret-bottom"
                size="mini"
                @click="positionMovement(scope, 1)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增窗口 -->
    <el-dialog title="新增" :visible.sync="addDialogVisible" width="40%">
      <el-form
        ref="addFormRed"
        :model="addForm"
        :rules="addFormRules"
        label-width="70px"
      >
        <div class="small_update_list">
          <!-- 新增 -->
          <div class="small_update_single">
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, addForm);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :headers="headerObj"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="addForm.img"
                  :src="addForm.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-form-item label="服务名称" prop="name">
                  <el-input
                    v-model="addForm.name"
                    placeholder="请输入服务名称"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="唯一标识" prop="code">
                  <el-input
                    v-model="addForm.code"
                    placeholder="请输入服务唯一标识"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="页面地址" prop="src">
                  <el-input
                    v-model="addForm.src"
                    placeholder="请输入页面地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="服务介绍" prop="mes">
                  <el-input
                    type="textarea"
                    v-model="addForm.mes"
                    placeholder="请输入服务介绍"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="是否启用">
                  <el-switch
                    v-model="addForm.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </el-form-item>
              </div>
            </div>
            <div class="small_update_single_mes_button">
              <el-button type="primary" style="width: 100%" @click="toAdd()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 编辑窗口 -->
    <el-dialog
      title="修改"
      :visible.sync="editDialogVisible"
      width="40%"
      @close="editDialogClose"
    >
      <el-form
        ref="editFormRed"
        :model="editForm"
        :rules="addFormRules"
        label-width="70px"
      >
        <div class="small_update_list">
          <div class="small_update_single">
            <div class="small_update_single_img_div">
              <el-upload
                class="avatar-uploader"
                :action="httpUrl + 'upload/uploadFile'"
                :show-file-list="false"
                :on-success="
                  (value) => {
                    handleAvatarSuccess(value, editForm);
                  }
                "
                :on-change="onChangeUpload"
                :before-upload="beforeAvatarUpload"
                :headers="headerObj"
              >
                <img
                  class="small_update_single_img avatar"
                  v-if="editForm.img"
                  :src="editForm.img"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
            <div class="small_update_single_mes">
              <div class="small_update_single_mes_input">
                <el-form-item label="服务名称" prop="name">
                  <el-input
                    v-model="editForm.name"
                    placeholder="请输入服务名称"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="唯一标识">
                  <el-input
                    disabled
                    v-model="editForm.code"
                    placeholder="请输入服务唯一标识"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="页面地址" prop="src">
                  <el-input
                    disabled
                    v-model="editForm.src"
                    placeholder="请输入页面地址"
                  ></el-input>
                </el-form-item>
                <el-form-item label="服务介绍" prop="mes">
                  <el-input
                    type="textarea"
                    v-model="editForm.mes"
                    placeholder="请输入服务介绍"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="small_update_single_mes_input">
                <el-form-item label="是否启用">
                  <el-switch
                    v-model="editForm.status"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                  >
                  </el-switch>
                </el-form-item>
              </div>
            </div>
            <div class="small_update_single_mes_button">
              <el-button type="primary" style="width: 100%" @click="editMes()"
                >修改</el-button
              >
            </div>
          </div>
        </div>
      </el-form>
    </el-dialog>
    <!-- 服务内容编辑 -->
    <el-dialog
      title="服务内容编辑"
      :visible.sync="serviceLoanMes.visible"
      width="40%"
    >
      <messageTemplateServiceLoanUpdate
        :visible="serviceLoanMes.visible"
        :code="serviceLoanMes.code"
      />
    </el-dialog>
  </div>
</template>
<script>
import messageTemplateServiceLoanUpdate from "./messageTemplateServiceLoanUpdate.vue";
export default {
  components: {
    messageTemplateServiceLoanUpdate,
  },
  created() {
    this.httpUrl = this.$httpUrl;
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
    this.getList();
  },
  data() {
    return {
      httpUrl: "",
      headerObj: {}, // token
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      //添加表单信息
      addForm: {
        img: "",
        code: "",
        name: "",
        src: "",
        mes: "",
        status: true,
      },
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        src: [
          { required: true, message: "请输入页面路径", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入服务唯一标识", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
      //修改信息
      editForm: {},
      //显示/隐藏修改栏
      editDialogVisible: false,
      // 加载中
      loadingPermission: false,
      // 贷款服务编辑弹窗
      serviceLoanMes: {
        visible: false,
        code: "",
      },
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取表格列表
    async getList() {
      const { data: res } = await this.$http.get("smallRoutineService/list", {
        params: this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.tableList = res.message.records;
      this.total = res.message.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 修改状态
    async stateChange(e) {
      const { data: res } = await this.$http.put(
        `/smallRoutineService/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 添加
    async toAdd() {
      let that = this;
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let addForm = that.addForm;
        let img = addForm.img;
        if (null == img || undefined == img || "" == img) {
          return that.$message.error("请先上传图片");
        }
        let { data: res } = await that.$http.post(
          "/smallRoutineService/add",
          addForm
        );
        if ("200" != res.code) {
          return that.$message.error(res.message);
        }
        // 重置新增数据
        that.addForm = {
          img: "",
          name: "",
          src: "",
          mes: "",
          status: true,
        };
        // 刷新表格
        that.getList();
        return that.$message.success("添加成功");
      });
    },
    // 删除信息
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "smallRoutineService/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    //更改信息对话框
    async showEditDialog(e) {
      const { data: res } = await this.$http.get(
        "smallRoutineService/getMesById?id=" + e.id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.editForm = res.message;
      this.editDialogVisible = true; //显示对话框
    },
    //关闭修改窗口
    editDialogClose() {},
    // 权限修改
    renderFunc(h, option) {
      // 穿梭框标签样式
      return <span>{option.label}</span>;
    },
    // 关闭授权加载中状态
    colseLoadingPermission() {
      let that = this;
      setTimeout(() => {
        if (that.loadingPermission) {
          that.loadingPermission = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
    // 数字正则表达式
    sortFormat(e) {
      let addForm = this.addForm;
      addForm.phone = addForm.phone.match(/^\d*/g)[0] || null;
      if (isNaN(addForm.phone)) {
        addForm.phone = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && addForm.phone.length >= 2) {
        addForm.phone = addForm.phone.replace(/0/, "");
      }
    },
    // 轮播图文件上传成功
    handleAvatarSuccess(file, item) {
      item.img = file.message;
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    // 图片上传修改
    onChangeUpload() {
      this.dataModificationLoading = !this.dataModificationLoading;
    },
    //确认修改
    editMes() {
      let that = this;
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const { data: res } = await that.$http.put(
          "/smallRoutineService/update",
          that.editForm
        );
        if ("200" != res.code) {
          return that.$message.error(res.message);
        }
        that.getList();
        that.$message.success("修改成功");
        // 隐藏添加弹出层
        that.editDialogVisible = false;
      });
    },
    // 位置移动
    async positionMovement(scop, sort) {
      let list = this.tableList;
      let index = scop.$index;
      if (0 == index && sort == -1) {
        this.$message.warning("已经是第一个了");
      } else if (index == list.length - 1 && sort == 1) {
        this.$message.warning("已经是最后一个了");
      }
      let newSort = index + sort;
      let { data: res } = await this.$http.put(
        "/smallRoutineService/updateMoveLocation",
        {
          id: list[index].id,
          toId: list[newSort].id,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      if (sort == -1) {
        // 前移
        list.splice(index - 1, 0, list[index]);
        //删除后一项
        list.splice(index + 1, 1);
      } else if (sort == 1) {
        // 后移
        // 在下一项插入该项
        list.splice(index + 2, 0, list[index]);
        // 删除前一项
        list.splice(index, 1);
      }
      this.$message.success("操作成功");
    },
    // 修改服务内容
    updateThisMes(e) {
      let code = e.code;
      switch (code) {
        case "loan":
        case "loan2":
        case "loan3":
        case "loan4":
          break;
        default:
          // 非贷款计算器
          return this.$message.warning("当前服务暂无可编辑内容");
      }
      this.serviceLoanMes.code = code;
      this.serviceLoanMes.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}

// 小程序页面展示编辑
.small_update_list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  .small_update_single {
    // 单个对象
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-left: 10px;
    // 图片
    .small_update_single_img_div {
      border: 1px solid #ccc;
      width: 280px;
      /deep/.el-upload {
        width: 100% !important;
        height: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px !important;
      }
      .small_update_single_img {
        width: 100%;
        height: 100%;
      }
    }
    // 图片下信息
    .small_update_single_mes {
      width: 70%;
      .small_update_single_mes_input {
        margin: 5px 0;
      }
    }
    .small_update_single_mes_button {
      width: 280px;
    }
  }
}
</style>
