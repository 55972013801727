<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- appId -->
      <el-form-item label="appid" prop="appId">
        <el-input
          v-model="updateForm.appId"
          clearable
          disabled
          maxlength="100"
          placeholder="请输入appid(不可更改)"
        ></el-input>
      </el-form-item>
      <!-- 应用私钥 -->
      <el-form-item label="应用私钥" prop="privateKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="updateForm.privateKey"
          clearable
          maxlength="20000"
          placeholder="请输入应用私钥"
        ></el-input>
      </el-form-item>
      <!-- 公钥 -->
      <el-form-item label="应用公钥" prop="publicKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="updateForm.publicKey"
          clearable
          maxlength="20000"
          placeholder="请输入应用公钥"
        ></el-input>
      </el-form-item>
      <!-- 支付宝公钥 -->
      <el-form-item label="支付宝公钥" prop="alipayPublicKey">
        <el-input
          type="textarea"
          :rows="4"
          v-model="updateForm.alipayPublicKey"
          clearable
          maxlength="20000"
          placeholder="请输入支付宝公钥"
        ></el-input>
      </el-form-item>
      <el-form-item label="aes密钥" prop="aesKey">
        <el-input
          v-model="updateForm.aesKey"
          maxlength="100"
          show-word-limit
          placeholder="请输入aes密钥"
        ></el-input>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="updateForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "applicationsUpdate",
  props: ["updateId", "refaushList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请输入小程序唯一标识", trigger: "blur" },
        ],
        appId: [
          { required: true, message: "请输入小程序appId", trigger: "blur" },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm(this.updateId);
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/thirdPartyApplications/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 刷新编辑对象
    async refaushForm(e) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/thirdPartyApplications/getUpdateMesById?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message;
      let startTimeText = message.startTimeText;
      let endTimeText = message.endTimeText;
      if (!this.isnull(startTimeText)) {
        let time = startTimeText.split(":");
        message.startTime = new Date(2016, 9, 10, time[0], time[1], time[2]);
      }
      if (!this.isnull(endTimeText)) {
        let time = endTimeText.split(":");
        message.endTime = new Date(2016, 9, 10, time[0], time[1], time[2]);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
