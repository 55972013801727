<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 排序 -->
      <el-form-item label="排序" prop="sort">
        <el-input
          @input="sortFormat"
          v-model="addForm.sort"
          placeholder="请输入排序,没填写默认99999999,值越小越靠前"
          maxlength="8"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="addForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "parentId"],
  data() {
    return {
      addForm: {
        name: "",
        status: true,
      },
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
      },
      disabled: false,
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.add();
      });
    },
    // 新增
    async add() {
      this.disabled = true;
      const { data: res } = await this.$http.post(
        "/smallRoutine3BottomCommodityMenuTwo/add",
        {
          parentId: this.parentId,
          ...this.addForm,
        }
      );
      this.disabled = false;
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 排序正则表达式
    sortFormat(e) {
      this.addForm.sort = this.addForm.sort.match(/^\d*(\d{0,0})/g)[0] || null;
      if (isNaN(this.addForm.sort)) {
        this.addForm.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.addForm.sort.length >= 2) {
        this.addForm.sort = this.addForm.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
