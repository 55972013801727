<template>
<div>
    <el-select  :value="smallMallMenuTemplateIdNumber"   placeholder="请选择菜单模板"  @change="change_select">
        <el-option  v-for="(item,index) in menu_templates" :key="index" :label="item.title"  :value="item.id"></el-option>
    </el-select>
</div>
</template>

<script>
	import axios from 'axios'
    import store from '@/store/index.js'
export default{
    components:{
		
    },
    props: {
        smallMallMenuTemplateId:{//默认选择第一个菜单模板
            type:String,
            default:null,
        }
    },
    data() {
        return {
            is_show:false,
            menu_templates:[],
        }
    },
    methods:{
        async    initialize_data(){
            let response = await    axios({
                url:store.state.global_base_url.main +"tb-third-party-wang-redpacket-menu-template/getAll"
            })
            this.menu_templates = response.data.message;
        },
        change_select(select_id){
            this.$emit("update:smallMallMenuTemplateId",select_id.toString());
        }
    },
    computed:{
		smallMallMenuTemplateIdNumber(){
            return parseInt(this.smallMallMenuTemplateId);
        }
	},
    created(){
		this.initialize_data();
    }
}
</script>

<style  lang="less" scoped>
</style>