<template>
  <div class="div_main">
    <el-form
      :model="copyFrom"
      status-icon
      :rules="copyRules"
      ref="copyRef"
      label-width="100px"
    >
      <el-form-item label="小程序" prop="chooseSmall">
        <el-select
          v-model="copyFrom.chooseSmall"
          filterable
          placeholder="请选择"
          @change="selectChange"
        >
          <el-option
            v-for="item in smallList"
            :key="item.name"
            :label="item.name"
            :value="item.code"
            :disabled="item.code == labelCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="复制内容" prop="list">
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="copyFrom.choostlist"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="modular in modularList"
            :label="modular"
            :key="modular"
            name="modular"
            >{{ modular }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('copyRef')"
          >提交
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "small_routine_layout_copy",
  props: ["labelCode", "refresh"],
  data() {
    return {
      copyFrom: {
        chooseSmall: "",
        choostlist: [],
      },
      copyRules: {
        chooseSmall: [
          { required: true, message: "请选择复制的小程序", trigger: "blur" },
        ],
      },
      checkAll: false,
      isIndeterminate: true,
      // 小程序列表
      smallList: [],
      // 模块列表
      modularList: [
        "基础设置",
        "轮播图",
        "菜单列表",
        "图文列表",
        "图文标题",
        "服务列表",
        "周边服务",
        "生活号",
        "消息模板",
      ],
    };
  },
  created() {
    this.getSmallList();
  },
  methods: {
    // 获取小程序列表
    async getSmallList() {
      const { data: res } = await this.$http.get(
        "smallRoutine/getAllCodeNameList"
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.smallList = res.message;
    },
    selectChange(e) {
      this.chooseSmall = e;
    },
    // 模块选中与取消全选
    handleCheckAllChange(val) {
      let modularList = this.modularList;
      this.copyFrom.choostlist = val ? modularList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.modularList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.modularList.length;
    },
    submitForm(formName) {
      let that = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          that.toCopy();
        } else {
          return false;
        }
      });
    },
    async toCopy() {
      let that = this;
      let form = that.copyFrom;
      if (form.choostlist.length == 0) {
        return this.$message.error("请至少选中一个复制的内容");
      }
      const { data: res } = await this.$http.post(
        "smallRoutine/smallRoutineCopyModular",
        {
          code: that.labelCode,
          copyCode: form.chooseSmall,
          copyModularList: form.choostlist,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.$message.success("操作成功");
      // this.smallList = res.message;
      // 刷新表格
      this.refresh();
    },
    // 清除选中信息
    clearForm() {
      this.copyFrom = {
        chooseSmall: "",
        choostlist: [],
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
