<template>
  <div>
    <el-button type="primary" @click="init">更新唯一标识</el-button>
    <el-link type="primary" @click="toCard">{{ url + uuid }}</el-link>
    <iframe
      :src="url + uuid"
      frameborder="0"
      width="100%"
      height="500px"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "offlineLink",
  props: ["url", "visible"],
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  data() {
    return {
      uuid: "",
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.uuid = this.getUID2();
    },
    getUID() {
      // 获取唯一值
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    getUID2() {
      let uuid = this.getUID();
      return uuid.replace(/-/g, "");
    },
    // 打开结果里的链接
    toCard() {
      let url = this.url;
      let uuid = this.uuid;
      let href = url + uuid;
      if (this.isNull(href)) {
        this.$message.warning("打开失败,跳转链接为空");
      }
      // 打开网页
      window.open(href);
      // 生成新的唯一标识
      this.init();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
