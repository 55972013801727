<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="head_input">
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="setShowType1()"
                icon="el-icon-arrow-left"
              >
                返回
              </el-button>
            </div>
            <div class="div_input">
              <!-- <div class="div_input">
                <label class="div_input_text">查询指定小程序:</label>
                <el-select
                  v-model="queryInfo2.selectAppId"
                  clearable
                  placeholder="全部"
                  @change="search"
                >
                  <el-option key="-1" label="全部" value=""> </el-option>
                  <el-option
                    v-for="item in smallRoutineList"
                    :key="'selectAppId' + item.appId"
                    :label="item.name"
                    :value="item.appId"
                  >
                  </el-option>
                </el-select>
              </div> -->
            </div>
            <!-- <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button> -->
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <img :src="scope.row.fileUrl" width="60" height="60" />
          </template>
        </el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="文件id" prop="fileId"></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%">
      <thisAdd
        :refaushList="getList"
        ref="addRef"
        :visible="addDialogVisible"
        :appId="appId"
      />
    </el-dialog>
  </div>
</template>

<script>
import { list, updateStatus, del } from "@/services/thirdParty/openFile";
import thisAdd from "./thisAdd.vue";
export default {
  components: {
    thisAdd,
  },
  props: ["setShowType1", "appId"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        selectAppId: this.appId,
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        selectAppId: this.appId,
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo2.appId = this.appId;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await list(this.queryInfo);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.pageMes.list = res.data.records;
      this.pageMes.total = res.data.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 查看详情
    async selectMes(e) {
      console.log(e);
    },
    // 修改状态
    async userStateChange(e) {
      const res = await updateStatus({
        id: e.id,
        status: e.status,
      });
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await del(id);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
