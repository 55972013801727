<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisible = true"
              >添加公司
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="联系人" prop="contacts"></el-table-column>
        <el-table-column label="联系人" prop="phone"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="stateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增窗口 -->
    <el-dialog
      title="新增"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="70px"
      >
        <!-- 公司 -->
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="公司名称"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 联系人 -->
        <el-form-item label="联系人" prop="contacts">
          <el-input
            v-model="addForm.contacts"
            clearable
            maxlength="100"
            show-word-limit
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="addForm.phone"
            clearable
            maxlength="11"
            show-word-limit
            placeholder="联系电话"
            @input="sortFormat"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="toAdd">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑窗口 -->
    <el-dialog
      title="修改"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose"
    >
      <el-form
        :model="editForm"
        :rules="addFormRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 公司 -->
        <el-form-item label="名称" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="公司名称"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 联系人 -->
        <el-form-item label="联系人" prop="contacts">
          <el-input
            v-model="editForm.contacts"
            clearable
            maxlength="100"
            show-word-limit
            placeholder="联系人"
          ></el-input>
        </el-form-item>
        <!-- 联系电话 -->
        <el-form-item label="电话" prop="phone">
          <el-input
            v-model="editForm.phone"
            clearable
            maxlength="11"
            show-word-limit
            placeholder="联系电话"
            @input="sortFormat"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  created() {
    this.getList();
  },
  data() {
    return {
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      //添加表单信息
      addForm: {
        name: "",
        contacts: "",
        phone: "",
      },
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        contacts: [
          { required: true, message: "请输入联系人名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度在 11 个字符",
            trigger: "blur",
          },
        ],
      },
      //修改信息
      editForm: {},
      //显示/隐藏修改栏
      editDialogVisible: false,
      // 加载中
      loadingPermission: false,
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取表格列表
    async getList() {
      const { data: res } = await this.$http.get("company/list", {
        params: this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.tableList = res.message.records;
      this.total = res.message.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 修改状态
    async stateChange(e) {
      const { data: res } = await this.$http.put(`/company/updateStatus`, {
        id: e.id,
        status: e.status,
      });
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    //监听添加
    addDialogClose() {
      this.$refs.addFormRed.resetFields();
    },
    // 添加
    async toAdd() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const { data: res } = await this.$http.post(
          "company/add",
          this.addForm
        );
        if ("200" != res.code) {
          return this.$message.error("操作失败:" + res.message);
        }
        this.$message.success("操作成功");
        // 隐藏添加弹出层
        this.addDialogVisible = false;
        //刷新表格
        this.getList();
      });
    },
    // 删除信息
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete("company/del?id=" + id);
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    //更改信息对话框
    async showEditDialog(e) {
      const { data: res } = await this.$http.get(
        "company/getMesById?id=" + e.id
      ); //获取内容
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.editForm = res.message;
      this.editDialogVisible = true; //显示对话框
    },
    //关闭修改窗口
    editDialogClose() {},
    //确认修改
    editUser() {
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const { data: res } = await this.$http.put(
          "company/update",
          this.editForm
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
        // 隐藏添加弹出层
        this.editDialogVisible = false;
        //刷新表格
        this.getList();
      });
    },
    // 权限修改
    renderFunc(h, option) {
      // 穿梭框标签样式
      return <span>{option.label}</span>;
    },
    // 关闭授权加载中状态
    colseLoadingPermission() {
      let that = this;
      setTimeout(() => {
        if (that.loadingPermission) {
          that.loadingPermission = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
    // 数字正则表达式
    sortFormat(e) {
      let addForm = this.addForm;
      addForm.phone = addForm.phone.match(/^\d*/g)[0] || null;
      if (isNaN(addForm.phone)) {
        addForm.phone = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && addForm.phone.length >= 2) {
        addForm.phone = addForm.phone.replace(/0/, "");
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
