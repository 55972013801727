import { render, staticRenderFns } from "./customerAdd.vue?vue&type=template&id=619b3306&scoped=true&"
import script from "./customerAdd.vue?vue&type=script&lang=js&"
export * from "./customerAdd.vue?vue&type=script&lang=js&"
import style0 from "./customerAdd.vue?vue&type=style&index=0&id=619b3306&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619b3306",
  null
  
)

export default component.exports