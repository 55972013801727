<template>
  <div>
    <el-form-item label="类目" prop="category">
      <template v-if="categoryMes.status">
        <template v-if="categoryMes.chooseIndex.length >= 1">
          <el-select
            filterable
            v-model="categoryMes.chooseIndex[0]"
            @change="categoryMesChange($event, 0)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.list"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="categoryMes.chooseIndex.length >= 2">
          <el-select
            filterable
            v-model="categoryMes.chooseIndex[1]"
            @change="categoryMesChange($event, 1)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.twoList"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="categoryMes.chooseIndex.length >= 3">
          <el-select
            filterable
            v-model="categoryMes.chooseIndex[2]"
            @change="categoryMesChange($event, 2)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.threeList"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <el-button type="primary" @click="clearCategoryMes">
          取消类目设置
        </el-button>
      </template>
      <el-button v-else type="primary" @click="getCategory">
        获取类目列表
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
import { queryCategory } from "@/services/thirdParty/queryCategory";
export default {
  name: "category",
  props: ["code", "type", "chooseMes", "id"],
  data() {
    return {
      // 分类列表
      categoryMes: {
        status: false,
        chooseIndex: [""],
        // 所有列表
        list: [],
        // 二级列表
        twoList: [],
        // 三级列表
        threeList: [],
      },
    };
  },
  watch: {
    id() {
      this.init();
    },
  },
  async created() {
    await this.init();
  },
  mounted() {},
  methods: {
    async init() {
      let e = this.chooseMes;
      if (this.isNull(e)) {
        this.clearCategoryMes();
        return;
      }
      await this.getCategory();
      let split = e.split("_");
      let length = split.length;
      for (let i = 0; i < length; i++) {
        this.categoryMesChange(split[i], i);
      }
    },
    // 获取选中的类目信息
    getChooseCategoryMes() {
      let categoryMes = this.categoryMes;
      let chooseIndex = categoryMes.chooseIndex;
      let length = chooseIndex.length;
      let category = "";
      if (length > 1) {
        for (let i = 0; i < length; i++) {
          let single = chooseIndex[i];
          if (this.isNull(single)) {
            return this.$message.warning("请填写完整类目信息");
          }
          if (category != "") {
            category += "_";
          }
          category += single;
        }
      }
      return category;
    },
    // 获取小程序类目
    async getCategory() {
      let isvCode = this.code;
      let isvType = this.type;
      if (this.isNull(isvCode) || this.isNull(isvType)) {
        return this.$message.warning("请先选中三方应用或模板");
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await queryCategory({
        smallRoutineType: isvType,
        code: isvCode,
      });
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        this.clearCategoryMes();
        return;
      }
      this.categoryMes.status = true;
      this.categoryMes.list = res.data;
    },
    // 清除类目列表
    clearCategoryMes() {
      this.categoryMes.status = false;
      this.categoryMes.list = [];
      this.categoryMes.chooseIndex = [""];
    },
    // 类目选中变更
    categoryMesChange(e, index) {
      let categoryMes = this.categoryMes;
      let chooseIndex = categoryMes.chooseIndex;
      let length = chooseIndex.length;
      if (length <= index) {
        return this.$message.error("数据有误,请刷新后重试");
      }
      chooseIndex.length = index;
      chooseIndex.push(e);
      let list, chooseMes;
      if (index == 0) {
        list = categoryMes.list;
        chooseMes = list[e];
        categoryMes.twoList = categoryMes.list[e].children;
      } else if (index == 1) {
        list = categoryMes.twoList;
        for (let i = 0, length = list.length; i < length; i++) {
          let single = list[i];
          if (e == single.category_id) {
            chooseMes = single;
          }
        }
        categoryMes.threeList = chooseMes.children;
      } else if (index == 2) {
        list = categoryMes.threeList;
      } else {
        return this.$message.error("数据有误,请刷新后重试");
      }
      if (!this.isNull(chooseMes)) {
        if (chooseMes.has_child) {
          chooseIndex.push("");
        }
      }
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
