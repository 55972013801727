<template>
  <div class="div_home">
    <!-- 顶部按钮 -->
    <div class="div_home_title">
      <el-row :gutter="25">
        <!-- 搜索区域 -->
        <!-- 搜索添加 -->
        <div class="head_input">
          <div class="div_input">
            <label class="div_input_text">物料选择:</label>
            <el-select
              v-model="queryInfo.materialId"
              placeholder="请选择搜索的物料商品"
              filterable
              @change="materialChange"
            >
              <el-option label="全部" value="0"> </el-option>
              <el-option
                v-for="item in materialList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-button type="primary" @click="flushTable"> 刷新 </el-button>
          <el-button
            v-show="pageMes.list.length > 0"
            type="primary"
            @click="toSave"
          >
            保存排序修改
          </el-button>
        </div>
      </el-row>
    </div>
    <!-- 数据内容 -->
    <el-card class="div_card">
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="商品图片" prop="pictUrl" width="130">
          <template slot-scope="scope">
            <img
              class="commodityImg"
              :src="scope.row.pictUrl"
              style=""
            /> </template
        ></el-table-column>
        <el-table-column
          label="商品信息-商品标题"
          prop="title"
        ></el-table-column>
        <el-table-column
          label="商品信息-佣金比率(%)"
          prop="commissionRate"
        ></el-table-column>
        <el-table-column
          label="商品信息-一口价"
          prop="reservePrice"
        ></el-table-column>
        <el-table-column
          label="优惠券（元） 若属于预售商品，该优惠券付尾款可用，付定金不可用"
          prop="couponAmount"
        ></el-table-column>
        <el-table-column
          label="折扣价（元） 若属于预售商品，付定金时间内，折扣价=预售价"
          prop="zkFinalPrice"
        ></el-table-column>
        <el-table-column label="销量(30天)" prop="volume"></el-table-column>
        <el-table-column label="店铺名称" prop="shopTitle"></el-table-column>
        <el-table-column label="卖家类型" prop="userTypeText"></el-table-column>
        <el-table-column label="物料名称" prop="materialName"></el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sort"
              maxlength="8"
              @focus="inputFocus(scope.row)"
              @blur="inputBlur(scope.row)"
              placeholder="数字越小排序越靠前，没填默认排序在最后面(99999999)"
            >
            </el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 详情 -->
    <el-dialog title="详情" :visible.sync="messageVisible.show" width="40%">
      <newProductMessage ref="newProductMessage" :mes="messageVisible.mes" />
    </el-dialog>
  </div>
</template>

<script>
import newProductMessage from "../newProduct/newProductMessage.vue";
export default {
  components: { newProductMessage },
  props: ["materialList"],
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        materialId: "0",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容(点击搜索后将queryInfo值赋予该对象)
      queryInfo2: {
        //查询信息
        materialId: "0",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示详情弹窗
      messageVisible: {
        mes: "",
        show: false,
      },
      // 修改的数据
      updateList: {},
      // input记录选中的顺序旧值
      sortUpdate: {
        id: "",
        sort: "",
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 刷新列表
    flushTable() {
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.get(
        "/taoBaoTbkDgOptimusMaterial/list",
        {
          params: { ...this.queryInfo2 },
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 物料修改
    materialChange(e) {
      let updateList = this.updateList;
      let updateListKey = Object.keys(updateList);
      let length = updateListKey.length;
      let that = this;
      if (length != 0) {
        this.$confirm("之前商品顺序有修改为保存是否保存", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 保存
            that.toSave();
            this.toMaterialChange(e);
          })
          .catch(() => {
            // 不保存
            // 清空修改数据内容
            that.updateList = {};
            // 提示
            that.$message({
              type: "info",
              message: "修改内容已还原",
            });
            this.toMaterialChange(e);
          });
      } else {
        this.toMaterialChange(e);
      }
    },
    toMaterialChange(e) {
      // 重置查询条件
      this.queryInfo2.materialId = e;
      this.queryInfo2.pageNum = 1;
      this.tableList = [];
      // 获取数据
      this.getList();
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo2.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo2.pageNum = newPage;
      this.getList();
    },
    // 保存
    async toSave() {
      let updateList = this.updateList;
      let updateListKey = Object.keys(updateList);
      let length = updateListKey.length;
      if (length == 0) {
        return this.$message.warning("数据没有进行修改");
      }
      let toPutList = [];
      for (let i = 0; i < length; i++) {
        let id = updateListKey[i];
        let updateSingle = updateList[id];
        toPutList.push({
          id: id,
          sort: updateSingle.newSort,
        });
      }
      const { data: res } = await this.$http.put(
        "/taoBaoTbkDgOptimusMaterial/updateSort",
        {
          updateList: toPutList,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.getList();
      this.updateList = {};
      this.$message.success("修改成功");
    },
    // 修改状态
    async productUpdate(e, status) {
      let updateList = this.updateList;
      let add = updateList.add;
      let addMes = updateList.addMes;
      let del = updateList.del;
      let itemId = e.item_id;
      if (status) {
        if (del.length > 0) {
          // 本来存在的删除后又新增
          let delIndex = del.indexOf(itemId);
          if (delIndex != -1) {
            del.splice(delIndex, 1);
            return;
          }
        }
        // 新增
        add.push(itemId);
        addMes.push(e);
      } else {
        // 删除
        if (add.length > 0) {
          let addIndex = add.indexOf(itemId);
          if (addIndex != -1) {
            add.splice(addIndex, 1);
            addMes.splice(addIndex, 1);
          } else {
            this.updateList.add(itemId);
          }
        } else {
          del.push(itemId);
        }
      }
    },
    // 查看详情
    showMessage(e) {
      if (this.$refs.newProductMessage == undefined) {
        this.messageVisible = {
          mes: e,
          show: true,
        };
      } else {
        this.$refs.newProductMessage.init(e);
        this.messageVisible = {
          mes: e,
          show: true,
        };
      }
    },
    // 顺序输入框获得焦点
    inputFocus(updateMes) {
      this.sortUpdate = {
        id: updateMes.id,
        sort: updateMes.sort,
      };
    },
    // 顺序输入框失去焦点
    inputBlur(updateMes) {
      let newSort = updateMes.sort;
      let sortUpdate = this.sortUpdate;
      let id = sortUpdate.id;
      let sort = sortUpdate.sort;
      if (!/^\d+$/.test(newSort)) {
        updateMes.sort = sort;
        return this.$message.warning("顺序只能为正整数");
      }
      let newSortNumber = Number(newSort);
      if (newSortNumber <= 0) {
        updateMes.sort = sort;
        return this.$message.warning("顺序不能小于1");
      }
      if (id == updateMes.id && sort != updateMes.sort) {
        // 有修改
        let updateList = this.updateList;
        let updateMes = updateList[id];
        if (updateMes == undefined) {
          // 当前id未临时修改记录
          updateMes = {
            oldSort: Number(sort),
            newSort: newSortNumber,
          };
          updateList[id] = updateMes;
        } else {
          // 当前id已有临时修改记录
          if (updateMes.oldSort == newSortNumber) {
            // 顺序改为修改前,删除临时修改记录
            delete updateList[id];
          } else {
            updateMes.newSort = newSortNumber;
            updateList[id] = updateMes;
          }
        }
      }
      this.sortUpdate = {
        id: "",
        sort: "",
      };
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      // 发起删除请求
      const { data: res } = await this.$http.delete(
        "taoBaoTbkDgOptimusMaterial/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
      // 如果该数据有临时修改记录则删除修改记录
      let updateList = this.updateList;
      if (updateList[id] != undefined) {
        delete updateList[id];
      }
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // 顶部按钮
  .div_home_title {
    height: 50px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    .head_input {
      display: flex;
      .div_input {
        display: flex;
        align-items: center;
        // flex: 1;
        width: 350px;
        padding-right: 5px;
        .div_input_text {
          white-space: nowrap;
          padding-right: 5px;
        }
      }
    }
  }
  .div_card {
    height: calc(100% - 70px);
    // width: 100%;
    // 商品图片
    .commodityImg {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
