<template>
  <div>
    <el-form-item
      :label="'类目' + (index + 1)"
      prop="category"
      v-for="(item, index) in categoryList"
      :key="'category_' + index"
    >
      <template v-if="item.status">
        <template v-if="item.list.length >= 1">
          <el-select
            filterable
            v-model="item.list[0]"
            @change="categoryMesChange($event, 0, index)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.list"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="item.list.length >= 2">
          <el-select
            filterable
            v-model="item.list[1]"
            @change="categoryMesChange($event, 1, index)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.twoList"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <template v-if="item.list.length >= 3">
          <el-select
            filterable
            v-model="item.list[2]"
            @change="categoryMesChange($event, 2, index)"
            placeholder="请选择类目"
          >
            <el-option
              v-for="item in categoryMes.threeList"
              :key="item.category_id"
              :label="item.category_name"
              :value="item.category_id"
            >
            </el-option>
          </el-select>
        </template>
        <el-button type="primary" @click="clearCategoryMes(item)">
          取消类目设置
        </el-button>
      </template>
      <el-button v-else type="primary" @click="getCategory(item)">
        获取类目列表
      </el-button>
    </el-form-item>
  </div>
</template>

<script>
import { queryVoByApplicationsId } from "@/services/thirdParty/queryVoByApplicationsId";
export default {
  name: "category",
  props: ["chooseMes", "id", "applicationsId"],
  data() {
    return {
      // 分类列表
      categoryMes: {
        status: false,
        // 所有列表
        list: [],
        // 二级列表
        twoList: [],
        // 三级列表
        threeList: [],
      },
      categoryList: [
        {
          status: false,
          list: [],
        },
        {
          status: false,
          list: [],
        },
        {
          status: false,
          list: [],
        },
      ],
    };
  },
  watch: {
    id() {
      this.init();
    },
  },
  async created() {
    await this.init();
  },
  mounted() {},
  methods: {
    async init() {
      let e = this.chooseMes;
      if (this.isNull(e)) {
        this.clearCategoryAll();
        return;
      }
      await this.getCategory();
      let split1 = e.split(";");
      for (let i = 0, length = split1.length; i < length; i++) {
        let split = split1[i].split("_");
        this.$set(this.categoryList[i], "status", true);
        this.$set(this.categoryList[i], "list", [""]);
        for (let j = 0, length1 = split.length; j < length1; j++) {
          await this.categoryMesChange(split[j], j, i);
        }
      }
    },
    // 获取选中的类目信息
    getChooseCategoryMes() {
      let categoryList = this.categoryList;
      let category = "";
      for (let i = 0, length = categoryList.length; i < length; i++) {
        let item = categoryList[i];
        if (!item.status) {
          continue;
        }
        let list = item.list;
        let nowCategory = "";
        for (let j = 0, length1 = list.length; j < length1; j++) {
          let single = list[j];
          if (this.isNull(single)) {
            return this.$message.warning("请填写完整类目信息");
          }
          if (nowCategory != "") {
            nowCategory += "_";
          }
          nowCategory += single;
        }
        if (nowCategory != "") {
          if (category != "") {
            category += ";";
          }
          category += nowCategory;
        }
      }
      return category;
    },
    // 获取小程序类目
    async getCategory(e) {
      let categoryMes = this.categoryMes;
      if (!categoryMes.status) {
        let applicationsId = this.applicationsId;
        if (this.isNull(applicationsId)) {
          return this.$message.warning("请先选中三方应用或模板");
        }
        const loading = this.$loading({
          lock: true,
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        let res = await queryVoByApplicationsId({
          id: applicationsId,
        });
        loading.close();
        if (!res.success) {
          this.$message.error(res.err);
          this.clearCategoryMes();
          return;
        }
        this.$set(this.categoryMes, "list", res.data);
        this.$set(this.categoryMes, "status", true);
      }
      if (!this.isNull(e)) {
        this.$set(e, "status", true);
        this.$set(e, "list", [""]);
      }
    },
    // 清除类目列表
    clearCategoryMes(e) {
      this.$set(e, "status", false);
      this.$set(e, "list", []);
      // this.categoryMes.status = false;
      // this.categoryMes.list = [];
      // this.categoryMes.chooseIndex = [""];
    },
    clearCategoryAll() {
      for (let i = 0; i < 3; i++) {
        this.$set(this.categoryList[i], "status", false);
        this.$set(this.categoryList[i], "list", []);
      }
    },
    // 类目选中变更
    categoryMesChange(e, index, type) {
      let categoryMes = this.categoryMes;
      let categoryList = this.categoryList;
      let chooseIndex = categoryList[type].list;
      let length = chooseIndex.length;
      if (length <= index) {
        return this.$message.error("数据有误,请刷新后重试");
      }
      chooseIndex.length = index;
      chooseIndex.push(e);
      let list, chooseMes;
      if (index == 0) {
        list = categoryMes.list;
        chooseMes = list[e];
        categoryMes.twoList = categoryMes.list[e].children;
      } else if (index == 1) {
        list = categoryMes.twoList;
        for (let i = 0, length = list.length; i < length; i++) {
          let single = list[i];
          if (e == single.category_id) {
            chooseMes = single;
          }
        }
        categoryMes.threeList = chooseMes.children;
      } else if (index == 2) {
        list = categoryMes.threeList;
      } else {
        return this.$message.error("数据有误,请刷新后重试");
      }
      if (!this.isNull(chooseMes)) {
        if (chooseMes.has_child) {
          chooseIndex.push("");
        }
      }
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="scss" scoped></style>
