// 接口请求地址
const path = 'thirdSmallRoutineAuthorizationNotice/';
import axios from 'axios'

/**
 * 校验模板授权标识是否已生成小程序
 */
export async function getByOutBizNo(outBizNo) {
  const { data: res } = await axios.get(
    path + 'getByOutBizNo?outBizNo='+outBizNo
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "校验模板授权标识是否已生成小程序失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

