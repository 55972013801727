<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="head_input">
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="setShowType1()"
                icon="el-icon-arrow-left"
              >
                返回
              </el-button>
            </div>
            <div class="div_input">
              <div class="div_input">
                <label class="div_input_text">查询指定小程序:</label>
                <el-select
                  v-model="queryInfo2.selectAppId"
                  clearable
                  placeholder="全部"
                  @change="search"
                >
                  <el-option key="-1" label="全部" value=""> </el-option>
                  <el-option
                    v-for="item in smallRoutineList"
                    :key="'selectAppId' + item.appId"
                    :label="item.name"
                    :value="item.appId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <!-- <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button> -->
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column type="expand" fixed>
          <template slot-scope="props">
            <el-form
              label-position="left"
              inline
              class="demo-table-expand"
              label-width="auto"
            >
              <el-form-item label="小程序名称">
                {{ props.row.app_name }}
              </el-form-item>
              <el-form-item label="appId">
                {{ props.row.appid }}
              </el-form-item>
              <el-form-item label="是不是最新的工单">
                <div v-if="props.row.latest">是</div>
                <div v-else>否</div>
              </el-form-item>
              <el-form-item label="申请单ID">
                {{ props.row.order_id }}
              </el-form-item>
              <el-form-item label="服务码">
                {{ props.row.service_code }}
              </el-form-item>
              <el-form-item label="申请类型">
                <div v-if="props.row.access_type == 'BASE'">基础信息</div>
                <div v-else-if="props.row.access_type == 'BRAND_BOX'">
                  品牌直达
                </div>
                <div v-else-if="props.row.access_type == 'SERVICE_BOX'">
                  服务直达
                </div>
                <div v-else>其他:{{ props.row.access_type }}</div>
              </el-form-item>
              <el-form-item label="审核状态">
                <div v-if="props.row.status == 'AGREE'">同意</div>
                <div v-else-if="props.row.status == 'REJECT'">拒绝</div>
                <div v-else-if="props.row.status == 'EDIT'">编辑</div>
                <div v-else-if="props.row.status == 'AUDIT'">审核</div>
                <div v-else-if="props.row.status == 'PART_AGREE'">部分同意</div>
                <div v-else>其他:{{ props.row.status }}</div>
              </el-form-item>
              <el-form-item
                label="审核原因"
                v-if="props.row.audit_reason != null"
              >
                {{ props.row.audit_reason }}
              </el-form-item>
              <template v-if="props.row.part_agree_info != null">
                <template v-for="(item, index) in props.row.part_agree_info">
                  <el-divider
                    content-position="left"
                    :key="'elDivider' + index"
                  >
                    审核提效部分剔除数据的信息{{ index + 1 }}
                  </el-divider>
                  <el-form-item
                    label="剔除数据名称"
                    :key="'audit_info' + index"
                  >
                    {{ item.audit_info }}
                  </el-form-item>
                  <el-form-item
                    label="剔除数据的operator信息"
                    :key="'audit_operator' + index"
                  >
                    {{ item.audit_operator }}
                  </el-form-item>
                  <el-form-item label="剔除原因" :key="'audit_reason' + index">
                    {{ item.audit_reason }}
                  </el-form-item>
                  <el-form-item
                    label="剔除数据类型"
                    :key="'audit_type' + index"
                  >
                    {{ item.audit_type }}
                  </el-form-item>
                </template>
              </template>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="小程序名称"
          prop="app_name"
          width="200"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="create_time"
          width="200"
        ></el-table-column>
        <el-table-column
          label="申请单ID"
          prop="order_id"
          width="200"
        ></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <div v-if="scope.row.status == 'AGREE'">同意</div>
            <div v-else-if="scope.row.status == 'REJECT'">拒绝</div>
            <div v-else-if="scope.row.status == 'EDIT'">编辑</div>
            <div v-else-if="scope.row.status == 'AUDIT'">审核</div>
            <div v-else-if="scope.row.status == 'PART_AGREE'">部分同意</div>
            <div v-else>其他:{{ scope.row.status }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="查看详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="selectMes(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%">
      <thisAdd
        :refaushList="getList"
        ref="addRef"
        :visible="addDialogVisible"
        :appId="appId"
      />
    </el-dialog>
  </div>
</template>

<script>
import { query } from "@/services/thirdParty/openSearchBaseOrder";
import { getAllSmallRoutineList } from "@/services/thirdParty/smallRoutine/smallRoutine";
import thisAdd from "./thisAdd.vue";
export default {
  components: {
    thisAdd,
  },
  props: ["setShowType1", "appId"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        selectAppId: this.appId,
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        selectAppId: this.appId,
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      smallRoutineList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search();
      this.getAllSmallRoutineList2();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo2.appId = this.appId;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post(
        "/aliPayOpenSearchBaseOrder/batchQuery",
        this.queryInfo
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.page_data;
      this.pageMes.total = res.message.total_number;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    async getAllSmallRoutineList2() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getAllSmallRoutineList();
      loading.close();
      if (!res.success) {
        return this.$message.error("获取小程序id与名称列表失败:" + res.err);
      }
      this.smallRoutineList = res.data;
    },
    // 查看详情
    async selectMes(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await query({
        appId: e.appid,
        applyId: e.order_id,
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
