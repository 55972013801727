<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <el-col :span="1.5">
            <el-button
              icon="el-icon-arrow-left"
              type="primary"
              @click="returnSmallRoutine"
              >返回
            </el-button>
          </el-col>
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="页面路径" prop="src"></el-table-column>
        <el-table-column label="是否使用该服务" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="addRel(scope.row, 1)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="首页展示"
          prop="status"
          v-if="smallRoutineName == 1 || smallRoutineName == 2"
        >
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.showIndex"
              @change="addRel(scope.row, 2)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
export default {
  created() {
    // 获取小程序管理页面传递的参数
    this.getAppId();
    this.getTableList();
  },
  data() {
    return {
      // 小程序页面传过来的参数(当前页及页码)
      smallRoutineQuery: {},
      // 小程序页面展示数据当前code
      code: "",
      // 来自哪个页面
      from: "",
      smallRoutineName: "",
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getTableList();
    },
    // 获取传递的信息
    getAppId() {
      let that = this;
      let query = this.$route.query;
      if (null == query || "" == query || undefined == query) {
        this.$message.error("参数有误，请刷新后重试");
        return setTimeout(function () {
          that.$router.push({ path: "/welcome" });
        }, 1500);
      }
      this.smallRoutineQuery = query.queryInfo;
      this.code = query.code;
      this.from = query.from;
      this.smallRoutineName = query.smallRoutineName;
    },
    // 返回小程序列表
    returnSmallRoutine() {
      let from = this.from;
      this.$router.push({
        path: from,
        query: { queryInfo: this.smallRoutineQuery },
      });
    },
    // 获取表格列表
    async getTableList() {
      let that = this;
      const { data: res } = await this.$http.get(
        "smallRoutineServiceRel/getRelList",
        {
          params: {
            ...this.queryInfo,
            code: that.code,
            smallRoutineName: that.smallRoutineName,
          },
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.tableList = res.message.records;
      this.total = res.message.total;
    },
    //最大数变更
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getTableList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getTableList();
    },
    // 修改状态
    async addRel(e, type) {
      // type :1 正常关联 2首页展示
      let status;
      if ("2" == type) {
        status = e.showIndex;
      } else {
        status = e.status;
      }
      let that = this;
      const { data: res } = await this.$http.post(
        `/smallRoutineServiceRel/updateRel`,
        {
          code: this.code,
          serviceId: e.id,
          status: status,
          type: type,
          smallRoutineName: that.smallRoutineName,
        }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      if (("2" == type && status == true) || ("1" == type && !status)) {
        // 开启首页展示或关闭服务
        e.showIndex = status;
        e.status = status;
      }
      this.$message.success("操作成功");
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
</style>
