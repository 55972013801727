// ———— 短链后台 ————
// 接口请求地址
const path = 'shortLink/';
import axios from 'axios'
import store  from '@/store/index';

/**
 * 新增
 */
 export async function add(e) {
  let {data:res} = await axios.post(path+"add",e).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 列表查询
 */
export async function list(e) {
  let {data:res} = await axios.get(path+"list",
  {
    params: e,
  }
  ).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改状态
 */
 export async function updateStatus(e) {
  let {data:res} = await axios.put(path+"updateStatus",{
    ...e
  }).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据id获取数据
 */
 export async function getUpdateMesById(id) {
  let {data:res} = await axios.get(path+"getUpdateMesById?id="+id).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
/**
 * 修改
 */
 export async function update(e) {
  let {data:res} = await axios.put(path+"update",{
    ...e
  }).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改
 */
 export async function del(id) {
  let {data:res} = await axios.delete(path+"del?id="+id).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 小程序模板授权接口生成短链接
 */
 export async function smallRoutineTemplateCreateShortLink(data={}) {
  let {data:res} = await axios.post(path+"smallRoutineTemplateCreateShortLink",{
    ...data,
    serviceBackUrl: store.state.global_base_url.main
  }).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据小程序模板id获取短链
 */
 export async function getShortLinkByTemplateId(templateId) {
  let {data:res} = await axios.get(path+"getShortLinkByTemplateId?id="+templateId+"&serviceBackUrl="+store.state.global_base_url.main).then(res => { return res })
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

