<template>
  <div class="div_home">
    <!-- <div class="div_title">用户列表</div> -->
    <!-- 用户主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.query"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
          <!-- 搜索按钮 -->
          <el-col :span="4">
            <el-button type="primary" @click="addDialogVisibleShow"
              >添加用户
            </el-button>
          </el-col>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="userList" border stripe>
        <el-table-column type="index"></el-table-column
        ><!-- 索引列 -->
        <el-table-column label="账号" prop="account"></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="角色" prop="roleName"></el-table-column>
        <el-table-column
          label="所属公司"
          prop="companyName"
          v-if="'1' == roleGrade"
        ></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑用户信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 权限 -->
            <!-- 文字提示 enterable隐藏 -->
            <el-tooltip
              effect="dark"
              content="权限分配"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="permissionToModify(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除用户"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteUser(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增用户信息 -->
    <el-dialog
      title="添加用户"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="70px"
      >
        <!-- 账号 -->
        <el-form-item label="账号" prop="account">
          <el-input v-model="addForm.account" clearable></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" clearable></el-input>
        </el-form-item>
        <!-- 名称 -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="addForm.name" clearable></el-input>
        </el-form-item>
        <!-- 公司 -->
        <el-form-item label="公司" prop="company" v-if="'1' == roleGrade">
          <el-select
            filterable
            v-model="addForm.company"
            placeholder="请选择所属公司"
            @change="selectRoleChange"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="'userRole_' + index"
              :label="item.name + '(' + item.contacts + ')'"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 角色 -->
        <el-form-item label="角色" prop="role">
          <el-select filterable v-model="addForm.role" placeholder="请选择角色">
            <el-option
              v-for="(item, index) in chooseUserRoleList"
              :key="'userRole_' + index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户信息 -->
    <el-dialog
      title="编辑用户信息"
      :visible.sync="editDialogVisible"
      width="50%"
      @close="editDialogClose"
    >
      <el-form
        :model="editForm"
        :rules="editFormRules"
        ref="editFormRed"
        label-width="70px"
      >
        <!-- 账号 -->
        <el-form-item label="账号" prop="account">
          <el-input v-model="editForm.account" clearable disabled></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="editForm.password"
            show-password
            placeholder="需要修改密码的时候才需要输入，不修改放空即可"
          ></el-input>
        </el-form-item>
        <!-- 名称 -->
        <el-form-item label="名称" prop="name">
          <el-input v-model="editForm.name" clearable></el-input>
        </el-form-item>
        <!-- 公司 -->
        <el-form-item label="公司" prop="company" v-if="'1' == roleGrade">
          <el-select
            filterable
            v-model="editForm.company"
            placeholder="请选择所属公司"
            @change="selectRoleChange"
          >
            <el-option
              v-for="(item, index) in companyList"
              :key="'userRole_' + index"
              :label="item.name + '(' + item.contacts + ')'"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 角色 -->
        <el-form-item label="角色" prop="role">
          <el-select
            filterable
            v-model="editForm.role"
            placeholder="请选择角色"
          >
            <el-option
              v-for="(item, index) in chooseUserRoleList"
              :key="'userRole_' + index"
              :label="item.name"
              :value="item.id"
              :disabled="item.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editUser">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改用户权限 -->
    <el-dialog
      title="权限分配"
      :visible.sync="permissionToModifyVisible"
      width="50%"
      v-loading="loadingPermission"
      element-loading-text="请稍等..."
    >
      <div style="text-align: center">
        <el-transfer
          style="text-align: left; display: inline-block"
          v-model="permissionValue"
          filterable
          :render-content="renderFunc"
          :titles="['未授权菜单', '已授权菜单']"
          :button-texts="['取消', '授权']"
          :data="permissionList"
        >
        </el-transfer>
      </div>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="permissionSave">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  async created() {
    this.roleGrade = window.sessionStorage.getItem("roleGrade");
    this.getUserList();
    // 现获取公司列表在获取角色列表
    await this.getCompanyList();
    let companyList = this.companyList;
    if (companyList.length == 0) {
      return this.$message.error("未获取到公司列表，请先创建公司");
    } else {
      let companyId = this.companyList[0].id;
      this.addForm.company = companyId;
      this.getRoleList(companyId);
    }
  },
  data() {
    return {
      roleGrade: "", //角色等级
      queryInfo: {
        //查询信息
        query: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      companyList: [], //公司列表
      userList: [], //用户列表
      userRoleList: {}, // 用户角色列表(公司id:角色列表)
      chooseUserRoleList: [], //新增的角色下拉列表
      total: 0, //总记录数
      addDialogVisible: false, //对话框显示状态
      //添加表单信息
      addForm: {
        acount: "",
        name: "",
        password: "",
        role: "",
        company: "",
      },
      // 表单验证
      addFormRules: {
        account: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        role: [{ required: true, message: "请选择角色", trigger: "blur" }],
        company: [{ required: true, message: "请选择公司", trigger: "blur" }],
      },
      //修改用户信息
      editForm: {},
      //显示/隐藏修改用户栏
      editDialogVisible: false,
      //表单验证
      editFormRules: {
        password: [
          { required: false, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "长度在 6 到 20 个字符",
            trigger: "blur",
          },
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 20,
            message: "长度在 1 到 20 个字符",
            trigger: "blur",
          },
        ],
        role: [{ required: false, message: "请选择角色", trigger: "blur" }],
      },
      // 用户权限
      permissionToModifyVisible: false,
      // 当前授权的用户id
      permissionUserId: "",
      // 授权人拥有的菜单列表
      permissionList: [],
      // 授权人拥有的菜单id列表
      permissionIdList: [],
      // 已授权的菜单id
      permissionValue: [],
      // 加载中
      loadingPermission: false,
    };
  },
  methods: {
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getUserList();
    },
    // 获取角色
    async getRoleList(companyId) {
      const { data: res } = await this.$http.get(
        "role/userControlGetRoleList?companyId=" + companyId
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.userRoleList[companyId] = res.message;
      this.chooseUserRoleList = res.message;
    },
    // 获取公司列表
    async getCompanyList() {
      const { data: res } = await this.$http.get("company/getAllCompany");
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.companyList = res.message;
    },
    //获取所有用户
    async getUserList() {
      const { data: res } = await this.$http.get("/user/getUserList", {
        params: this.queryInfo,
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.userList = res.message.records;
      this.total = res.message.total;
    },
    // 角色选择框值变动
    selectRoleChange(companyId) {
      let userRoleList = this.userRoleList[companyId];
      this.chooseUserRoleList = [];
      this.addForm.role = "";
      if (null == userRoleList || undefined == userRoleList) {
        // 获取角色列表
        this.getRoleList(companyId);
      } else {
        this.chooseUserRoleList = userRoleList;
      }
    },
    //最大数
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getUserList();
    },
    //当前页面变化触发
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getUserList();
    },
    // 修改用户状态
    async userStateChange(userInfo) {
      const { data: res } = await this.$http.put(
        `/user/updateUserState?id=${userInfo.id}&status=${userInfo.status}`
      );
      if (res.code != "200") {
        userInfo.status = !userInfo.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    //监听添加用户
    addDialogClose() {
      this.$refs.addFormRed.resetFields();
    },
    // 添加用户
    async addUser() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const { data: res } = await this.$http.post(
          "user/addUser",
          this.addForm
        );
        if ("200" != res.code) {
          return this.$message.error("操作失败:" + res.message);
        }
        this.$message.success("操作成功");
        // 隐藏添加弹出层
        this.addDialogVisible = false;
        //刷新表格
        this.getUserList();
      });
    },
    // 删除用户信息
    async deleteUser(id) {
      const result = await this.$confirm(
        "此操作将永久删除用户,是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancleButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete("user/deleteUser?id=" + id);
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getUserList(); //刷新表格
    },
    // 修改用户权限
    async permissionToModify(id) {
      let { data: res } = await this.$http.get("menu/getMenuListById?id=" + id);
      if ("200" != res.code) {
        return this.$message.error("获取菜单列表失败:" + res.message);
      }
      this.permissionUserId = id;
      let permissionList = res.message.menuList;
      this.permissionList = permissionList;
      this.permissionValue = res.message.haveMenuIdList;
      let permissionIdList = [];
      for (let index = 0; index < permissionList.length; index++) {
        permissionIdList.push(permissionList[index].key);
      }
      this.permissionIdList = permissionIdList;
      this.permissionToModifyVisible = true;
    },
    //更改用户信息对话框
    //显示对话
    async showEditDialog(userMes) {
      this.editForm = userMes;
      this.selectRoleChange(userMes.company);
      this.editDialogVisible = true; //显示对话框
    },
    // 点击新建
    addDialogVisibleShow() {
      this.addForm.role = "";
      this.editForm.role = "";
      this.addDialogVisible = true;
    },
    //关闭修改窗口
    editDialogClose() {},
    //确认修改
    editUser() {
      this.$refs.editFormRed.validate(async (valid) => {
        if (!valid) return;
        //发起请求
        const { data: res } = await this.$http.put(
          "user/updateUser",
          this.editForm
        );
        if ("200" != res.code) {
          return this.$message.error("修改失败:" + res.message);
        }
        this.$message.success("修改成功");
        // 隐藏添加弹出层
        this.editDialogVisible = false;
        //刷新表格
        this.getUserList();
      });
    },
    // 用户权限修改
    renderFunc(h, option) {
      // 穿梭框标签样式
      return <span>{option.label}</span>;
    },
    // 用户授权点击保存
    permissionSave() {
      // 访问后台
      this.asyncPermissionSave();
      // 超时则关闭加载中状态
      this.colseLoadingPermission();
    },
    async asyncPermissionSave() {
      this.loadingPermission = true;
      let permissionValue = this.permissionValue;
      let permissionIdList = this.permissionIdList;
      let userId = this.permissionUserId;
      let { data: res } = await this.$http.put("user/updateUserMenuRel", {
        userId: userId,
        menuIdList: permissionValue,
        operatorMenuIdList: permissionIdList,
      });
      if ("200" != res.code) {
        return this.$message.error("修改失败:" + res.message);
      }
      this.$message.success("修改成功");
      this.loadingPermission = false;
    },
    // 关闭用户授权加载中状态
    colseLoadingPermission() {
      let that = this;
      setTimeout(() => {
        if (that.loadingPermission) {
          that.loadingPermission = false;
          that.$message.error("连接服务器超时");
        }
      }, that.$lodingMaxTime);
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
