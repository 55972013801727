
<template>
  <div>
    <el-form	:model="updateForm"	label-width="auto"	class="form_class">
      <el-form-item label="是否展示" class="text">
        <el-switch v-model="updateForm.isShowAdvertiseImage"> </el-switch>
      </el-form-item>
      <el-form-item label="跳转的类型" prop="advertiseImageJumpType" class="text">
        <el-select v-model="updateForm.advertiseImageJumpType" placeholder="请选择">
          <el-option		label="h5"	value="h5"></el-option>
          <el-option		label="app"	value="app"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转的h5路径" prop="advertiseImageH5" class="text">
        <el-input  v-model="updateForm.advertiseImageH5"	placeholder="请输入跳转h5"  clearable></el-input>
      </el-form-item>
      <el-form-item label="跳转的appId" prop="advertiseImageAppId" class="text">
        <el-input  v-model="updateForm.advertiseImageAppId"  placeholder="请输入跳转的appId"  clearable></el-input>
      </el-form-item>
      <el-form-item label="图片设置">
        <image_choose
            :image_url.sync="updateForm.advertiseImageUrl"
            prefix_path="redpacket/advertise"
            is_show_cdn>
        </image_choose>
      </el-form-item>
    </el-form>

    <el-button icon="el-icon-picture" round @click="advertiseImage(1)" class="custom-color" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button icon="el-icon-picture" round @click="advertiseImage(2)" class="custom-color" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>

import image_choose from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue";

export default {
  components: {
    image_choose

  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        batchClassList: [],
        isShowAdvertiseImage: false,
        advertiseImageJumpType: "",
        advertiseImageH5: "",
        advertiseImageAppId: "",
        advertiseImageUrl: "",
      },
      loading: false,

    }
  },
  methods: {
    // 查询上一次数据接口
    async getAdvertiseImage() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getAdvertiseImage",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.isShowAdvertiseImage = res.message.isShowAdvertiseImage;
        this.updateForm.advertiseImageJumpType = res.message.advertiseImageJumpType;
        this.updateForm.advertiseImageH5 = res.message.advertiseImageH5;
        this.updateForm.advertiseImageAppId = res.message.advertiseImageAppId;
        this.updateForm.advertiseImageUrl = res.message.advertiseImageUrl;
      }
    },
    // 更新数据接口
    async advertiseImage(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      if (is === 1) {
        this.updateForm.batchClassList = this.batchClassList;
        this.updateForm.tbThirdPartySmallRoutineType = null;
      } else {
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        this.updateForm.batchClassList = null;
      }

      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/advertiseImage",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「广告图片」配置完成");
      } else {
        this.$message.error("「广告图片」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getAdvertiseImage()
  }
}
</script>

<style scoped lang="less">
.text{
  font-size: 30px;
}
.custom-color {
  color: #fff;
  background-color: #4e72b8;
}
</style>