<template>
  <div>
    <jump_object_parse
        :jump_object_json.sync="updateForm.homeSwiperJson"
        prefix_path="redpacket/small_mall_1/swiper"
        content_title="顶部轮播图">
    </jump_object_parse>
    <el-button icon="el-icon-s-home" round @click="updateHomeSwiperJson(1)" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button icon="el-icon-s-home" round @click="updateHomeSwiperJson(2)" class="buttonUseDeploy" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>
import jump_object_parse
  from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/jump_object_parse.vue";

export default {
  components: {
    jump_object_parse
  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [],
        batchClassList: [],
        homeSwiperJson: "",
      },
      loading: false,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getHomeSwiperJson() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getHomeSwiperJson",
      );
      // console.log("查询上一次数据接口：", res.message)
      if (200 === res.code && res.message != null) {
        this.updateForm.homeSwiperJson = res.message;
      }
    },
    // 更新数据接口
    async updateHomeSwiperJson(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      if (is === 1) {
        this.updateForm.batchClassList = this.batchClassList;
        this.updateForm.tbThirdPartySmallRoutineType = null;
      } else {
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        this.updateForm.batchClassList = null;
      }

      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateHomeSwiperJson",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「首页」配置成功");
      } else {
        this.$message.error("「首页」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
  },
  computed:{

  },
  mounted() {
    this.getHomeSwiperJson()
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 20px;
  color: #fff;
  background-color: #4e72b8;
}
</style>