<template>
<div>
    <el-table   :data="keywordList">
        <el-table-column  
            v-for="(item,index) in columns"  
            :width="item.width==undefined?160:item.width"
            :key="index"
            :label="item.label"
            :prop="item.prop"
            :row-class-name="tableRowClassName"
            >
        </el-table-column>
        <el-table-column  width="100"   label="当前状态">
            <template   slot-scope="scope">
                {{ status_map[scope.row.status].label }}
            </template>
        </el-table-column>
        <el-table-column  width="130"   label="修改与删除">
            <template   slot-scope="scope">
                <el-popover
                    placement="bottom"
                    title="更改关键词"
                    width="500"
                    trigger="click"
                    >
                    <el-input   
                        v-model="edit_word"    
                        placeholder="请输入关键词"
                    ></el-input>
                    <el-button  @click="edit_keyword(scope.row)">
                        更改
                    </el-button>
                    <el-button
                        type="success"
                        icon="el-icon-document-copy"
                        size="mini" 
                        slot="reference" 
                    >
                    </el-button>
                </el-popover>
                
                <el-button
                    type="danger"
                    icon="el-icon-delete"
                    size="mini"
                    style="margin-left: 10px;"  
                    @click="show_delete_confirm(scope.row)"
                ></el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-button  type="primary"  @click="throttle_apply">提交关键词</el-button>
    <span   class="input">
        <el-input v-model="apply_word"   placeholder="请输入申请的关键词"></el-input>
    </span>
</div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex'
import * as utility from '@/services/wCommonFunction.js'

export default{
    components:{
        
    },
    props: {
        appId:{
			type:String,
		},
		keywordList:{
			type:Array,
		}
    }, 
    data() {
        return {
			columns:[
                {label:'审核完成的关键词',prop:'keyWord',},
                {label:'审核中的关键词',prop:'auditKeyWord',},
                {label:'审核原因',prop:'auditReason',width:500},
                {label:'审核时间',prop:'auditTime',},
                {label:'修改时间',prop:'gmtCreate',},
            ],
            status_map:{
                'INITIAL':{label:'初始',},
                'AUDIT':{label:'审核中'},
                'AUDIT_HISTORY_EFFECT':{label:'审核中，历史生效中'},
                'REJECT_HISTORY_EFFECT':{label:'审核驳回，历史生效中'},
                'CANCEL':{label:'已取消'},
                'ONLINE':{label:'已上架'},
                'REJECT':{label:'审核驳回'},
                'OFFLINE':{label:'已下架'},
                'EXPIRE':{label:'已失效'},
                'MODIFY_AUDIT':{label:'修改审核中'},
            },
            apply_word:null,
            edit_word:null,
            throttle_apply:utility.throttle(this.apply,2000),
            //是否等待刷新
            is_waiting_for_refresh:false,
        }
    },
    methods:{
        tableRowClassName({row, rowIndex}) {
            if (row.status == 'REJECT') {
                return 'warning-row';
            } else if (row.status === 'ONLINE') {
                return 'success-row';
            }
            return 'warning-row';
        },
        //申请关键词 
        async    apply(){
            if(this.apply_word == null||this.apply_word == ""){
                this.$message.error("请先填写关键词");
                return ;
            }
            let url = this.global_url + 'tb-third-party-small-routine-keyword/apply';
            let send_data = {
                appId:this.appId,
                keyWords:[this.apply_word]
            }
            const res = await axios({
                method:"POST",
                url:url,
                data:send_data,
            })
            const data = utility.handleResponse(res);
            if(data){
                this.$message.success("提交成功");
                setTimeout(
                    ()=>{this.$emit("refresh_keyword",this.appId);},
                    1000
                )
            }
            
        },
        //确认删除关键词
        async show_delete_confirm(row){
            console.log(row);
            let alertString ='此操作将删除该键词'+row.keyWord+'/'+row.auditKeyWord+', 是否继续?'
            this.$confirm(alertString, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(async()=>{
                await this.delete_keyword(row);
            })
            .catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        //删除关键词
        async delete_keyword(row){
            let url = this.global_url + "tb-third-party-small-routine-keyword/delete"
            let send_data = {
                appId:this.appId,
                keyWords:[row.keyWord]
            }
            const res = await axios({
                method:"DELETE",
                url:url,
                data:send_data,
            })
            const data = utility.handleResponse(res);
            if(data){
                this.$message.success("删除成功");
                setTimeout(
                    ()=>{this.$emit("refresh_keyword",this.appId);},
                    1000
                )
            }
        },
        //更换关键词
        async edit_keyword(row){
            if(this.edit_word == null||this.edit_word == ""){
                this.$message.error("请先填写关键词");
                return ;
            }
            let url = this.global_url + "tb-third-party-small-routine-keyword/apply"
            let send_data = {
                appId:this.appId,
                keyWords:[this.edit_word],
                configId:row.configId,
            }
            const res = await axios({
                method:"POST",
                url:url,
                data:send_data
            })
            const data = utility.handleResponse(res);
            if(data){
                this.$message.success("更改提交成功");
                setTimeout(
                    ()=>{this.$emit("refresh_keyword",this.appId);},
                    1000
                )
            }
        }
    },
    computed:{
		...mapState({
            global_url:state=>state.global_base_url.main
        })
	},
	watch:{
		
	},
    created(){
        
    },
}
</script>

<style  lang="less" scoped>
	.el-input {
        width: 350px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .el-popover{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
</style>