// 接口请求地址
const path = 'thirdParty/queryVoByApplicationsId';
import axios from 'axios'

/**
 * 小程序类目树查询
 * @param {*} params
 */
export async function queryVoByApplicationsId(data = {}) {
  const { data: res } = await axios.get(
    path,
    {
      params: { ...data },
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取小程序类目树查询失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}