<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
    >
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="物料id" prop="materialId">
        <el-input
          v-model="updateForm.materialId"
          placeholder="请输入物料id"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="是否开启" prop="status">
        <el-switch v-model="updateForm.status"></el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "materialManagementUpdate",
  props: ["updateMes", "refaushList", "getMaterialList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        name: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入客户联系电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为 11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.updateForm = { ...this.updateMes };
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/taoBaoCommodityMaterial/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
      this.getMaterialList();
    },
    // 刷新编辑对象
    refaushForm(e) {
      this.updateForm = { ...e };
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
