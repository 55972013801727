// 配置搜索直达
const path = 'alipayOpenSearchBox/';
import axios from 'axios'

/**
 * 获取列表
 */
export async function list(data={}) {
  const { data: res } = await axios.post(
    path + 'batchQuery',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 查询搜索直达配置详情
 */
 export async function query(data={}) {
  const { data: res } = await axios.post(
    path + 'query',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 申请创建搜索直达活动配置 
 */
 export async function apply(data={}) {
  const { data: res } = await axios.post(
    path + 'apply',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 下架 
 */
 export async function offline(data={}) {
  const { data: res } = await axios.post(
    path + 'offline',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 上架 
 */
 export async function online(data={}) {
  const { data: res } = await axios.post(
    path + 'online',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: message
    };
  }
  return {
    success: true,
    data:message,
  };
}
