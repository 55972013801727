<template>
  <div class="div_main">
		<el-divider content-position="left">后端密钥的配置</el-divider>
			<el-form  label-width="auto"	class="form2">
				<el-form-item :label="item.label"		v-for="(item,index) in configMesColu" :key="index">
					<div	class="form2_row">
						<el-input   clearable  v-model="configMes[item.prop]"  :placeholder="item.label"  ></el-input>
						<el-button type="success" @click="copy(configMes[item.prop])">复制内容</el-button>
					</div>
				</el-form-item>
				<div class="buttomButton">
					<el-button type="success" @click="tosaveConfig">确 定 修改密钥等配置</el-button>
				</div>
			</el-form>
		
		<el-divider content-position="left"	class="divider">前端页面的配置</el-divider>
			<el-form :model="formMes"  label-width="auto"	class="form2">
				<!-- 文字 -->
				<el-form-item label="标题" prop="text">
					<el-input  maxlength="20"  show-word-limit  clearable
						v-model="formMes.title"  placeholder="最上面的标题"
					></el-input>
				</el-form-item>
				<!-- 标题 -->
				<el-form-item label="副标题" prop="title">
					<el-input  maxlength="200"  show-word-limit  clearable  v-model="formMes.describeText"  placeholder="标题下面的文字"></el-input>
				</el-form-item>
				<!-- h5路径 -->
				<el-form-item label="h5路径" prop="h5Path">
					<el-input  maxlength="300"  show-word-limit  clearable  v-model="formMes.h5Path"  placeholder="按钮按下去的路径"></el-input>
				</el-form-item>
				<!-- 这个是下面的表格：之所以用is_show_detail来控制，主要是想让他在还没读取列表（响应中的数据）前，先不要加载 -->
				<flexible_form		v-if="is_show_detail"		:input_list.sync="formMes.detail"></flexible_form>
			</el-form>
			<!-- 底部按钮 -->
			<div class="buttomButton">
				<el-button type="primary" @click="tosave">确 定 修改前端配置</el-button>
			</div>	
			
		<el-divider content-position="left"	class="divider">菜单列表的配置</el-divider>
		<menu_table></menu_table>
		<el-divider content-position="left"	class="divider">信用卡的模板配置</el-divider>
		<card_table ></card_table>
		<el-divider content-position="left"	class="divider">信用卡的弹窗配置</el-divider>
		<card_pop_table ></card_pop_table>
  </div>
</template>
<script>
import axios from "axios";
import flexible_form from './component/flexible_form.vue'
import menu_table from './component/default_menu_table.vue'
import card_pop_table from './component/default_card_pop_table.vue'
import card_table from './component/default_card_table_new.vue'

export default {
  props: ["appId", "visible"],
  name: "smallroutineConfiguration",
	components:{
		flexible_form,card_table,menu_table,card_pop_table
	},
  data() {
    return {
      headers: { Authorization: window.sessionStorage.getItem("token") },
      formMes: {},
			configMes:{},
			configMesColu:[
				{label:'我们自己的测试私钥',prop:'myTestPrivateKey',},
				{label:'我们自己的生产私钥',prop:'myFormalPrivateKey',},
				{label:'陆卡给的测试公钥',prop:'lucardTestPublicKey',},
				{label:'陆卡给的生产公钥',prop:'lucardFormalPublicKey',},
				{label:'AES密钥 测试',prop:'testAESSecretKey',},
				{label:'AES密钥 生产',prop:'formalAESSecretKey',},
				{label:'posterCode',prop:'posterCode',},
				{label:'渠道号',prop:'channelNo',},
				{label:'给陆卡的测试公钥',prop:'myTestPublicKey',},
				{label:'给陆卡的生产公钥',prop:'myFormalPublicKey',}
			],
			is_show_detail:false,
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
			this.getConfigMes();
    },
		// 获取初始数据
    async getMes() {
      const res = await axios({
				method:'get',
				url:this.$httpUrl+'tb-third-party-wang-lucard-view/getDefault'
			})
			if(res.data.code != 200){	return this.$message.error(res.data.message);	}
			res.data.message.detail = JSON.parse(decodeURI(res.data.message.detail)) ;
			// console.log(res.data.message);
      this.$set(this, "formMes", res.data.message);
			this.is_show_detail = true;
    },
		// 保存
    async tosave() {
      let formMes ={};
			Object.assign(formMes,this.formMes)
			console.log(formMes)
			// 把要发送的内容进行base64编码
			formMes.detail = encodeURI(JSON.stringify(formMes.detail)) ;
      const res = await axios({
				method:'POST',
				url:this.$httpUrl+'tb-third-party-wang-lucard-view/addOrUpdateDefault',
				data:formMes,
			})
      if(res.data.code != 200){
        return this.$message.error(res.data.message);
      }
      this.$message.success(res.data.message);
    },
		
		// 获取初始密钥等的配置数据
		async getConfigMes() {
			const res = await axios({
				method:'get',
				url:this.$httpUrl+'tb-third-party-wang-lucard-configuration/getConfig'
			})
			if(res.data.code != 200){	return this.$message.error(res.data.message);	}
			this.$set(this, "configMes", res.data.message);
		},
		// 保存
		async tosaveConfig() {
			const res = await axios({
				method:'POST',
				url:this.$httpUrl+'tb-third-party-wang-lucard-configuration/addOrUpdateConfig',
				data:this.configMes,
			})
			if(res.data.code != 200){	return this.$message.error(res.data.message);	}
			this.$message.success(res.data.message);
		},
		copy(text){
				// text是复制文本
				// 创建input元素
				const el = document.createElement('input')
				// 给input元素赋值需要复制的文本
				el.setAttribute('value', text)
				// 将input元素插入页面
				document.body.appendChild(el)
				// 选中input元素的文本
				el.select()
				// 复制内容到剪贴板
				document.execCommand('copy')
				// 删除input元素
				document.body.removeChild(el)
				// alert('复制成功')
				this.$message({ message: '复制成功',type: 'success', center: true});
		},
		
    getStrMes(e) {
      if (this.$publicJs.isNull(e) || e == undefined || e == "undefined") {
        return "";
      }
      return e;
    },
  },
};
</script>

<style lang="less" scoped>
.div_main{
	background-color: white;
	border-radius: 10px;
	padding: 20px;
	// display: flex;	flex-direction: column;	
	.buttomButton{
		margin-top: 20px;
	}
	.form2_row{
		display: flex;	flex-direction: row;
	}
	.divider{	//分割器
		margin-top: 70px;		margin-bottom: 20px;
	}
}
</style>
