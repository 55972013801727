// 接口请求地址
const path = 'tikTokLabel/';
import axios from 'axios'

/**
 * 列表查询
 * @param {*} params
 */
export async function list(data={}) {
  const { data: res } = await axios.get(
    path+'list',
    {
      params: data,
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "列表查询失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改状态
 * @param {*} params
 */
 export async function updateState(data={}) {
  const { data: res } = await axios.put(
    path+'updateState',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 删除
 */
 export async function del(id) {
  const { data: res } = await axios.delete(
    path+'del?id='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据id获取编辑内容
 */
 export async function getUpdateMes(id) {
  const { data: res } = await axios.get(
    path+'getUpdateMes?id='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 编辑
 */
 export async function updateMes(data={}) {
  const { data: res } = await axios.put(
    path+'updateMes',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


/**
 * 修改广告状态
 * @param {*} params
 */
 export async function updateAdvertisementState(data={}) {
  const { data: res } = await axios.put(
    path+'updateAdvertisementState',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
