<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="head_input">
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="setShowType1()"
                icon="el-icon-arrow-left"
              >
                返回
              </el-button>
            </div>
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.name"
                clearable
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="图片" width="100">
          <template slot-scope="scope">
            <img
              :src="scope.row.resourceUrl"
              width="60"
              height="60"
              class="head_pic"
            />
          </template>
        </el-table-column>
        <el-table-column
          label="活动名称"
          prop="activityName"
          width="200"
        ></el-table-column>
        <el-table-column label="活动状态" prop="activityStatus" width="200">
          <template slot-scope="scope">
            <div v-if="scope.row.activityStatus == 1">创建中</div>
            <div v-if="scope.row.activityStatus == 2">已发布</div>
            <div v-if="scope.row.activityStatus == 3">已终止</div>
          </template>
        </el-table-column>
        <el-table-column
          label="发行券数量"
          prop="quantity"
          width="200"
        ></el-table-column>
        <el-table-column
          label="已核销数量"
          prop="quantityUse"
          width="200"
        ></el-table-column>
        <el-table-column
          label="面额"
          prop="amount"
          width="200"
        ></el-table-column>
        <el-table-column
          label="门槛金额"
          prop="floorAmount"
          width="200"
        ></el-table-column>
        <el-table-column label="券类型" width="150">
          <template slot-scope="scope">
            <div v-if="scope.row.voucherType == 'FIX_VOUCHER'">满减券</div>
            <div v-if="scope.row.voucherType == 'DISCOUNT_VOUCHER'">折扣券</div>
            <div v-if="scope.row.voucherType == 'SPECIAL_VOUCHER'">特价券</div>
          </template>
        </el-table-column>
        <el-table-column
          label="券发放时间(开始)"
          prop="publishStartTime"
          width="200"
        ></el-table-column>
        <el-table-column
          label="券发放时间(结束)"
          prop="publishEndTime"
          width="200"
        ></el-table-column>
        <el-table-column
          label="券可用时间(开始)"
          prop="validBeginTime"
          width="200"
        ></el-table-column>
        <el-table-column
          label="券可用时间(结束)"
          prop="validEndTime"
          width="200"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="gmtCreate"
          width="200"
        ></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column width="150" label="操作" fixed="right">
          <template slot-scope="scope">
            <!-- 同步商家券券码 -->
            <el-tooltip
              effect="dark"
              content="同步商家券券码"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-postcard"
                size="mini"
                @click="toAddCode(scope.row.activityId)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%">
      <thisAdd
        v-if="toAddVisible"
        :refaushList="getList"
        :appId="appId"
        :showSingleMes="showSingleMes"
        :marketingPictures="marketingPictures"
        :flushAddVisible="flushAddVisible"
      />
    </el-dialog>
    <!-- 同步商家券 -->
    <el-dialog
      title="同步商家券"
      :visible.sync="addCodeMes.visible"
      width="50%"
    >
      <addCode :refaushList="getList" :activityId="addCodeMes.activeId" />
    </el-dialog>
  </div>
</template>

<script>
import thisAdd from "./thisAdd.vue";
import addCode from "./code/index.vue";
export default {
  components: {
    thisAdd,
    addCode,
  },
  props: ["setShowType1", "appId", "showSingleMes"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      addCodeMes: {
        visible: false,
        activeId: "",
      },
      // 营销图片列表
      marketingPictures: [],
      toAddVisible: true,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search();
      this.getAllMarketingPicturesIdAndNameList();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.appId = this.appId;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post(
        "/aliMerchantCoupon/list",
        this.queryInfo
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.delete(
        "aliMerchantCoupon/del?id=" + id
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const { data: res } = await this.$http.put(
        `/aliMerchantCoupon/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 获取营销图片列表
    async getAllMarketingPicturesIdAndNameList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.get(
        "/aliMarketingPictures/getAllIdAndNameList?appId=" + this.appId
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("获取营销图片列表失败:" + res.message);
      }
      this.marketingPictures = res.message;
    },
    flushAddVisible() {
      this.toAddVisible = false;
      let that = this;
      setTimeout(function () {
        that.toAddVisible = true;
      }, 100);
    },
    toAddCode(e) {
      this.$set(this.addCodeMes, "visible", true);
      this.$set(this.addCodeMes, "activeId", e);
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
}
</style>
