<template>
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="configuration"
        ref="addFormRed"
        label-width="auto"
      >
        <el-divider content-position="left">首页配置</el-divider>
        <!-- 跳转h5 -->
        <el-form-item label="跳转h5" prop="jumpToH5">
          <el-input
            v-model="configuration.jumpToH5"
            placeholder="请输入首页进入时跳转的h5路径"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="开启跳转" prop="jumpToH5Status">
          <el-switch v-model="configuration.jumpToH5Status"> </el-switch>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "applet2Configuration",
  data() {
    return {
      configuration: {
        // 跳转h5
        jumpToH5: "",
        jumpToH5Status: false,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await this.$http.get(
        "applet2Configuration/getConfiguration"
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.configuration = message;
    },
    add() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) return;
        let configuration = this.configuration;
        //发起请求
        const { data: res } = await this.$http.post(
          "applet2Configuration/updateConfiguration",
          configuration
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("修改成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
