<!-- 
    这个是用来做 显示数据的表格的
-->
<template>
    <el-cascader    v-if="is_initial"   :value="mallGoodId"    :props="cascader_props" @change="change"></el-cascader>
</template>
        
<script>
import axios from 'axios';
import { mapState } from 'vuex';
import * as utility from '@/services/wCommonFunction.js'
export default{
    components:{
        
    },
    props: {
        mallGoodId:{
            type:String
        }
    },
    data() {
        return {
            //商品的类
            goods_groups:[],
            //判断从后端获取数据是否成功，这里从initialize_data是否成功执行判断,用来控制本组件什么时候加载
            is_initial:false,
            //挑选表所需要的内容
            cascader_props:{
                lazy:true,
                lazyLoad:this.lazyLoad,
            }
        }
    },
    methods:{
        //初始化数据
        async    initialize_data(){
            const res = await axios({
                url:this.global_url+"tb-third-party-wang-mall-goods-group/listGoodsGroup"
            });
            const data = utility.handleResponse(res);
            this.goods_groups = data ;
            this.is_initial = true ;
        },
        //获取整个挑选表的树状结构
        async    lazyLoad(node,resolve){
            const {level} = node ;
            if(level == 0){
                let nodes = this.goods_groups.map(e=>{
                    e.label = e.goodsGroup ;
                    e.value = e.id  ;
                    e.leaf  = false ; //非叶子节点
                    return e ;
                })
                resolve(nodes);
            }else if(level==1){
                let nodes = await this.get_goods_by_group(node.data.id) ;
                nodes = nodes.map(e=>{
                    e.leaf = true    ;
                    e.value = e.id   ;
                    e.label = e.title;
                    return e;
                })
                resolve(nodes);
            }
        },
        //通过群id，获取商品
        async get_goods_by_group(group_id){
            const res = await axios.get(this.global_url+
                "tb-third-party-wang-mall-goods/getByGoodsGroup?goodsGroupingid="+group_id);
            const data = utility.handleResponse(res);
            return data ;
        },
        //用户确认了数据之后，提交给父组件用户选择的数据
        change(e){
            //这里之所以是第二个元素是因为他是树状的，所以第一个是想要结果的父节点
            //我们取第二个（也就是子节点，也是叶子节点）
            let mallGoodId =  e[1] ;
            this.$emit("update:mallGoodId",mallGoodId);
        }
    },
    computed:{
        ...mapState({
            global_url:state=>state.global_base_url.main
        })
    },
    created(){
        this.initialize_data();
    },
}
</script>
        
<style  lang="less" scoped>
    
</style>