<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="活动标题" prop="title">
        <el-input
          v-model="form.title"
          placeholder="请输入活动标题"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="文件类型" prop="materialType">
        <el-select v-model="form.materialType" placeholder="请选择文件类型">
          <el-option label="图片" value="IMAGE"> </el-option>
          <el-option label="视频" value="VIDEO" disabled> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="支付宝上传图片" prop="materialId">
        <el-select v-model="form.materialId" placeholder="请选择支付宝上传图片">
          <el-option
            v-for="item in allOpenFileList"
            :key="item.fileId"
            :label="isNull(item.name) ? '未设置名称' : item.name"
            :value="item.fileId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="跳转应用ID" prop="targetAppid">
        <el-input
          v-model="form.targetAppid"
          placeholder="请输入跳转应用ID"
          maxlength="18"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="跳转小程序名称	" prop="targetAppname">
        <el-input
          v-model="form.targetAppname"
          placeholder="请输入跳转小程序名称	"
          maxlength="18"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="活动时间" prop="activityTime">
        <el-date-picker
          v-model="form.activityTime"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label=""> 开始和结束时间之间不得超过90天 </el-form-item>
      <el-form-item label="活动区域"> 福建省 </el-form-item>
      <!-- <el-form-item label="投放区域" prop="regionCode">
        <el-input
          v-model="form.regionCode"
          placeholder="请输入投放区域，多个区域用逗号隔开(,)"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="">
        请输入投放区域(如：363000)，多个区域用逗号隔开(,)
      </el-form-item> -->
      <el-form-item label="活动链接" prop="actionUrl">
        <el-input
          type="textarea"
          v-model="form.actionUrl"
          placeholder="请输入活动链接"
          clearable
          class="div_input_single"
          :rows="3"
          maxlength="500"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { apply } from "@/services/thirdParty/openSearchBoxActivity";
import { getAllListByAppId } from "@/services/thirdParty/openFile";
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "appId", "boxId", "showSingleMes"],
  data() {
    return {
      form: {
        serviceCode: "",
        materialType: "IMAGE",
        actionUrl: "",
        targetAppid: "",
        targetAppname: "",
        // 投放区域
        regionCode: "363000",
        // 活动时间
        activityTime: [],
      },
      formRules: {
        title: [{ required: true, message: "请输入活动标题", trigger: "blur" }],
        targetAppid: [
          { required: true, message: "请输入跳转应用ID", trigger: "blur" },
        ],
        targetAppname: [
          { required: true, message: "跳转小程序名称", trigger: "blur" },
        ],
        regionCode: [
          { required: true, message: "请输入投放区域", trigger: "blur" },
        ],
        actionUrl: [
          { required: true, message: "请输入活动链接", trigger: "blur" },
        ],
        activityTime: [
          { required: true, message: "请输入活动链接", trigger: "change" },
        ],
        materialId: [
          {
            required: true,
            message: "请选择支付宝上传图片",
            trigger: "change",
          },
        ],
        serviceCode: [
          { required: true, message: "请选择服务", trigger: "change" },
        ],
      },
      // 支付宝上传文件列表
      allOpenFileList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      let appId = this.appId;
      this.$set(this.form, "targetAppid", appId);
      this.$set(this.form, "targetAppname", this.showSingleMes.name);
      this.$set(
        this.form,
        "actionUrl",
        "alipays://platformapi/startapp?appId=" + appId + "&&page="
      );
      // 获取已通过的服务列表
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.getAllOpenFileList();
      loading.close();
    },
    async getAllOpenFileList() {
      let res = await getAllListByAppId(this.appId);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "allOpenFileList", res.data);
    },
    appIdChange() {
      this.$set(this.oldMes, "serviceCode", this.form.serviceCode);
      this.getServices();
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apply({
        appId: this.appId,
        boxId: this.boxId,
        ...this.form,
      });
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新页面信息
      this.refaushList();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
