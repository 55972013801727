// 接口请求地址
const path = 'redEnvelopeForNewcomersContentForm/';
import axios from 'axios'

/**
 * 获取表单列表
 */
export async function pageByFormId(data={}) {
  const { data: res } = await axios.get(
    path + 'pageByFormId',
    {
      params: data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取表单列表失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取表单列表
 */
 export async function listByFormId(data={}) {
  const { data: res } = await axios.get(
    path + 'listByFormId',
    {
      params: data
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取表单列表失败:" + message
    };
  }
  return {
    success: true,
    data:message,
  };
}
