<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">新增:</label>
              <el-checkbox-group>
                <el-button type="primary" @click="updateJiFenBao">
                  集分宝
                </el-button>
                <el-button type="primary" @click="updateCustomContent">
                  自定义内容
                </el-button>
              </el-checkbox-group>
            </div>
          </div>
        </el-row>
        <el-row class="elRow" :gutter="25">
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">备注:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.remarks"
                clearable
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">类型:</label>
              <el-select v-model="queryInfo2.type" clearable placeholder="全部">
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="div_input_type_1" label="集分宝" value="1">
                </el-option>
                <el-option key="div_input_type_2" label="自定义" value="2">
                </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">创建时间:</label>
              <el-date-picker
                v-model="queryInfo2.createTime"
                :picker-options="pickerOptions"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </div>

            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.records" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column
          label="备注"
          prop="remarks"
          min-width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="类型"
          prop="type"
          min-width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="gmtCreate"
          min-width="180"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" fixed="right" min-width="150">
          <template slot-scope="scope">
            <!-- 查看 -->
            <el-tooltip
              effect="dark"
              content="详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="seeMessage(scope.row)"
              ></el-button>
            </el-tooltip>

            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="delThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.current"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>

    <!-- 转盘内容编辑 -->
    <el-dialog
      title="集分宝转盘内容编辑"
      :visible.sync="jiFenBaoVisible"
      width="50%"
    >
      <turntableRewardJiFenBao />
    </el-dialog>
    <!-- 转盘内容编辑 -->
    <el-dialog
      title="自定义转盘内容编辑"
      :visible.sync="customContentVisible"
      width="50%"
    >
      <turntableRewardCustomContent />
    </el-dialog>
  </div>
</template>

<script>
import turntableRewardJiFenBao from "./turntableRewardJiFenBao.vue";
import turntableRewardCustomContent from "./turntableRewardCustomContent.vue";
export default {
  components: {
    turntableRewardJiFenBao,
    turntableRewardCustomContent,
  },
  name: "turntableReward",
  data() {
    return {
      jiFenBaoVisible: false,
      customContentVisible: false,
      // 搜索内容
      queryInfo: {
        //查询信息
        current: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容(点击搜索后将queryInfo值赋予该对象)
      queryInfo2: {
        //查询信息
        current: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.get("/turnTableReward/list", {
        params: { ...this.queryInfo },
      });
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes = res.message;
    },
    // 文字内容转盘编辑
    updateCustomContent() {
      this.customContentVisible = true;
    },
    // 集分宝转盘编辑
    updateJiFenBao() {
      this.jiFenBaoVisible = true;
    },

    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.current = newPage;
      this.getList();
    },
    // 删除
    async delThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete("role/del?id=" + id);
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getRoleList(); //刷新表格
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  .elRow {
    margin-top: 10px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 10px;
      }
    }
  }
}
</style>
