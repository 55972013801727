<template>
  <div class="div_main">
    <el-form :model="formMes"  label-width="auto"	v-if="showSingleMes.appletType == 51">
		<!-- 文字 -->
		<el-form-item label="标题" prop="text">
			<el-input  maxlength="10"  show-word-limit  clearable	v-model="formMes.title"  placeholder="最上面的标题"	></el-input>
		</el-form-item>
		  <!-- 标题 -->
		<el-form-item label="副标题" prop="title">
			<el-input  maxlength="100"  show-word-limit  clearable  v-model="formMes.describeText"  placeholder="标题下面的文字"></el-input>
		</el-form-item>
				<!-- h5路径 -->
		<el-form-item label="h5路径" prop="h5Path">
			<el-input  maxlength="300"  show-word-limit  clearable  v-model="formMes.h5Path"  placeholder="按钮按下去的路径"></el-input>
		</el-form-item>
			
		<el-divider content-position="left"	class="divider">最下面的玩法</el-divider>
		<!-- 这个是下面的表格：之所以用is_show_detail来控制，主要是想让他在还没读取列表（响应中的数据）前，先不要加载 -->
		<flexible_form		v-if="is_show_detail"		:input_list.sync="formMes.detail"></flexible_form>
		<!-- 底部按钮 -->
		<div class="buttomButton">
		  <el-button type="primary" @click="tosave">确 定</el-button>
		</div>
    </el-form>
	
	<el-divider content-position="left"	class="divider">菜单列表的配置</el-divider>
	<menu_table		:appId="appId"></menu_table>
		
	<el-divider content-position="left"	class="divider">信用卡</el-divider>
	<card_table		:appId="appId"></card_table>
  </div>
</template>
<script>
import axios from "axios";
import flexible_form from './component/flexible_form.vue'
import card_table from './component/card_table.vue'
import menu_table	from './component/menu_table.vue'

export default {
  props: ["appId", "visible","showSingleMes"],
  name: "smallroutineConfiguration",
	components:{
		flexible_form,card_table,menu_table
	},
  data() {
    return {
		headers: { Authorization: window.sessionStorage.getItem("token") },
		formMes: {},
		is_show_detail:false,
    };
  },
  watch: {
    visible(e) {
		if (e) {
			this.init();
		}
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
		// 获取初始数据
    async getMes() {
		const res = await axios({
				method:'get',
				url:this.$httpUrl+'tb-third-party-wang-lucard-view/getByAppId?appId='+this.appId
			})
		if(res.data.code != 200){
			return this.$message.error(res.data.message);
		}
		res.data.message.detail = JSON.parse(decodeURI(res.data.message.detail)) ;//发送的内容base64解码
		this.$set(this, "formMes", res.data.message);
		this.is_show_detail = true ;
    },
		// 保存
    async tosave() {
		let formMes ={};
		Object.assign(formMes,this.formMes)
			// 把要发送的内容进行base64编码
			formMes.detail = encodeURI(JSON.stringify(formMes.detail)) ;
			formMes.appId = this.appId;	
		const res = await axios({
				method:'POST',
				url:this.$httpUrl+'tb-third-party-wang-lucard-view/addOrUpdate',
				data:formMes,
			})
		if(res.data.code != 200){
			return this.$message.error(res.data.message);
		}
		this.$message.success(res.data.message);
    },
  },
};
</script>

<style lang="less" scoped>
.div_main {
  .div_main_img {
    height: 100%;
    width: 100%;
  }
}
</style>
