<template>
  <div>
    <el-divider content-position="left">
      <span class="Span">排序选择</span>
    </el-divider>
    <div>
      <el-button type="primary" @click="getList('today_uv')">今日UV排序</el-button>
      <el-button type="primary" @click="getList('yesterday_uv')">昨日UV排序</el-button>
      <!-- 切换 register 状态的按钮 -->
      <el-button type="primary" @click="handleRegister">
        {{ queryInfo.icpStatus === '未备案' ? '当前为未备案' : '当前为已备案' }}
      </el-button>
    </div>
    <el-table :data="pageMes.list">
      <el-table-column label="小程序名称" prop="name" fixed width="300"></el-table-column>
      <el-table-column label="appId" prop="appId" width="280"></el-table-column>
      <el-table-column label="今日uv" prop="todayUv" fixed="right" width="200"></el-table-column>
      <el-table-column label="昨日uv" prop="yesterdayUv" fixed="right" width="200"></el-table-column>
    </el-table>
    <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
    <div>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    //第三方类型 Type
    'appletType', 'isThirdParty'
  ],
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        appletType: this.appletType,
        isThirdParty: this.isThirdParty,
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        icpStatus: '未备案',
        orderBy: 'today_uv',
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
        current: -1,
        pages: -1,
      },

    }
  },
  methods: {
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    handleRegister() {
      // 切换icpStatus值并刷新列表
      this.queryInfo.icpStatus = this.queryInfo.icpStatus === '未备案' ? '已备案' : '未备案';
      this.getList();
    },
    async getList(orderBy) {
      if (orderBy != null) {
        this.queryInfo.orderBy = orderBy // 将排序赋值给查询信息
      }
      const {data: res} = await this.$http.post(
          "/thirdPartySmallRoutine/list",
          this.queryInfo
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      // 列表内容
      this.pageMes.list = res.message.records;
      // 总数
      this.pageMes.total = res.message.total;
    },
  },
  watch: {
    // 监听appletType的变化
    appletType(newVal) {
      this.queryInfo.appletType = newVal;
      this.getList();
    },
  },
  created() {
    this.getList();
  }
}
</script>

<style scoped lang="less">
.Span {
  color: #ff8b8b;
  font-size: 20px;
  font-weight: bold;
}
</style>