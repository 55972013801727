<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">第三方应用:</label>
              <el-select
                v-model="queryInfo2.applicationsId"
                clearable
                filterable
                placeholder="全部"
                @change="search"
              >
                <el-option
                  v-for="item in applications"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <!-- <div class="div_input">
              <label class="div_input_text">模板类型:</label>
              <el-select
                v-model="queryInfo2.appletType"
                clearable
                filterable
                placeholder="全部"
                @change="search"
              >
                <el-option
                  v-for="item in smallRoutineMes.nameList"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div> -->
            <!-- <div class="div_input">
              <label class="div_input_text">模板名称:</label>
              <el-input
                placeholder="请输入模板名称"
                v-model="queryInfo2.name"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">模板appId:</label>
              <el-input
                placeholder="请输入模板appId"
                v-model="queryInfo2.appId"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div> -->
            <!-- <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="toAdd()"
              icon="el-icon-plus"
              >新增
            </el-button> -->
          </div>
          <div class="head_input head_input_two">
            <div class="div_input">
              <label class="div_input_text">模板名称:</label>
              <el-input
                placeholder="请输入模板名称"
                v-model="queryInfo2.name"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">模板appId:</label>
              <el-input
                placeholder="请输入模板appId"
                v-model="queryInfo2.appId"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          label="模板名称"
          prop="name"
          fixed
          width="200"
        ></el-table-column>
        <el-table-column
          label="模板appId"
          prop="appId"
          width="160"
        ></el-table-column>
        <el-table-column label="模板类型" prop="appletType" width="160">
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <template v-if="scope.row.appletType == '1'"> 高德小程序 </template>
            <template v-if="scope.row.appletType == '2'"> 互动小程序 </template>
            <template v-if="scope.row.appletType == '3'"> 高德临时1 </template>
            <template v-if="scope.row.appletType == '4'"> 高德临时2 </template>
            <template v-if="scope.row.appletType == '5'"> 高德临时3 </template>
            <template v-if="scope.row.appletType == '6'"> 租机 </template>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" width="200">
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <div class="div_line_2">
              {{ scope.row.remark }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="第三方应用名称"
          prop="applicationsName"
          width="200"
        ></el-table-column>
        <el-table-column
          label="授权token"
          prop="appAuthToken"
          width="200"
        ></el-table-column>
        <el-table-column
          label="创建时间"
          prop="gmtCreate"
          width="200"
        ></el-table-column>
        <el-table-column label="二维码分享" fixed="right" width="150">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="生成线下链接"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-promotion"
                size="mini"
                @click="showOfflineLink(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog title="线下推广" :visible.sync="offlineLink.visible" width="50%">
      <offlineLink
        :id="offlineLink.id"
        :url="offlineLink.url"
        :visible="offlineLink.visible"
        :appId="offlineLink.appId"
        @authorizationCompletion="authorizationCompletion"
      />
    </el-dialog>
  </div>
</template>

<script>
import offlineLink from "../thirdParty/template/offlineLink.vue";
// import offlineLink from "./offlineLink.vue";
import { getNameAndIdListApplications } from "@/services/thirdParty/getNameAndIdListApplications";
import { getAllType } from "@/services/thirdParty/thirdPartySmallRoutineType.js";
export default {
  components: {
    offlineLink,
  },
  props: [],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        name: "",
        appId: "",
        isvCode: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        name: "",
        appId: "",
        isvCode: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
        current: -1,
        pages: -1,
      },
      // 线下授权链接
      offlineLink: {
        visible: false,
        id: "",
        url: "",
        appId: "",
      },
      // 第三方应用列表
      applications: [],
      // 小程序类型
      smallRoutineTypes: [],
    };
  },
  watch: {
    "$route.query.type"() {
      this.initAppletType();
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      // 获取第三方应用列表
      this.getApplications();
      this.initAppletType();
      // 获取小程序类型
      this.getAllSmallRoutineType();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
      this.initAppletType();
    },
    // 设置小程序类型
    initAppletType() {
      let appletType = this.$route.query.type;
      this.$set(this.queryInfo, "appletType", appletType);
      this.$set(this.queryInfo2, "appletType", appletType);
      this.getList();
    },
    // 获取所有小程序分类列表
    async getAllSmallRoutineType() {
      let res = await getAllType();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.smallRoutineTypes = res.data;
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post(
        "/thirdPartyTemplate/list",
        this.queryInfo
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      // 列表内容
      this.pageMes.list = res.message.records;
      // 总数
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 编辑
    showUpdate(id) {
      this.$set(this.updateMes, "id", id);
      this.$set(this.updateMes, "visible", true);
    },
    // 生成线下链接
    showOfflineLink(e) {
      console.log(e);
      let offlineLink = e.offlineLink;
      if (this.isNull(offlineLink)) {
        return this.$message.warning("该模板还未设置线下授权链接");
      }
      this.offlineLink.visible = true;
      this.offlineLink.id = e.id;
      this.offlineLink.url = offlineLink;
      this.$set(this.offlineLink, "appId", e.appId);
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.delete(
        "thirdPartyTemplate/del?id=" + id
      );
      loading.close();
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.put(
        `/thirdPartyTemplate/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      loading.close();
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 打开详情
    showAppletMessage(e) {
      this.showMessage(e);
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    // 获取第三方应用列表
    async getApplications() {
      let res = await getNameAndIdListApplications();
      if (!res.success) {
        return;
      }
      this.applications = res.data;
    },
    // 用户扫码授权完成
    authorizationCompletion(e) {
      this.$set(this.offlineLink, "visible", false);
      this.$emit("showExamine", e);
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input_two {
    margin-top: 10px;
  }
}
</style>
