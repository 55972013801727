// 接口请求地址
const path = 'thirdParty2/';
import axios from 'axios'

/**
 * 查看最近一次驳回原因
 */
export async function reasonForRejection(appId) {
  const { data: res } = await axios.get(
    path+`reasonForRejection?appId=`+appId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}