<template>
  <!-- 菜单列表 -->
  <div class="div_home">
    <el-card v-show="showType == 1">
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="showList()"
              icon="el-icon-arrow-left"
            >
              返回
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 按钮模块 -->
      <div class="div_button div_modle">
        <div class="div_button_modle">
          <div class="div_button_modle_title">商家券</div>

          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-chat-dot-square"
            @click="showType = 2"
          >
            营销图片管理
          </el-button>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-s-check"
            @click="showType = 3"
          >
            商家券管理
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">服务提报</div>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="showType = 5"
          >
            服务提报
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">搜索关键词</div>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="showType = 4"
          >
            搜索关键词
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">支付宝文件</div>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="showType = 8"
          >
            支付宝文件
          </el-button>
        </div>
        <div class="div_button_modle">
          <div class="div_button_modle_title">搜索直达</div>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="openDocuments"
          >
            搜索直达准入行业
          </el-button>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="showType = 6"
          >
            认证品牌
          </el-button>
          <el-button
            class="buttonSingle"
            type="primary"
            icon="el-icon-search"
            @click="showType = 7"
          >
            配置搜索直达
          </el-button>
        </div>
      </div>
    </el-card>
    <!-- 营销图片 -->
    <marketingPictures
      v-if="showType == 2"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
    />
    <!-- 商家券 -->
    <merchantCoupon
      v-if="showType == 3"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
      :showSingleMes="showSingleMes"
    />
    <!-- 搜索关键词 -->
    <openSearchBaseOrder
      v-if="showType == 4"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
    />
    <!-- 服务提报 -->
    <openAppService
      v-if="showType == 5"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
    />
    <!-- 认证品牌 -->
    <openMiniNiniAppBrand
      v-if="showType == 6"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
    />
    <!-- 搜索直达 -->
    <openSearchBox
      v-if="showType == 7"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
      :showSingleMes="this.showSingleMes"
    />
    <!-- 支付宝文件 -->
    <openFile
      v-if="showType == 8"
      :setShowType1="setShowType1"
      :appId="showSingleMes.appId"
    />
  </div>
</template>

<script>
import marketingPictures from "./marketingPictures/index.vue";
import merchantCoupon from "./merchantCoupon/index.vue";
import openSearchBaseOrder from "./openSearchBaseOrder";
import openAppService from "./openAppService";
import openMiniNiniAppBrand from "./openMiniNiniAppBrand";
import openFile from "./openFile";
import openSearchBox from "./openSearchBox";
export default {
  name: "serviceProvider",
  props: ["showList", "showSingleMes"],
  components: {
    marketingPictures,
    merchantCoupon,
    openSearchBaseOrder,
    openAppService,
    openMiniNiniAppBrand,
    openFile,
    openSearchBox,
  },
  data() {
    return {
      showType: 1,
    };
  },
  watch: {
    showSingleMes(e) {
      this.init(e);
    },
  },
  created() {
    console.log(this.showSingleMes);
  },
  methods: {
    // 初始化
    setShowType1() {
      this.showType = 1;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
    openDocuments() {
      window.open("https://opendocs.alipay.com/b/03al6e");
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  position: relative;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .div_modle {
    .div_button_modle {
      padding-top: 20px;
      .div_button_modle_title {
        padding-right: 20px;
        font-weight: 700;
        font-size: 20px;
      }
    }
  }
  .div_title {
    width: 100%;
    // background-color: aquamarine;
    // text-align: center;
    font-size: 30px;
    color: #626263;
    padding-bottom: 10px;
  }
  // 点击的按钮
  .div_button {
    .buttonSingle {
      margin-top: 10px;
    }
  }
}
</style>
