
<!-- 
    这个是用来做 显示数据的表格的
-->
<template>
<div    class="picker_container">
    <div class="row-container">
        <div    class="row">
            <div    class="block" :class="bigType.current_index==index?'block_clicked':''"
                    v-for="(item,index) in bigType.options" :key="index"
                    @click="choose_bigType(index)"
            >
                <div    class="name">{{ item.name }}</div>
                <div    class="editAndDel"  :class="bigType.current_index==index?'':'no_show'">
                    <el-button  icon="el-icon-edit" @click="openDialog('edit',true)"></el-button>
                    <el-button  icon="el-icon-delete"   @click="deleteMessage(true)"></el-button>
                </div>
            </div>
            <div    class="add" @click="openDialog('add',true)">
                <i class="el-icon-plus"></i>新增
            </div>
        </div>
    </div>
    <div class="row-container">
      <div  class="row "  >
        <div    class="block" :class="smallType.current_index==index?'block_clicked':''"
                v-for="(item,index) in smallType.options" :key="index"
                @click="choose_smallType(index)"
        >
          <div    class="name">{{ item.name }}</div>
          <div    class="editAndDel"  :class="smallType.current_index==index?'':'no_show'">
            <el-button  icon="el-icon-edit" @click="openDialog('edit',false)"></el-button>
            <el-button  icon="el-icon-delete"  @click="deleteMessage(false)"></el-button>
          </div>
        </div>
        <div    class="add" @click="openDialog('add',false)"    key="ffffffff">
          <i class="el-icon-plus"></i>新增
        </div>
      </div>
    </div>
    <!-- 新的小类选择按钮 -->
  <div class="small-type-selection">
    <el-button @click="toggleSmallTypeSelection" type="primary">
      {{ showSmallTypeSelection ? '隐藏' : '显示' }}小类类别选择
    </el-button>
    <div v-if="showSmallTypeSelection" class="small-type-grid">
      <el-checkbox v-model="selectAllSmallTypes" @change="handleSelectAllSmallTypes">全选</el-checkbox>
      <div class="grid-container">
        <el-checkbox
            v-for="item in smallType.options"
            :key="item.key"
            v-model="item.selected"
            @change="handleSmallTypeChange(item)"
        >
          {{ item.name }}
        </el-checkbox>
      </div>
    </div>
  </div>
    <!-- 下面是弹窗 -->
    <el-dialog  :title="dialog_title"    :visible.sync="dialog.is_show"  width="800px" append-to-body>
        <el-form     label-width="180px">
            <el-form-item label="键值（需英文）">
                <el-input v-model="tempData.key"    :disabled="dialog.operation=='edit'"></el-input>
            </el-form-item>
            <el-form-item label="名字（必填）">
                <el-input v-model="tempData.name"></el-input>
            </el-form-item>
            <el-form-item label="页面路径"  v-if="dialog.isBigType">
                <el-input v-model="tempData.pagePath"></el-input>
            </el-form-item>
        </el-form>
        <el-button  @click="submit">确定</el-button>
        <el-button  @click="()=>{dialog.is_show = false;}">取消</el-button>
    </el-dialog>
</div>
</template>
    
<script>
import * as utility from '@/services/wCommonFunction.js'
import axios from 'axios';
import { mapState } from 'vuex';
export default{
    components:{
        
    },
    props: {
        
    },
    data() {
        return {
            bigType:{
                options:[],
                current:null,
                current_index:null,
            },
            smallType:{
                options:[],
                current:null,
                current_index:null,
            },
            tempData:{
                key:null,
                name:null
            },
            //下面是控制弹出的窗口
            dialog:{
                is_show:false,  //是否显示，默认为false
                title:null,     //窗口标题
                operation:"add",//窗口类型，可选项为： add  添加 ；   edit  编辑
                isBigType:true, //是否是大类，默认是
            },
          selectAllSmallTypes: false,
          showSmallTypeSelection: false,
        }
    },
    methods:{
        //初始化数据
        async initialize_data(){
            //1.加载大类
            this.load_bigType();
        },
        //加载大类,并且把第一个大类作为当前选择，可作为刷新
        async    load_bigType(){
            const res = axios({
                url:this.global_url+"tb-third-party-wang-redpacket-menu-page/listBigType"
            })
            const data = utility.handleResponse(await    res);
            this.bigType.options.splice(0,9999,...data);
            if(data.length != 0 ){
                this.choose_bigType(0);
            }
        },
        //加载小类,并且如果有输入index，可选中改下标的东西
        //可作为刷新
        //参数index是选择某个小类并且选中该下标，也就是smallType.current_index
        async   load_smallType(index = 0){
            this.smallType = {
                options:[],
                current:null,
                current_index:null,
            };
            const res = await axios({
                url:this.global_url + 'tb-third-party-wang-redpacket-menu-page/listSmallType?bigTypeKey='
                        + this.bigType.current.key,
            });
            const data =    utility.handleResponse(res);
            // this.smallType.options.splice(0,0,...data);
            this.smallType.options = data.map(item => ({
              ...item,
              selected: false, // 添加选中状态
            }));
            if(data.length != 0){
                this.choose_smallType(index);
                this.$emit("smallTypeOption",this.smallType.options);
            }else{
                this.$emit("smallType",null);
            }
        },
        //选择某个大类,index为下标
        choose_bigType(index){
            //更改当前用户选择的类型
            this.bigType.current_index=index;
            this.bigType.current = this.bigType.options[index];
            this.$emit("pagePath",this.bigType.current.pagePath);
            this.$emit("bigType",this.bigType.current.key);
            //当前大类底下的小类
            this.load_smallType();
        },
        //选择某个小类,index为下标
        choose_smallType(index){
            this.smallType.current_index = index;
            //更改当前用户选择的类型
            this.smallType.current = this.smallType.options[index]; 
            //将选择的类型提交上去
            this.$emit("smallType",this.smallType.current.key); 
        },
        //第一参数填写的是选择的操作：String类型
        //  add  为添加,        edit  为编辑
        //第二参数填写的是是否为大类：Boolean类型
        // 大类填写 true ，小类填写 false
        openDialog(operation,isBigType){
            this.dialog.is_show = true ;
            this.dialog.operation = operation   ;
            this.dialog.isBigType = isBigType   ;
            if(operation =='add'){
                this.tempData = {};
            }else   if(operation=='edit'){
                let type = isBigType ? 'bigType':'smallType' ;
                this.tempData = JSON.parse(JSON.stringify(this[type].options[this[type].current_index]));
            }
        },
        async    submit(){
            if(this.dialog.operation == 'add'){
                this.addType();
            }else   if(this.dialog.operation == 'edit' ){
                this.updateType();
            }
            this.dialog.is_show = false;
        },
        //添加大类或者小类
        async    addType(){
            //1.检查参数是否完整
            if(this.tempData.name==null){
                this.$message.error("请填写名字");
                return ;
            }
            //2.准备请求
            let url = this.global_url+"tb-third-party-wang-redpacket-menu-page/addBigType";
            let req_data = this.tempData;
            if(!this.dialog.isBigType){
                url = this.global_url+"tb-third-party-wang-redpacket-menu-page/addSmallType";
                if(this.bigType.current== null){
                    this.$message.error("请您先选择大类");
                    return ;
                }
                req_data = {
                    smallType:this.tempData,
                    bigTypeKey:this.bigType.current.key
                }
            }
            //3.发送请求
            const res = await axios({
                url:url,
                method:"POST",
                data:req_data,
            })
            const data = utility.handleResponse(res);
            if(data == true ){
                this.$message.success("成功添加");
                if(this.dialog.isBigType){
                    this.load_bigType();
                }else{
                    this.load_smallType();
                }
            }
        },
        //更改信息
        async updateType(){  
            if(this.tempData.name == null) {
                this.$message.error("请填写名字");
                return ;
            }
            let url = this.global_url+"tb-third-party-wang-redpacket-menu-page/updateBigType";
            if(!this.dialog.isBigType){
                url = this.global_url+"tb-third-party-wang-redpacket-menu-page/updateSmallType";
            }
            const res = await axios({
                url:url,
                method:"put",
                data:this.tempData,
            });
            const data = utility.handleResponse(res);
            if(data == true){
                this.$message.success("成功更改");
                if(this.dialog.isBigType){
                    this.load_bigType(this.bigType.current_index);
                }
            }
        },
        //显示删除的弹窗，询问是否要删除
        deleteMessage(isBigType){
            this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
            .then(()=>{this.deleteType(isBigType);})
            .catch(() => {
                this.$message({type: 'info',message: '已取消删除'});
            });  
        },
        //删除大类或者小类
        async deleteType(isBigType){
            let url ;
            let req_data ;
            if(isBigType){
                url = this.global_url+"tb-third-party-wang-redpacket-menu-page/removeBigType?bigTypeKey="
                    +   this.bigType.current.key;
                req_data = {} ;
            }else{
                url = this.global_url+"tb-third-party-wang-redpacket-menu-page/removeSmallType"
                req_data = {bigTypeKey:this.bigType.current.key,smallType:this.smallType.current}
            }
            const res = await    axios({
                url:url,
                method:"DELETE",
                data:req_data,
            })
            const data = utility.handleResponse(res);
            if(data == true){
                this.$message.success("删除成功");
                if(isBigType){
                    this.load_bigType();
                }else{
                    this.load_smallType();
                }
            }else{
                this.$message.error("删除失败");
            }
        },
        // 全选
        handleSelectAllSmallTypes(value) {
          this.smallType.options.forEach(item => item.selected = value);
          this.emitSelectedSmallTypes();
        },
        handleSmallTypeChange(item) {
          this.selectAllSmallTypes = this.smallType.options.every(item => item.selected);
          this.emitSelectedSmallTypes();
        },
        toggleSmallTypeSelection() {
          this.showSmallTypeSelection = !this.showSmallTypeSelection
        },
        emitSelectedSmallTypes() {
          const selectedTypes = this.smallType.options.filter(item => item.selected).map(item => item.key);
          this.$emit("selectedSmallTypes", selectedTypes);
        },

    },
    computed:{
		...mapState({
            global_url:state=>state.global_base_url.main
        }),
        dialog_title:function(){
            const text1 = this.dialog.operation == 'add'?'添加':'编辑'  ;
            const text2 = this.dialog.isBigType ? '大类':'小类'         ;
            return text1+text2;
        },
    },
    watch:{

    },
    created(){
        this.initialize_data();
    },
}
</script>
    
<style  lang="less" scoped>
.picker_container{
    display: flex;  
    flex-direction: column;
    padding-bottom: 30px;

    .row-container {
      overflow: auto;
    }

    .small-type-selection {
      margin-top: 20px;

      .small-type-grid {
        margin-top: 10px;
        padding: 15px;
        border: 1px solid #dcdfe6;
        border-radius: 4px;

        .grid-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
          gap: 10px;
          margin-top: 10px;
        }
      }
    }

    .row{
        margin-top: 15px;
        height: 50px;
        //background-color:antiquewhite;
        display: flex;
        // 统一顶部筛选器按钮文字大小
        font-size: 16px;
        .block{
            padding: 10px;
            //background-color: antiquewhite;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: width 1s ease-in-out;
            .name{
                min-width: 6rem;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
            }
            .editAndDel{
                // 按钮盒子设置宽度为了能够让其父元素实现过渡效果
                // 宽度为按钮宽度+按钮间距，略大一点
                width: 95px;
                margin-left: 10px;
                overflow-x: hidden;
                display: flex;
                justify-content: center;
                transition: width 1s;

                // 穿透修改 el-button 大小
                // el-button 组件 size 属性设置 mini 不够小
                /deep/ .el-button {
                    width: 35px;
                    height: 28px;
                    padding: 12px 15px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
            .no_show{
                width: 0px;
            }
        }
        .block:hover{
            background-color: antiquewhite;
        }
        .block_clicked{
            //border-color: white;
            //border-style: solid;
            padding: 10px;
            background-color:#72b6fb !important;
        }
        
        .add{
            min-width: 70px;
            padding: 0 10px;
            background-color: rgb(207, 200, 200);
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            //border-color: white;
            //border-style: solid;
        }
    }

    /* 滚动条整体部分样式 */
    .row-container::-webkit-scrollbar {
        width: 8px;
        height: 8px
    }
    /* 滚动条外层轨道样式（包含内层轨道和两端的按钮） */
    .row-container::-webkit-scrollbar-track {
        background-color: #F1F1F1;
    }
    /* 滚动条两端的按钮样式 */
    .row-container::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }
    /* 滚动条内嵌滑块样式 */
    .row-container::-webkit-scrollbar-thumb {
        border-radius: 9999px;
        background-color: #C1C1C1;
    }
}

</style>