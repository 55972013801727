// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineConfiguration/';

/**
 * 获取配置
 * @param {*} params
 */
export async function getConfiguration(smallRoutineId) {
  const { data: res } = await axios.get(
    path + 'getConfiguration',
    {
      params: { 
        smallRoutineId: smallRoutineId
       },
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取关联的生活号失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}



/**
 * 修改配置
 * @param {*} params
 */
 export async function updateConfiguration(data={}) {
  const { data: res } = await axios.put(
    path + 'updateConfiguration',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "修改关联的生活号失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}
/**
 * 获取口令红包
 * @param {*} params
 */
export async function getFilePasswordRedEnvelope(id) {
  const { data: res } = await axios.get(
    path + 'getFilePasswordRedEnvelope?smallRoutineId='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 口令红包修改
 * @param {*} params
 */
 export async function uploadFilePasswordRedEnvelope(data={}) {
  const { data: res } = await axios.post(
    path + 'uploadFilePasswordRedEnvelope',data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 口令红包修改（无图）
 * @param {*} params
 */
 export async function uploadFilePasswordRedEnvelopeNoImg(data={}) {
  const { data: res } = await axios.post(
    path + 'uploadFilePasswordRedEnvelopeNoImg',data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}