<template>
  <!-- 基础配置 -->
  <div class="div_home">
    <el-card>
      <el-form
        class="viewForm"
        :model="configuration"
        :rules="addFormRules"
        ref="addFormRed"
        label-width="auto"
      >
        <el-divider content-position="left">基础配置</el-divider>
        <!-- key -->
        <el-form-item label="appkey" prop="appkey">
          <el-input
            v-model="configuration.appkey"
            placeholder="key"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- secret -->
        <el-form-item label="appsecret" prop="appsecret">
          <el-input
            v-model="configuration.appsecret"
            placeholder="appsecret"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 广告位id -->
        <el-form-item label="推广位pid" prop="pid">
          <el-input
            v-model="configuration.pid"
            placeholder="pid(格式:mm_xxx_xxx_xxx)"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <el-divider content-position="left">首页商品管理</el-divider>
        <!-- key -->
        <el-form-item label="标题" prop="materialTitle">
          <el-input
            v-model="configuration.materialTitle"
            placeholder="请输入首页展示的商品标题"
            maxlength="100"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 物料选择 -->
        <el-form-item label="物料选择" prop="materialId">
          <el-select
            v-model="configuration.materialId"
            clearable
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in materialList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="add">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["materialList"],
  data() {
    return {
      headerObj: {}, // token
      configuration: {
        id: "",
        appkey: "",
        appsecret: "",
        pid: "",
        adzoneId: "",
        materialTitle: "",
      },
      // 新增表单验证
      addFormRules: {
        appkey: [{ required: true, message: "请输入appkey", trigger: "blur" }],
        appsecret: [
          { required: true, message: "请输入appsecret", trigger: "blur" },
        ],
        pid: [{ required: true, message: "请输入推广位pid", trigger: "blur" }],
      },
    };
  },
  created() {
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
    this.init();
  },
  methods: {
    async init() {
      const { data: res } = await this.$http.get(
        "taoBaoCommodityInterface/getMes"
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.configuration = message;
    },
    add() {
      this.$refs.addFormRed.validate(async (valid) => {
        if (!valid) return;
        let configuration = this.configuration;
        let maxQuota = configuration.certificateMaxQuota;
        if (null != maxQuota && undefined != maxQuota && "" != maxQuota) {
          if (!this.moneyCheck(configuration.certificateMaxQuota)) {
            return this.$message.warning("每日限额格式有误");
          }
        }
        //发起请求
        const { data: res } = await this.$http.put(
          "taoBaoCommodityInterface/update",
          configuration
        );
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("保存成功");
      });
    },
  },
};
</script>

<style lang="less" scoped></style>
