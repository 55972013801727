<template>
	<div>
<!-- 		<el-select v-model="selectedValue" placeholder="请选择哪个类别可用券">
			    <el-option
			      v-for="item in options"
			      :key="item.value"
			      :label="item.label"
			      :value="item.value">
			    </el-option>
		</el-select> -->
		<el-collapse>
		  <el-collapse-item >
			<template >
				 <el-table
				     :data="data_list"
				     highlight-current-row
				     @selection-change="handleSelectionChange"
					row-key="id"
					ref="multipleTable"

				   >
				    <el-table-column  type="selection" width="55"  :reserve-selection="true"></el-table-column>
				     <el-table-column
				       prop="goodsGroup"
				       label="名称"
				       width="180">
				     </el-table-column>
				     <el-table-column
				       prop="id"
				       label="id"
				       width="180">
				     </el-table-column>

				   </el-table>
			</template>
		  </el-collapse-item>
		</el-collapse>

	</div>
</template>

<script>
	import axios from 'axios'
import { Row } from 'element-ui';
	import {mapState} from 'vuex'
	export default{
	    components:{
			
	    },
	    props: {
			input_list:{	
				type:String ,
				default:'[]'
			},
			list_max_length:{	type:Number, default:5}
	    },
	    data() {
	        return {
				//商品分组id和商品id列表
				selectedValue: '',
	             options: [],
				 data_list:[],
				 groupIdAndGoodsId:[],
	        }
	    },
		computed:{
			...mapState({
				httpUrl:state=>state.global_base_url.main
			})
		},
	    methods:{
			async getAll(){
				let e = await axios.get(this.httpUrl+"tb-third-party-wang-mall-goods-group/listGoodsGroup");
				this.data_list =  e.data.message;
				setTimeout(()=>{
						// for(let i=0;i<this.input_list.length;i++){
						// 	this.$refs.multipleTable.toggleRowSelection(()=>true);
						// }
					//提取id列表
					let ids_data_list = JSON.parse(this.input_list).map(e=>e.id);
					const cmp = (row,ids_data_list) =>
						( ids_data_list.findIndex(id=>row.id==id) !=-1 )
					this.$refs.multipleTable.clearSelection();
					this.$refs.multipleTable.data.forEach(
						row=>{
							this.$refs.multipleTable.toggleRowSelection(row,cmp(row,ids_data_list));
						}
					)
				},1000)

			},
			getRowKey(row){
				return row.id;
			},
			handleSelectionChange(val) {
			      this.groupIdAndGoodsId= val;
				  console.log(this.groupIdAndGoodsId)
			},
		},
		watch:{
			//对结果进行解码，上传给父组件
			groupIdAndGoodsId:{
				deep:true,
				handler(newValue){
					this.$emit("update:input_list",JSON.stringify(newValue));
				}
			}
			
		},
		created(){
			this.getAll();
		},
	}
</script>

<style>
	
	
</style>