<template>
  <div>
    <el-card>
      <defaultIndex />
    </el-card>
  </div>
</template>
<script>
import defaultIndex from "./index.vue";
export default {
  name: "defaultConfiguration",
  components: {
    defaultIndex,
  },
  data() {
    return {};
  },
  watch: {},
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped></style>
