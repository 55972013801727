import { render, staticRenderFns } from "./table_template_main_new.vue?vue&type=template&id=54d43add&scoped=true&"
import script from "./table_template_main_new.vue?vue&type=script&lang=js&"
export * from "./table_template_main_new.vue?vue&type=script&lang=js&"
import style0 from "./table_template_main_new.vue?vue&type=style&index=0&id=54d43add&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54d43add",
  null
  
)

export default component.exports