<template>
	<div class="ordersHomepage">
		<!-- 顶部搜索框，添加按钮，批量删除按钮 -->
		<div class="selCondition">
			<el-button type="primary" icon="el-icon-arrow-left" @click="getAll" style="margin-right: 10px;">返回列表</el-button>
			<el-button type="primary" icon="el-icon-search" @click="getOrders" style="margin-right: 10px;">搜索</el-button>
			<el-input v-model="orderId" placeholder="请输入订单id" style="width: 200px; margin-right: 10px;" ></el-input>
			  <el-input  v-model="userId" placeholder="请输入用户id" style="width: 200px;" ></el-input>
			  <el-popconfirm
			              confirm-button-text='好的'
			              cancel-button-text='取消'
			              icon="el-icon-info"
			              icon-color="red"
			              @confirm="handleDeletes()"
			              title="删除后数据不能恢复,确定进行批量删除吗？"
			              >
						<el-button type="danger" round  slot="reference" :disabled="multiple">批量删除</el-button>
			   </el-popconfirm>
			      <el-date-picker v-model="startDate" type="date" placeholder="选择起始日期"></el-date-picker>
				  <el-date-picker v-model="endDate" type="date" placeholder="选择结束日期"></el-date-picker>
		</div>
		<!-- 表单 -->
		<el-dialog title="提示" ref="form" :model="form" :visible.sync="dialogVisible" width="50%" >
		<el-form  label-width="80px" >
		  <el-form-item label="状态" >
			<el-select v-model="form.orderStatus" placeholder="请选择要修改的状态" >
			    <el-option label="已取消" value="已取消"></el-option>
			    <el-option label="待付款" value="待付款"></el-option>
				<el-option label="待发货" value="待发货"></el-option>
				<el-option label="已发货" value="已发货"></el-option>
				<el-option label="已完成" value="已完成"></el-option>
			  </el-select>
		  </el-form-item>
			  
			  <el-form-item>
				<el-button type="primary" @click="onUpd">修改</el-button>
				<el-button @click="cancellation">取消</el-button>
			  </el-form-item>
			</el-form>
		</el-dialog>
		<!-- 商品列表 -->
		<div class="ordersAll">
				<el-table :data="filteredData" height="800" style="width: 100%" @selection-change="handleSelectionChange"
				 :default-expand-all="true"> 
				<el-table-column  type="selection" width="55"></el-table-column>
				<el-table-column type="expand">
						<template slot-scope="props">
						  <el-form label-position="left" inline class="demo-table-expand" v-for="(item,index) in props.row.orderGoodsList" :key="index">
						    <el-form-item label="商品 ID :">
						      <span>{{ item.goodsId}}</span>
						    </el-form-item>
							<el-form-item label="商品标题 :">
							  <span>{{ item.title}}</span>
							</el-form-item>
							 <el-form-item label="购买的数量 :">
							   <span>{{ item.amount }}</span>
							 </el-form-item>
						    <el-form-item label="商品需付价格 :">
						      <span>{{ item.price}}</span>
						    </el-form-item>
						    <el-form-item label="商品实付价格 :">
						      <span>{{ item.actualPrice }}</span>
						    </el-form-item>
						  </el-form>
						</template>
				   </el-table-column>
			    <el-table-column fixed label="订单id" width="180" prop="orderId"></el-table-column>
				<el-table-column label="用户id" width="180" prop="userId"></el-table-column>
				<el-table-column label="收货人姓名" width="180" prop="name"></el-table-column>
				<el-table-column label="收货人电话" width="180" prop="phone"></el-table-column>
				<el-table-column label="收货地址 " width="180" prop="detailAddress"></el-table-column>
				<el-table-column label="详细地址 " width="180" prop="house"></el-table-column>
				<el-table-column label="配送方式" width="180" prop="deliveryMethod"></el-table-column>
				<el-table-column label="订单运费" width="180" prop="freight"></el-table-column>
				<el-table-column label="需付金额" width="180" prop="price"></el-table-column>
				<el-table-column label="实付金额" width="180" prop="actualPrice"></el-table-column>
				<el-table-column label="生成订单时间" sortable width="250" prop="orderDate"></el-table-column>
				<el-table-column label="订单状态" width="180" prop="orderStatus"
				:filters="[{ text: '已取消', value: '已取消' },  { text: '待付款', value: '待付款' },
				{ text: '待发货', value: '待发货' }, { text: '已发货', value: '已发货' },{ text: '已完成', value: '已完成' }]"
				:filter-method="filterStatus"></el-table-column>
			    <el-table-column label="操作" width="250">
					  <template #default="scope">
							<el-button
							  size="mini"
							  type="danger"
							  @click="handleDelete(scope.$index, scope.row)">删除</el-button>
							  <el-button
							    size="mini"
							    @click="handleEdit(scope.$index, scope.row)">修改状态</el-button>
						  </template>
			    </el-table-column>
			  </el-table>
		</div>
	<!-- 分页信息 -->
		<div class="ordersPage">
			<el-pagination
			      @size-change="handleSizeChange"
			      @current-change="handleCurrentChange"
			      :current-page="current"
			      layout="total, prev, pager, next, jumper"
			      :total="total">
			 </el-pagination>
		</div>
	</div>
</template>


<script>
	import axios from 'axios'
	import store from '@/store/index.js'
	export default {
	  data() {
		  return{
			  //时间过滤器
			  	//起始时间
			  	startDate: '',
			  	//结束时间
			  	endDate: '',
				
			  dialogVisible:false,
			  //表单数据
			  form:{
				  orderId:"",
				  orderStatus:""
			  },
			//批量删除
			  // 选中数组
			      multipleSelection:'',
			  // 非单个禁用
			      single: true,
			  // 非多个禁用
			      multiple: true,
			 //长度
			  lth:0,
			  //订单编号（搜索框）
			  orderId:'',
			  userId:'',
			  //订单列表包含订单商品信息
			  orderData:[],
			  //订单列表不包含订单商品信息
			  tableData: [],
				//分页信息
					 //当前页
					 current:0,
					 //总共多少条数据
					 total:0,
					 //总共几页
					 pages:0,
					 //每页个数
					 size:0
					}
				},
		  methods:{
			  //表单取消按钮
			   cancellation(){
			  	this.value='';
			  	this.dialogVisible=false;
			  	this.$message.info("取消操作");
			  },
			  //编辑
			  		handleEdit(index, row) {
			  			this.dialogVisible=true;
			  			axios.get(store.state.global_base_url.main+"tb-third-party-wang-mall-order/listByOrderIdOrUserId?orderId="+row.orderId).then((res)=>{
			  					this.form.orderId=res.data.message[0].orderId;
								this.form.orderStatus=res.data.message[0].orderStatus;
			  			}).finally(()=>{
			  				this.getAll();
			  			})
			  		},
			  //修改
			  onUpd(){
			   axios.put(store.state.global_base_url.main+"tb-third-party-wang-mall-order/updateByOrderId?orderId="+this.form.orderId+"&orderStatus="+this.form.orderStatus).then((res)=>{
			  	if(res.data.message==true){
			   		this.$message.success("修改成功");
			   		this.dialogVisible=false;
			   	}else{
			   		this.$message.error("修改失败");
			   	}
			   }).finally(()=>{
			   	this.getAll();
			   });
			  },
				//删除
				handleDelete(index, row) {
					this.$confirm("此操作将永久删除当前信息，是否继续？","提示",{type:"info"}).then(()=>{
						axios.delete(store.state.global_base_url.main+"tb-third-party-wang-mall-order/delById?orderId="+row.orderId).then((res)=>{
							if(res.data.message==true){
								this.$message.success("删除成功");
							}else{
								this.$message.error("删除失败");
							}
						}).finally(()=>{
							this.getAll();
						});
					}).catch(()=>{
						this.$message.info("取消操作");
					});				  
				},
				handleSizeChange(val) {
				  console.log(`每页 ${val} 条`);
			    },
			    handleCurrentChange(val) {
				  this.current=val;
				  this.getAll();
			    },
				//选中的数据
				handleSelectionChange(val){
						  console.log(val);
						  this.multipleSelection = val;
					      this.single = this.multipleSelection.length != 1;
					      this.multiple = !this.multipleSelection.length;
				},
				//过滤订单状态
				filterStatus(value, row) {
				        return row.orderStatus === value;
				},
				//批量删除
				handleDeletes(){
					let checkArr = this.multipleSelection;   // multipleSelection存储了勾选到的数据
					let params = [];
					let self = this;
					checkArr.forEach(function (item) {     
					     params.push(item.orderId);       // 添加所有需要删除数据的orderId到一个数组，post提交过去
					   });
					axios.post(store.state.global_base_url.main+"tb-third-party-wang-mall-order/delByIds", params)
					      .then((res)=> {
							if(res.data.message=="批量删除成功"){
								this.$message.success("删除成功");
							}else{
								this.$message.error("删除失败");
							}
					      }).finally(()=>{
							this.getAll();
						});
				},
				//条件查询
				async getOrders(){
					if(this.orderId==''&&this.userId==''){
						this.$message.info("请输入要查询的条件")
					}else{
						let param="?orderId="+this.orderId;
						 param+="&userId="+this.userId;
						const res=await axios.get(store.state.global_base_url.main+"tb-third-party-wang-mall-order/listByOrderIdOrUserId"+param);
						if(res.data.message.length==0){
							this.$message.info("没有该商品信息的数据！")
						}else{
							this.tableData=res.data.message;
							this.orderId='';
							this.userId='';
							axios.post(store.state.global_base_url.main+"tb-third-party-wang-mall-order/orderDemo", this.tableData).then((e)=>{
								this.orderData = e.data.message;
							});
						}			
					}
				},
				//获取数据 并分页
				async getAll(){
					const res=await axios.get(store.state.global_base_url.main+"tb-third-party-wang-mall-order/page?current="
						+this.current);
					if(res.data.message.current>res.data.message.pages){
						const ress=await axios.get(store.state.global_base_url.main+"tb-third-party-wang-mall-order/page?current="+res.data.message.pages);
						this.current=ress.data.message.current;
						this.tableData=ress.data.message.records;
						this.size=ress.data.message.size;
						this.pages=ress.data.message.pages;
						this.total=ress.data.message.total;
					}else{
						this.current=res.data.message.current;
						this.tableData=res.data.message.records;
						this.size=res.data.message.size;
						this.pages=res.data.message.pages;
						this.total=res.data.message.total;
					}
					axios.post(store.state.global_base_url.main+"tb-third-party-wang-mall-order/orderDemo", this.tableData).then((e)=>{
						this.orderData = e.data.message;
					});
				}
			  },
		created() {
			this.getAll();
		},
		computed:{
			filteredData() {
			      if (!this.startDate || !this.endDate) {
			        return this.orderData;
			      }
			      return this.orderData.filter(item => {
			        const itemDate = new Date(item.orderDate);
			        const start = new Date(this.startDate);
			        const end = new Date(this.endDate);
			        return itemDate >= start && itemDate <= end;
			      });
			    }
		},
			  
	}
		
</script>
<style>
	.ordersHomepage{
		display: flex;
		width: 100%;
		height: 960px;
		flex-direction: column;
	}
	.selCondition{
		width: 96%;
		height: 50px;
		border: 4px solid #ace7ef;
		border-radius: 12px;
		margin: 5px auto;
		line-height: 50px;
	}
	.ordersAll{
		margin-top: 20px;
		width: 96%;
		height: 800px;
		border: 4px solid #ace7ef;
		border-radius: 12px;
		margin: 3px auto;
	}
	.ordersPage{
		display: grid;
		place-items: center;
		width: 96%;
		height: 50px;
		border: 4px solid #ace7ef;
		border-radius: 12px;
		margin: 5px auto;	
	}
	.demo-table-expand {
		display: flex;
		font-size: 0;
	}
    .demo-table-expand label {
		width: 120px;
		color: #2f89fc;
	}
	.demo-table-expand .el-form-item {
		flex: 1;
		width:250px;
		margin-left: 50px;
	}
	.el-table__expand-icon{
	  display: none;
	}
</style>