<template>
  <div>
    <!-- 菜单列表 -->
    <menuList
      v-show="showType == 1"
      :showList="showList"
      :showSingleMes="showSingleMes"
      :showServiceRel="showServiceRel"
      :showMessageTemplate="showMessageTemplate"
      :showMessageGaoDeTemplate="showMessageGaoDeTemplate"
    />
    <!-- 服务列表 -->
    <applet1ServiceRel
      v-if="showType == 2"
      :showMenuList="showMenuList"
      :showSingleId="showSingleMes.id"
    />
    <!-- 消息模板 -->
    <messageTemplate
      v-if="showType == 3"
      :appId="showSingleMes.appId"
      :appletId="showSingleMes.id"
      :showMenuList="showMenuList"
    />
    <!-- 高德临时服务 -->
    <gaoDeTemplate
      v-if="showType == 4"
      :appId="showSingleMes.appId"
      :showMenuList="showMenuList"
    />
  </div>
</template>

<script>
import menuList from "./menuList.vue";
import applet1ServiceRel from "./childrenPage/applet1ServiceRel.vue";
import messageTemplate from "./childrenPage/messageTemplate.vue";
import gaoDeTemplate from "./childrenPage/gaoDeTemplate/gaoDeTemplate.vue";
export default {
  name: "index",
  props: ["showList", "showSingleMes"],
  components: {
    menuList,
    applet1ServiceRel,
    messageTemplate,
    gaoDeTemplate,
  },
  data() {
    return {
      showType: 1,
    };
  },
  methods: {
    showMenuList() {
      this.showType = 1;
    },
    showServiceRel() {
      this.showType = 2;
    },
    showMessageTemplate() {
      this.showType = 3;
    },
    showMessageGaoDeTemplate() {
      this.showType = 4;
    },
  },
};
</script>

<style lang="scss" scoped></style>
