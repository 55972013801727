<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <div class="head_input head_input_two">
            <div class="div_input">
              <label class="div_input_text">小程序:</label>
              <el-select
                filterable
                v-model="queryInfo2.appId"
                placeholder="请选择小程序"
                @change="smallRoutineChange"
              >
                <el-option
                  v-for="(item, index) in smallRoutineList"
                  :key="'smallRoutine_' + index"
                  :label="item.name"
                  :value="item.appId"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">表单列表:</label>
              <el-select
                filterable
                v-model="queryInfo2.formId"
                placeholder="请选择表单"
                @change="formListChange"
              >
                <el-option
                  v-for="(item, index) in formList"
                  :key="'formList_' + index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div_input" v-show="pageMes.total > 0">
              <label class="div_input_text">导出EXCEL:</label>
              <el-button @click="getAllListMes">导出EXCEL</el-button>
              <div class="home">
                <download-excel
                  ref="downloadExcelRef"
                  v-show="false"
                  :data="excelMes.json_data"
                  :fields="excelMes.json_fields"
                  type="xls"
                  worksheet="My Worksheet"
                  name="新人红包表格"
                >
                  <el-button>导出EXCEL</el-button>
                </download-excel>
              </div>
            </div>
            <!-- <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button> -->
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.records" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          v-for="(item, index) in pageMes.labelKey"
          :key="'el-table-column_' + index"
          :label="item.label"
          :prop="item.id"
        ></el-table-column>
        <el-table-column label="创建时间" prop="gmtCreate"></el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getFormList } from "@/services/redEnvelopeForNewcomers/redEnvelopeForNewcomers.js";
import {
  pageByFormId,
  listByFormId,
} from "@/services/redEnvelopeForNewcomers/redEnvelopeForNewcomersContentForm.js";
import { getAllSmallRoutineList } from "@/services/thirdParty/smallRoutine/smallRoutine";
export default {
  name: "redEnvelopeForNewcomersList",
  props: ["showMessage"],
  data() {
    return {
      excelMes: {
        json_fields: {},
        json_data: [],
      },
      // 小程序列表
      smallRoutineList: [],
      // 表单列表
      formList: [],
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        name: "",
        appId: "",
        formId: "",
        isvCode: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        name: "",
        appId: "",
        formId: "",
        isvCode: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        labelKey: [],
        records: [],
        list: [],
        total: 0,
        current: -1,
        pages: -1,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      // 获取小程序列表
      const res = await this.$publicJs.request(this, getAllSmallRoutineList);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "smallRoutineList", res.data);
    },
    // 小程序选中变更
    smallRoutineChange(e) {
      this.$set(this.queryInfo, "appId", e);
      this.$set(this.queryInfo2, "appId", e);
      this.$set(this.queryInfo, "formId", "");
      this.$set(this.queryInfo2, "formId", "");
      this.getFormList();
    },
    // 表单列表选中变更
    formListChange(e) {
      this.$set(this.queryInfo, "formId", e);
      this.$set(this.queryInfo2, "formId", e);
      if (!this.$publicJs.isNull(e)) {
        this.search();
      }
    },
    // 获取表单列表
    async getFormList() {
      const appId = this.queryInfo.appId;
      const res = await this.$publicJs.request(this, getFormList, appId);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      let data = res.data;
      if (data.length == 0) {
        this.$message.warning("当前小程序没有表单信息");
      }
      this.$set(this, "formList", data);
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
    },
    // 获取列表
    async getList() {
      const res = await this.$publicJs.request(
        this,
        pageByFormId,
        this.queryInfo
      );
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "pageMes", res.data);
      // // 列表内容
      // this.pageMes.list = res.message.records;
      // // 总数
      // this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 获取excel
    async getAllListMes() {
      // 获取excel数据
      const res = await this.$publicJs.request(
        this,
        listByFormId,
        this.queryInfo
      );
      if (!res.success) {
        return this.$message.error(res.err);
      }
      let data = res.data;
      if (data.total <= 0) {
        return this.$message.warning("没有数据可导出");
      }
      let labelKey = data.labelKey;
      labelKey.push({
        id: "gmtCreate",
        label: "创建时间",
      });
      let newLabelKey = {};
      for (let i = 0; i < labelKey.length; i++) {
        const single = labelKey[i];
        newLabelKey[single.label] = {
          field: single.id,
          callback: (value) => {
            return value + "&nbsp;";
          },
        };
      }
      await this.$set(this, "excelMes", {
        json_fields: newLabelKey,
        json_data: data.records,
      });
      // // 模拟点击事件
      await this.$refs["downloadExcelRef"].$el.click();
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input_two {
    margin-top: 10px;
  }
}
</style>
