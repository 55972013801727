<script>
import LineChart from "@/components/tcw/line-chart/LineChart.vue";

export default {
  components: {LineChart},
  props: {
    uvType: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    lineChartDialogTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      uv: 0,
      uvAll: [],
      isShowLineChart: false,
    }
  },
  methods: {
    async getUv() {
      const res = await this.$http.get("uv/get?uvType=" + this.uvType)
      if (res.status === 200) this.uv = res.data.message
    },
    async getUvAll() {
      const res = await this.$http.get("uv/get/all?uvType=" + this.uvType)
      if (res.status === 200) this.uvAll = res.data.message
    },
    showLineChart() {
      if (this.uvAll.length <= 0) {
        this.$message('暂无相关数据记录');
        return
      }
      this.isShowLineChart = true
    },
    closeLineChart() {
      this.isShowLineChart = false
    },
    flush() {
      this.getUv()
      this.getUvAll()
    }
  },
  created() {
    this.getUv()
    this.getUvAll()
  }
}
</script>

<template>
  <div>
    <el-tooltip content="点击刷新, 右键查看最近 30 天的记录">
      <div class="uv" @click="flush" @contextmenu.prevent="showLineChart">
        <span class="uv__label">{{ label }}</span>
        <span class="uv_num">{{ uv }}</span>
      </div>
    </el-tooltip>
    <el-dialog :title="lineChartDialogTitle" v-if="isShowLineChart" :visible="isShowLineChart" @close="closeLineChart">
      <LineChart
        :id="uvType"
        :x-axis="{type: 'category', data: uvAll.map(item => item.date)}"
        :series="[{data: uvAll.map(item => item.uv), type: 'line', label: {show: true}}]"
      ></LineChart>
    </el-dialog>
  </div>
</template>

<style scoped lang="less">
.uv {
  box-sizing: border-box;
  height: 100%;
  padding: 5px;
  background-color: #B8BBBF;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}
</style>