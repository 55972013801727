<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
    >
      <el-form-item label="客户名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入客户名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户地址" prop="address">
        <el-input
          v-model="updateForm.address"
          placeholder="请输入客户地址"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="updateForm.phone"
          placeholder="请输入客户联系电话"
          maxlength="11"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="微信" prop="wechat">
        <el-input
          v-model="updateForm.wechat"
          placeholder="请输入客户微信"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="updateForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "applet2AdvertisementCustomerUpdate",
  props: ["updateMes", "refaushList", "refreshCustomerList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        name: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入客户联系电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为 11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {
    this.updateForm = { ...this.updateMes };
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.chechPhone(this.updateForm.phone)) {
          return this.$message.warning("手机号格式有误");
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/advertisementCustomer/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
      this.refreshCustomerList();
    },
    // 手机号校验
    chechPhone(phone) {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      return regExp.test(phone);
    },
    // 刷新编辑对象
    refaushForm(e) {
      this.updateForm = { ...e };
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
