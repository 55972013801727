import { render, staticRenderFns } from "./messageCarouselapp.vue?vue&type=template&id=2cbfdc8e&scoped=true&"
import script from "./messageCarouselapp.vue?vue&type=script&lang=js&"
export * from "./messageCarouselapp.vue?vue&type=script&lang=js&"
import style0 from "./messageCarouselapp.vue?vue&type=style&index=0&id=2cbfdc8e&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cbfdc8e",
  null
  
)

export default component.exports