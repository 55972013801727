
<template>
  <div>
    <div style="margin-bottom: 30px">
      <el-progress :text-inside="true" :stroke-width="22" status="success" :percentage="percentage2"></el-progress>
    </div>
    <el-button type="primary" plain @click="useDeploy()" :loading="useDeployLoadingA" class="but">
      {{ useDeployLoadingA? '加载中' : '执行选择的类别' }}</el-button>
    <el-button type="warning" plain @click="useDeploythirdparty()" :loading="useDeployLoadingA" class="but">
      {{ useDeployLoadingA? '加载中' : '执行选择的第三方' }}</el-button>
    <el-button type="info" plain @click="dialogVisible = true">查看执行的失败结果</el-button>
    <el-dialog
        title="查看执行的失败结果"
        :visible.sync="dialogVisible"
        width="80%"
        append-to-body>
      <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
    </el-dialog>
  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";

let intervalId2 = null

export default {
  components: {
    exhibitionMessagesTheSecond
  },
  props: [
    /**
     * 1. 选中的类别
     * 2. 选中的第三方
     * 3. 第三方类型的 区分未备案/已备案
     */
    "checkedCities",
    "dimensionArr",
    "appletTypeIcpStatusList"
  ],
  data() {
    return {
      //已完成的进度
      percentage2: 0,
      //总任务数
      appletAmount2: 0,
      //按钮配置
      useDeployLoadingA: false,
      //记录执行的结果
      messages:[],
      //弹出对话框
      dialogVisible: false,
    }
  },
  methods: {
    //执行选择的多个类别
    async useDeploy() {
      // console.log("选中的类别", this.checkedCities);
      this.useDeployLoadingA = true;
      if (this.checkedCities.length < 1) {
        this.$message.error("请选择类别");
        this.useDeployLoadingA = false
        return
      }
      let batchClassIdList = this.checkedCities.map(city => city.id);
      // console.log("batchClassIdList", batchClassIdList)

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        batchClassIdList: batchClassIdList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfig?batchClassIdList=" + batchClassIdList
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //显示结果
      this.messages = res.message;
      // console.log("结果", res.message)
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件
      this.$emit('updateGetList');
    },
    //执行选择的多个第三方
    async useDeploythirdparty() {
      this.useDeployLoadingA = true;
      // console.log("选中的第三方", this.dimensionArr)
      if (this.dimensionArr.length < 1) {
        this.$message.error("请选择第三方");
        this.useDeployLoadingA = false
        return
      }
      let appletTypeList = this.dimensionArr.map(city => city.id);
      let appletTypeIcpStatus = this.checkIcpStatus(this.appletTypeIcpStatusList)

      //计算要执行多少小程序
      let multiAppletTypeOrBatchClassIdVo = {
        appletTypeList: appletTypeList,
      }
      await this.countAppletAmount2(multiAppletTypeOrBatchClassIdVo);
      //启动进度条
      this.startTaskProgress2();

      const { data: res } = await this.$http.put(
          "/tb-third-party-wang-batch-class/updateBatchClassAppsMultiConfigThirdparty?appletTypeList=" + appletTypeList + "&appletTypeIcpStatus=" + appletTypeIcpStatus
      )
      if(res.code == 200){
        this.$message.success("「应用配置」执行完成");
      }else{
        this.$message.error("「应用配置」执行失败");
      }
      //记录结果
      this.messages = res.message;
      this.useDeployLoadingA = false

      //任务真正的完成了
      this.percentage2 = 100;
      clearInterval(intervalId2); // 所有任务完成后清除定时器

      //触发父组件
      this.$emit('updateGetList');
    },
    //获取 计算要执行多少小程序
    async countAppletAmount2(multiAppletTypeOrBatchClassIdVo) {
      //后台计算有多少小程序
      const { data: res } = await this.$http.post(
          "/tb-third-party-wang-batch-class/countAppletAmount",
          multiAppletTypeOrBatchClassIdVo
      );
      if (200 === res.code) {
        this.appletAmount2 = res.message;
      } else {
        this.$message.error("计算进度条进度失败:" + res.message);
      }
    },
    //进度条
    startTaskProgress2() {
      this.percentage2 = 0;
      intervalId2 = setInterval(() => {
        if (this.percentage2 < 100) {
          this.percentage2 += Math.round(100 / this.appletAmount2);
        } else {
          // 所有任务完成后清除定时器
          clearInterval(intervalId2);
        }
      }, 300); // 每300毫秒更新一次
    },
  }

}
</script>

<style scoped lang="less">
.but{
  margin-right: 10px;
}
</style>