<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="head_input">
              <el-button
                class="headButtonSingle"
                type="primary"
                @click="setShowType1()"
                icon="el-icon-arrow-left"
              >
                返回
              </el-button>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
            >
              申请创建搜索直达活动
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="搜索直达名称" prop="name"></el-table-column>
        <el-table-column label="搜索直达配置id" prop="box_id"></el-table-column>
        <el-table-column label="品牌id" prop="brand_id"></el-table-column>
        <el-table-column label="小程序id" prop="target_appid"></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <div v-if="scope.row.status == 'INITIAL'">初始</div>
            <div v-else-if="scope.row.status == 'ONLINE'">已上架</div>
            <div v-else-if="scope.row.status == 'EXPIRE'">已失效</div>
            <div v-else-if="scope.row.status == 'OFFLINE'">已下架</div>
            <div v-else>其他:{{ scope.row.status }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="查看详情"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="showMessage(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="运营管理"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-operation"
                size="mini"
                @click="toOperationManagement(scope.row.box_id)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="上架"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-star-on"
                size="mini"
                @click="toOnline(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip
              effect="dark"
              content="下架"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-star-off"
                size="mini"
                @click="toOffline(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog
      title="申请创建搜索直达活动"
      :visible.sync="addDialogVisible"
      width="50%"
    >
      <thisAdd
        :refaushList="getList"
        :visible="addDialogVisible"
        :appId="appId"
      />
    </el-dialog>
    <!-- 详情 -->
    <el-drawer title="详情" size="50%" :visible.sync="messageMes.visible">
      <thisMessage :messageMes="messageMes.mes" />
    </el-drawer>
  </div>
</template>

<script>
import {
  list,
  query,
  online,
  offline,
} from "@/services/thirdParty/openSearchBox";
import thisAdd from "./thisAdd.vue";
import thisMessage from "./thisMessage.vue";
export default {
  components: {
    thisAdd,
    thisMessage,
  },
  props: ["setShowType1", "appId", "toOperationManagement"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 搜索内容
      queryInfo2: {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 查看详情
      messageMes: {
        visible: false,
        mes: {},
      },
      // 运营管理
      operationManagementMes: {
        visible: false,
        boxId: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.search();
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let queryInfo = this.queryInfo;
      const res = await list({
        appId: this.appId,
        model: {
          pageNumber: queryInfo.pageNum,
          pageSize: queryInfo.pageSize,
        },
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.pageMes.list = res.data.box_infos;
      this.pageMes.total = res.data.total_count;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 查看详情
    async stateChange(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res;
      // let res = await updateStatus({
      //   id: e.id,
      //   status: e.status,
      // });
      loading.close();
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    // 查看详情
    async showMessage(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await query({
        appId: this.appId,
        model: {
          boxId: e.box_id,
        },
      });
      loading.close();
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      this.$set(this.messageMes, "visible", true);
      this.$set(this.messageMes, "mes", res.data);
    },
    // 上架
    async toOnline(e) {
      let res = await online({
        appId: e.target_appid,
        model: {
          boxId: e.box_id,
        },
      });
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.getList();
    },
    // 下架
    async toOffline(e) {
      let res = await offline({
        appId: e.target_appid,
        model: {
          boxId: e.box_id,
        },
      });
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.getList();
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .headButtonSingle {
      margin-right: 10px;
    }
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
  .demo-table-expand {
    font-size: 0;
  }
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }
  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 100%;
  }
}
</style>
