
<!-- 这个是用来做 菜单栏的编辑 -->
<template>
<div class="whole_container">
    <div class="top_oprations">
        <el-button  type="primary"  icon="el-icon-folder-add"  @click="show_add_dialog">新建一个模板</el-button>
    </div>
    <div class="audit_templates_container">
        <div    class="audit_template"   v-for="(item,index) in audit_template.list"  :key="index">
            <div    class="audit_template_title">{{item.groupName}}</div>
            <div    class="audit_template_content">
                <menu_item_editor   class="width_limit"   :input_list.sync="item.logoImagePathList"   content_type="logo"   :is_editable="false"></menu_item_editor>
                <menu_item_editor   class="width_flexible"   :input_list.sync="item.digestList"   content_type="digest"   :is_editable="false"></menu_item_editor>
                <menu_item_editor   class="width_flexible"   :input_list.sync="item.describeList"   content_type="describe"   :is_editable="false"></menu_item_editor>
            </div>
            <div class="button_list button_list_lift_up">
                <el-button  type="primary"  @click="show_edit_dialog(item)"   icon="el-icon-edit">编辑</el-button>
                <el-button  type="primary"  @click="changeSortIndex(item.id,'up')"   icon="el-icon-top">向上</el-button>
                <el-button  type="primary"  @click="changeSortIndex(item.id,'down')"   icon="el-icon-bottom">向下</el-button>
                <el-button  type="danger"   @click="delete_template(item)"    icon="el-icon-delete">删除</el-button>
            </div>
        </div>
    </div>
    <el-dialog :title="dialog.type_map_title[dialog.type]" :visible.sync="dialog.is_show"
           width="1000px"   class="dialog"  top="8vh"   :close-on-click-modal="false">
        <div    v-if="dialog.is_show">
            <el-form    label-width="130px">
                <el-form-item label="名称">
                    <el-input v-model="dialog.template.groupName"></el-input>
                </el-form-item>
                <el-form-item label="logo照片列表">
                    <menu_item_editor :id="dialog.template.id"   :input_list.sync="dialog.template.logoImagePathList"    content_type="logo"></menu_item_editor>
                </el-form-item>
                <el-form-item label="简介">
                    <menu_item_editor   :input_list.sync="dialog.template.digestList"    content_type="digest"></menu_item_editor>    
                </el-form-item>
                <el-form-item label="描述">
                    <menu_item_editor   :input_list.sync="dialog.template.describeList"    content_type="describe"></menu_item_editor>    
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="()=>{dialog.is_show = false;}">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';
import { handleResponse } from '@/services/wCommonFunction.js'
import menu_item_editor from './defaultConfig/menu_item_editor.vue'
export default{
    components:{
        menu_item_editor
    },
    props: {
        
    },
    data() {
        return {
            audit_template:{
                list:[],
            },
            dialog:{
                template:{},
                is_show:false,  //是否展示弹窗
                type:'add',//add or edit 添加东西或者编辑东西
                type_map_title:{    //上面那个属性的映射
                    add:"新增模板",
                    edit:"编辑模板"
                }
            }
        }
    },
    methods:{
        initialize_data(){
            axios({
                url:this.httpUrl + 'tb-third-party-small-routine-audit-template/list'
            })
            .then(res=>{
                const data = handleResponse(res);
                this.audit_template.list = JSON.parse(JSON.stringify(data));
            });
        },
        async   show_edit_dialog(item){   //展示编辑编辑弹窗，并且把数据输送进去
            this.dialog.type = 'edit';
            this.dialog.template = JSON.parse(JSON.stringify(item));
            this.dialog.is_show = true ;
        },
        async   show_add_dialog(){  //展示新增弹窗
            this.dialog.template = {
                logoImagePathList:'[]',
                digestList:'[]',
                describeList:'[]',
            };
            this.dialog.type = 'add';
            this.dialog.is_show = true;
        },
        async    submit(){   //确定更改或者添加某个数据
            let response    = await     axios({
                url:this.$httpUrl+"tb-third-party-small-routine-audit-template/saveOrUpdate",
                method:"POST",
                data:this.dialog.template,
            })
            const data = handleResponse(response);
            if(data){
                this.$message.success("成功添加");
                this.initialize_data(); //刷新一下数据
            }
            this.dialog.is_show = false;
        },
        async   delete_template(item){
            this.$confirm(
                '此操作将删除该模板, 是否继续?','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(async()=>{
                let send_form_data = new FormData();
                send_form_data.append("id",item.id);
                let response = await    axios({
                    url:this.$httpUrl+'tb-third-party-small-routine-audit-template/delete',
                    method:'DELETE',
                    data:send_form_data,
                });
                const data = handleResponse(response);
                if(data){
                    this.$message.success("操作成功");
                    this.initialize_data();
                }
            }).catch(
                
            )
        },
        //这个是更改排序
        //如果direction是up就是向上走（sortIndex增加)
        //如果direction是down就是向下走（sortIndex降低)
        async changeSortIndex(id,direction='down'){
            let url = this.httpUrl + 'tb-third-party-small-routine-audit-template/sortIndex';
            if(direction === 'up'){
                url += "Increase" ;
            }else{
                url += 'Decrease' ;
            }
            let send_data = {id:id};
            const response =    await axios({
                url:url,
                method:'PUT',
                data:send_data,
            });
            const data = handleResponse(response);
            if(data){
                this.$message.success("成功交换");
                this.initialize_data(); //刷新一下数据
            }else{
                this.$message.error("交换失败");
            }
        }
    },
    computed:{
        ...mapState({
            httpUrl:state=>state.global_base_url.main
        })
    },
    created(){
        this.initialize_data()
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
	background-color: #fafafa;
	border-radius: 10px;
	padding: 40px;
    flex: 1;
    display: flex;  flex-direction: column;
    .top_oprations{
        margin-bottom: 20px;
        height: 70px;
        display: flex;  align-items: center;
    }
    .audit_templates_container{
        display: flex;  flex-wrap: wrap;
        .audit_template{
            min-width: 1200px;
            width: 1300px;
            height: 660px;
            margin-bottom: 30px;   margin-right: 30px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;  flex-direction: column; align-items: center;
            position: relative;
            overflow: hidden;
            .audit_template_title{
                margin-top: 40px;   margin-bottom: 30px;
                text-align: center;
                font-size: 50px;  font-weight: bold;
            }
            .audit_template_content{
                width: 1100px;
                display: flex;
                .width_limit{
                    width:160px;
                }
                .width_flexible{
                    flex: 1;
                }
            }
            .button_list{
                position: absolute;
                bottom: -100px;    
                width:  100%;  height: 100px;
                display: flex;  justify-content: space-evenly; align-items: center;
                transition-property: transform;
                transition-duration: 0.5s;
            }
        }
        .audit_template:hover{
            //cursor: pointer;
            //background-color: beige;
            box-shadow: 0 0 40px #ccc;
            .button_list{
                transition-property: transform;
                transition-duration: 0.5s;
            }
            .button_list_lift_up{
                transform: translateY(-100px);
            }
        }

    }
    .dialog{
        display: flex;  flex-direction: column;     align-items: center;
    }
}


</style>