import { render, staticRenderFns } from "./smallRoutine3LuckDraw.vue?vue&type=template&id=e2727dd2&scoped=true&"
import script from "./smallRoutine3LuckDraw.vue?vue&type=script&lang=js&"
export * from "./smallRoutine3LuckDraw.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2727dd2",
  null
  
)

export default component.exports