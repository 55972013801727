<template>
  <div>
    <el-form
      :model="addForm"
      :rules="addFormRules"
      ref="addFormRed"
      label-width="auto"
    >
      <el-form-item label="客户名称" prop="name">
        <el-input
          v-model="addForm.name"
          placeholder="请输入客户名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客户地址" prop="address">
        <el-input
          v-model="addForm.address"
          placeholder="请输入客户地址"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="phone">
        <el-input
          v-model="addForm.phone"
          placeholder="请输入客户联系电话"
          maxlength="11"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="微信" prop="wechat">
        <el-input
          v-model="addForm.wechat"
          placeholder="请输入客户微信"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="addForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "applet2AdvertisementCustomerAdd",
  props: ["closeThis", "refaushList", "refreshCustomerList"],
  data() {
    return {
      addForm: {},
      // 表单验证
      addFormRules: {
        name: [
          { required: true, message: "请输入客户名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        phone: [
          { required: true, message: "请输入客户联系电话", trigger: "blur" },
          {
            min: 11,
            max: 11,
            message: "长度为 11 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      this.$refs["addFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.chechPhone(this.addForm.phone)) {
          return this.$message.warning("手机号格式有误");
        }
        this.add();
      });
    },
    // 新增
    async add() {
      const { data: res } = await this.$http.post(
        "/advertisementCustomer/add",
        {
          ...this.addForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.resetForm();
      this.$message.success("操作成功");
      this.refaushList();
      this.refreshCustomerList(); //刷新客户列表
    },
    // 手机号校验
    chechPhone(phone) {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      return regExp.test(phone);
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
