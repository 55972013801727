<template>
  <div class="view_main" v-loading="loading">
    订单详情
    <el-divider></el-divider>
    <!-- 余额查询 -->
    <div class="div_shopinfo" v-if="fromMes.shopinfo != undefined">
      <div class="div_single">
        <div class="single_title">商户名称:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.shop_name }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">关联手机号:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.tel }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">账户余额:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.credit }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">支付方式:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.pay_mode == 1 ? "自建商户" : "芒果商户" }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">当前状态 :</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.test_if == 1 ? "测试" : "生产" }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">公众号APPID:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.gzh_appid }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">小程序APPID:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.xcx_appid }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">出票回调地址:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.callback_url }}
        </div>
      </div>
      <div class="div_single">
        <div class="single_title">对接api的域名:</div>
        <div class="single_mes">
          {{ fromMes.shopinfo.api_url }}
        </div>
      </div>
    </div>
    <!-- 订单明细（默认最近100条） -->
    <div class="div_income" v-if="fromMes.income != undefined">
      <el-divider content-position="left">订单明细（默认最近100条）</el-divider>
      <div
        class="div_income_single"
        v-for="(item, index) in fromMes.income"
        :key="'div_income_single_' + index"
      >
        <div class="div_single_mes">
          <div class="div_single_mes_title">代理ID:</div>
          <div class="div_single_mes_mes">
            {{ item.agent_id }}
          </div>
        </div>
        <div class="div_single_mes">
          <div class="div_single_mes_title">佣金总额:</div>
          <div class="div_single_mes_mes">
            {{ item.amount }}
          </div>
        </div>
        <div class="div_single_mes">
          <div class="div_single_mes_title">自增ID:</div>
          <div class="div_single_mes_mes">
            {{ item.order_id }}
          </div>
        </div>
        <div class="div_single_mes">
          <div class="div_single_mes_title">结算状态:</div>
          <div class="div_single_mes_mes">
            <template v-if="item.state == -1">退款</template>
            <template v-if="item.state == 0">待结算</template>
            <template v-if="item.state == 1">已结算</template>
          </div>
        </div>
        <div class="div_single_mes">
          <div class="div_single_mes_title">出票时间:</div>
          <div class="div_single_mes_mes">
            {{ item.created_at }}
          </div>
        </div>
        <div class="div_single_mes">
          <div class="div_single_mes_title">订单号:</div>
          <div class="div_single_mes_mes">
            {{ item.order_number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "balanceMes",
  data() {
    return {
      fromMes: {},
      loading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const { data: res } = await this.$http.get("/film/shopGetKjlk12jk");
      this.loading = false;
      if ("200" != res.code) {
        return this.$message.error("获取用户余额失败:" + res.message);
      }
      this.fromMes = res.message;
    },
  },
};
</script>

<style lang="less" scoped>
@titleWidth: 120px;
.view_main {
  padding: 24px;
  .div_shopinfo {
    .div_single {
      display: flex;
      padding-bottom: 5px;
      .single_title {
        // width: 100px;
        width: @titleWidth;
        text-align: right;
        padding-right: 15px;
      }
    }
  }
  .div_income {
    .div_income_single {
      margin-bottom: 20px;
      background: #ccc;
      border-radius: 10px;
      padding: 5px 0;
      .div_single_mes {
        display: flex;
        padding-bottom: 5px;
        .div_single_mes_title {
          width: @titleWidth;
          text-align: right;
          padding-right: 15px;
        }
        .div_single_mes_mes {
        }
      }
    }
  }
}
</style>
