// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineLabel/';

/**
 * 新增标签
 * @param {*} params
 */
export async function addLabel(data={}) {
  const { data: res } = await axios.post(
    path + 'addLabel',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "新增标签失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 根据小程序表id获取小程序页面展示列表
 * @param {*} params
 */
 export async function getListBySmallRoutineId(smallRoutineId) {
  const { data: res } = await axios.get(
    path + 'getListBySmallRoutineId?id='+smallRoutineId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "获取小程序页面展示列表失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改文字内容
 * @param {*} params
 */
 export async function updateSrcById(data={}) {
  const { data: res } = await axios.put(
    path + 'updateSrcById',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: "修改文字内容失败:" + res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 删除展示内容
 * @param {*} params
 */
 export async function delLabel(id) {
  const { data: res } = await axios.delete(
    path + 'del?id=' + id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


