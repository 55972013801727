
<template>
  <div>
    <el-table
        :data="messages"
        style="width: 100%"
        height="600">
      <el-table-column
          fixed="left"
          label="结果"
          width="100">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.operateMessage === '成功'">成功</el-tag>
          <el-tag type="danger" v-else>失败</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          fixed="left"
          prop="appId"
          label="AppId"
          width="200">
      </el-table-column>
      <el-table-column
          fixed="left"
          prop="name"
          label="小程序名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="menuPageName"
          label="服务提报名"
          width="200">
      </el-table-column>
      <el-table-column
          prop="gmtCreate"
          label="该条记录的创建时间"
          width="200">
      </el-table-column>
      <el-table-column
          fixed="right"
          prop="operateMessage"
          label="执行结果"
          width="300">
      </el-table-column>
      <el-table-column
          fixed="right"
          prop="time"
          label="执行时间"
          width="200">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>

export default {
  props: [
    "messages",
  ],
  data() {
    return {


    }
  },
  created() {

  },
  methods: {


  }
}
</script>

<style scoped lang="less">

</style>