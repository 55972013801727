
<template>
  <div>

    <el-divider content-position="left">
      <span class="Span">类别选择</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAll1" @change="handleCheckAllChange1">全选</el-checkbox>
      <el-button size="medium" type="primary" @click="switchover()" style="margin-left: 30px">{{ icpStatus }}</el-button>
      <div style="margin: 15px 0;"></div>
      <el-checkbox-group v-model="checkedCities1" @change="handleCheckedCitiesChange1">
        <el-checkbox v-for="city in allCategoryList" :label="city" :key="city.id" style="margin-left: 20px; margin-top: 10px; width: 150px">
          {{city.name}}</el-checkbox>
      </el-checkbox-group>
    </div>

    <el-divider content-position="left" class="deploy">
      <span class="Span">选择第三方</span>
    </el-divider>
    <div>
      <el-checkbox v-model="checkAll" @change="handleCheckAllChange" class="checkboxClass">全选</el-checkbox>
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox class="checkboxClass" border size="medium" v-for="city in tbThirdPartySmallRoutineType" :label="city" :key="city.id">
          {{city.name}}
        </el-checkbox>
      </el-checkbox-group>
    </div>

    <el-divider content-position="left" class="deploy">
      <span class="Span">首页自动跳转</span>
    </el-divider>
    <jumpToH5 :checkedCities="checkedCities" :batchClassList="checkedCities1"></jumpToH5>

    <el-divider content-position="left" class="deploy">
      <span class="Span">生活号</span>
    </el-divider>
    <deploySpan :checkedCities="checkedCities" :batchClassList="checkedCities1"></deploySpan>

    <el-divider content-position="left" class="deploy">
      <span class="Span">其他功能</span>
    </el-divider>
    <isShowPop :checkedCities="checkedCities" :batchClassList="checkedCities1"></isShowPop>

    <el-divider content-position="left" class="deploy">
      <span class="Span">广告图片</span>
    </el-divider>
    <advertiseImage :checkedCities="checkedCities" :batchClassList="checkedCities1"></advertiseImage>

    <el-divider content-position="left" class="deploy">
      <span class="Span">云码</span>
    </el-divider>
    <isShowYunma :checkedCities="checkedCities" :batchClassList="checkedCities1"></isShowYunma>

    <el-divider content-position="left" class="deploy">
      <span class="Span">淘宝客</span>
    </el-divider>
    <isShowTaobao :checkedCities="checkedCities" :batchClassList="checkedCities1"></isShowTaobao>

    <el-divider content-position="left" class="deploy">
      <span class="Span">小程序客服</span>
    </el-divider>
    <optionSpanKf :checkedCities="checkedCities" :batchClassList="checkedCities1"></optionSpanKf>

    <el-divider content-position="left" class="deploy">
      <span class="Span">底部导航栏</span>
    </el-divider>
    <bottomNavigation :checkedCities="checkedCities" :batchClassList="checkedCities1"></bottomNavigation>

    <el-divider content-position="left" class="deploy">
      <span class="Span">小商城</span>
    </el-divider>
    <smallMallSwiperJson :checkedCities="checkedCities" :batchClassList="checkedCities1"></smallMallSwiperJson>

    <el-divider content-position="left" class="deploy">
      <span class="Span">首页配置</span>
    </el-divider>
    <homeSwiperJson :checkedCities="checkedCities" :batchClassList="checkedCities1"></homeSwiperJson>

    <el-divider content-position="left" class="deploy">
      <span class="Span">记账本</span>
    </el-divider>
    <accountBookSwiperJson :checkedCities="checkedCities" :batchClassList="checkedCities1"></accountBookSwiperJson>

    <el-divider content-position="left" class="deploy">
      <span class="Span">只有轮播图的首页</span>
    </el-divider>
    <indexSwiperJson :checkedCities="checkedCities" :batchClassList="checkedCities1"></indexSwiperJson>

    <el-divider content-position="left" class="deploy">
      <span class="Span">商城</span>
    </el-divider>
    <shoppingMall :checkedCities="checkedCities" :batchClassList="checkedCities1"></shoppingMall>

    <el-divider content-position="left" class="deploy">
      <span class="Span">自定义广告第一条</span>
    </el-divider>
    <isCustomAdvertise :checkedCities="checkedCities" :batchClassList="checkedCities1"></isCustomAdvertise>

    <el-divider content-position="left" class="deploy">
      <span class="Span">粉丝群</span>
    </el-divider>
    <isShowFanGroup :checkedCities="checkedCities" :batchClassList="checkedCities1"></isShowFanGroup>

    <el-divider content-position="left" class="deploy">
      <span class="Span">口令红包</span>
    </el-divider>
    <pwdRedPacket :checkedCities="checkedCities" :batchClassList="checkedCities1"></pwdRedPacket>

    <div class="divBottom"></div>
  </div>
</template>

<script>
  import deploySpan from "./deploySpan/index.vue";
  import optionSpanKf from "./optionSpanKf/index.vue";
  import bottomNavigation from "./bottomNavigation/index.vue";
  import isShowPop from "./isShowPop/index.vue";
  import jumpToH5 from "./jumpToH5/index.vue";
  import advertiseImage from "./advertiseImage/index.vue";
  import isShowYunma from "./isShowYunma/index.vue";
  import isShowTaobao from "./isShowTaobao/index.vue";
  import isShowFanGroup from "./isShowFanGroup/index.vue";
  import isCustomAdvertise from "./isCustomAdvertise/index.vue";
  import smallMallSwiperJson from "./smallMallSwiperJson/index.vue";
  import homeSwiperJson from "./homeSwiperJson/index.vue";
  import accountBookSwiperJson from "./accountBookSwiperJson/index.vue";
  import indexSwiperJson from "./indexSwiperJson/index.vue";
  import shoppingMall from "./shoppingMall/index.vue";
  import pwdRedPacket from './pwdRedPacket/index.vue';

  export default {
    components:{
      deploySpan, optionSpanKf, bottomNavigation, isShowPop, jumpToH5,
      advertiseImage, isShowYunma, isShowTaobao, isShowFanGroup, isCustomAdvertise,
      smallMallSwiperJson, homeSwiperJson, accountBookSwiperJson, indexSwiperJson, shoppingMall,
      pwdRedPacket
    },
    props: [
      'appletType', //第三方类型 Type
    ],
    data() {
      return {
        //类别多选选
        checkAll1: true,
        allCategoryList: [], //显示所有类别
        icpStatus: "已备案",
        checkedCities1: [], //选中的类别数据

        //第三方多选项
        tbThirdPartySmallRoutineType:[],
        checkAll: true,
        checkedCities: [],
      }
    },
    methods: {
      /**
       * 类别多选项
       */
      switchover() {
        this.icpStatus = this.icpStatus === "已备案"? "未备案" : "已备案";
        this.getBatchClassServiceList();
      },
      //获取全部的类别（区分已备案和未备案）
      async getBatchClassServiceList() {
        console.log("this.appletType", this.appletType)
        const { data: res } = await this.$http.get(
            "/tb-third-party-wang-batch-class/getBatchClassServiceList?appletType=" + this.appletType + "&icpStatus=" + this.icpStatus
        )
        if(res.code == 200){
          this.checkedCities1 = res.message;
          this.allCategoryList = res.message;
        }else{
          this.$message.error("获取类别失败");
        }
      },
      handleCheckAllChange1(val) {
        this.checkedCities1 = val ? this.allCategoryList : [];
      },
      handleCheckedCitiesChange1(value) {
        let checkedCount = value.length;
        this.checkAll1 = checkedCount === this.allCategoryList.length;
      },

      /**
       * 第三方多选项
       */
      async getAllType() {
        const { data: res } = await this.$http.get("/thirdPartySmallRoutineType/getAllType");
        if(res.code == "200"){
          this.tbThirdPartySmallRoutineType = res.message;
          this.checkedCities = res.message;
        }else{
          this.$message.error("获取列表失败");
        }
        // console.log(this.tbThirdPartySmallRoutineType)
      },
      handleCheckAllChange(val) {
        this.checkedCities = val ? this.tbThirdPartySmallRoutineType : [];
      },
      handleCheckedCitiesChange(value) {
        let checkedCount = value.length;
        this.checkAll = checkedCount === this.tbThirdPartySmallRoutineType.length;
      },
    },
    mounted() {
      this.getAllType();
      this.getBatchClassServiceList();
    }
  }
</script>

<style scoped lang="less">
.checkboxClass{
  margin-top: 20px;
  width: 150px;
  margin-left: 10px;
}
.deploy{
  margin-top: 100px;
  margin-bottom: 30px;
}

.Span{
  color: #ff8b8b;
  font-size: 20px;
  font-weight: bold;
}

.divBottom{
  margin: 100px;
}
</style>
