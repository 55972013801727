
<template>
  <div>
    <h3 style="color: #dd6161">注：无需选择 类别或第三方，这是覆盖性删除</h3>
    <div class="block">
      <span class="demonstration">未选择则删除全部；若需要删除某个时间段,请选择：</span>
        <el-date-picker
            v-model="dateTimeList"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
        ></el-date-picker>
    </div>
    <div>
      <span class="spantext1">总删除的数量：{{sum}}</span>
      <span class="spantext2">已执行的数量：{{done}}</span>
      <span class="spantext2">成功的数量：{{succeedCount}}</span>
      <span class="spantext3">失败的数量：{{messages.length - succeedCount}}</span>
    </div>
    <div style="margin-top: 20px">
      <el-button class="but" type="primary" plain :loading="useDeployLoading" @click="multiDeleteServiceApply()">{{ useDeployLoading? '加载中' : '执行删除' }}</el-button>
      <el-button class="but" type="danger" plain @click="ceaseTask()">强制终止执行</el-button>
      <el-button class="but" type="info" plain @click="dialogVisible = true">查看执行的全部结果</el-button>
      <el-dialog
          title="查看执行的全部结果"
          :visible.sync="dialogVisible"
          width="80%"
          append-to-body>
        <exhibitionMessagesTheSecond :messages="messages"></exhibitionMessagesTheSecond>
      </el-dialog>
      <el-button style="background-color: #6c6c6c; color: #ffffff" plain @click="getTask">刷新↕获取上一次执行的结果</el-button>
    </div>
  </div>
</template>

<script>
import exhibitionMessagesTheSecond from "../gatherInformation/exhibitionMessagesTheSecond.vue";

let intervalId = null

export default {
  components: {exhibitionMessagesTheSecond},
  props: [
      "multiDeleteServiceApplyRedisKey",
      "multiDeleteServiceApplyLock"
  ],
  data() {
    return {
      //进度  1.总任务数  2.已执行数  3.成功数
      sum: 0,
      done: 0,
      succeedCount: 0,

      //加载中
      useDeployLoading: false,
      //异步任务的标识
      redisKey: null,

      //记录执行的结果
      messages:[],
      //弹出对话框
      dialogVisible: false,

      //时间选择
      dateTimeList: '',
    }
  },
  methods: {
    //查询历史记录
    async getTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/getTask?taskId=" + this.multiDeleteServiceApplyRedisKey
      );
      if (res.message != null) {
        //总任务数
        this.sum = res.message.taskSum? res.message.taskSum : '';
        //已执行的数量
        this.done = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
        //失败信息列表
        this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
        //计算出成功数
        this.succeedCount = this.messages.filter(function(message) {
          return message.operateMessage === "成功";
        }).length;
      }
    },

    //执行删除
    async multiDeleteServiceApply() {
      //初始操作  1.按钮加载中  2.清空总任务数  3.清空已执行数  4.清空成功数
      this.useDeployLoading = true
      this.sum = 0;
      this.done = 0;
      this.succeedCount = 0;

      console.log("dateTimeList", this.dateTimeList)
      //执行删除
      const { data: res } = await this.$http.get(
          "/thirdParty2/multiDeleteServiceApply?dateTimeList=" + this.dateTimeList
      );
      if (200 === res.code) {
        this.$message.warning("「批量删除服务提报」已提交，等待审核结果");
      } else {
        this.$message.error("「批量删除服务提报」提交失败：" + res.message);
        this.useDeployLoading = false
      }

      this.redisKey = res.message?.redisKey;
      this.sum = res.message?.taskSum;

      //查询进度
      this.startTaskProgress();
    },

    //轮询查 异步任务的进度
    async startTaskProgress() {
      intervalId = setInterval(async () => {
        const { data: res } = await this.$http.get(
            "/redisTasksBufferController/getTask?taskId=" + this.redisKey
        );
        console.log("删除进度：", res.message)
        if (res.message != null) {
          //已执行的数量
          this.done = res.message.auditedAccomplishCount? res.message.auditedAccomplishCount : '';
          //失败信息列表
          this.messages = res.message?.multiAuditApplySbuMsgVoList? res.message?.multiAuditApplySbuMsgVoList : [];
          //计算出成功数
          this.succeedCount = this.messages.filter(function(message) {
            return message.operateMessage === "成功";
          }).length;
        }

        //所有任务进度完成
        if (this.done == this.sum) {
          //解除按钮等待状态
          this.useDeployLoading = false;
          //清除定时器
          clearInterval(intervalId);
          //触发父组件
          this.$emit('updateGetList');
          this.$message.success("「批量删除服务提报」执行完成");
        }
      }, 5000); // 每5秒查询一次
    },

    //强制终止执行
    async ceaseTask() {
      const { data: res } = await this.$http.get(
          "/redisTasksBufferController/ceaseTask?lock=" + this.multiDeleteServiceApplyLock
      );
      console.log("终止res", res);

      //解除按钮等待状态
      this.useDeployLoading = false;
      //清除定时器
      clearInterval(intervalId);
      //触发父组件
      this.$emit('updateGetList');
      this.$message.success("强制终止执行成功");
    },

  }
}
</script>

<style scoped lang="less">
.spantext1{
  color: #34a4f4;
  font-size: 20px;
  margin-right: 50px;
}
.spantext2{
  color: #5daf34;
  font-size: 20px;
  margin-right: 50px;
}
.spantext3{
  color: #ff0000;
  font-size: 20px;
  margin-right: 50px;
}
.but{
  margin-right: 10px;
}
.block{
  margin: 20px 0 20px 0;
  .demonstration{
    margin-right: 10px;
    font-size: 16px;
  }
}
</style>