// 接口请求地址
const path = 'thirdPartyExamine/';
import axios from 'axios'

/**
 * 上传版本
 */
export async function upload(appId) {
  const { data: res } = await axios.post(
    path + 'upload',
    {
      appId: appId
    }
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}



