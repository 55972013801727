<template>
  <div class="div_main">
    <el-radio-group v-model="labelPosition" size="small">
      <el-radio-button label="left">左对齐</el-radio-button>
      <el-radio-button label="right">右对齐</el-radio-button>
      <el-radio-button label="top">顶部对齐</el-radio-button>
    </el-radio-group>
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="commodityForm"
      :model="form"
      label-width="80px"
      :rules="commodityRules"
      :label-position="labelPosition"
    >
      <el-form-item label="商品图片">
        <uploadImg ref="uploadImg" :chooseImg="chooseImg" />
      </el-form-item>
      <el-form-item label="商品名称" prop="name">
        <el-input v-model="form.name" maxlength="50" show-word-limit></el-input>
      </el-form-item>
      <el-form-item label="详细介绍" prop="introduce">
        <el-input
          type="textarea"
          v-model="form.introduce"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
      <el-row>
        <el-col :span="8">
          <el-form-item label="商品分类" prop="type" class="formType">
            <el-select
              filterable
              v-model="form.type"
              placeholder="请选择商品分类"
              @change="selectTypeChange"
            >
              <el-option
                v-for="(item, index) in commodityTypeList"
                :key="'commodityType_' + index"
                :label="item.name"
                :value="item.id"
              ></el-option>
              <el-option label="新增分类" value="-1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="1"> <p></p></el-col>
        <el-col :span="8" v-if="this.form.type == '-1'">
          <el-form-item label="新建分类" prop="newType">
            <el-input
              type="text"
              placeholder="请输入新分类"
              v-model="form.newType"
              maxlength="4"
              show-word-limit
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="商品标签" prop="label">
        <el-checkbox-group v-model="form.label">
          <el-checkbox
            v-for="(item, index) in commodityLabelList"
            :key="'commodityLabel_' + index"
            :label="item.id"
            name="commodityLabel"
            >{{ item.name }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="商品价格" prop="price">
        <el-input
          v-model="form.price"
          maxlength="10"
          show-word-limit
          @input="priceFormat"
        ></el-input>
      </el-form-item>
      <el-form-item label="商品排序" prop="sort">
        <el-input
          v-model="form.sort"
          maxlength="9"
          show-word-limit
          placeholder="数字越小排序越靠前，没填默认排序在最后面(999999999)"
          @input="sortFormat"
        ></el-input>
      </el-form-item>
      <el-form-item label="是否上架" prop="sell">
        <el-switch v-model="form.sell"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          :loading="formIsSubmit"
          @click="submitForm('commodityForm')"
          >保存
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import uploadImg from "../module/UploadImg.vue";
export default {
  props: ["refreshData", "updateId"],
  name: "Commodity_update",
  components: { uploadImg },
  data() {
    return {
      // 表格提交内容
      labelPosition: "right",
      // 商品分类列表
      commodityTypeList: [],
      // 商品标签列表
      commodityLabelList: [],
      form: {
        id: "",
        imgUrl: "",
        name: "",
        label: [],
        sell: true,
        introduce: "",
        type: "",
        price: "",
        newType: "",
        sort: "",
      },
      // 表格提交验证
      commodityRules: {
        name: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "长度在 1 到 50 个字符",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择商品分类", trigger: "change" },
        ],
        newType: [
          { required: true, message: "请输入商品分类名称", trigger: "blur" },
          {
            min: 1,
            max: 4,
            message: "长度在 1 到 3 个字符",
            trigger: "blur",
          },
        ],
        price: [
          {
            required: true,
            message: "请填写正确的金额,保留两位小数",
            trigger: "blur",
          },
        ],
      },
      // 表单是否提交中
      formIsSubmit: false,
      // 是否新建表格分类
      isAddType: false,
    };
  },
  created() {
    this.getFormList();
    this.getMesById(this.updateId);
  },
  methods: {
    // 商品页面点击编辑，获取页面信息
    refreshMes(updateId) {
      this.getMesById(updateId);
    },
    // 根据id获取商品信息
    async getMesById(id) {
      const { data: res } = await this.$http.get("commodity/getById?id=" + id);
      if (res.code != 200) {
        this.$message.error("获取商品信息失败:" + res.message);
        return;
      }
      this.form = res.message;
      this.$refs.uploadImg.changeImageUrl(this.form.imgUrl);
    },
    priceFormat(e) {
      this.form.price = this.form.price.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.form.price)) {
        this.form.price = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.form.price.length >= 2
      ) {
        this.form.price = this.form.price.replace(/0/, "");
      }
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let form = this.form;
      if (form.imgUrl == "") {
        this.$message.warning("请上传商品图片");
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("commodity/update", form);
      loading.close();
      if (res.code != 200) {
        this.$message.error("操作失败:" + res.message);
        return;
      }
      this.$message.success("操作成功");
      // 更新商品分类与标签信息
      await this.getFormList();
      // 商品分类如果是新增则默认选中新增的分类
      if (form.type == -1) {
        let typeName = form.newType;
        let commodityTypeList = this.commodityTypeList;
        for (let index = 0; index < commodityTypeList.length; index++) {
          const type = commodityTypeList[index];
          if (typeName == type.name) {
            form.type = type.id;
            form.newType = "";
            break;
          }
        }
      }
      // 更新商品页面表格信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
      // 清除选中的图片
      this.$refs.uploadImg.clearMes();
      // 清除form的图片属性
      this.form.imgUrl = "";
    },
    // 商品分类变更选中
    selectTypeChange() {
      this.form.newType = "";
    },
    // 获取商品分类与标签列表
    async getFormList() {
      const { data: resType } = await this.$http.get("commodityType/list");
      if (resType.code != 200) {
        this.$message.error("获取商品分类失败");
        return;
      }
      this.commodityTypeList = resType.message;
      const { data: resLabel } = await this.$http.get("commodityLabel/list");
      if (resLabel.code != 200) {
        this.$message.error("获取商品标签失败");
        return;
      }
      this.commodityLabelList = resLabel.message;
    },
    chooseImg(e) {
      this.form.imgUrl = e;
    },
    // 排序正则表达式
    sortFormat(e) {
      this.form.sort = this.form.sort.match(/^\d*(\.?\d{0,0})/g)[0] || null;
      if (isNaN(this.form.sort)) {
        this.form.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.form.sort.length >= 2) {
        this.form.sort = this.form.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
