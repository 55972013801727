// 接口请求地址
const path = 'aliOpenMiniNiniAppBrand/';
import axios from 'axios'

/**
 * 分页查询
 */
export async function list(data = {}) {
  const { data: res } = await axios.post(
    path + 'list',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改状态
 */
 export async function updateStatus(data = {}) {
  const { data: res } = await axios.put(
    path + 'updateStatus',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}


