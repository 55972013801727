<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
    >
      <el-form-item label="小程序" prop="appId">
        <el-select
          v-model="form.appId"
          placeholder="请选择小程序"
          @change="appIdChange"
        >
          <el-option
            v-for="item in allSmallRoutines"
            :key="item.appId"
            :label="item.name"
            :value="item.appId"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="服务信息" prop="serviceCode">
        <el-select v-model="form.serviceCode" placeholder="请选择服务信息">
          <el-option
            v-for="item in allPromotionServices"
            :key="item.serviceName"
            :label="item.serviceName"
            :value="item.serviceCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { getAllPromotionServices } from "@/services/thirdParty/openAppService";
import { apply } from "@/services/thirdParty/openSearchBox";
import { getAllSmallRoutineList } from "@/services/thirdParty/smallRoutine/smallRoutine";
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "appId"],
  data() {
    return {
      form: {
        appId: "",
        serviceCode: "",
      },
      formRules: {
        appId: [{ required: true, message: "请选择小程序", trigger: "change" }],
        serviceCode: [
          { required: true, message: "请选择服务", trigger: "change" },
        ],
      },
      allPromotionServices: [],
      allSmallRoutines: [],
      oldMes: {
        appId: "",
        serviceCode: "",
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.$set(this.oldMes, "appId", this.appId);
      this.$set(this.form, "appId", this.appId);
      // 获取已通过的服务列表
      let loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      await this.getServices();
      await this.getSmallRoutines();
      loading.close();
    },
    // 获取服务列表
    async getServices() {
      this.$set(this.form, "serviceCode", "");
      let res = await getAllPromotionServices(this.form.appId);
      if (!res.success) {
        this.$set(this.form, "appId", this.oldMes.appId);
        this.$set(this.form, "serviceCode", this.oldMes.serviceCode);
        return this.$message.error(res.err);
      }
      let data = res.data;
      if (data.length == 0) {
        this.$message.warning("当前小程序还未有服务通过");
      }
      this.$set(this, "allPromotionServices", data);
    },
    // 获取小程序列表
    async getSmallRoutines() {
      let res = await getAllSmallRoutineList();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      console.log(res);
      this.$set(this, "allSmallRoutines", res.data);
    },
    appIdChange() {
      this.$set(this.oldMes, "serviceCode", this.form.serviceCode);
      this.getServices();
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await apply(this.form);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新页面信息
      this.refaushList();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
