<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.name"
                clearable
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column
          label="名称"
          prop="name"
          width="200"
          fixed
        ></el-table-column>
        <el-table-column
          label="appId"
          prop="appId"
          width="200"
        ></el-table-column>
        <el-table-column
          label="唯一标识"
          prop="code"
          width="200"
        ></el-table-column>
        <!-- <el-table-column
          label="所属公司"
          prop="companyName"
          v-if="'1' == roleGrade"
          width="200"
        ></el-table-column> -->
        <!-- <el-table-column
          label="创建人"
          prop="createBy"
          width="200"
        ></el-table-column> -->
        <el-table-column
          label="跳转h5"
          prop="jumpToH5StatusText"
        ></el-table-column>
        <el-table-column label="状态" prop="status" width="80" fixed="right">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="配置" width="300" fixed="right">
          <template slot-scope="scope">
            <!-- 配置信息 -->
            <el-tooltip
              effect="dark"
              content="配置信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-setting"
                size="mini"
                @click="showSetting(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 消息推送 -->
            <el-tooltip
              effect="dark"
              content="消息模板"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-chat-dot-square"
                size="mini"
                @click="messageTemplate(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 关注生活号 -->
            <el-tooltip
              effect="dark"
              content="关注生活号"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-s-check"
                size="mini"
                @click="lifeRelationVisibleShow(scope.row.id)"
              ></el-button>
            </el-tooltip>
            <!-- 服务管理 -->
            <el-tooltip
              effect="dark"
              content="服务管理"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-guide"
                size="mini"
                @click="serviceManagement(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 周边服务管理 -->
            <el-tooltip
              effect="dark"
              content="周边服务管理"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="warning"
                icon="el-icon-map-location"
                size="mini"
                @click="peripheralServiceVisibleShow(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="150">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <smallRoutine2Add :refaushList="getList" ref="addRef" />
    </el-dialog>
    <!-- 编辑信息 -->
    <el-dialog
      title="编辑"
      :visible.sync="updateDialogVisible"
      width="50%"
      @close="updateDialogClose"
    >
      <smallRoutine2Update
        :refaushList="getList"
        :updateId="updateId"
        ref="updateRef"
      />
    </el-dialog>
    <!-- 配置信息 -->
    <el-dialog
      title="配置信息"
      :visible.sync="updateSettingVisible"
      width="50%"
    >
      <smallRoutine2Configurater
        :refaushList="getList"
        :updateCode="updateCode"
        ref="updateConfiguraterRef"
      />
    </el-dialog>
    <!-- 关联生活号 -->
    <el-dialog
      title="关联生活号"
      :visible.sync="lifeRelation.visible"
      width="50%"
    >
      <smallRoutine2LifeNumber
        :smallId="lifeRelation.smallId"
        ref="smallRoutine2LifeNumber"
      />
    </el-dialog>
    <!-- 周边服务管理 -->
    <el-dialog
      title="周边服务管理"
      :visible.sync="peripheralService.visible"
      width="50%"
    >
      <smallRoutine2PeripheralService
        :updateId="peripheralService.id"
        ref="smallRoutine2PeripheralServiceRef"
      />
    </el-dialog>
  </div>
</template>

<script>
import smallRoutine2Add from "./smallRoutine2Add.vue";
import smallRoutine2Update from "./smallRoutine2Update.vue";
import smallRoutine2Configurater from "./smallRoutine2Configurater.vue";
import smallRoutine2LifeNumber from "./smallRoutine2LifeNumber.vue";
import smallRoutine2PeripheralService from "./smallRoutine2PeripheralService.vue";
export default {
  components: {
    smallRoutine2Add,
    smallRoutine2Update,
    smallRoutine2Configurater,
    smallRoutine2LifeNumber,
    smallRoutine2PeripheralService,
  },
  data() {
    return {
      roleGrade: "", //角色等级
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        query: "",
        status: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 是否展示编辑弹窗
      updateDialogVisible: false,
      // 编辑的对象id
      updateId: "",
      // 是否展示配置弹窗
      updateSettingVisible: false,
      // 编辑的对象code
      updateCode: "",
      // 小程序关联生活号
      lifeRelation: {
        // 是否展示弹出框
        visible: false,
        // 生活号id
        lifeId: "",
        // 小程序id
        smallId: "",
      },
      // 周边服务管理
      peripheralService: {
        // 是否展示弹出框
        visible: false,
        // 修改的表id
        id: "",
      },
    };
  },
  created() {
    this.roleGrade = window.sessionStorage.getItem("roleGrade");
    // 获取当前需要展示的页码及条件(如果是消息模板返回的时候有值，没有则默认从第一页开始)
    this.getQueryInfo();
    this.getList();
  },
  methods: {
    // 获取表格列表查询条件
    getQueryInfo() {
      let query = this.$route.query;
      if (null == query || "" == query || undefined == query) {
        return;
      }
      let queryObj = query.queryInfo;
      if (undefined == queryObj || undefined == queryObj.pageNum) {
        return;
      }
      this.queryInfo = { ...queryObj };
      this.queryInfo2 = { ...queryObj };
    },
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post(
        "/smallRoutine2/list",
        this.queryInfo
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 关闭新增弹窗
    addDialogClose() {
      this.$refs.addRef.resetForm();
    },
    // 关闭编辑弹窗
    updateDialogClose() {
      this.updateId = "";
    },
    // 编辑
    showEditDialog(e) {
      if (this.$refs.updateRef == undefined) {
        this.updateId = e.id;
      } else {
        this.$refs.updateRef.refaushForm(e.id);
      }
      this.updateDialogVisible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "smallRoutine2/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const { data: res } = await this.$http.put(
        `/smallRoutine2/updateStatus`,
        {
          id: e.id,
          status: e.status,
        }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 打开配置
    showSetting(e) {
      if (this.$refs.updateConfiguraterRef == undefined) {
        this.updateCode = e.code;
      } else {
        this.$refs.updateConfiguraterRef.refaushForm(e.code);
      }
      this.updateSettingVisible = true;
    },
    // 消息模板
    messageTemplate(e) {
      let appId = e.appId;
      if (null == appId || "" == appId || undefined == appId) {
        return this.$message.warning("请先设置小程序的appId");
      }
      let code = e.code;
      if (null == code || "" == code || undefined == code) {
        return this.$message.warning("请先设置小程序的唯一标识码");
      }
      this.$router.push({
        path: "/messageTemplate",
        query: {
          appId: appId,
          code: code,
          queryInfo: this.queryInfo,
          from: "/smallRoutine2",
          smallRoutineName: "2",
        },
      });
    },
    // 关联生活号
    async lifeRelationVisibleShow(id) {
      if (this.$refs.smallRoutine2LifeNumber == undefined) {
        this.lifeRelation.lifeId = id;
      } else {
        this.$refs.smallRoutine2LifeNumber.refaushForm(id);
      }
      this.lifeRelation.smallId = id;
      this.lifeRelation.visible = true;
    },
    // 服务管理
    serviceManagement(e) {
      let code = e.code;
      if (null == code || "" == code || undefined == code) {
        return this.$message.warning("请先设置小程序的唯一标识");
      }
      this.$router.push({
        path: "/smallRoutineServiceRel",
        query: {
          code: code,
          queryInfo: this.queryInfo,
          from: "/smallRoutine2",
          smallRoutineName: "2",
        },
      });
    },
    // 周边服务
    peripheralServiceVisibleShow(e) {
      let id = e.id;
      if (this.$refs.smallRoutine2PeripheralServiceRef == undefined) {
        this.peripheralService.id = id;
      } else {
        this.$refs.smallRoutine2PeripheralServiceRef.refaushForm(id);
      }
      this.peripheralService.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
}
</style>
