<template>
  <div>
    <el-form label-width="70px">
      <el-divider content-position="left" v-if="type == 'rotationImg'">
        轮播图
      </el-divider>
      <el-divider content-position="left" v-else-if="type == 'menuList'">
        菜单列表
      </el-divider>
      <el-divider content-position="left" v-else-if="type == 'imageText'">
        图文列表
      </el-divider>
      <div class="small_update_list">
        <div class="small_update_single">
          <div class="small_update_single_img_div">
            <el-upload
              class="avatar-uploader"
              :action="httpUrl + 'upload/uploadFile'"
              :show-file-list="false"
              :on-success="
                (value) => {
                  handleAvatarSuccess(value, updateMes);
                }
              "
              :on-change="onChangeUpload"
              :before-upload="beforeAvatarUpload"
              :headers="headerObj"
              :data="{
                type: 'menuList',
                code: dataModificationCode,
              }"
            >
              <img
                class="small_update_single_img avatar"
                v-if="updateMes.img"
                :src="updateMes.img"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
          <div class="small_update_single_mes">
            <div class="small_update_single_mes_input">
              <el-input
                v-model="updateMes.src"
                placeholder="请输入跳转路径"
              ></el-input>
            </div>
            <div class="small_update_single_mes_input">
              <el-input
                v-if="type == 'menuList'"
                v-model="updateMes.mes"
                placeholder="请输入菜单名称"
                maxlength="5"
                show-word-limit
              ></el-input>
              <el-input
                v-else
                v-model="updateMes.mes"
                placeholder="请输入名称"
                maxlength="10"
                show-word-limit
              ></el-input>
            </div>
            <div class="small_update_single_mes_button">
              <el-button type="primary" style="width: 100%" @click="toUpdate()">
                修改
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "syncLabelUpdate",
  props: ["updateParameter", "refreshList"],
  data() {
    return {
      id: "",
      type: "",
      httpUrl: "",
      updateMes: {
        id: "",
        img: "",
        src: "",
        mes: "",
      },
      headerObj: {}, // token
      // 小程序页面展示数据当前code
      dataModificationCode: "",
    };
  },
  created() {
    this.httpUrl = this.$httpUrl;
    this.headerObj = {
      Authorization: window.sessionStorage.getItem("token"),
    };
    this.refreshMes(this.updateParameter);
  },
  methods: {
    // 刷新id
    async refreshMes(e) {
      this.id = e.id;
      this.type = e.type;
      this.dataModificationCode = e.code;
      let { data: res } = await this.$http.get(
        "/smallRoutineLabel/getLabelMesById",
        {
          params: {
            id: e.id,
          },
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.updateMes = res.message;
    },
    // 图片上传成功
    handleAvatarSuccess(file, item) {
      item.img = file.message.src;
    },
    onChangeUpload() {
      this.dataModificationLoading = !this.dataModificationLoading;
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    // 修改
    async toUpdate() {
      let type = this.type;
      let updateMes = this.updateMes;
      if (this.isNull(updateMes.id) || this.isNull(updateMes.img)) {
        return this.$message.error("参数丢失，请刷新后重试");
      }
      if (this.isNull(updateMes.src)) {
        return this.$message.error("保存失败,请填写完整参数");
      }
      if ("menuList" == type) {
        if (this.isNull(updateMes.mes)) {
          return this.$message.error("保存失败,请填写菜单名称");
        }
      }
      const { data: res } = await this.$http.put(
        `/smallRoutineLabel/editBatchMes`,
        updateMes
      );
      if (res.code != "200") {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      // 刷新表格
      this.refreshList();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped>
// 小程序页面展示编辑
.small_update_list {
  display: flex;
  align-content: center;
  justify-content: center;
  //   flex-wrap: wrap;
  .small_update_single {
    // 单个对象
    display: flex;
    flex-direction: column;
    border: 1px solid #ccc;
    width: 280px;
    margin-left: 10px;
    // 图片
    .small_update_single_img_div {
      /deep/.el-upload {
        width: 100% !important;
        height: 200px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 30px !important;
      }
      .small_update_single_img {
        width: 100%;
        height: 100%;
      }
    }
    // 图片下信息
    .small_update_single_mes {
      width: 100%;
      .small_update_single_mes_input {
        margin: 5px 5px;
      }
      .small_update_single_mes_button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 0 5px 5px 5px;
      }
    }
  }
}
</style>
