<template>
  <div class="div_home">
    <!-- 顶部按钮 -->
    <div class="div_home_title">
      <el-row :gutter="25">
        <!-- 搜索区域 -->
        <!-- 搜索添加 -->
        <div class="head_input">
          <div class="div_input">
            <label class="div_input_text">物料选择:</label>
            <el-select
              v-model="queryInfo.materialId"
              placeholder="请选择搜索的物料商品"
              filterable
              @change="materialChange"
            >
              <el-option
                v-for="item in materialList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <el-button
            v-show="tableList.length > 0"
            type="primary"
            @click="flushTable"
          >
            刷新
          </el-button>
          <el-button
            v-show="tableList.length > 0"
            type="primary"
            @click="getMoreList"
          >
            获取更多
          </el-button>
          <el-button
            v-show="tableList.length > 0"
            type="primary"
            @click="toSave"
          >
            保存
          </el-button>
        </div>
      </el-row>
    </div>
    <!-- 数据内容 -->
    <el-card class="div_card">
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- <el-table-column label="id" prop="id"></el-table-column> -->
        <el-table-column label="状态" prop="status" width="90">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="productUpdate(scope.row, $event)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="商品图片" prop="pict_url" width="130">
          <template slot-scope="scope">
            <img
              class="commodityImg"
              :src="scope.row.pict_url"
              style=""
            /> </template
        ></el-table-column>
        <el-table-column
          label="商品信息-商品标题"
          prop="title"
        ></el-table-column>
        <el-table-column label="店铺名称" prop="shop_title"></el-table-column>
        <el-table-column
          label="商品信息-佣金比率(%)"
          prop="commission_rate"
        ></el-table-column>
        <el-table-column
          label="商品信息-一口价"
          prop="reserve_price"
        ></el-table-column>
        <el-table-column
          label="优惠券（元） 若属于预售商品，该优惠券付尾款可用，付定金不可用"
          prop="coupon_amount"
        ></el-table-column>
        <el-table-column
          label="折扣价（元） 若属于预售商品，付定金时间内，折扣价=预售价"
          prop="zk_final_price"
        ></el-table-column>
        <el-table-column label="销量(30天)" prop="volume"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-tooltip
              effect="dark"
              content="详细信息"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-search"
                size="mini"
                @click="showMessage(scope.row)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <div class="block"></div>
    </el-card>
    <!-- 详情 -->
    <el-dialog
      title="详情"
      :visible.sync="messageVisible.show"
      width="40%"
      @close="messageDialogClose"
    >
      <newProductMessage ref="newProductMessage" :mes="messageVisible.mes" />
    </el-dialog>
  </div>
</template>

<script>
import newProductMessage from "./newProductMessage.vue";
export default {
  components: { newProductMessage },
  props: ["materialList"],
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        materialId: "",
        pageNum: 1, //当前页
        pageSize: 20, //每页最大数
      },
      // 表格中的数据列表
      tableList: [],
      // 是否展示详情弹窗
      messageVisible: {
        mes: "",
        show: false,
      },
      // 修改的数据
      updateList: {
        // 新增的id列表
        add: [],
        // 新增的数据列表
        addMes: [],
        // 删除的id
        del: [],
      },
    };
  },
  created() {},
  methods: {
    // 刷新表格
    flushTable() {
      this.tableList = [];
      this.queryInfo.pageNum = 1;
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.get(
        "/taoBao/tbkDgOptimusMaterial",
        {
          params: { ...this.queryInfo },
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.tableList = [...this.tableList, ...res.message];
    },
    // 物料修改
    materialChange(e) {
      let updateList = this.updateList;
      let add = updateList.add;
      let addMes = updateList.addMes;
      let del = updateList.del;
      let that = this;
      if (add.length != 0 || addMes.length != 0 || del.length != 0) {
        this.$confirm("之前物料有修改为保存是否保存", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            // 保存
            that.toSave();
          })
          .catch(() => {
            // 不保存
            // 清空修改数据内容
            that.updateList = {
              add: [],
              addMes: [],
              del: [],
            };
            // 提示
            that.$message({
              type: "info",
              message: "修改内容已还原",
            });
          });
      }
      // 重置查询条件
      this.queryInfo.materialId = e;
      this.queryInfo.pageNum = 1;
      this.tableList = [];
      // 获取数据
      this.getList();
    },
    // 获取更多数据
    getMoreList() {
      this.queryInfo.pageNum = this.queryInfo.pageNum + 1;
      this.getList();
    },
    // 保存
    async toSave() {
      let updateList = this.updateList;
      let addMes = updateList.addMes;
      let del = updateList.del;
      if (addMes.length == 0 && del.length == 0) {
        return this.$message.warning("数据没有进行修改");
      }
      let materialId = this.queryInfo.materialId;
      if (materialId == null || materialId == undefined || materialId == "") {
        return this.$message.warning("倾向选择物料类型");
      }
      const { data: res } = await this.$http.post(
        "/taoBaoTbkDgOptimusMaterial/updateMes",
        {
          materialId: materialId,
          ...this.updateList,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.updateList = {
        add: [],
        addMes: [],
        del: [],
      };
      this.$message.success("修改成功");
    },
    // 修改状态
    async productUpdate(e, status) {
      let updateList = this.updateList;
      let add = updateList.add;
      let addMes = updateList.addMes;
      let del = updateList.del;
      let itemId = e.item_id;
      if (status) {
        if (del.length > 0) {
          // 本来存在的删除后又新增
          let delIndex = del.indexOf(itemId);
          if (delIndex != -1) {
            del.splice(delIndex, 1);
            return;
          }
        }
        // 新增
        add.push(itemId);
        addMes.push(e);
      } else {
        // 删除
        if (add.length > 0) {
          let addIndex = add.indexOf(itemId);
          if (addIndex != -1) {
            add.splice(addIndex, 1);
            addMes.splice(addIndex, 1);
          } else {
            this.updateList.add(itemId);
          }
        } else {
          del.push(itemId);
        }
      }
    },
    // 查看详情
    showMessage(e) {
      if (this.$refs.newProductMessage == undefined) {
        this.messageVisible = {
          mes: e,
          show: true,
        };
      } else {
        this.$refs.newProductMessage.init(e);
        this.messageVisible = {
          mes: e,
          show: true,
        };
      }
    },
    // 关闭查看详情
    messageDialogClose() {},
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // 顶部按钮
  .div_home_title {
    height: 50px;
    padding-left: 20px;
    display: flex;
    align-items: center;
    .head_input {
      display: flex;
      .div_input {
        display: flex;
        align-items: center;
        // flex: 1;
        width: 350px;
        padding-right: 5px;
        .div_input_text {
          white-space: nowrap;
          padding-right: 5px;
        }
      }
    }
  }
  .div_card {
    height: calc(100% - 70px);
    // width: 100%;
    // 商品图片
    .commodityImg {
      width: 100px;
      height: 100px;
    }
  }
}
</style>
