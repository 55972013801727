<template>
  <div class="div_main">
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页跳转h5</el-divider>
      <!-- 跳转h5 -->
      <el-form-item label="跳转h5" prop="jumpToH5">
        <el-input
          v-model="updateForm.jumpToH5"
          placeholder="请输入跳转h5"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"></el-switch>
      </el-form-item>
      <el-divider content-position="left">首页跳转小程序</el-divider>
      <el-form-item label="小程序appId" prop="jumpToSmallRoutineAppId">
        <el-input
          v-model="updateForm.jumpToSmallRoutineAppId"
          placeholder="请输入小程序appId"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序页面路径" prop="jumpToSmallRoutinePath">
        <el-input
          v-model="updateForm.jumpToSmallRoutinePath"
          placeholder="请输入小程序页面路径(非必填)"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="跳转小程序" prop="jumpToSmallRoutine">
        <el-switch v-model="updateForm.jumpToSmallRoutine"></el-switch>
      </el-form-item>
      <el-divider content-position="left">电影审核页管理</el-divider>
      <!-- 跳转电影小程序 -->
      <el-form-item label="跳转电影" prop="jumpToFilmStatus">
        <el-switch v-model="updateForm.jumpToFilmStatus"></el-switch>
      </el-form-item>
      <!-- 审核页面轮播图 -->
      <el-form-item label="审核页面轮播图" prop="examineHeaderImg">
        <el-switch v-model="updateForm.examineHeaderImg"></el-switch>
      </el-form-item>
      <el-divider content-position="left">其他功能</el-divider>
      <!-- 灯火广告 -->
      <el-form-item label="灯火广告" prop="lightAdStatus">
        <el-switch v-model="updateForm.lightAdStatus"></el-switch>
      </el-form-item>
      <!-- 消息模板 -->
      <el-form-item label="消息模板" prop="message">
        <el-switch v-model="updateForm.message"></el-switch>
      </el-form-item>
      <!-- 消息模板 -->
      <el-form-item label="生活号id" prop="lifeId">
        <el-input
          v-model="updateForm.lifeId"
          placeholder="请输入小程序appId"
          clearable
        ></el-input>
      </el-form-item>
      <!-- poi列表 -->
      <poiChoose :updateForm="updateForm" />
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>
<script>
import {
  getConfiguration,
  updateConfiguration,
} from "@/services/thirdParty/smallRoutine/applet3/configuration.js";
import poiChoose from "../../publicMes/poiChoose.vue";
export default {
  props: ["showSingleId", "poiList"],
  name: "smallroutineConfiguration",
  components: {
    poiChoose,
  },
  data() {
    return {
      updateForm: {
        poiType: [],
      },
      showLoading: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getMes();
    },
    async getMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getConfiguration(this.showSingleId);
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.updateForm = res.data;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转H5开关开启时跳转的h5路径不能为空"
          );
        }
        if (
          updateForm.jumpToSmallRoutine &&
          this.isnull(updateForm.jumpToSmallRoutineAppId)
        ) {
          return this.$message.error(
            "首页自动跳转小程序开关开启时跳转的小程序appId不能为空"
          );
        }
        if (updateForm.jumpToSmallRoutine && updateForm.jumpToH5Status) {
          return this.$message.error("不能同时开启h5与小程序跳转");
        }
        this.save();
      });
    },
    async save() {
      let res = await updateConfiguration(this.updateForm);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("操作成功");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
