<template>
  <div class="div_home">
    <!-- 主体样式 -->
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <el-col :span="1.5">
            <el-button
              icon="el-icon-arrow-left"
              type="primary"
              @click="showMenuList"
            >
              返回菜单列表
            </el-button>
          </el-col>
          <!-- 搜索添加 -->
          <el-col :span="10">
            <el-input
              placeholder="请输入搜索内容"
              v-model="queryInfo.serviceName"
              clearable
              @clear="getNewList"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getNewList"
              ></el-button>
            </el-input>
          </el-col>
        </el-row>
      </div>
      <!-- 列表 border:边框  stripe隔行变色-->
      <el-table :data="tableList" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- 索引列 -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="页面路径" prop="src"></el-table-column>
        <el-table-column label="是否使用该服务" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="addRel(scope.row, 1)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="首页展示" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.showIndex"
              @change="addRel(scope.row, 2)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>
<script>
import {
  getRelList,
  updateShowStatus,
} from "@/services/thirdParty/smallRoutine/applet1/serviceRelService.js";
export default {
  name: "applet1ServiceRel",
  created() {
    this.init();
  },
  props: ["showMenuList", "showSingleId"],
  data() {
    return {
      // 小程序页面传过来的参数(当前页及页码)
      smallRoutineQuery: {},
      // 小程序页面展示数据当前code
      code: "",
      // 来自哪个页面
      from: "",
      smallRoutineName: "",
      queryInfo: {
        //查询信息
        serviceName: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
      },
      tableList: [], // 表格列表
      total: 0, //总记录数
    };
  },
  methods: {
    init() {
      this.getTableList();
    },
    getNewList() {
      this.queryInfo.pageNum = 1;
      this.getTableList();
    },
    // 获取表格列表
    async getTableList() {
      let res = await getRelList({
        ...this.queryInfo,
        smallRoutineId: this.showSingleId,
      });
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.tableList = res.data;
    },
    // 修改状态
    async addRel(e, type) {
      let data = {};
      // type :1 正常关联 2首页展示
      if ("2" == type) {
        data.showIndex = e.showIndex;
      } else {
        data.status = e.status;
      }
      data.id = e.id;
      data.serviceId = e.serviceId;
      data.smallRoutineId = this.showSingleId;
      let res = await updateShowStatus(data);
      if (!res.success) {
        e.status = !e.status;
        return this.$message.error(res.err);
      }
      let rData = res.data;
      e.id = rData.id;
      e.showIndex = rData.showIndex;
      e.status = rData.status;
      this.$message.success("操作成功");
    },
  },
};
</script>
<style lang="less" scoped>
.el-breadcrumb {
  margin-bottom: 15px;
  font-size: 17px;
}
.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/.el-form-item__label {
  width: 100px !important;
}
/deep/.el-form-item__content {
  margin-left: 100px !important;
}
</style>
