<template>
  <!-- 首页吃喝玩乐转盘内容 -->
  <div>
    <el-form
      ref="form"
      :model="updateForm"
      class="demo-ruleForm"
      label-position="left"
      label-width="80px"
    >
      <el-divider content-position="left">转盘信息</el-divider>
      <el-form-item label="图片" prop="img">
        <el-upload
          ref="upload"
          :auto-upload="false"
          class="avatar-uploader"
          :action="
            $httpUrl + 'smallRoutine3LuckDrawEat/updateEatServerImgAndMes'
          "
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="uploadChange"
          :data="{
            ...this.smallRoutineMes,
            ...this.updateForm,
          }"
          :headers="headers"
        >
          <img v-if="updateForm.img" :src="updateForm.img" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="名称" prop="name">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.name"
          placeholder="请输入转盘名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="介绍" prop="mes">
        <el-input
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="3"
          v-model="updateForm.mes"
          placeholder="请输入转盘介绍"
        ></el-input>
      </el-form-item>
      <el-divider content-position="left">转盘内容</el-divider>
      <el-form-item label="内容1" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.one"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容2" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.two"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容3" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.three"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容4" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.four"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容5" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.five"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容6" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.six"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容7" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.seven"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
      <el-form-item label="内容8" prop="one">
        <el-input
          maxlength="10"
          show-word-limit
          v-model="updateForm.eight"
          placeholder="请输入转盘内容"
        ></el-input>
      </el-form-item>
    </el-form>

    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="save" :disabled="disabled"
        >确 定</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["type", "code"],
  name: "smallRoutine3SignInLuckDraSignIn",
  data() {
    return {
      // 当前修改的小程序信息
      smallRoutineMes: {
        serviceType: "",
        smallRoutineCode: "",
      },
      updateForm: {},
      headers: { Authorization: window.sessionStorage.getItem("token") },
      disabled: false,
      imgChange: false,
    };
  },
  created(type, code) {
    this.refaushForm(type, code);
  },
  methods: {
    // 发起请求
    async save() {
      let imgChange = this.imgChange;
      let that = this;
      this.disabled = true;
      let timeOutId = setTimeout(function () {
        that.disabled = false;
      }, 10000);
      if (imgChange) {
        // 图片有更换
        this.$refs.upload.submit();
      } else {
        // 图片没更换
        const { data: res } = await this.$http.put(
          "/smallRoutine3LuckDrawEat/updateEatServerMes",
          {
            ...this.smallRoutineMes,
            ...this.updateForm,
          }
        );
        this.disabled = false;
        if ("200" != res.code) {
          return this.$message.error(res.message);
        }
        this.$message.success("操作成功");
      }
      // 清除定时器
      clearTimeout(timeOutId);
    },
    // 刷新编辑对象
    async refaushForm(newTepe, newCode) {
      this.updateForm = {};
      this.imgChange = false;
      if (undefined == newTepe) {
        newTepe = this.type;
        newCode = this.code;
      }
      this.smallRoutineMes = {
        serviceType: newTepe,
        smallRoutineCode: newCode,
      };
      // // 根据id获取数据
      const { data: res } = await this.$http.get(
        "smallRoutine3LuckDrawEat/getEatServerMes",
        {
          params: this.smallRoutineMes,
        }
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // 图片上传
    handleAvatarSuccess(res) {
      this.disabled = false;
      //上传成功之后清除历史记录
      this.$refs.upload.clearFiles();
      if (res.code != 200) {
        return this.$message.error("文件上传失败：" + res.message);
      }
      this.$message.success("保存成功");
      let that = this;
      setTimeout(function () {
        that.resetForm();
      }, 100);
      this.refaushList();
    },
    // 图片格式与大小校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG || isGIF) && isLt2M;
    },
    // 选择图片
    uploadChange(file, fileList) {
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.upload.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      // 直接赋值页面不渲染，所以用下面方法
      this.$set(this.updateForm, "img", url);
      this.imgChange = true;
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
