<template>
  <div>
    <el-form
      :model="addForm"
      ref="addFormRed"
      :rules="formRules"
      label-width="auto"
    >
      <el-form-item label="appId">
        {{ addForm.appId }}
      </el-form-item>
      <el-form-item label="类目id" prop="model.categoryId">
        <el-input
          v-model="addForm.model.categoryId"
          placeholder="请输入类目id"
          maxlength="10"
          show-word-limit
          clearable
          :disabled="schemaQuery.success"
        ></el-input>
      </el-form-item>
      <el-form-item label="类目名称" prop="model.categoryName">
        <el-input
          v-model="addForm.model.categoryName"
          placeholder="请输入类目名称"
          maxlength="10"
          show-word-limit
          clearable
          :disabled="schemaQuery.success"
        ></el-input>
      </el-form-item>
      <el-form-item label="" v-show="!schemaQuery.success">
        <el-link type="primary" @click="clickCategoryId">
          各个行业场景服务接入资料
        </el-link>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button
        type="primary"
        @click="getSchemaQuery"
        v-show="!schemaQuery.success"
      >
        服务schema下发
      </el-button>
    </div>
    <template v-if="schemaQuery.success">
      <el-form :model="schemaQuery" ref="schemaQueryRef" label-width="auto">
        <el-form-item
          v-for="(item, index) in schemaQuery.data.xml"
          :key="item.id + index"
          :label="item.name"
        >
          <div v-if="item.inputType == 'options'">
            <template v-if="item.valueType == 'value'">
              <!-- 单选 -->
              <el-radio
                v-for="item1 in item.options"
                :key="item1.value"
                :label="item1.value"
                v-model="item.value"
              >
                {{ item1.label }}
              </el-radio>
            </template>
            <template v-else>
              <!-- 复选框 -->
              <el-checkbox-group v-model="item.value">
                <el-checkbox
                  v-for="item1 in item.options"
                  :key="item1.value"
                  :label="item1.label"
                ></el-checkbox>
              </el-checkbox-group>
            </template>
          </div>
          <!-- 输入框 -->
          <el-input
            v-else
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
            clearable
          ></el-input>
          <el-popover
            v-for="(item1, index1) in item.rule"
            :key="item.id + index1"
            placement="top-start"
            :title="item1.type"
            width="400"
            trigger="hover"
            :content="item1.value"
          >
            <el-button slot="reference">{{ item1.type }}</el-button>
          </el-popover>
        </el-form-item>
      </el-form>
      <div class="buttomButton">
        <el-button type="primary" @click="toApply"> 提交 </el-button>
        <el-button type="info" @click="resetSchemaQuery">
          重新选择类目
        </el-button>
      </div>
    </template>
  </div>
</template>

<script>
import { schemaQuery, apply } from "@/services/thirdParty/openAppService";
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "visible", "appId"],
  data() {
    return {
      // 默认类目：计算器
      defaultCategoryId: "C000003559",
      defaultCategoryName: "在线购票",
      imageUrl: "",
      addForm: {
        appId: this.appId,
        model: {
          categoryId: "",
          categoryName: "",
        },
      },
      formRules: {
        "model.categoryId": [
          { required: true, message: "请输入类目id", trigger: "blur" },
        ],
        "model.categoryName": [
          { required: true, message: "请输入类目名称", trigger: "blur" },
        ],
      },
      headers: { Authorization: window.sessionStorage.getItem("token") },
      loading: {},
      // 服务下发数据
      schemaQuery: {
        success: false,
      },
    };
  },
  created() {
    this.$set(this.addForm.model, "categoryId", this.defaultCategoryId);
    this.$set(this.addForm.model, "categoryName", this.defaultCategoryName);
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
      this.$set(this.addForm.model, "categoryId", this.defaultCategoryId);
      this.$set(this.addForm.model, "categoryName", this.defaultCategoryName);
    },
    // 点击提交
    getSchemaQuery() {
      // 表单提交信息校验
      this.$refs["addFormRed"].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await schemaQuery(this.addForm);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.schemaQuery = res;
      this.$message.success("操作成功");
      // this.resetForm();
      // // 刷新页面信息
      // this.refaushList();
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
    // 打开类目id列表链接
    clickCategoryId() {
      window.open("https://opendocs.alipay.com/mini/03ci0w");
    },
    // 页面内容重置
    flushForm() {
      // this.resetForm();
      // this.$set(this.addForm.model, "categoryId", this.defaultCategoryId);
      this.resetSchemaQuery();
    },
    // 重新选择类目
    resetSchemaQuery() {
      this.schemaQuery = {
        success: false,
      };
    },
    // 服务提报
    async toApply() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let categoryName = this.addForm.model.categoryName;
      let res = await apply({
        appId: this.appId,
        category_name: categoryName,
        ...this.schemaQuery.data,
      });
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.schemaQuery = res;
      this.$message.success("操作成功");
      // 信息重置
      this.flushForm();
      // 刷新页面信息
      this.refaushList();
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
