<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">名称:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.name"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <div class="div_input">
              <label class="div_input_text">物料id:</label>
              <el-input
                placeholder="请输入搜索内容"
                v-model="queryInfo2.materialId"
                clearable
                @clear="search"
              >
              </el-input>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <!-- <el-table-column label="id" prop="id"></el-table-column> -->
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="物料id" prop="materialId"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 商品 -->
            <el-tooltip
              effect="dark"
              content="商品"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-s-goods"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="40%"
      @close="addDialogClose"
    >
      <materialManagementAdd
        ref="customerAddRef"
        :refaushList="getList"
        :getMaterialList="getMaterialList"
      />
    </el-dialog>
    <!-- 编辑信息 -->
    <el-dialog
      title="编辑"
      :visible.sync="updateDialogVisible"
      width="40%"
      @close="updateDialogClose"
    >
      <materialManagementUpdate
        :refaushList="getList"
        :updateMes="updateMes"
        ref="customerUpdateRef"
        :getMaterialList="getMaterialList"
      />
    </el-dialog>
  </div>
</template>

<script>
import materialManagementAdd from "./materialManagementAdd.vue";
import materialManagementUpdate from "./materialManagementUpdate.vue";
export default {
  props: ["getMaterialList"],
  components: { materialManagementAdd, materialManagementUpdate },
  data() {
    return {
      // 搜索内容
      queryInfo: {
        //查询信息
        name: "",
        materialId: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 搜索内容(点击搜索后将queryInfo值赋予该对象)
      queryInfo2: {
        //查询信息
        name: "",
        materialId: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 是否展示编辑弹窗
      updateDialogVisible: false,
      // 编辑的对象信息
      updateMes: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    //点击搜索
    async search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.get(
        "/taoBaoCommodityMaterial/list",
        {
          params: { ...this.queryInfo },
        }
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = res.message.records;
      this.pageMes.total = res.message.total;
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 关闭新增弹窗
    addDialogClose() {
      this.$refs.customerAddRef.resetForm();
    },
    // 关闭编辑弹窗
    updateDialogClose() {
      this.updateMes = {};
    },
    // 编辑
    showEditDialog(e) {
      if (this.$refs.customerUpdateRef == undefined) {
        this.updateMes = e;
      } else {
        this.$refs.customerUpdateRef.refaushForm(e);
      }
      this.updateDialogVisible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删除,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "taoBaoCommodityMaterial/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e) {
      const { data: res } = await this.$http.put(
        `/taoBaoCommodityMaterial/updateState`,
        { id: e.id, status: e.status }
      );
      if (res.code != "200") {
        e.status = !e.status;
        return this.$message.error(res.message);
      }
      this.getMaterialList();
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
}
</style>
