
<!-- 这个是用来做 菜单栏的编辑 -->
<template>
<div class="whole_container">
    <div class="top_oprations">
        <el-button  type="primary"  icon="el-icon-folder-add"  @click="show_add_dialog">新建一个模板</el-button>
    </div>
    <div class="menu_templates_container">
        <div    class="menu_template"   v-for="(item,index) in menu_template.list"  :key="index">
            <div    class="menu_template_title">{{item.title}}</div>
            <menu_item_editor   :input_list.sync="item.mainContent"     :is_editable="false"></menu_item_editor>
            <div class="button_list button_list_lift_up">
                <el-button  type="primary"  @click="show_edit_dialog(item)"   icon="el-icon-edit">编辑</el-button>
                <el-button  type="danger"   @click="delete_template(item)"    icon="el-icon-delete">删除</el-button>
            </div>
        </div>
    </div>
    <el-dialog :title="dialog.type_map_title[dialog.type]" :visible.sync="dialog.is_show"   width="1800px"   class="dialog">
        <div    v-if="dialog.is_show">
            <el-form    label-width="100px">
                <el-form-item label="名称">
                    <el-input v-model="dialog.template.title"></el-input>
                </el-form-item>
                <el-form-item label="菜单按钮">
                    <menu_item_editor   :input_list.sync="dialog.template.mainContent"></menu_item_editor>    
                </el-form-item>
            </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="()=>{dialog.is_show = false;}">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </div> 
    </el-dialog>
</div>
</template>

<script>
import axios from 'axios';
import menu_item_editor from './defaultConfig/menu_item_editor.vue'
	
export default{
    components:{
        menu_item_editor
    },
    props: {
        
    },
    data() {
        return {
			httpUrl:this.$httpUrl,
            menu_template:{
                list:[],
            },
            dialog:{
                template:{},
                is_show:false,  //是否展示弹窗
                type:'add',//add or edit 添加东西或者编辑东西
                type_map_title:{    //上面那个属性的映射
                    add:"新增模板",
                    edit:"编辑模板"
                }
            }
        }
    },
    methods:{
        async    initialize_data(){     //初始化数据
            this.menu_template.list = [] ;
            let response   =   await    axios({
                url:this.httpUrl+"tb-third-party-wang-redpacket-menu-template/getAll"
            });
            this.menu_template.list = response.data.message ;
        },
        async    submit(){   //确定更改或者添加某个数据
            let response    = await     axios({
                url:this.$httpUrl+"tb-third-party-wang-redpacket-menu-template/saveOrUpdate",
                method:"POST",
                data:this.dialog.template,
            })
            response = response.data.message;
            if(response){
                this.$message.success("成功添加");
                this.initialize_data();//刷新一下数据
            }else{
                this.$message.error("添加失败");
            }
            this.dialog.is_show = false;
        },
        async   show_edit_dialog(item){   //展示编辑编辑弹窗，并且把数据输送进去
            this.dialog.type = 'edit';
            this.dialog.template = JSON.parse(JSON.stringify(item));
            this.dialog.is_show = true ;
        },
        async   show_add_dialog(){  //展示新增弹窗
            this.dialog.template = {mainContent:'[]'};
            this.dialog.type = 'add';
            this.dialog.is_show = true;
        },
        async   delete_template(item){
            this.$confirm(
                '此操作将删除该模板, 是否继续?','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(async()=>{
                let send_form_data = new FormData();
                send_form_data.append("id",item.id);
                let response = await    axios({
                    url:this.$httpUrl+'tb-third-party-wang-redpacket-menu-template/del',
                    method:'DELETE',
                    data:send_form_data,
                });
                if(response.data.message){
                    this.$message.success("操作成功");
                    this.initialize_data();
                }else{
                    this.$message.error("操作失败");
                }
                this.initialize_data();
            }).catch(
                
            )
        },
    },
    computed:{
			
	},
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
	background-color: #fafafa;
	border-radius: 10px;
	padding: 40px;
    flex: 1;
    display: flex;  flex-direction: column;
    .top_oprations{
        height: 70px;
        display: flex;  align-items: center;
        margin-bottom: 20px;
    }
    .menu_templates_container{
        display: flex;  flex-wrap: wrap;
        .menu_template{
            min-width: 1300px;
            width: 1300px;
            height: 660px;
            margin-bottom: 30px;   margin-right: 30px;
            background-color: #fff;
            border-radius: 10px;
            display: flex;  flex-direction: column; align-items: center;
            position: relative;
            overflow: hidden;
            .menu_template_title{
                margin-top: 40px;   margin-bottom: 30px;
                text-align: center;
                font-size: 50px;  font-weight: bold;
            }
            .button_list{
                position: absolute;
                bottom: -100px;    
                width:  100%;  height: 100px;
                display: flex;  justify-content: space-evenly; align-items: center;
                transition-property: transform;
                transition-duration: 0.5s;
            }
        }
        .menu_template:hover{
            //cursor: pointer;
            //background-color: beige;
            box-shadow: 0 0 40px #ccc;
            .button_list{
                transition-property: transform;
                transition-duration: 0.5s;
            }
            .button_list_lift_up{
                transform: translateY(-100px);
            }
        }

    }
    .dialog{
        display: flex;  flex-direction: column;     align-items: center;
    }
}

</style>