<template>
  <div>
    <el-tree
      :data="treeMes.data"
      show-checkbox
      default-expand-all
      node-key="id"
      ref="tree"
      highlight-current
      :props="defaultProps"
      :default-checked-keys="treeMes.defaultCheckedKeys"
    >
    </el-tree>
    <!-- <div class="buttons">
      <el-button @click="getCheckedNodes">通过 node 获取</el-button>
      <el-button @click="getCheckedKeys">通过 key 获取</el-button>
      <el-button @click="setCheckedNodes">通过 node 设置</el-button>
      <el-button @click="setCheckedKeys">通过 key 设置</el-button>
      <el-button @click="resetChecked">清空</el-button>
    </div> -->
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toSave" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallRoutine3BottomCommodityRel",
  props: ["code"],
  data() {
    return {
      smallRoutineCode: "",
      // 树节点数据
      treeMes: {
        data: [],
        defaultCheckedKeys: [],
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      // 底部保存按钮是否禁用
      disabled: false,
    };
  },
  created() {
    this.refaushForm(this.code);
  },
  methods: {
    // 刷新编辑对象
    async refaushForm(e) {
      this.smallRoutineCode = e;
      this.getTreeMes(e);
    },
    // 获取树节点数据
    async getTreeMes(code) {
      const { data: res } = await this.$http.get(
        "/smallRoutine3BottomCommodityMenuRel/getTreeMes?code=" + code
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.treeMes = res.message;
    },
    // 保存
    async toSave() {
      this.disabled = true;
      let idList = this.$refs.tree.getCheckedKeys();
      const { data: res } = await this.$http.put(
        "/smallRoutine3BottomCommodityMenuRel/saveRel",
        {
          code: this.smallRoutineCode,
          idList: idList,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.disabled = false;
      return this.$message.success("保存成功");
    },
    getCheckedNodes() {
      console.log(this.$refs.tree.getCheckedNodes());
    },
    getCheckedKeys() {
      console.log(this.$refs.tree.getCheckedKeys());
    },
    setCheckedNodes() {
      this.$refs.tree.setCheckedNodes([
        {
          id: 5,
          label: "二级 2-1",
        },
        {
          id: 9,
          label: "三级 1-1-1",
        },
      ]);
    },
    setCheckedKeys() {
      this.$refs.tree.setCheckedKeys([3]);
    },
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
