// 接口请求地址
import axios from 'axios'
const path = 'thirdPartySmallRoutineMessageTemplateKey/';

/**
 * 
 * @param {*} params
 */
export async function getMessageTemplateKeyList(id) {
  const { data: res } = await axios.get(
    path + 'getMessageTemplateKeyList?templateTableId='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}



