// 接口请求地址
const path = 'passwordRedEnvelopeDefault/';
import axios from 'axios'

/**
 * 获取默认配置信息
 */
export async function getMes() {
    const {data: res} = await axios.get(
        path + 'getMes'
    );
    let message = res.message;
    if (res.code != 200) {
        return {
            success: false,
            err: "获取默认配置失败:" + message
        };
    }
    return {
        success: true,
        data: message,
    };
}

/**
 * 编辑(无文件)
 */
export async function updateNoFile(data = {}) {
    const {data: res} = await axios.post(
        path + 'updateNoFile',
        data
    );
    let message = res.message;
    if (res.code != 200) {
        return {
            success: false,
            err: "编辑失败:" + message
        };
    }
    return {
        success: true,
        data: message,
    };
}

/**
 * 编辑(有文件)
 */
export async function updateHasFile(data = {}) {
    const {data: res} = await axios.post(
        path + 'updateHasFile',
        data
    );
    let message = res.message;
    if (res.code !== 200) {
        return {
            success: false,
            err: "编辑失败:" + message
        };
    }
    return {
        success: true,
        data: message,
    };
}


