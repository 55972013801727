<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
      :loading="formIsSubmit"
    >
      <el-form-item label="模板名称" prop="merchantName">
        <el-input
          v-model="form.merchantName"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板appId" prop="merchantAppId">
        <el-input
          disabled
          v-model="form.merchantAppId"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="form.appAuthToken"
          maxlength="50"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="三方应用" prop="isvCode">
        <el-select
          v-model="form.isvType"
          @change="smallRoutineSelectChaneg"
          placeholder="请选择小程序类型"
        >
          <el-option
            v-for="item in smallRoutineMes.nameList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <el-select
          v-model="form.isvCode"
          style="margin-left: 20px"
          placeholder="请选择"
        >
          <el-option
            v-for="item in smallRoutineMes.smallRoutineList[form.isvType]"
            :key="item.name"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <category
        v-if="!isNull(form.id)"
        ref="categoryRef"
        :code="form.isvCode"
        :type="form.isvType"
        :chooseMes="form.category"
        :formMes="form"
        :id="id"
      />
      <el-form-item label="线下授权链接" prop="offlineLink">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="form.offlineLink"
          placeholder="请输入线下授权链接(用于生成推广链接)复制到{标识符}前即可，要保留‘=’号"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          保存
        </el-button>
        <!-- <el-button @click="resetForm('formRef')">重置</el-button> -->
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import category from "../category/category.vue";
export default {
  props: ["refreshData", "id", "visible"],
  name: "applet_add",
  components: {
    category,
  },
  data() {
    return {
      smallRoutineMes: {
        nameList: [
          {
            label: "高德小程序",
            value: "applet1",
          },
          {
            label: "互动小程序",
            value: "applet2",
          },
          {
            label: "电影小程序",
            value: "applet3",
          },
        ],
        smallRoutineList: {
          // 小程序名称：列表
        },
      },
      form: {
        id: "",
        merchantName: "",
        merchantAppId: "",
        appAuthToken: "",
        isvAppId: "",
        isvCode: "",
        status: true,
        // 小程序类型选择
        isvType: "",
        // 线下授权链接
        offlineLink: "",
      },
      // 表格提交验证
      formRules: {
        merchantName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        merchantAppId: [
          { required: true, message: "请输入模板appId", trigger: "blur" },
        ],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        isvCode: [
          { required: true, message: "请选择三方应用", trigger: "change" },
        ],
      },
      // 表单是否提交中
      formIsSubmit: false,
      // 分类列表
      categoryMes: {
        status: false,
        chooseIndex: [""],
        // 所有列表
        list: [],
        // 二级列表
        twoList: [],
        // 三级列表
        threeList: [],
      },
    };
  },
  watch: {
    visible(e) {
      if (e) {
        this.form.id = "";
        this.init();
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    init() {
      this.getMesById();
    },
    async getMesById() {
      let id = this.id;
      const { data: res } = await this.$http.get(
        "thirdSmallRoutineTemplate/getMesById?id=" + id
      );
      if (res.code != "200") {
        return this.$message.error(res.message);
      }
      // 获取小程序分类对应的列表
      let message = res.message;
      this.smallRoutineSelectChaneg(message.isvType);
      this.form = message;
    },
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let form = this.form;
      let offlineLink = form.offlineLink;
      if (!this.isNull(offlineLink)) {
        let split = offlineLink.split("&ticket=");
        if ((split.length1 = 2 && split[1] != "")) {
          return this.$message.warning(
            "线下授权格式校验失败,请以“&ticket=”结尾"
          );
        }
      }
      let category = await this.$refs.categoryRef.getChooseCategoryMes();
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.put(
        "thirdSmallRoutineTemplate/update",
        {
          ...form,
          category: category,
        }
      );
      loading.close();
      if (res.code != 200) {
        this.$message.error("上传失败:" + res.message);
        return;
      }
      this.$message.success("操作成功");
      // 刷新商品页面信息
      this.refreshData();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    // 支付宝类型变化
    async smallRoutineSelectChaneg(e) {
      let form = this.form;
      form.isvCode = "";
      let smallRoutineMes = this.smallRoutineMes;
      let smallRoutineList = smallRoutineMes.smallRoutineList;
      let list = smallRoutineList[e];
      if (this.isNull(list)) {
        // 当前小程序还未获取
        let list = await this.getSmallRoutineList(e);
        smallRoutineList[e] = list;
      }
      this.$set(this.smallRoutineMes, smallRoutineList[e], list);
    },
    // 获取小程序名称与标识列表
    async getSmallRoutineList(e) {
      let res;
      switch (e) {
        case "applet1":
          res = await this.$http.get("/smallRoutine/getSmallNameCodeList");
          break;
        case "applet2":
          res = await this.$http.get("/smallRoutine2/getSmallNameCodeList");
          break;
        case "applet3":
          res = await this.$http.get("/smallRoutine3/getSmallNameCodeList");
          break;
        default:
          this.$message.error("获取小程序名称与标识列表,无效标识:" + e);
          return null;
      }
      return res.data.message;
    },
    clearCategoryMes() {
      this.$refs.categoryRef.clearCategoryMes();
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
