<template>
  <div>
    <el-form
      :model="addForm"
      ref="addFormRed"
      :rules="formRules"
      label-width="auto"
    >
      <el-form-item label="appId">
        {{ addForm.appId }}
      </el-form-item>
      <el-form-item label="提报关键词列表" prop="keyWords">
        <el-input
          type="textarea"
          :rows="3"
          v-model="addForm.keyWords"
          placeholder="请输入提报关键词列表，如果是多个词用' ,(英文逗号) '隔开"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="服务描述	" prop="descprise">
        <el-input
          type="textarea"
          :rows="3"
          v-model="addForm.descprise"
          placeholder="请输入服务描述	"
          maxlength="200"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="是否为草稿态	" prop="isDraft">
        <el-switch v-model="addForm.model.bizData.isDraft"></el-switch>
      </el-form-item>
      <el-form-item label="搜索可见性" prop="canSearch">
        <el-switch
          v-model="addForm.model.bizData.baseItems.canSearch"
        ></el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="toAdd"> 确 定 </el-button>
    </div>
  </div>
</template>

<script>
import { modify } from "@/services/thirdParty/openSearchBaseOrder";
export default {
  name: "bottomCommodityMenuTwoAdd",
  props: ["refaushList", "visible", "appId"],
  data() {
    return {
      imageUrl: "",
      addForm: {
        appId: this.appId,
        keyWords: "",
        model: {
          bizData: {
            isDraft: true,
            baseItems: {
              keyWords: [],
              canSearch: true,
            },
          },
        },
      },
      formRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        keyWords: [
          { required: true, message: "请输入提报关键词列表", trigger: "blur" },
        ],
      },
      headers: { Authorization: window.sessionStorage.getItem("token") },
      loading: {},
    };
  },
  methods: {
    // 清空表单
    resetForm() {
      // 清空表单内容
      this.$refs["addFormRed"].resetFields();
    },
    // 点击提交
    toAdd() {
      // 表单提交信息校验
      this.$refs["addFormRed"].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let form = this.addForm;
      let keyWords = form.keyWords;
      let keyWordsList = keyWords.replace(/，/g, ",");
      this.$set(
        this.addForm.model.bizData.baseItems,
        "keyWords",
        keyWordsList.split(",")
      );
      let res = await modify(form);
      loading.close();
      if (!res.success) {
        this.$message.error(res.err);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm();
      // 刷新页面信息
      this.refaushList();
    },
    // 图片上传
    handleAvatarSuccess(res) {
      this.loading.close();
      //上传成功之后清除历史记录
      this.$refs.upload.clearFiles();
      if (res.code != 200) {
        return this.$message.error("文件上传失败：" + res.message);
      }
      this.$message.success("成功");
      let that = this;
      setTimeout(function () {
        that.imageUrl = "";
        // that.resetForm();
      }, 100);
      this.refaushList();
    },
    // 图片格式与大小校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return (isJPG || isPNG || isGIF) && isLt2M;
    },
    // 选择图片
    uploadChange(file, fileList) {
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.upload.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      this.imageUrl = url;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
    // 排序正则表达式
    sortFormat(e) {
      this.addForm.sort = this.addForm.sort.match(/^\d*(\d{0,0})/g)[0] || null;
      if (isNaN(this.addForm.sort)) {
        this.addForm.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.addForm.sort.length >= 2) {
        this.addForm.sort = this.addForm.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
