
<!-- 这个是用来做 菜单栏的编辑 -->
<template>
    <div class="whole_container">
        <div class="top_oprations">
            <el-button  type="primary"  icon="el-icon-folder-add"  @click="show_add_dialog">新建一个模板</el-button>
        </div>
        <el-table   :data="fanGroupTemplateId.list">
            
            <el-table-column    
                v-for="(item,index) in columns" 
                :width="item.width?'':item.width"    
                :prop="item.prop"    
                :label="item.label"
                :key="index">
                <template slot-scope="scope">
                    <el-popover	
                            width="500"	
                            trigger="hover"	
                            :content="scope.row[item.prop]"
                            v-if="(typeof scope.row[item.prop] == 'string') && scope.row[item.prop].length >40">
                        <span slot="reference">{{scope.row[item.prop].slice(0,40)+'...'}}</span>
                    </el-popover>
                    <span	v-else>{{scope.row[item.prop]}}</span>
                </template>
            </el-table-column>
            <el-table-column    label="是否开启" width="100">
                <template slot-scope="scope">
                    <el-switch
                        v-model="scope.row.status"
                        @change="switchIsOpen(scope.row)">
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column   label="编辑"    width="280">
                <template slot-scope="scope">
                    <el-button   
                        type="primary"  
                        icon="el-icon-edit"
                        @click="show_update_dialog(scope.row)">
                         编辑
                    </el-button>
                    <el-button  
                        type="danger"       
                        icon="el-icon-delete"
                        @click="delete_item(scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="dialog.type_map_title[dialog.type]" :visible.sync="dialog.is_show"   width="1000px"   class="dialog">
            <div    v-if="dialog.is_show">
                <el-form    label-width="120px">
                    <el-form-item   
                        v-for="(item,index) in columns" 
                        :label="item.label"
                        :key="index">    
                        <el-input 
                            v-model="dialog.template[item.prop]"  
                            :disabled="item.is_uneditable && dialog.type=='update'">
                        </el-input>    
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="()=>{dialog.is_show = false;}">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div> 
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
export default{
    components:{
        
    },
    props: {
        
    },
    data() {
        return {
            httpUrl:this.$httpUrl,
            fanGroupTemplateId:{
                list:[],
            },
            dialog:{
                template:{},
                is_show:false,  //是否展示弹窗
                type:'add',//add or update 添加东西或者编辑东西
                type_map_title:{    //上面那个属性的映射
                    add:"新增模板",
                    update:"编辑模板"
                }
            },
            columns:[
                {prop:'id',label:'模板id(必须）',is_need_check:true,is_uneditable:true,width:'320'},
                {prop:'appId',label:'小程序id号（可选）',is_need_check:true,width:'180'},
                {prop:'appName',label:'小程序名字（可选）',width:'200'},
                {prop:'appLink',label:'小程序链接（可选）'},
                {prop:'describeText',label:'描述',width:'250'},
            ]
        }
    },
    methods:{
        async    initialize_data(){     //初始化数据
            this.fanGroupTemplateId.list = [] ;
            let response   =   await    axios({
                url:this.httpUrl+"fanGroupTemplateId/list"
            });
            this.fanGroupTemplateId.list= response.data.message;
        },
        show_update_dialog(item){   //展示编辑编辑弹窗，并且把数据输送进去
            this.dialog.type = 'update';
            this.dialog.template = JSON.parse(JSON.stringify(item));
            this.dialog.is_show = true ;
        },
        show_add_dialog(){  //展示新增弹窗
            this.dialog.template = {};
            this.dialog.type = 'add';
            this.dialog.is_show = true;
        },
        check_form(form){   //检查是否有填写
            let filter_columns = this.columns.filter(e=>e.is_need_check);
            for(let item of filter_columns){
                let input_content = form[item['prop']] ;
                if(!input_content || input_content == ''){
                    this.$message.error("您的"+item['label']+"尚未填写");
                    return false;
                }
            }
            return true;
        },
        async    submit(){  //提交表单
            if(!this.check_form(this.dialog.template)){
                return ;
            }
            let url = this.httpUrl+"fanGroupTemplateId/"+this.dialog.type ;
            let type_map_requestMethod = {
                add:'POST',
                update:'PUT'
            };
            let response = await axios({
                url:url,
                data:this.dialog.template,
                method:type_map_requestMethod[this.dialog.type],
            })
            if(response.data.message){
                this.$message.success("操作成功");
                this.initialize_data();
            }else{
                this.$message.error("操作失败");
            }
            this.dialog.is_show = false ;
        },
        async   delete_item(item){  //删除某一项
            this.$confirm(
                '此操作将删除该模板, 是否继续?','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(async()=>{
                let send_form_data = new FormData();
                send_form_data.append("key",item.key);
                let response = await    axios({
                    url:this.$httpUrl+'fanGroupTemplateId/del/'+item.id,
                    method:'DELETE',
                    //data:send_form_data,
                });
                if(response.data.message){
                    this.$message.success("操作成功");
                    this.initialize_data();
                }else{
                    this.$message.error("操作失败");
                }
                this.initialize_data();
            }).catch(
                
            )
        },
        async    switchIsOpen(row){ //打开开关
            console.log(row);
            let response = await axios({
                url: this.httpUrl+"fanGroupTemplateId/update",
                method:"PUT",
                data:row
            })
            if(response.data.message){
                this.$message.success("操作成功");
            }else{
                this.$message.error("操作失败");
            }
        }
    },
    computed:{
            
    },
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
    background-color: #fafafa;
    border-radius: 10px;
    padding: 40px;
    flex: 1;
    display: flex;  flex-direction: column;
    .el-table{
        overflow-y: auto;
    }
    .top_oprations{
        height: 70px;
        display: flex;  align-items: center;
    }
    .dialog{
        display: flex;  flex-direction: column;     align-items: center;
    }
    
}

</style>