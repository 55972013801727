<template>
  <div>
    <el-form :model="updateForm" label-width="auto">


      <el-form-item label="是否展示菜单按钮列表" prop="isShowMallMenuList">
        <el-switch v-model="updateForm.isShowMallMenuList"></el-switch>
      </el-form-item>
      <el-button :type="loadingA? 'info': 'primary'" round plain @click="updateShoppingMallVA(1)" :loading="loadingA" >
        {{ loadingA? '加载中' : '执 行 选 择 的 类 别' }}
      </el-button>
      <el-button :type="loadingA? 'info': 'primary'" round plain @click="updateShoppingMallVA(2)" :loading="loadingA" >
        {{ loadingA? '加载中' : '执 行 选 择 的 第 三 方' }}
      </el-button>
      <el-divider class="buttonUseDeploy"></el-divider>

      <el-form-item label="是否展示抵用券领取按钮" prop="isShowMallCoupon">
        <el-switch v-model="updateForm.isShowMallCoupon"></el-switch>
      </el-form-item>
      <el-form-item label="抵用券抵扣金额" prop="couponAmount">
        <el-input-number v-model="updateForm.couponAmount" :step="1" size="medium"></el-input-number>
      </el-form-item>
      <el-form-item label="抵用券领取按钮 logo" prop="couponBtnLogo">
        <image_choose :image_url.sync="updateForm.couponBtnLogo"
                      prefix_path="mall/coupon/btn/logo"></image_choose>
      </el-form-item>
      <el-form-item label="抵用券领取按钮标题" prop="couponBtnTitle">
        <el-input v-model="updateForm.couponBtnTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券领取按钮标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="抵用券弹窗标题" prop="couponPopTitle">
        <el-input v-model="updateForm.couponPopTitle" :maxlength="50" show-word-limit placeholder="请输入抵用券弹窗标题" clearable></el-input>
      </el-form-item>
      <el-button :type="loadingB? 'info': 'primary'" round plain @click="updateShoppingMallVB(1)" :loading="loadingB">
        {{ loadingB? '加载中' : '执 行 选 择 的 类 别' }}
      </el-button>
      <el-button :type="loadingB? 'info': 'primary'" round plain @click="updateShoppingMallVB(2)" :loading="loadingB">
        {{ loadingB? '加载中' : '执 行 选 择 的 第 三 方' }}
      </el-button>
      <el-divider class="buttonUseDeploy"></el-divider>

      <el-form-item label="是否展示领取表单按钮" prop="isShowMallForm">
        <el-switch v-model="updateForm.isShowMallForm"></el-switch>
      </el-form-item>
      <el-form-item label="领取表单的标题" prop="mallFormTitle">
        <el-input v-model="updateForm.mallFormTitle" placeholder="请输入领取表单的标题" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示口令红包" prop="isShowMallPwdRedpacket">
        <el-switch v-model="updateForm.isShowMallPwdRedpacket"></el-switch>
      </el-form-item>
      <el-form-item label="是否展示加入粉丝群" prop="isShowMallFanGroup">
        <el-switch v-model="updateForm.isShowMallFanGroup"></el-switch>
      </el-form-item>
      <el-button :type="loadingC? 'info': 'primary'" round plain @click="updateShoppingMallVC(1)" :loading="loadingC">
        {{ loadingC? '加载中' : '执 行 选 择 的 类 别' }}
      </el-button>
      <el-button :type="loadingC? 'info': 'primary'" round plain @click="updateShoppingMallVC(2)" :loading="loadingC">
        {{ loadingC? '加载中' : '执 行 选 择 的 第 三 方' }}
      </el-button>
      <el-divider class="buttonUseDeploy"></el-divider>

      <el-form-item label="商品当前价格说明" prop="mallGoodsPriceDescCurrent">
        <el-input v-model="updateForm.mallGoodsPriceDescCurrent" placeholder="请输入商品当前价格说明(未被划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的原价" prop="isShowMallGoodsPriceOrigin">
        <el-switch v-model="updateForm.isShowMallGoodsPriceOrigin"></el-switch>
      </el-form-item>
      <el-form-item label="商品原始价格说明" prop="mallGoodsPriceDescOrigin">
        <el-input v-model="updateForm.mallGoodsPriceDescOrigin" placeholder="请输入商品原始价格说明(划线的商品价格前的文字)" clearable></el-input>
      </el-form-item>
      <el-form-item label="是否展示商品的购买量" prop="isShowMallGoodsSaleCnt">
        <el-switch v-model="updateForm.isShowMallGoodsSaleCnt"></el-switch>
      </el-form-item>
      <el-button :type="loadingD? 'info': 'primary'" round plain @click="updateShoppingMallVD(1)" :loading="loadingD">
        {{ loadingD? '加载中' : '执 行 选 择 的 类 别' }}
      </el-button>
      <el-button :type="loadingD? 'info': 'primary'" round plain @click="updateShoppingMallVD(2)" :loading="loadingD">
        {{ loadingD? '加载中' : '执 行 选 择 的 第 三 方' }}
      </el-button>
      <el-divider class="buttonUseDeploy"></el-divider>

      <el-form-item label="商城首页要展示的商品列表" prop="mallIndexGoodsList">
        <mall_goods_list	:input_list.sync="updateForm.mallIndexGoodsList"></mall_goods_list>
      </el-form-item>
      <el-form-item label="商城首页轮播图条轮的h5路径">
        <el-input v-model="updateForm.mallIndexSwiperH5Url" placeholder="请输入跳转h5链接"></el-input>
      </el-form-item>
      <el-form-item label="商城首页轮播图条轮的h5路径开关">
        <el-switch v-model="updateForm.mallIsSwiperH5Url" active-color="#13ce66" style="display: flex; margin-top: 9px;"></el-switch>
      </el-form-item>
      <el-form-item label="商城顶部轮播图" prop="mallIndexSwiperUrl">
        <image_choose
            :image_url.sync="updateForm.mallIndexSwiperUrl"
            prefix_path="redpacket/mall/indexSwiper"
            is_show_cdn>
        </image_choose>
      </el-form-item>
      <el-button :type="loadingE? 'info': 'primary'" round plain @click="updateShoppingMallVE(1)" :loading="loadingE">
        {{ loadingE? '加载中' : '执 行 选 择 的 类 别' }}
      </el-button>
      <el-button :type="loadingE? 'info': 'primary'" round plain @click="updateShoppingMallVE(2)" :loading="loadingE">
        {{ loadingE? '加载中' : '执 行 选 择 的 第 三 方' }}
      </el-button>
      <el-divider class="buttonUseDeploy"></el-divider>
    </el-form>

    <el-button icon="el-icon-office-building" round @click="updateShoppingMall(1)" class="buttonUseDeployU" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 类 别（全部）' }}
    </el-button>
    <el-button icon="el-icon-office-building" round @click="updateShoppingMall(2)" class="buttonUseDeployU" :loading="loading">
      {{ loading? '加载中' : '执 行 选 择 的 第 三 方（全部）' }}
    </el-button>
  </div>
</template>

<script>
import image_choose from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose.vue";
import mall_goods_list from "../../../../smallRoutine/applet1/childrenPage/smallroutineConfiguration/mall_goods_list.vue";

export default {
  components: {
    mall_goods_list,
    image_choose
  },
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      //需要的数据字段
      updateForm: {
        tbThirdPartySmallRoutineType: [], //选中的第三方
        batchClassList: [], //选中的类别
        //A区
        isShowMallMenuList: false,
        //B区
        isShowMallCoupon: false,
        couponAmount: 0,
        couponBtnLogo: "",
        couponBtnTitle: "",
        couponPopTitle: "",
        //C区
        isShowMallForm: false,
        mallFormTitle: "",
        isShowMallPwdRedpacket: false,
        isShowMallFanGroup: false,
        //D区
        mallGoodsPriceDescCurrent: "",
        isShowMallGoodsPriceOrigin: false,
        mallGoodsPriceDescOrigin: "",
        isShowMallGoodsSaleCnt: false,
        //E区
        mallIndexSwiperUrl: "",
        mallIndexGoodsList: "[{}]",
        mallIndexSwiperH5Url: "",
        mallIsSwiperH5Url: false,
      },
      loading: false,
      loadingA: false,
      loadingB: false,
      loadingC: false,
      loadingD: false,
      loadingE: false,

    }
  },
  methods: {
    /**
     * 查询数据
     */
    // 查询上一次历史数据（全部）
    async getShoppingmall() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmall",
      );
      if (200 === res.code && res.message != null) {
        this.updateForm.isShowMallMenuList = res.message.isShowMallMenuList;
        this.updateForm.isShowMallCoupon = res.message.isShowMallCoupon;
        this.updateForm.couponAmount = res.message.couponAmount;
        this.updateForm.couponBtnLogo = res.message.couponBtnLogo;
        this.updateForm.couponBtnTitle = res.message.couponBtnTitle;
        this.updateForm.couponPopTitle = res.message.couponPopTitle;
        this.updateForm.isShowMallForm = res.message.isShowMallForm;
        this.updateForm.mallFormTitle = res.message.mallFormTitle;
        this.updateForm.isShowMallPwdRedpacket = res.message.isShowMallPwdRedpacket;
        this.updateForm.isShowMallFanGroup = res.message.isShowMallFanGroup;
        this.updateForm.mallGoodsPriceDescCurrent = res.message.mallGoodsPriceDescCurrent;
        this.updateForm.isShowMallGoodsPriceOrigin = res.message.isShowMallGoodsPriceOrigin;
        this.updateForm.mallGoodsPriceDescOrigin = res.message.mallGoodsPriceDescOrigin;
        this.updateForm.isShowMallGoodsSaleCnt = res.message.isShowMallGoodsSaleCnt;
        this.updateForm.mallIndexSwiperUrl = res.message.mallIndexSwiperUrl;
        this.updateForm.mallIndexGoodsList = res.message.mallIndexGoodsList;
        this.updateForm.mallIndexSwiperH5Url = res.message.mallIndexSwiperH5Url;
        this.updateForm.mallIsSwiperH5Url = res.message.mallIsSwiperH5Url;
      }
    },
    // 查询上一次的历史数据（A/B/C/D/E区）
    async getShoppingmallABCDE() {
      const { data: resa } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmallVA",
      );
      if (200 === resa.code && resa.message != null) {
        this.updateForm.isShowMallMenuList = resa.message.isShowMallMenuList;
      }

      const { data: resb } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmallVB",
      );
      if (200 === resb.code && resb.message != null) {
        this.updateForm.isShowMallCoupon = resb.message.isShowMallCoupon;
        this.updateForm.couponAmount = resb.message.couponAmount;
        this.updateForm.couponBtnLogo = resb.message.couponBtnLogo;
        this.updateForm.couponBtnTitle = resb.message.couponBtnTitle;
        this.updateForm.couponPopTitle = resb.message.couponPopTitle;
      }

      const { data: resc } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmallVC",
      );
      if (200 === resc.code && resc.message != null) {
        this.updateForm.isShowMallForm = resc.message.isShowMallForm;
        this.updateForm.mallFormTitle = resc.message.mallFormTitle;
        this.updateForm.isShowMallPwdRedpacket = resc.message.isShowMallPwdRedpacket;
        this.updateForm.isShowMallFanGroup = resc.message.isShowMallFanGroup;
      }

      const { data: resd } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmallVD",
      );
      if (200 === resd.code && resd.message != null) {
        this.updateForm.mallGoodsPriceDescCurrent = resd.message.mallGoodsPriceDescCurrent;
        this.updateForm.isShowMallGoodsPriceOrigin = resd.message.isShowMallGoodsPriceOrigin;
        this.updateForm.mallGoodsPriceDescOrigin = resd.message.mallGoodsPriceDescOrigin;
        this.updateForm.isShowMallGoodsSaleCnt = resd.message.isShowMallGoodsSaleCnt;
      }

      const { data: rese } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getShoppingmallVE",
      );
      if (200 === rese.code && rese.message != null) {
        this.updateForm.mallIndexSwiperUrl = rese.message.mallIndexSwiperUrl;
        this.updateForm.mallIndexGoodsList = rese.message.mallIndexGoodsList;
        this.updateForm.mallIndexSwiperH5Url = rese.message.mallIndexSwiperH5Url;
        this.updateForm.mallIsSwiperH5Url = rese.message.mallIsSwiperH5Url;
      }
    },

    /**
     * 更新数据
     */
    // 更新数据接口（全部）
    async updateShoppingMall(is) {
      this.loading = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loading = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loading = false; //关闭加载中
        return;
      }

      if (is === 1) {
        this.updateForm.batchClassList = this.batchClassList;
        this.updateForm.tbThirdPartySmallRoutineType = null;
      } else {
        this.updateForm.tbThirdPartySmallRoutineType = this.checkedCities;
        this.updateForm.batchClassList = null;
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMall",
          this.updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城」配置成功");
      } else {
        this.$message.error("「商城」配置失败:" + res.message);
      }
      this.loading = false; //关闭加载中
    },
    // 更新数据A区 -- 菜单按钮列表
    async updateShoppingMallVA(is) {
      this.loadingA = true; //开启加载中
      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingA = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingA = false; //关闭加载中
        return;
      }
      let updateForm = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        isShowMallMenuList: this.updateForm.isShowMallMenuList
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMallVA",
          updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城A区」配置成功");
      } else {
        this.$message.error("「商城A区」配置失败:" + res.message);
      }
      this.loadingA = false; //关闭加载中
    },
    // 更新数据B区 -- 是否展示抵用卷按钮 ~ 抵用卷弹窗标题
    async updateShoppingMallVB(is) {
      this.loadingB = true; //开启加载中
      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingB = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingB = false; //关闭加载中
        return;
      }
      let updateForm = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        isShowMallCoupon: this.updateForm.isShowMallCoupon,
        couponAmount: this.updateForm.couponAmount,
        couponBtnLogo: this.updateForm.couponBtnLogo,
        couponBtnTitle: this.updateForm.couponBtnTitle,
        couponPopTitle: this.updateForm.couponPopTitle,
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMallVB",
          updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城B区」配置成功");
      } else {
        this.$message.error("「商城B区」配置失败:" + res.message);
      }
      this.loadingB = false; //关闭加载中
    },
    // 更新数据C区 -- 是否展示领取表单按钮 ~ 是否展示加入粉丝群
    async updateShoppingMallVC(is) {
      this.loadingC = true; //开启加载中
      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingC = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingC = false; //关闭加载中
        return;
      }
      let updateForm = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        isShowMallForm: this.updateForm.isShowMallForm,
        mallFormTitle: this.updateForm.mallFormTitle,
        isShowMallPwdRedpacket: this.updateForm.isShowMallPwdRedpacket,
        isShowMallFanGroup: this.updateForm.isShowMallFanGroup,
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMallVC",
          updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城C区」配置成功");
      } else {
        this.$message.error("「商城C区」配置失败:" + res.message);
      }
      this.loadingC = false; //关闭加载中
    },
    // 更新数据D区 -- 商品当前价格说明 ~ 是否展示商品的购买量
    async updateShoppingMallVD(is) {
      this.loadingD = true; //开启加载中
      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingD = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingD = false; //关闭加载中
        return;
      }
      let updateForm = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        mallGoodsPriceDescCurrent: this.updateForm.mallGoodsPriceDescCurrent,
        isShowMallGoodsPriceOrigin: this.updateForm.isShowMallGoodsPriceOrigin,
        mallGoodsPriceDescOrigin: this.updateForm.mallGoodsPriceDescOrigin,
        isShowMallGoodsSaleCnt: this.updateForm.isShowMallGoodsSaleCnt,
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMallVD",
          updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城D区」配置成功");
      } else {
        this.$message.error("「商城D区」配置失败:" + res.message);
      }
      this.loadingD = false; //关闭加载中
    },
    // 更新数据E区 -- 商城首页展示的商品列表 ~ 顶部轮播图
    async updateShoppingMallVE(is) {
      this.loadingE = true; //开启加载中
      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingE = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingE = false; //关闭加载中
        return;
      }
      let updateForm = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        mallIndexSwiperUrl: this.updateForm.mallIndexSwiperUrl,
        mallIndexGoodsList: this.updateForm.mallIndexGoodsList,
        mallIndexSwiperH5Url: this.updateForm.mallIndexSwiperH5Url,
        mallIsSwiperH5Url: this.updateForm.mallIsSwiperH5Url,
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/updateShoppingMallVE",
          updateForm
      );
      if (200 === res.code) {
        this.$message.success("「商城E区」配置成功");
      } else {
        this.$message.error("「商城E区」配置失败:" + res.message);
      }
      this.loadingE = false; //关闭加载中
    },

  },
  computed:{

  },
  mounted() {
    this.getShoppingmall();
    this.getShoppingmallABCDE();
  }
}
</script>


<style scoped lang="less">
.buttonUseDeploy{
  margin-top: 30px;
  margin-bottom: 50px;
}
.buttonUseDeployU{
  margin-top: 30px;
  color: #fff;
  background-color: #4e72b8;
}
</style>