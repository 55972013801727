
<template>
  <div>
    <div class="top">
      灯火广告：
      <el-switch v-model="lightAdStatus" active-text="开启" inactive-text="关闭"></el-switch>
    </div>
    <div class="top">
      轮播图：
      <el-switch v-model="rotationMap" active-text="开启" inactive-text="关闭"></el-switch>
    </div>
    <div class="top">
      弹框小广告：
      <el-switch v-model="coupon" active-text="开启" inactive-text="关闭"></el-switch>
    </div>
    <div class="top">
      菜单按钮：
      <el-select v-model="smallMallMenuTemplateId" placeholder="请选择菜单模板">
        <el-option
            v-for="item in menu_templates"
            :key="item.id"
            :label="item.title"
            :value="item.id">
        </el-option>
      </el-select>
    </div>
    <el-button icon="el-icon-message-solid" round @click="popUpAdvertising(1)" class="buttonUseDeploy" :loading="loadingtc">
      {{ loadingtc? '加载中' : '执 行 选 择 的 类 别' }}
    </el-button>
    <el-button icon="el-icon-message-solid" round @click="popUpAdvertising(2)" class="buttonUseDeploy" :loading="loadingtc">
      {{ loadingtc? '加载中' : '执 行 选 择 的 第 三 方' }}
    </el-button>
  </div>
</template>

<script>
import axios from "axios";
import store from '@/store/index.js'

export default {
  props: [
    'checkedCities',
    'batchClassList'
  ],
  data() {
    return {
      lightAdStatus:false,
      rotationMap:false,
      coupon:false,
      loadingtc:false,
      menu_templates:[],
      smallMallMenuTemplateId: 0,
    }
  },
  methods: {
    // 查询上一次数据接口
    async getPopUpAdvertising() {
      const { data: res } = await this.$http(
          "/thirdPartySmallRoutineConfiguration/getPopUpAdvertising",
      );
      // console.log("查询上一次数据接口：", res)
      if (200 === res.code) {
        this.lightAdStatus = res.message.lightAdStatus;
        this.rotationMap = res.message.rotationMap;
        this.coupon = res.message.coupon;
        this.smallMallMenuTemplateId = parseInt(res.message.smallMallMenuTemplateId);
        // console.log("this.smallMallMenuTemplateId",  this.smallMallMenuTemplateId)
        // console.log("菜单", this.menu_templates)
      }
    },
    // 修改数据接口
    async popUpAdvertising(is) {
      this.loadingtc = true; //开启加载中

      if (is === 1 && this.batchClassList.length === 0 ) {
        this.$message.error("请先选择类别");
        this.loadingtc = false; //关闭加载中
        return;
      }
      if (is === 2 && this.checkedCities.length === 0) {
        this.$message.error("请先选择第三方");
        this.loadingtc = false; //关闭加载中
        return;
      }

      //参数：checkedCities或batchClassList（选中的第三方列表或选中的类别列表）、lightAdStatus（灯火广告）、rotationMap（轮播图）、coupon（弹窗小广告配置）
      const routineConfigurationVo = {
        batchClassList: is === 1? this.batchClassList : null,
        tbThirdPartySmallRoutineType: is === 2? this.checkedCities : null,
        lightAdStatus: this.lightAdStatus,
        rotationMap: this.rotationMap,
        coupon: this.coupon,
        smallMallMenuTemplateId: this.smallMallMenuTemplateId.toString()
      }
      const { data: res } = await this.$http.post(
          "/thirdPartySmallRoutineConfiguration/popUpAdvertising",
          routineConfigurationVo
      );
      if (200 === res.code) {
        this.$message.success("「其他功能」配置成功");
      } else {
        this.$message.error("「其他功能」配置失败:" + res.message);
      }
      this.loadingtc = false; //关闭加载中
    },
    // 菜单
    async initialize_data(){
      let response = await axios({
        url:store.state.global_base_url.main +"tb-third-party-wang-redpacket-menu-template/getAll"
      })
      this.menu_templates = response.data.message;
    },
  },
  computed:{

  },
  mounted() {
    this.initialize_data();
    this.getPopUpAdvertising();
  }
}
</script>


<style scoped lang="less">
//弹窗小广告配置
.buttonUseDeploy{
  margin-top: 10px;
  color: #fff;
  background-color: #4e72b8;
}
.xcxkf{
  margin-top: 20px;
}
.top{
  margin-bottom: 30px;
}
</style>