<template>
  <div class="div_home">
    <list v-show="showListVisible" :showMessage="showMessage" ref="listRef" />
    <message
      v-if="!showListVisible"
      :showList="showList"
      :showSingleMes="showSingleMes"
      :refreshList="refreshList"
    />
  </div>
</template>

<script>
import list from "./list.vue";
import message from "./message.vue";
export default {
  components: {
    list,
    message,
  },
  data() {
    return {
      showListVisible: true,
      showSingleMes: {},
    };
  },
  created() {},
  methods: {
    // 刷新列表
    refreshList() {
      this.$refs.listRef.getList();
    },
    showMessage(e) {
      this.showListVisible = false;
      this.showSingleMes = e;
    },
    showList() {
      this.showListVisible = true;
      this.showSingleMes = {};
    },
  },
};
</script>

<style lang="less" scoped></style>
