<template>
  <div class="div_home">
    <el-card>
      <div class="div_home_title">
        <el-row :gutter="25">
          <!-- 搜索区域 -->
          <!-- 搜索添加 -->
          <div class="head_input">
            <div class="div_input">
              <label class="div_input_text">客户:</label>
              <el-select
                v-model="queryInfo2.customerId"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">状态:</label>
              <el-select
                v-model="queryInfo2.status"
                clearable
                placeholder="全部"
              >
                <el-option key="-1" label="全部" value=""> </el-option>
                <el-option key="input_status_1" label="开启" value="1">
                </el-option>
                <el-option key="input_status_0" label="关闭" value="0">
                </el-option>
              </el-select>
            </div>
            <div class="div_input">
              <label class="div_input_text">广告投放起始时间:</label>

              <el-date-picker
                v-model="queryInfo2.startDate"
                type="date"
                placeholder="所有"
                clearable
              >
              </el-date-picker>
            </div>
            <div class="div_input">
              <label class="div_input_text">广告投放结束时间:</label>
              <el-date-picker
                v-model="queryInfo2.endDate"
                type="date"
                placeholder="所有"
                clearable
              >
              </el-date-picker>
            </div>
          </div>
          <div class="head_input head_input2">
            <div class="div_input">
              <label class="div_input_text">每日开始时间:</label>
              <el-time-picker
                v-model="queryInfo2.startTime"
                placeholder="每天投放开始时间段"
              >
              </el-time-picker>
            </div>
            <div class="div_input">
              <label class="div_input_text">每日结束时间:</label>
              <el-time-picker
                v-model="queryInfo2.endTime"
                placeholder="每天投放结束时间段"
              >
              </el-time-picker>
            </div>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="search()"
              icon="el-icon-search"
              >搜索
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="clearSearch()"
              icon="el-icon-refresh"
              >重置
            </el-button>
            <el-button
              class="headButtonSingle"
              type="primary"
              @click="addDialogVisible = true"
              icon="el-icon-plus"
              >新增
            </el-button>
          </div>
        </el-row>
      </div>
      <!-- 用户列表 border:边框  stripe隔行变色-->
      <el-table :data="pageMes.list" border stripe>
        <el-table-column type="index"></el-table-column>
        <el-table-column label="客户" prop="customerName"></el-table-column>
        <el-table-column
          label="广告类型"
          prop="advertisingClientText"
        ></el-table-column>
        <el-table-column label="排序" prop="sort"></el-table-column>
        <el-table-column label="广告量" prop="quantity"></el-table-column>
        <el-table-column label="总价" prop="totalPrice"></el-table-column>
        <el-table-column label="单价" prop="unitPrice"></el-table-column>
        <el-table-column label="小程序类型" prop="appletType">
          <template slot-scope="scope">
            <el-select  v-model="scope.row.appletType"  :disabled="true">
              <el-option  v-for="(item,index) in appletTypeList"  :key="index"  :label="item.name"  :value="item.code"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="分组" prop="appletType">
          <template slot-scope="scope">
            {{batchId_map_name[scope.row.batchClassId]}}
          </template>
        </el-table-column>
        <el-table-column label="小程序" prop="appletType">
          <template slot-scope="scope">
            {{appId_map_name[scope.row.appId]}}
          </template>
        </el-table-column>
        <el-table-column label="用户佣金" prop="commission"></el-table-column>
        <el-table-column label="开始日期" prop="startDate"></el-table-column>
        <el-table-column label="结束日期" prop="endDate"></el-table-column>
        <el-table-column
          label="开始时间"
          prop="startTimeText"
        ></el-table-column>
        <el-table-column label="结束时间" prop="endTimeText"></el-table-column>
        <el-table-column label="状态" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="userStateChange(scope.row, 'status')"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否发放佣金" prop="commissionPayment">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.commissionPayment"
              @change="userStateChange(scope.row, 'commissionPayment')"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <!-- 修改 -->
            <el-tooltip
              effect="dark"
              content="编辑"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="showEditDialog(scope.row)"
              ></el-button>
            </el-tooltip>
            <!-- 删除 -->
            <el-tooltip
              effect="dark"
              content="删除"
              placement="top-start"
              :enterable="false"
            >
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteThis(scope.row.id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!-- element分页组件 size-change每页最大变化数 current-change当前最大变化 layout功能组件-->
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNum"
          :page-sizes="[5, 10, 20, 100]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageMes.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <!-- 新增信息 -->
    <el-dialog
      title="添加"
      :visible.sync="addDialogVisible"
      width="50%"
      @close="addDialogClose"
    >
      <informationAdd
        :refaushList="getList"
        :customerList="customerList"
        :clientList="clientList"
        ref="addRef"
      />
    </el-dialog>
    <!-- 编辑信息 -->
    <el-dialog
      title="编辑"
      :visible.sync="updateDialogVisible"
      width="50%"
      @close="updateDialogClose"
    >
      <informationUpdate
        :refaushList="getList"
        :customerList="customerList"
        :clientList="clientList"
        :updateId="updateId"
        ref="updateRef"
      />
    </el-dialog>
  </div>
</template>

<script>
import informationAdd from "./informationAdd.vue";
import informationUpdate from "./informationUpdate.vue";
import store from "@/store";
import axios from "axios";
import {handleResponse} from "@/services/wCommonFunction";
import app from "@/App.vue";

export default {
  components: { informationAdd, informationUpdate },
  props: ["customerList"],
  data() {
    return {
      // 搜索内容(点击搜索后将queryInfo2值赋予该对象)
      queryInfo: {
        //查询信息
        customerId: "",
        status: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 搜索内容
      queryInfo2: {
        //查询信息
        customerId: "",
        status: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      },
      // 分页信息
      pageMes: {
        list: [],
        total: 0,
      },
      // 是否展示新增弹窗
      addDialogVisible: false,
      // 是否展示编辑弹窗
      updateDialogVisible: false,
      // 编辑的对象id
      updateId: "",
      // 客户列表
      // customerList: [],
      clientList: [
        { label: "h5", value: 1 },
        { label: "支付宝小程序", value: 2 },
        { label: "生活号", value: 3 },
      ],
      // 小程序种类列表
      appletTypeList:[],
      batchId_map_name:{},
      appId_map_name:{},
    };
  },
  created() {
    // this.getCustomerList();
    //加载小程序类别选项
    this.getAppletTypes();
    this.getList();
  },
  methods: {
    //点击搜索
    search() {
      let queryInfo = this.queryInfo;
      let queryInfo2 = this.queryInfo2;
      queryInfo2.pageNum = 1;
      queryInfo = { ...queryInfo2 };
      this.queryInfo = queryInfo;
      this.getList();
    },
    // 重置搜索内容
    clearSearch() {
      let queryInfo = {
        pageNum: 1, //当前页
        pageSize: 10, //每页最大数
        smallCodeList: [], //查询的小程序唯一标识列表
      };
      this.queryInfo = { ...queryInfo };
      this.queryInfo2 = { ...queryInfo };
      this.getList();
    },
    // 获取列表
    async getList() {
      const { data: res } = await this.$http.post(
        "/advertisementInformation/list",
        this.queryInfo
      );
      if ("200" != res.code) {
        return this.$message.error("获取列表失败:" + res.message);
      }
      this.pageMes.list = [];
      this.pageMes.list.push(...res.message.records) ;
      this.pageMes.total = res.message.total;
      // 对每一个batchClassId找出对应的名字
      this.init_batchId_map_name();
      this.init_appId_map_name();
    },
    // 对每一个batchClassId找出对应的名字
    init_batchId_map_name(){
      let batchClassIdList = new Set();
      this.pageMes.list
          .filter(e=>e.batchClassId)
          .forEach(e=>batchClassIdList.add(e.batchClassId));
      batchClassIdList.delete(-1);
      // this.batchId_map_name[-1] = '尚未选择';
      batchClassIdList.forEach(
          async id=>{
            const response = await  axios({
              url:store.state.global_base_url.main+"tb-third-party-wang-batch-class/getById",
              params:{id:id}
            });
            const data = handleResponse(response);
            this.$set(this.batchId_map_name,id,data.name);
            // this.batchId_map_name[id] = data['name'];
          }
      )
    },
    init_appId_map_name(){
      let appIdList = new Set();
      this.pageMes.list
          .filter(e=>e.appId)
          .forEach(e=>appIdList.add(e.appId));
      appIdList.delete("-1");
      appIdList.forEach(async  id=>{
          const response = await axios({
            url:store.state.global_base_url.main+"thirdApplet1/getSmallRoutineByAppId",
            params:{appId:id},
          })
          let app = handleResponse(response);
          this.$set(this.appId_map_name,id,app.name)
      })
    },
    // 最大数发生变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getList();
    },
    // 换页
    handleCurrentChange(newPage) {
      this.queryInfo.pageNum = newPage;
      this.getList();
    },
    // 获取客户列表
    async getCustomerList() {
      const { data: res } = await this.$http.get(
        "/advertisementCustomer/getCustomerKeyNameList"
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.customerList = res.message;
    },
    // 获取小程序种类列表
    async getAppletTypes(){
      const {data:res} = await this.$http.get(
        "/thirdPartySmallRoutineType/getAllTypeWithAllOption"
      );
      if('200'!=res.code){
        return this.$message.error(res.message);
      }
      this.appletTypeList = res.message;
    },
    // 关闭新增弹窗
    addDialogClose() {
      this.$refs.addRef.resetForm();
    },
    // 关闭编辑弹窗
    updateDialogClose() {
      this.updateId = "";
    },
    // 编辑
    showEditDialog(e) {
      if (this.$refs.updateRef == undefined) {
        this.updateId = e.id;
      } else {
        this.$refs.updateRef.refaushForm(e.id);
      }
      this.updateDialogVisible = true;
    },
    // 删除
    async deleteThis(id) {
      const result = await this.$confirm("此操作将永久删,是否继续?", "提示", {
        confirmButtonText: "确定",
        cancleButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result != "confirm") {
        //取消删除
        return;
      }
      const { data: res } = await this.$http.delete(
        "advertisementInformation/del?id=" + id
      );
      if ("200" != res.code) {
        return this.$message.error("删除失败:" + res.message);
      }
      this.$message.success("删除成功");
      this.getList(); //刷新表格
    },
    // 修改状态
    async userStateChange(e, type) {
      let status;
      if (type == "status") {
        // 修改状态
        status = e.status;
      } else if (type == "commissionPayment") {
        // 修改佣金
        status = e.commissionPayment;
      } else {
        return this.$message.error("操作失败，类型有误");
      }
      const { data: res } = await this.$http.put(
        `/advertisementInformation/updateState`,
        { id: e.id, status: status, type: type }
      );
      if (res.code != "200") {
        status = !status;
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  // height: 90%;
  margin-top: 30px;
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .head_input {
    display: flex;
    .div_input {
      display: flex;
      align-items: center;
      // flex: 1;
      // max-width: 350px;
      padding-right: 5px;
      .div_input_text {
        white-space: nowrap;
        padding-right: 5px;
      }
    }
  }
  .head_input2 {
    padding-top: 10px;
  }
}
</style>
