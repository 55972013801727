<template>
  <!-- 签到管理 -->
  <div>
    <template>
      <el-divider content-position="left">签到奖励</el-divider>
      <el-table :data="updateForm.list" border style="width: 100%">
        <el-table-column prop="days" label="签到天数" width="180">
        </el-table-column>
        <el-table-column prop="reward" label="奖励积分" width="180">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-input
              v-model="scope.row.reward"
              placeholder="请输入奖励积分"
              type="number"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="luckDraw" label="抽奖">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch v-model="scope.row.luckDraw"></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <!-- <el-divider content-position="left">签到抽奖</el-divider>
    <el-form
      ref="form"
      :model="updateForm.luckDraw"
      class="demo-ruleForm"
      label-position="left"
      label-width="80px"
    >
      <el-form-item label="奖励1" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.one"></el-input>
      </el-form-item>
      <el-form-item label="奖励2" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.two"></el-input>
      </el-form-item>
      <el-form-item label="奖励3" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.three"></el-input>
      </el-form-item>
      <el-form-item label="奖励4" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.four"></el-input>
      </el-form-item>
      <el-form-item label="奖励5" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.five"></el-input>
      </el-form-item>
      <el-form-item label="奖励6" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.six"></el-input>
      </el-form-item>
      <el-form-item label="奖励7" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.seven"></el-input>
      </el-form-item>
      <el-form-item label="奖励8" prop="one">
        <el-input type="number" v-model="updateForm.luckDraw.eight"></el-input>
      </el-form-item>
    </el-form> -->

    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "smallRoutine3SignIn",
  props: ["updateCode"],
  data() {
    return {
      updateForm: {
        id: "",
        list: [],
      },
      showLoading: false, // 加载中
    };
  },
  created() {
    this.refaushForm(this.updateCode);
  },
  methods: {
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/smallRoutine3SignInReward/updateMes",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
    },
    // 刷新编辑对象
    async refaushForm(code) {
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "smallRoutine3SignInReward/getMesByCode?code=" + code
      );
      let message = res.message;
      if ("200" != res.code) {
        return this.$message.error(message);
      }
      this.updateForm = message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // input校验不能中文
    inputCheck(e) {
      if (/.*[\u4e00-\u9fa5]+.*$/.test(e)) {
        this.$message.error("小程序唯一码不能含有中文！");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
