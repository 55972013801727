<template>
  <div class="reasonForRejectionMes">
    <el-form :model="message" ref="formMesRef" label-width="auto">
      <el-form-item label="时间">
        {{ message.gmtCreate }}
      </el-form-item>
      <el-form-item label="版本号">
        {{ message.mini_app_version }}
      </el-form-item>
      <el-form-item label="驳回理由">
        {{ message.audit_reason }}
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { reasonForRejection } from "@/services/thirdParty/smallRoutine/thirdParty2";
export default {
  name: "reasonForRejection",
  props: ["showSingleMes"],
  data() {
    return {
      message: {},
    };
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await reasonForRejection(this.showSingleMes.appId);
      loading.close();
      console.log(res);
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.message = res.data;
    },
  },
};
</script>

<style lang="less" scoped>
.reasonForRejectionMes {
  -webkit-user-select: text;
  -moz-user-select: text;
  -o-user-select: text;
  user-select: text;
}
</style>
