<template>
    <div class="container">
		<div class="mask"  v-if="is_show_mask">
			<div class="loading_text">正在加载中</div>
			<img  class="loading_icon" src="./image/Loading.svg"	/>
		</div>
		<div	class="content">
			<el-button type="primary" @click="getAll">获取所有商品</el-button>
			<el-button type="primary" @click="clear">清空</el-button>
			<!-- 折叠面板-根据分组折叠 -->
			<el-collapse  accordion v-model="choosedGroup">
			  <el-collapse-item  :name="indexG" v-for="(itemG,indexG) in goodsGroupList" :key="itemG.id"  >
          <template slot="title">
            <div style="width: 100px;"><el-tag size="small">{{itemG.goodsGroup}}</el-tag> </div>
            <el-button type="primary" size="mini" style="margin-left: 150px;" @click.stop.prevent="row_upGp(indexG)" :disabled="indexG==0">向上</el-button>
            <el-button type="primary" size="mini" @click.stop.prevent="row_downGp(indexG)" :disabled="indexG==goodsGroupList.length-1">向下</el-button>
          </template>
          <el-table  v-if="choosedGroup==indexG" ref="multipleTable" :data="itemG.goodsList"  :row-key="getRowKey" @selection-change="handleSelectionChange(indexG, $event)">
            <el-table-column  type="selection" width="55" :reserve-selection="true"></el-table-column>
            <el-table-column	label="图片" width="80">
              <template slot-scope="scope" fit="contain">
                <el-image :src="scope.row.imgUrl"></el-image>
              </template>
            </el-table-column>
            <template  v-for="(item,index) in data_colu" >
              <el-table-column :width="item.width" :key="index" :label="item.lable" :prop="item.prop"></el-table-column>
            </template>
            <el-table-column  label="排序"	width="150">
              <template slot-scope="scope">
                <el-button type="primary" @click="row_up(itemG.id,scope.$index)"
                           :disabled="scope.$index==0"	size="mini">向上</el-button>
                <el-button type="primary" @click="row_down(itemG.id,scope.$index)"
                           :disabled="scope.$index==itemG.goodsList.length-1" size="mini">向下</el-button>
              </template>
            </el-table-column>
          </el-table>
			  </el-collapse-item>
			</el-collapse>
		</div>
    </div>
</template>

<script>
	import axios from 'axios'
	import {mapState} from 'vuex'
export default{
    components:{
		
    },
    props: {
		input_list:{	
			type:String ,
			default:'[]'
		},
		list_max_length:{	type:Number, default:5}
    },
    data() {
        return {
			//商品分组id和商品id列表
        groupIdAndGoodsId:[],
			//商品列表
			goodsList:[],
			is_first_watch:true,
			is_show_mask:true,
			data_colu:[
				{lable:"商品id",prop:"id",width:90},
				{lable:"商品名称",prop:"goodsName",width:80},
				{lable:"商品价格",prop:"price",width:80},
				{lable:"商品实际价格",prop:"finalPrice",width:80},
				{lable:"商品组类id",prop:"goodsGroupingId",width:80}
			],
			//分组列表
			goodsGroupList:[],
          choosedGroup:"",
        }
    },
    methods:{
		//将勾选的数据id存储到数组groupIdAndGoodsId中
		getSelectData(){
			if(this.goodsGroupList !==undefined && this.goodsGroupList.length > 0){
					this.groupIdAndGoodsId=this.goodsGroupList.map(t=>{
					const item={
						groupId:t.id,
						goodsIdList:t.goodsList.map(i=>{return {goodsId:i.id,isCheck:i.isCheck}})
					}
					return item;
				})
			}else{
				return this.groupIdAndGoodsId=[];
			}
			
		},
		getRowKey(row){
			return row.id;
		},
		//gIndex为勾选发生变化时所在分组的下标
		handleSelectionChange(gIndex,val) {
			if(val[0]!=null){
				//获取勾选的商品所在的分组的下标
				let index = this.goodsGroupList.findIndex(e => e.id == val[0].goodsGroupingId);
				//先将该分组下的所有isCheck设为false，然后将勾选的设置为true
				this.goodsGroupList[index].goodsList.forEach(t=>{t.isCheck=false});
				for(let i =0;i<val.length;i++){
					let indexG = this.goodsGroupList[index].goodsList.findIndex(e => e.id == val[i].id);
					this.goodsGroupList[index].goodsList[indexG].isCheck=true;
				}
			}else{
				//若取消所有勾选则该分组下的商品的isCheck都为false
				this.goodsGroupList[gIndex].goodsList.forEach(t=>{t.isCheck=false});
			}
			this.getSelectData();
		},
	//对数据进行增删改查的操作
		//获取所有数据
        async getAll(){ 
			if(this.goodsList ===undefined || this.goodsList.length <= 0 ){
				let res	= await axios.get(this.httpUrl+"tb-third-party-wang-mall-goods/list");
				this.goodsList =  res.data.message;
				let e = await axios.get(this.httpUrl+"tb-third-party-wang-mall-goods-group/listGoodsGroup");
				let that =this;
				this.goodsGroupList = e.data.message.map(e=>{
					//映射goodsGroupList并添加一个goodsList [...,isCheck]字段
					e.goodsList = that.goodsList.filter(a=>a.goodsGroupingId == e.id);
					e.goodsList.forEach(t=>{t.isCheck=false});
					return e;
				});
				const inputList=JSON.parse(this.input_list);
				if(inputList.length > 0 ){
					//实现初始化表格时的商品的排序顺序与保存的排序顺序一致
					const sortedIds = inputList.map(item => item.groupId);
					this.goodsGroupList=this.goodsGroupList.slice().sort((a, b) => {
						return sortedIds.indexOf(a.id) - sortedIds.indexOf(b.id);
					})
					.map(item => {
						const index = inputList.findIndex(t => t.groupId === item.id);
						const sortedGoodsIds=inputList[index].goodsIdList.map(e => e.goodsId);
						item.goodsList = item.goodsList.sort((a,b)=>{return sortedGoodsIds.indexOf(a.id) - sortedGoodsIds.indexOf(b.id);});
						return item;
					});
					//实现默认勾选
					for(let i =0;i<this.goodsGroupList.length;i++){
						let result =this.goodsGroupList[i].goodsList.filter(e=>{return inputList[i].goodsIdList.find(t=>{ 
							return e.id === t.goodsId && t.isCheck === true;
								})
							});
						if(result !==undefined && result.length > 0 ){
							this.$nextTick(() => {
								result.forEach(row => {
								this.$refs.multipleTable[i].toggleRowSelection(this.goodsGroupList[i].goodsList.find(item=>{return item.id===row.id}),true);
									})
							})
						}
					}
				}
			}else{
				this.$message.info("商品已经添加过了，不用再点啦QAQ");
			}
					  
        },
		//排序的名次上升，指排序的下标上升
		//1.第一个参数是分组的下表，第二个参数是（分组下的）商品的下标
        row_up(itemGId,indexGoods){
			//求出所在的分组下标
			let indexG = this.goodsGroupList.findIndex(item=>item.id == itemGId);
        	let temp = this.goodsGroupList[indexG].goodsList[indexGoods] ;
			this.goodsGroupList[indexG].goodsList[indexGoods] = this.goodsGroupList[indexG].goodsList[indexGoods-1];
			this.goodsGroupList[indexG].goodsList.splice(indexGoods-1,1,temp);
			this.getSelectData();
        },
		//排序的名次下降，指排序的下标下降
        row_down(itemGId,indexGoods){
        	this.row_up(itemGId,indexGoods+1); 
        },
		//排序的名次上升，指排序的下标上升
		row_upGp(index){
			let temp = this.goodsGroupList[index];
			this.goodsGroupList[index] = this.goodsGroupList[index-1];
			this.goodsGroupList.splice(index-1,1,temp);
			// this.$set(this.goodsGroupList,index,this.goodsGroupList[index-1]);
			// this.$set(this.goodsGroupList,index-1,temp);
			this.getSelectData();
		},
		//排序的名次下降，指排序的下标下降
		row_downGp(index){
			this.row_upGp(index+1);
		},

		//清空所有的数据
        clear(){
			this.goodsList=[];
			this.goodsGroupList=[];
			this.groupIdAndGoodsId=[];
        },
		
//下面处理初始化数据的，因为props进来的数据比较慢，需要等props内的参数进来才开始初始化的操作
		//根据输入进来的数据 处理
		async	initiailize_data(){
			//先进行解码（在网络传输中会变内容，所以传输的时候会进行编码解码）
			//后对于JSON格式经行 转化
			this.groupIdAndGoodsId = JSON.parse(this.input_list) ;
		},
		//在本地数据还没有初始化之前，把页面内容遮盖，然后轮询props是否初始化完成，再进行本地数据初始化
		wait_util_not_undefined(callbackFunction){
			const  intervalTimer = setInterval(
				()=>{
					if(this.input_list != undefined){
						this.initiailize_data();
						clearInterval(intervalTimer);
						this.is_show_mask = false; 
					}
				},
				100
			)
		},
    },
    computed:{
		...mapState({
			httpUrl:state=>state.global_base_url.main
		})
	},
    created(){
		this.wait_util_not_undefined();
		this.getAll();
    },
	watch:{
		//对结果进行解码，上传给父组件
		groupIdAndGoodsId:{
			deep:true,
			handler(newValue){
				this.$emit("update:input_list",JSON.stringify(newValue));
			}
		}
		
	}
}
</script>

<style  lang="less" scoped>
	.container{
		position: relative;
		min-height: 200px;
		.mask{
			position: absolute;
			z-index: 200;
			background-color: white;
			opacity: 0.8;
			width: 100%;
			height: 100%;
			
			display: flex;	justify-content: center;	align-items: center;
			
			.loading_text{
				font-size: 23px;
			}
			
			.loading_icon{
				margin-left: 6px;
				width: 30px;		height: 30px;
				animation-name: rotate;
				animation-duration: 2s;
				animation-timing-function: linear;
				animation-iteration-count: infinite;
			}
			@keyframes rotate {
				0%		{	transform: rotate(0deg);	}
				50%		{	transform: rotate(180deg);	}
				100%		{	transform: rotate(360deg);	}
			}
		}
		.content{
			position: static;
		}
	}
</style>