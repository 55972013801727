
<!-- 这个是用来做 菜单栏的编辑 -->
<template>
    <div class="whole_container">
        <div class="top_oprations">
            <el-button  type="primary"  icon="el-icon-folder-add"  @click="show_add_dialog">新建一个模板</el-button>
        </div>
        <el-table   :data="customAdvertiseType.list">
            <el-table-column    prop="key"    label="键值"    width="180"></el-table-column>
            <el-table-column    prop="name"    label="名称"    width="180"></el-table-column>
            <el-table-column    prop="buttonText"    label="按钮上的字"    width="180"></el-table-column>
            <el-table-column    prop="h5Path"    label="h5跳转路径"></el-table-column>
            <el-table-column    label="图片路径"    width="180">
                <template slot-scope="scope">
                    <el-image  style="width: 100px; height: 100px"  :src="scope.row.iconPath"  fit="container"></el-image>
                  </template>
            </el-table-column>
            <el-table-column   label="编辑"    width="280">
                <template slot-scope="scope">
                    <el-button @click="show_update_dialog(scope.row)"  type="primary"  icon="el-icon-edit">编辑</el-button>
                    <el-button  type="danger"   @click="delete_item(item)"    icon="el-icon-delete">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="dialog.type_map_title[dialog.type]" :visible.sync="dialog.is_show"   width="1000px"   class="dialog">
            <div    v-if="dialog.is_show">
                <el-form    label-width="120px">
                    <el-form-item label="键值(需英文）">    
                        <el-input v-model="dialog.template.key"  placeholder="AIR_TICKET"></el-input>    
                    </el-form-item>
                    <el-form-item label="名称">
                        <el-input v-model="dialog.template.name"  placeholder="飞机票"></el-input>    
                    </el-form-item>
                    <el-form-item label="按钮上的字">
                        <el-input v-model="dialog.template.buttonText"  placeholder="立即领取"></el-input>    
                    </el-form-item>
                    <el-form-item label="h5跳转路径">
                        <el-input v-model="dialog.template.h5Path"  placeholder="https://baidu.com"></el-input>    
                    </el-form-item>
                    <el-form-item label="图片路径">
                        <image_choose 
                            :image_url.sync="dialog.template.iconPath"
                            prefix_path="redpacket/customAdvertiseType">
                        </image_choose>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="()=>{dialog.is_show = false;}">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </div> 
        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import image_choose from '@/components/thirdParty/smallRoutine/applet1/childrenPage/smallroutineConfiguration/image_choose';  
export default{
    components:{
        image_choose
    },
    props: {
        
    },
    data() {
        return {
            httpUrl:this.$httpUrl,
            customAdvertiseType:{
                list:[],
            },
            dialog:{
                template:{},
                is_show:false,  //是否展示弹窗
                type:'add',//add or update 添加东西或者编辑东西
                type_map_title:{    //上面那个属性的映射
                    add:"新增模板",
                    update:"编辑模板"
                }
            }
        }
    },
    methods:{
        async    initialize_data(){     //初始化数据
            this.customAdvertiseType.list = [] ;
            let response   =   await    axios({
                url:this.httpUrl+"tb-third-party-wang-redpacket-custom-advertise/list"
            });
            console.log("response",response.data.message);
            this.customAdvertiseType.list= response.data.message;
        },
        show_update_dialog(item){   //展示编辑编辑弹窗，并且把数据输送进去
            this.dialog.type = 'update';
            this.dialog.template = JSON.parse(JSON.stringify(item));
            this.dialog.is_show = true ;
        },
        show_add_dialog(){  //展示新增弹窗
            this.dialog.template = {};
            this.dialog.type = 'add';
            this.dialog.is_show = true;
        },
        check_form(form){   //检查是否有填写
            let colomn = [
                {label:'键值',prop:'key'},
                {label:'名称',prop:'name'},
                {label:'按钮上的字',prop:'buttonText'},
                {label:'h5跳转路径',prop:'h5Path'},
            ]
            for(let item of colomn){
                let input_content = form[item['prop']] ;
                if(!input_content || input_content == ''){
                    this.$message.error("您的"+item['label']+"尚未填写");
                    return false;
                }
            }
            return true;
        },
        async    submit(){  //提交表单
            if(!this.check_form(this.dialog.template)){
                return ;
            }
            let url = this.httpUrl+"tb-third-party-wang-redpacket-custom-advertise/"+this.dialog.type ;
            let type_map_requestMethod = {
                add:'POST',
                update:'PUT'
            };
            let response = await axios({
                url:url,
                data:this.dialog.template,
                method:type_map_requestMethod[this.dialog.type],
            })
            if(response.data.message){
                this.$message.success("操作成功");
                this.initialize_data();
            }else{
                this.$message.error("操作失败");
            }
            this.dialog.is_show = false ;
            this.initialize_data();
        },
        async   delete_item(item){  //删除某一项
            this.$confirm(
                '此操作将删除该模板, 是否继续?','提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }
            ).then(async()=>{
                let send_form_data = new FormData();
                send_form_data.append("key",item.key);
                let response = await    axios({
                    url:this.$httpUrl+'tb-third-party-wang-redpacket-custom-advertise/del',
                    method:'DELETE',
                    data:send_form_data,
                });
                if(response.data.message){
                    this.$message.success("操作成功");
                    this.initialize_data();
                }else{
                    this.$message.error("操作失败");
                }
                this.initialize_data();
            }).catch(
                
            )
        },
    },
    computed:{
            
    },
    created(){
        this.initialize_data();
    },
}
</script>

<style  lang="less" scoped>
.whole_container{
    background-color: #fafafa;
    border-radius: 10px;
    padding: 40px;
    flex: 1;
    display: flex;  flex-direction: column;
    .top_oprations{
        height: 70px;
        display: flex;  align-items: center;
    }
    .dialog{
        display: flex;  flex-direction: column;     align-items: center;
    }
    
}

</style>