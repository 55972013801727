<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <!-- 图片 -->
      <el-form-item label="图片" prop="img">
        <el-upload
          ref="upload"
          :auto-upload="false"
          class="avatar-uploader"
          :action="$httpUrl + 'smallRoutineGaoDeTemplateLabel/updateMesAndImg'"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
          :on-change="uploadChange"
          :data="{
            ...updateForm,
          }"
          :headers="headers"
        >
          <img v-if="updateForm.img" :src="updateForm.img" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <!-- 名称 -->
      <el-form-item label="名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入名称"
          maxlength="10"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 介绍 -->
      <el-form-item label="介绍" prop="mes">
        <el-input
          type="textarea"
          :rows="2"
          maxlength="100"
          show-word-limit
          v-model="updateForm.mes"
          placeholder="请输入介绍"
        >
        </el-input>
      </el-form-item>
      <!-- 跳转类型 -->
      <el-form-item label="跳转类型" prop="toType">
        <el-radio v-model="updateForm.toType" label="1">h5</el-radio>
        <el-radio v-model="updateForm.toType" label="2">服务页面</el-radio>
        <el-radio v-model="updateForm.toType" label="3">小程序</el-radio>
      </el-form-item>
      <!-- h5路径 -->
      <el-form-item label="h5路径" prop="toH5Url" v-if="updateForm.toType == 1">
        <el-input
          type="textarea"
          :rows="2"
          v-model="updateForm.toH5Url"
          placeholder="请输入跳转h5路径"
        >
        </el-input>
      </el-form-item>
      <!-- 跳转服务 -->
      <el-form-item
        label="跳转服务"
        prop="toServiceId"
        v-if="updateForm.toType == 2"
      >
        <el-select v-model="updateForm.toServiceId" placeholder="请选择服务">
          <el-option
            v-for="item in serviceList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <!-- 小程序appId -->
      <template v-if="updateForm.toType == 3">
        <el-form-item label="小程序appId" prop="toSmallRoutineAppId">
          <el-input
            v-model="updateForm.toSmallRoutineAppId"
            placeholder="请输入小程序appId"
            maxlength="64"
            show-word-limit
            clearable
          ></el-input>
        </el-form-item>
        <!-- 小程序appId -->
        <el-form-item label="小程序页面路径" prop="toSmallRoutinePath">
          <el-input
            v-model="updateForm.toSmallRoutinePath"
            placeholder="请输入小程序页面路径"
            clearable
          ></el-input>
        </el-form-item>
      </template>
      <!-- 排序 -->
      <el-form-item label="排序" prop="sort">
        <el-input
          v-model="updateForm.sort"
          placeholder="请输入排序,没填写默认99999999,值越小越靠前"
          maxlength="8"
          show-word-limit
          clearable
          @input="sortFormat"
        ></el-input>
      </el-form-item>
      <!-- 状态 -->
      <el-form-item label="状态" prop="status">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave" :disabled="disabled">
        确 定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomCommodityMenuTwoUpdate",
  props: ["updateId", "serviceList", "visible", "refaushList"],
  data() {
    return {
      // 编辑内容
      updateForm: {
        img: "",
      },
      // 表单验证
      updateFormRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        toType: [
          { required: true, message: "请选择跳转类型", trigger: "blur" },
        ],
        toH5Url: [
          { required: true, message: "请输入跳转h5路径", trigger: "blur" },
        ],
        toServiceId: [
          { required: true, message: "请选择服务", trigger: "change" },
        ],
        toSmallRoutineAppId: [
          { required: true, message: "请输入小程序appId", trigger: "blur" },
        ],
      },
      disabled: false,
      headers: { Authorization: window.sessionStorage.getItem("token") },
      // 是否有更新图片
      imgChange: false,
    };
  },
  created() {
    this.refaushForm(this.updateId);
  },
  watch: {
    visible(e) {
      if (e) {
        this.refaushForm(this.updateId);
      }
    },
  },
  methods: {
    // 清空表单
    resetForm() {
      this.disabled = false;
      this.imgChange = false;
      // 清除文件历史记录
      this.$refs.upload.clearFiles();
    },
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.sale && this.isNull(updateForm.saleUrl)) {
          return this.$message.error("商品售卖状态开启，路径不能为空");
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      this.disabled = true;
      let imgChange = this.imgChange;
      let that = this;
      let timeOutId = setTimeout(function () {
        that.disabled = false;
      }, 10000);
      if (imgChange) {
        // 图片有更换
        this.$refs.upload.submit();
      } else {
        // 图片没更换
        const { data: res } = await this.$http.put(
          "/smallRoutineGaoDeTemplateLabel/updateMes",
          {
            ...this.updateForm,
          }
        );
        this.disabled = false;
        if ("200" != res.code) {
          this.disabled = false;
          return this.$message.error(res.message);
        }
        this.$message.success("操作成功");
        this.refaushList();
      }
      // 清除定时器
      clearTimeout(timeOutId);
    },
    // 刷新编辑对象
    async refaushForm(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/smallRoutineGaoDeTemplateLabel/getUpdateMes?id=" + e
      );
      loading.close();
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      this.updateForm = res.message;
    },
    // 图片上传成功
    handleAvatarSuccess(res) {
      //上传成功之后清除历史记录
      this.disabled = false;
      if (res.code != 200) {
        return this.$message.error("文件上传失败：" + res.message);
      }
      this.$message.success("保存成功");
      this.refaushList();
    },
    // 图片格式与大小校验
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isGIF = file.type === "image/gif";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG && !isGIF) {
        this.$message.error("上传头像图片只能是 JPG、PNG、GIF 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      let res = (isJPG || isPNG || isGIF) && isLt2M;
      return res;
    },
    // 选择图片
    uploadChange(file, fileList) {
      if (fileList.length > 1) {
        // 多选删除之前选中的文件
        fileList.splice(0, 1);
        this.$refs.upload.uploadFiles = fileList;
      }
      let url;
      if (window.createObjectURL != undefined) {
        url = window.createObjectURL(file.raw);
      } else if (window.URL != undefined) {
        url = window.URL.createObjectURL(file.raw);
      } else if (window.webkitURL != undefined) {
        url = window.webkitURL.createObjectURL(file.raw);
      } else {
        url = URL.createObjectURL(file.raw);
      }
      this.updateForm.img = url;
      this.imgChange = true;
    },
    isNull(e) {
      return e == null || e == "" || e == undefined;
    },
    // 排序正则表达式
    sortFormat(e) {
      this.updateForm.sort =
        this.updateForm.sort.match(/^\d*(\d{0,0})/g)[0] || null;
      if (isNaN(this.updateForm.sort)) {
        this.updateForm.sort = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let sort = e.toString();
      if (sort.charAt(0) == "0" && this.updateForm.sort.length >= 2) {
        this.updateForm.sort = this.updateForm.sort.replace(/0/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
// 图片上传
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
// 底部按钮
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
