<template>
  <!-- 基础配置 -->
  <div class="div_home">
    <el-card>
      <el-form class="viewForm" label-width="auto">
        <el-divider content-position="left">店铺信息</el-divider>
        <el-form-item label="店铺名称">
          <template class="message_text">{{ message.shop_title }}</template>
        </el-form-item>
        <el-form-item label="卖家id">
          <template class="message_text">{{ message.seller_id }}</template>
        </el-form-item>
        <el-form-item label="商品短标题">
          <template class="message_text">{{ message.short_title }}</template>
        </el-form-item>
        <el-divider content-position="left">商品信息</el-divider>
        <el-form-item label="商品图片">
          <el-image
            class="commodityImg"
            :src="message.pict_url"
            :preview-src-list="[message.pict_url]"
          />
        </el-form-item>
        <el-form-item label="商品小图列表">
          <el-image
            v-for="(item, index) in message.small_images"
            :key="'small_images_' + index"
            class="commoditySmallImg"
            :src="item"
            :z-index="index"
            :preview-src-list="[...message.small_images]"
          />
        </el-form-item>
        <el-form-item label="宝贝id">
          <template class="message_text">{{ message.item_id }}</template>
        </el-form-item>
        <el-form-item label="商品标题">
          <template class="message_text">{{ message.title }}</template>
        </el-form-item>
        <el-form-item label="商品子标题">
          <template class="message_text">{{ message.sub_title }}</template>
        </el-form-item>
        <el-form-item label="宝贝描述">
          <template class="message_text">
            {{ message.item_description }}
          </template>
        </el-form-item>
        <el-form-item label="佣金比率(%)">
          <template class="message_text">
            {{ message.commission_rate }}
          </template>
        </el-form-item>
        <el-form-item label="一口价">
          <template class="message_text">{{ message.reserve_price }}</template>
        </el-form-item>
        <el-form-item label="30天销量">
          <template class="message_text">{{ message.volume }}</template>
        </el-form-item>
        <el-form-item label="折扣价（元）">
          <template class="message_text">{{ message.zk_final_price }}</template>
        </el-form-item>
        <el-form-item label="一级类目ID">
          <template class="message_text">
            {{ message.level_one_category_id }}
          </template>
        </el-form-item>
        <el-form-item label="一级类目名称">
          <template class="message_text">
            {{ message.level_one_category_name }}
          </template>
        </el-form-item>
        <el-form-item label="叶子类目id">
          <template class="message_text">{{ message.category_id }}</template>
        </el-form-item>
        <el-form-item label="叶子类目名称">
          <template class="message_text">{{ message.category_name }}</template>
        </el-form-item>
        <el-divider content-position="left">优惠券信息</el-divider>
        <el-form-item label="优惠券（元）">
          <template class="message_text">{{ message.coupon_amount }}</template>
        </el-form-item>
        <el-form-item label="优惠券起用门槛">
          <template class="message_text">
            {{ message.coupon_start_fee }}
          </template>
        </el-form-item>
        <el-form-item
          label="优惠券结束时间"
          v-show="message.coupon_end_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.coupon_end_time) }}
          </template>
        </el-form-item>
        <el-form-item label="优惠券总量">
          <template class="message_text">
            {{ message.coupon_total_count }}
          </template>
        </el-form-item>
        <el-form-item label="优惠券剩余量">
          <template class="message_text">
            {{ message.coupon_remain_count }}
          </template>
        </el-form-item>
        <el-form-item
          label="优惠券开始时间"
          v-show="message.coupon_start_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.coupon_start_time) }}
          </template>
        </el-form-item>
        <el-form-item label="卖家昵称">
          <template class="message_text">{{ message.nick }}</template>
        </el-form-item>
        <el-form-item label="定金（元）">
          <template class="message_text">
            {{ message.presale_deposit }}
          </template>
        </el-form-item>
        <el-divider content-position="left">预售商品</el-divider>
        <el-form-item
          label="付定金结束时间"
          v-show="message.presale_end_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.presale_end_time) }}
          </template>
        </el-form-item>
        <el-form-item
          label="付定金开始时间"
          v-show="message.presale_start_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.presale_start_time) }}
          </template>
        </el-form-item>
        <el-form-item
          label="付尾款结束时间"
          v-show="message.presale_tail_end_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.presale_tail_end_time) }}
          </template>
        </el-form-item>
        <el-form-item
          label="付尾款开始时间"
          v-show="message.presale_tail_start_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.presale_tail_start_time) }}
          </template>
        </el-form-item>
        <el-divider content-position="left">天猫限时抢可售</el-divider>
        <el-form-item
          label="开始时间"
          v-show="message.tmall_play_activity_start_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.tmall_play_activity_start_time) }}
          </template>
        </el-form-item>
        <el-form-item
          label="结束时间"
          v-show="message.tmall_play_activity_end_time != 0"
        >
          <template class="message_text">
            {{ getLocalTime(message.tmall_play_activity_end_time) }}
          </template>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["mes"],
  data() {
    return {
      message: {},
    };
  },
  created() {
    this.init(this.mes);
  },
  methods: {
    async init(e) {
      this.message = e;
    },
    // 时间戳转时间
    getLocalTime(nS) {
      return new Date(parseInt(nS)).toLocaleString().replace(/:\d{1,2}$/, " ");
    },
  },
};
</script>

<style lang="less" scoped>
.commodityImg {
  width: 200px;
  height: 200px;
}
.commoditySmallImg {
  width: 100px;
  height: 100px;
}
</style>
