<template>
  <div>
    <el-form
      :model="updateForm"
      :rules="updateFormRules"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-form-item label="客户" prop="customerId">
        <el-select v-model="updateForm.customerId" placeholder="请选择">
          <el-option
            v-for="item in customerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告顺序" prop="sort">
        <el-input
          v-model="updateForm.sort"
          placeholder="请输入广告顺序，值越小用户越早拉取，默认最大值99999999"
          maxlength="8"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告名称" prop="name">
        <el-input
          v-model="updateForm.name"
          placeholder="请输入广告名称"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告文案" prop="copyWriting">
        <el-input
          v-model="updateForm.copyWriting"
          placeholder="请输入广告文案"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="副标题" prop="subtitle">
        <el-input
          v-model="updateForm.subtitle"
          placeholder="请输入副标题"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="按钮文字" prop="buttonText">
        <el-input
          v-model="updateForm.buttonText"
          placeholder="请输入按钮文字"
          maxlength="4"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" prop="icon">
        <el-upload
          class="avatar-uploader"
          :action="$httpUrl + 'upload/uploadFile'"
          :show-file-list="false"
          :on-success="
            (value) => {
              iconUploadSuccess(value);
            }
          "
          :on-change="onChangeUpload"
          :before-upload="beforeAvatarUpload"
          :headers="headerObj"
        >
          <img class="avatar" v-if="updateForm.icon" :src="updateForm.icon" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="广告量" prop="quantity">
        <el-input
          v-model="updateForm.quantity"
          placeholder="请输入广告量"
          maxlength="8"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="总价" prop="totalPrice">
        <el-input
          v-model="updateForm.totalPrice"
          placeholder="请输入总价"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatTotalPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="单价" prop="unitPrice">
        <el-input
          v-model="updateForm.unitPrice"
          placeholder="请输入单价"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatUnitPrice"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序类型选择" prop="appletType">
        <small_routine_select
              v-if="is_get_data"
              :appId.sync="updateForm.appId"
              :isAnyLevel="true"
              :batchClassId.sync="updateForm.batchClassId"
              :appletType.sync ="updateForm.appletType"
              :array_of_type_batch_id="array_of_type_batch_id"
        >
        </small_routine_select>
      </el-form-item>
      <el-form-item label="用户佣金" prop="commission">
        <el-input
          v-model="updateForm.commission"
          placeholder="请输入用户佣金"
          maxlength="12"
          show-word-limit
          clearable
          @input="priceFormatCommission"
        ></el-input>
      </el-form-item>
      <el-form-item label="浏览时间(秒)" prop="browseTime">
        <el-input
          v-model="updateForm.browseTime"
          placeholder="请输入浏览时间"
          maxlength="4"
          show-word-limit
          clearable
          @input="priceFormatBrowseTime"
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序appId" prop="jumpAppId">
        <el-input
          v-model="updateForm.jumpAppId"
          placeholder="请输入小程序appId"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="小程序path" prop="appletPath">
        <el-input
          v-model="updateForm.appletPath"
          placeholder="请输入小程序path"
          maxlength="100"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="h5_url" prop="h5Url">
        <el-input
          v-model="updateForm.h5Url"
          placeholder="请输入h5_url"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="生活号id" prop="lifeNumber">
        <el-input
          v-model="updateForm.lifeNumber"
          placeholder="请输入生活号id"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="广告客户端" prop="advertisingClient">
        <el-select v-model="updateForm.advertisingClient" placeholder="请选择">
          <el-option
            v-for="item in clientList"
            :key="item.value"
            :label="item.label"
            :value="item.value + ''"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="广告投放起始时间" prop="startDate">
        <el-date-picker
          v-model="updateForm.startDate"
          type="date"
          placeholder="广告投放起始时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="广告终止时间" prop="endDate">
        <el-date-picker
          v-model="updateForm.endDate"
          type="date"
          placeholder="广告终止时间"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="每天投放开始时间段" prop="startTime">
        <el-time-picker
          v-model="updateForm.startTime"
          placeholder="每天投放开始时间段"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="每天投放结束时间段" prop="endTime">
        <el-time-picker
          v-model="updateForm.endTime"
          placeholder="每天投放结束时间段"
        >
        </el-time-picker>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="updateForm.remark"
          placeholder="请输入备注"
          clearable
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <!-- 发放佣金 -->
      <el-form-item label="发放佣金" prop="commissionPayment">
        <el-switch v-model="updateForm.commissionPayment"> </el-switch>
      </el-form-item>
      <el-form-item label="是否开启" prop="remark">
        <el-switch v-model="updateForm.status"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>

<script>
import small_routine_select
  from "@/components/thirdParty/other/redpacket/menuPage/defaultConfig/data_table/small_routine_select.vue";

export default {
  name: "informationUpdate",
  components:{
    small_routine_select
  },
  props: ["updateId", "refaushList", "customerList", "clientList"],
  data() {
    return {
      // 编辑内容
      updateForm: {},
      // 表单验证
      updateFormRules: {
        customerId: [
          { required: true, message: "请选择客户", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入名称", trigger: "blur" },
          {
            min: 1,
            max: 100,
            message: "长度在 1 到 100 个字符",
            trigger: "blur",
          },
        ],
        copyWriting: [
          { required: true, message: "请输入广告文案", trigger: "blur" },
        ],
        buttonText: [
          { required: true, message: "请输入按钮文字", trigger: "blur" },
        ],
        icon: [{ required: true, message: "请上传图片", trigger: "blur" }],
        quantity: [
          { required: true, message: "请输入广告量", trigger: "blur" },
        ],
        totalPrice: [
          { required: true, message: "请输入总价", trigger: "blur" },
        ],
        unitPrice: [{ required: true, message: "请输入单价", trigger: "blur" }],
        commission: [
          { required: true, message: "请输入用户佣金", trigger: "blur" },
        ],
        browseTime: [
          { required: true, message: "请输入浏览时间", trigger: "blur" },
        ],
        advertisingClient: [
          { required: true, message: "请选择广告客户端", trigger: "blur" },
        ],
        appletType:[{required:true,message:'请输入类别',trigger:'blur'}],
        startDate: [
          {
            required: true,
            message: "请选择广告投放起始时间",
            trigger: "blur",
          },
        ],
      },
      headerObj: { Authorization: window.sessionStorage.getItem("token") },
      showLoading: false, // 加载中
      appletTypeList:[],
      // 当数据加载完成时变成true
      is_get_data:false,
    };
  },
  created() {
    //加载小程序类别选项
    this.getAppletTypes();
    this.refaushForm(this.updateId);
  },
  computed:{
    array_of_type_batch_id:function (){
      let result = [];
      if(this.updateForm.appletType){
        result[0] = this.updateForm.appletType;
      }
      if(this.updateForm.batchClassId){
        result[1] = this.updateForm.batchClassId;
      }
      if(this.updateForm.appId){
        result[2] = this.updateForm.appId;
      }
      return result;
    }
  },
  methods: {
    // 点击提交
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        let advertisingClient = updateForm.advertisingClient;
        if (1 == advertisingClient) {
          // h5
          if (updateForm.h5Url == "") {
            return this.$message.error("请输入h5_url");
          }
        } else if (2 == advertisingClient) {
          // 支付宝
          if (updateForm.appId == "") {
            return this.$message.error("请输入小程序appId与小程序path");
          }
        } else if (3 == advertisingClient) {
          // 生活号
          if (updateForm.lifeNumber == "") {
            return this.$message.error("请输入生活号id");
          }
        } else {
          return this.$message.error("请选择广告客户端");
        }
        this.save();
      });
    },
    // 发起请求
    async save() {
      const { data: res } = await this.$http.put(
        "/advertisementInformation/update",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.refaushList();
    },
    // 手机号校验
    chechPhone(phone) {
      var regExp = new RegExp("^1[3578]\\d{9}$");
      return regExp.test(phone);
    },
    // 刷新编辑对象
    async refaushForm(e) {
      this.is_get_data = false ;
      // 根据id获取数据
      const { data: res } = await this.$http.get(
        "/advertisementInformation/getById?id=" + e
      );
      if (res.code != 200) {
        return this.$message.error(res.message);
      }
      let message = res.message;
      let startTimeText = message.startTimeText;
      let endTimeText = message.endTimeText;
      if (!this.isnull(startTimeText)) {
        let time = startTimeText.split(":");
        message.startTime = new Date(2016, 9, 10, time[0], time[1], time[2]);
      }
      if (!this.isnull(endTimeText)) {
        let time = endTimeText.split(":");
        message.endTime = new Date(2016, 9, 10, time[0], time[1], time[2]);
      }
      this.updateForm = message;
      this.is_get_data = true;
    },
    onChangeUpload() {
      this.showLoading = !this.showLoading;
    },
    // 图片上传前
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      const maxSize = file.size / 1024 / 1024 < 10;
      if (!maxSize) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return maxSize;
    },
    // 图片上传成功
    iconUploadSuccess(e) {
      if (e.code != 200) {
        return this.$message.error("图片上传失败");
      }
      this.updateForm.icon = e.message;
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
    // 金额正则表达式
    priceFormatTotalPrice(e) {
      this.updateForm.totalPrice =
        this.updateForm.totalPrice.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.updateForm.totalPrice)) {
        this.updateForm.totalPrice = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.updateForm.totalPrice.length >= 2
      ) {
        this.updateForm.totalPrice = this.updateForm.totalPrice.replace(
          /0/,
          ""
        );
      }
    },
    priceFormatUnitPrice(e) {
      this.updateForm.unitPrice =
        this.updateForm.unitPrice.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.updateForm.unitPrice)) {
        this.updateForm.unitPrice = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.updateForm.unitPrice.length >= 2
      ) {
        this.updateForm.unitPrice = this.updateForm.unitPrice.replace(/0/, "");
      }
    },
    priceFormatCommission(e) {
      this.updateForm.commission =
        this.updateForm.commission.match(/^\d*(\.?\d{0,2})/g)[0] || null;
      if (isNaN(this.updateForm.commission)) {
        this.updateForm.commission = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.updateForm.commission.length >= 2
      ) {
        this.updateForm.commission = this.updateForm.commission.replace(
          /0/,
          ""
        );
      }
    },
    // 获取小程序种类列表
    async getAppletTypes(){
      const {data:res} = await this.$http.get(
        "/thirdPartySmallRoutineType/getAllTypeWithAllOption"
      );
      if('200'!=res.code){
        return this.$message.error(res.message);
      }
      this.appletTypeList = res.message;
    },
    priceFormatBrowseTime(e) {
      this.updateForm.browseTime =
        this.updateForm.browseTime.match(/^\d*/g)[0] || null;
      if (isNaN(this.updateForm.browseTime)) {
        this.updateForm.browseTime = "";
      }
      // 在不是“0.”开头的字符进行修改：“01”=>1
      let price = e.toString();
      if (
        price.charAt(0) == "0" &&
        price.charAt(1) != "." &&
        this.updateForm.browseTime.length >= 2
      ) {
        this.updateForm.browseTime = this.updateForm.browseTime.replace(
          /0/,
          ""
        );
      }
    },
  },
};
</script>

<style lang="less" scoped>
.buttomButton {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar-uploader {
  .el-upload {
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
