// 接口请求地址
const path = 'alipayOpenFile/';
import axios from 'axios'

/**
 * 分页查询
 */
export async function list(data = {}) {
  const { data: res } = await axios.post(
    path + 'list',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 修改状态
 */
 export async function updateStatus(data = {}) {
  const { data: res } = await axios.put(
    path + 'updateStatus',
    data
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 删除
 */
 export async function del(id) {
  const { data: res } = await axios.delete(
    path + 'del?id='+id
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

/**
 * 获取appId上传的所有图片
 */
 export async function getAllListByAppId(appId) {
  const { data: res } = await axios.get(
    path + 'getAllListByAppId?appId='+appId
  );
  let message = res.message;
  if (res.code != 200) {
    return {
      success: false,
      err: res.message
    };
  }
  return {
    success: true,
    data:message,
  };
}

