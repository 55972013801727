<template>
  <div class="div_home">
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo home_menu"
      mode="horizontal"
      background-color="#373d41"
      text-color="#ffffff"
      active-text-color="#ffd04b"
      @select="handleSelect"
    >
      <el-menu-item index="1">基础配置</el-menu-item>
      <el-menu-item index="2">物料管理</el-menu-item>
      <el-menu-item index="3">商品新增</el-menu-item>
      <el-menu-item index="4">商品管理</el-menu-item>
    </el-menu>
    <basicConfiguration
      v-show="activeIndex == '1'"
      :materialList="materialList"
    />
    <materialManagement
      v-show="activeIndex == '2'"
      :getMaterialList="getMaterialList"
    />
    <newProduct v-show="activeIndex == '3'" :materialList="materialList" />
    <productController
      v-show="activeIndex == '4'"
      :materialList="materialList"
    />
  </div>
</template>

<script>
import basicConfiguration from "./basicConfiguration/basicConfiguration.vue";
import materialManagement from "./materialManagement/materialManagement.vue";
import newProduct from "./newProduct/newProduct.vue";
import productController from "./productController/productController.vue";
export default {
  components: {
    basicConfiguration,
    materialManagement,
    newProduct,
    productController,
  },
  data() {
    return {
      // 默认展示模块
      activeIndex: "1",
      // 物料列表
      materialList: [],
    };
  },
  created() {
    this.getMaterialList();
  },
  methods: {
    // 顶部菜单变化
    handleSelect(e) {
      this.activeIndex = e + "";
    },
    // 获取物料列表
    async getMaterialList() {
      const { data: res } = await this.$http.get(
        "taoBaoCommodityMaterial/getAllMaterial"
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.materialList = res.message;
    },
  },
};
</script>

<style lang="less" scoped>
.div_home {
  height: 99%;
  .home_menu {
    position: fixed;
    top: 0;
  }
  .commodityImg {
    width: 100px;
    height: 100px;
  }
  .div_input {
    display: flex;
    align-items: center;
    .div_input_text {
      white-space: nowrap;
      padding-right: 3px;
    }
  }
}
</style>
