<template>
  <div class="div_main">
    <div style="margin: 20px"></div>
    <el-form
      class="elForm"
      ref="formRef"
      :model="form"
      label-width="auto"
      :rules="formRules"
      :loading="formIsSubmit"
    >
      <el-form-item label="模板名称" prop="name">
        <el-input
          v-model="form.name"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="模板appId" prop="appId">
        <el-input
          v-model="form.appId"
          maxlength="50"
          show-word-limit
          placeholder="请输入模板appId"
        ></el-input>
      </el-form-item>
      <el-form-item label="授权token" prop="appAuthToken">
        <el-input
          v-model="form.appAuthToken"
          maxlength="50"
          show-word-limit
          placeholder="请输入授权token"
        ></el-input>
      </el-form-item>
      <el-form-item label="第三方应用" prop="applicationsId">
        <el-select
          v-model="form.applicationsId"
          placeholder="请选择关联的第三方应用"
        >
          <el-option
            v-for="item in applications"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板类型" prop="appletType">
        <el-select v-model="form.appletType" placeholder="请选择小程序类型">
          <el-option
            v-for="item in smallRoutineTypes"
            :key="item.id"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <category ref="categoryRef" :applicationsId="form.applicationsId" />
      <el-form-item label="线下授权链接" prop="offlineLink">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="form.offlineLink"
          placeholder="请输入线下授权链接(用于生成推广链接)复制到{标识符}前即可，要保留‘=’号"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <!-- 备注 -->
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          placeholder="请输入备注"
          clearable
          maxlength="150"
          show-word-limit
          type="textarea"
          :rows="2"
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-switch v-model="form.status"></el-switch>
      </el-form-item>
      <el-divider content-position="left">
        模板关联的小程序提交审核时的默认填充内容
      </el-divider>
      <el-form-item label="应用简介" prop="appSlogan">
        <el-input
          class="div_input_single"
          v-model="form.appSlogan"
          placeholder="请输入小程序应用简介"
          maxlength="32"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="客服电话" prop="servicePhone">
        <el-input
          class="div_input_single"
          v-model="form.servicePhone"
          placeholder="请输入客服电话"
          maxlength="30"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="logo图标" prop="appLogo">
        <el-upload
          ref="appLogoUploadRef"
          :action="$httpUrl + 'upload/smallRoutineTemplateUploadFile'"
          :show-file-list="false"
          :headers="headerObj"
          :before-upload="beforeAvatarUpload"
          :on-success="handleAvatarSuccess"
          :data="{
                  appId: form.appId

                }"
        >
          <img
            v-if="chooseImages.appLogo"
            :src="chooseImages.appLogo"
            class="imgAppLogo"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="小程序描述" prop="appDesc">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="form.appDesc"
          placeholder="请输入版本描述"
          :rows="3"
          maxlength="200"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="版本描述" prop="versionDesc">
        <el-input
          type="textarea"
          class="div_input_single"
          v-model="form.versionDesc"
          placeholder="请输入版本描述"
          :rows="3"
          maxlength="500"
          show-word-limit
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formRef')">
          新建
        </el-button>
        <el-button @click="resetForm('formRef')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import category from "../category/category.vue";
export default {
  props: ["refreshData", "applications", "smallRoutineTypes"],
  name: "applet_add",
  components: {
    category,
  },
  data() {
    return {
      form: {
        name: "",
        appId: "",
        appAuthToken: "",
        isvAppId: "",
        isvCode: "",
        status: true,
        // 小程序类型选择
        isvType: "",
        // 线下授权链接
        offlineLink: "",
        appLogo:null,//logo显示的地方
      },
      // 表格提交验证
      formRules: {
        name: [{ required: true, message: "请输入模板名称", trigger: "blur" }],
        appId: [
          { required: true, message: "请输入模板appId", trigger: "blur" },
        ],
        appAuthToken: [
          { required: true, message: "请输入授权token", trigger: "blur" },
        ],
        applicationsId: [
          {
            required: true,
            message: "请选择关联的第三方应用",
            trigger: "change",
          },
        ],
        appletType: [
          {
            required: true,
            message: "请选择模板类型",
            trigger: "change",
          },
        ],
      },
      // 表单是否提交中
      formIsSubmit: false,

      // 选中的图片临时路径（logo图片)
      chooseImages: {
        // 本地路径
        appLogo: "",
        // 服务器路径
        appLogoHttp: "",
      },
    };
  },
  created() {},
  methods: {
    // 表单提交
    submitForm(formName) {
      // 表单提交信息校验
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormMes();
        } else {
          return false;
        }
      });
    },
    async submitFormMes() {
      let form = this.form;
      let offlineLink = form.offlineLink;
      if (!this.isNull(offlineLink)) {
        let split = offlineLink.split("&ticket=");
        if ((split.length1 = 2 && split[1] != "")) {
          return this.$message.warning(
            "线下授权格式校验失败,请以“&ticket=”结尾"
          );
        }
      }
      let category = this.$refs.categoryRef.getChooseCategoryMes();
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { data: res } = await this.$http.post("thirdPartyTemplate/add", {
        ...form,
        category: category,
      });
      loading.close();
      if (res.code != 200) {
        this.$message.error("上传失败:" + res.message);
        return;
      }
      this.$message.success("操作成功");
      this.resetForm("formRef");
      // 刷新商品页面信息
      this.refreshData();
      // 清除类目选中信息
      this.$refs.categoryRef.clearCategoryAll();
    },
    // 清空表单
    resetForm(formName) {
      // 清除form表单内容
      this.$refs[formName].resetFields();
    },
    // 获取小程序名称与标识列表
    async getSmallRoutineList(e) {
      let res;
      switch (e) {
        case "applet1":
          res = await this.$http.get("/smallRoutine/getSmallNameCodeList");
          break;
        case "applet2":
          res = await this.$http.get("/smallRoutine2/getSmallNameCodeList");
          break;
        case "applet3":
          res = await this.$http.get("/smallRoutine3/getSmallNameCodeList");
          break;
        default:
          this.$message.error("获取小程序名称与标识列表,无效标识:" + e);
          return null;
      }
      return res.data.message;
    },
    isNull(e) {
      return e == null || e == undefined || e == "";
    },

    //下面是关于logo图片的代码
      //logo图片上传前的处理
      beforeAvatarUpload(file) {
        switch (file.type) {
          case "image/jpeg":
          case "image/png":
          case "image/jpg":
          case "image/PNG":
          case "image/JPG":
          case "image/JPEG":
            // 图片格式校验
            break;
          default:
            this.$message.error("上传头像图片只能是 jpeg,png,jpg 格式!");
            return false;
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
          return this.$message.error("上传头像图片大小不能超过 2MB!");
        }
        //检查appId有没有填写
        if(this.form.appId == '')
        {  return this.$message.error('您的appId尚未填写'); }
        return isLt2M;
      },
      async handleChange(file, fileList) {
        if (!this.beforeAvatarUpload(file.raw)) {
          return;
        }
        if (fileList.length == 0) {
          // 清空选中列表
          return;
        }
        if (fileList.length > 1) {
          // 多选删除之前选中的文件
          fileList.splice(0, 1);
          this.$refs.appLogoUploadRef.uploadFiles = fileList;
        }
        let url;
        if (window.createObjectURL != undefined) {
          url = window.createObjectURL(file.raw);
        } else if (window.URL != undefined) {
          url = window.URL.createObjectURL(file.raw);
        } else if (window.webkitURL != undefined) {
          url = window.webkitURL.createObjectURL(file.raw);
        } else {
          url = URL.createObjectURL(file.raw);
        }
        this.chooseImages.appLogo = url;
        // this.getArrayBuffer(file.raw);
      },
      // logo图片上传成功
      handleAvatarSuccess(e) {
        let message = e.message;
        if (e.code != 200) {
          this.$message.error(message);
          return;
        }
        this.chooseImages.appLogo = this.$fileUrl + message;
        this.form.appLogo = message;
      },


  },
};
</script>

<style lang="less" scoped>
// 文件上传
.avatar-uploader-icon,
.imgAppLogo {
  height: 100px;
  width: 100px;
}
.avatar-uploader-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d9d9d9;
  font-size: 25px;
}
</style>
