import { render, staticRenderFns } from "./smallRoutine2.vue?vue&type=template&id=152a78e2&scoped=true&"
import script from "./smallRoutine2.vue?vue&type=script&lang=js&"
export * from "./smallRoutine2.vue?vue&type=script&lang=js&"
import style0 from "./smallRoutine2.vue?vue&type=style&index=0&id=152a78e2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "152a78e2",
  null
  
)

export default component.exports