<template>
  <div class="div_main">
    <el-form
      :model="updateForm"
      ref="updateFormRed"
      label-width="auto"
      v-loading="showLoading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <el-divider content-position="left">首页自动跳转</el-divider>
      <!-- 跳转h5 -->
      <el-form-item label="跳转h5" prop="jumpToH5">
        <el-input
          v-model="updateForm.jumpToH5"
          placeholder="请输入跳转h5"
          clearable
        ></el-input>
      </el-form-item>
      <!-- 是否开启 -->
      <el-form-item label="跳转h5" prop="jumpToH5Status">
        <el-switch v-model="updateForm.jumpToH5Status"> </el-switch>
      </el-form-item>
      <el-divider content-position="left">其他功能</el-divider>
      <!-- 灯火广告 -->
      <el-form-item label="灯火广告" prop="lightAdStatus">
        <el-switch v-model="updateForm.lightAdStatus"> </el-switch>
      </el-form-item>
    </el-form>
    <!-- 底部按钮 -->
    <div class="buttomButton">
      <el-button type="primary" @click="tosave">确 定</el-button>
    </div>
  </div>
</template>
<script>
export default {
  props: ["code"],
  name: "smallroutineConfiguration",
  data() {
    return {
      updateForm: {},
      showLoading: false,
    };
  },
  created(e) {
    this.init(e);
  },
  methods: {
    init(e) {
      if (e != undefined) {
        this.getMes(e);
      } else {
        this.getMes(this.code);
      }
    },
    async getMes(code) {
      this.showLoading = true;
      const { data: res } = await this.$http.get(
        `/smallRoutineConfiguration/getConfiguration?code=` + code
      );
      this.showLoading = false;
      if (res.code != "200") {
        return this.$message.error(res.message);
      }
      this.updateForm = res.message;
    },
    tosave() {
      this.$refs["updateFormRed"].validate((valid) => {
        if (!valid) {
          return;
        }
        let updateForm = this.updateForm;
        if (updateForm.jumpToH5Status && this.isnull(updateForm.jumpToH5)) {
          return this.$message.error(
            "首页自动跳转开关开启时跳转的h5路径不能为空"
          );
        }
        this.save();
      });
    },
    async save() {
      const { data: res } = await this.$http.post(
        "/smallRoutineConfiguration/updateConfiguration",
        {
          ...this.updateForm,
        }
      );
      if ("200" != res.code) {
        return this.$message.error(res.message);
      }
      this.$message.success("操作成功");
      this.$emit("refreshTable");
    },
    isnull(e) {
      return e == null || e == undefined || e == "";
    },
  },
};
</script>

<style lang="less" scoped></style>
