<template>
  <div class="gaoDeLinShi_main">
    <el-card>
      <el-table :data="serviceList" border stripe>
        <el-table-column type="index" fixed></el-table-column>
        <el-table-column label="名称" prop="name"></el-table-column>
        <el-table-column label="首页展示" prop="status">
          <!-- 作用域插槽 -->
          <template slot-scope="scope">
            <!-- {{scope.row}}每一行封装的数据 -->
            <el-switch
              v-model="scope.row.status"
              @change="updateStatus(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 新增信息 -->
    <!-- <el-dialog title="添加" :visible.sync="addDialogVisible" width="50%">
    </el-dialog> -->
  </div>
</template>

<script>
import { getAllServiceIdAndName } from "@/services/smallRoutineService.js";
import {
  getShowServiceIdList,
  updateIndexShowService,
} from "@/services/tikTokConfiguration.js";
export default {
  name: "tikTokConfiguration",
  components: {},
  data() {
    return {
      // 服务列表
      serviceList: [],
      showServiceIdList: [],
    };
  },
  created() {
    this.init();
  },
  methods: {
    // 初始化
    async init() {
      // 获取服务列表
      await this.toGetShowServiceIdList();
    },
    // 获取首页展示内容
    async toGetShowServiceIdList() {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await getShowServiceIdList();
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$set(this, "showServiceIdList", res.data);
      await this.getServiceList();
    },
    // 获取服务列表
    async getServiceList() {
      let showServiceIdList = this.showServiceIdList;
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await getAllServiceIdAndName();
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      let serviceList = res.data;
      let length = serviceList.length;
      if (showServiceIdList.length > 0 && length > 0) {
        for (let i = 0; i < length; i++) {
          let single = serviceList[i];
          single.status = showServiceIdList.indexOf(single.id) != -1;
        }
      }
      this.$set(this, "serviceList", res.data);
    },
    async updateStatus(e) {
      const loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await updateIndexShowService({
        serviceId: e.id,
        status: e.status,
      });
      loading.close();
      if (!res.success) {
        return this.$message.error(res.err);
      }
      this.$message.success("成功");
    },
  },
};
</script>

<style lang="less" scoped>
.gaoDeLinShi_main {
  .gaoDeLinShi_title {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .gaoDeLinShi_title_text {
      margin: 0 10px 0 20px;
    }
    .headButtonSingle {
      margin-left: 10px;
    }
  }
}
</style>
